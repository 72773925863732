import { EPlan } from "@/shared/lib/types";
import { EProcessStatus } from "@/entities/Process";

export interface ITeamListSearch {
    admin: string;
    countOfUsers: number;
    name: string;
    teamId: string;
}

export interface IMember {
    id: string;
    lastLogin: null | string | Date;
    memberRights: ETypeRightsParticipant;
    memberStatus: EValueRightsParticipant; // ENUM
    user: string;
}

export interface ITeamForm {
    name: string;
    rights: EValueRightsParticipant;
    users: string[];
}

export interface ITeam {
    allowedDomain: string | null;
    apiKey: string;
    iamAdmin: boolean;
    id: string;
    members: IMember[];
    name: string;
    teamDiagramId: null | string;
    teamDiagramName: null | string;
    currentInvoice: ICurrentInvoice;
}
export interface ICurrentInvoice {
    externalId: string;
    createdOn: string;
    validTo: string;
    amount: null;
    teamName: string;
    card: any;
    invoiceStatus: string; // ENUM
    membersCount: number;
    paymentPlan: EPlan;
    paymentInterval: string;
}

export interface ITeamSetting {
    setting: string;
    value: string;
}

export enum ESettingsName {
    SCORING = "scoringSettings",
    MIN_DIAGRAM_SCORE = "minimumDiagramScore",
    MIN_PERCENT_OF_ASSIGNEES = "minumumPercentOfAssignees",
    MIN_PERCENT_OF_SYSTEMS = "minumumPercentOfSystems",
    MIN_PERCENT_OF_DOCUMENTS = "minumumPercentOfDocuments",
    FOLDER_REQURIED = "folderRequried",
}

export interface IGetAdminTeam {
    totalElements: number;
    totalPages: number;
    content: IContent[];
}

export interface IContent {
    name: string;
    id: string;
    iamAdmin: boolean;
    teamDiagramId: any;
    teamDiagramName: any;
    allowedDomain: any;
    apiKey?: string;
    members: IMember[];
}

export interface ISendInvitedColleague {
    recaptchaToken?: string;
    rights: ETypeRightsParticipant | null;
    users: string[];
}

export interface IPayloadChangeRights {
    membersId: string[];
    newRights?: ETypeRightsParticipant;
}

export interface IDiagramOption {
    text: string;
    label: string;
    id: number;
    key: string;
    diagramId: string;
    status: EProcessStatus;
    updatedBy: string;
    updatedOn: string;
    pid: number;
    activesecurecount: number;
    waitsecurecount: number;
    isLeaf: boolean;
    noParentFolder: boolean;
    children: any[];
}

export interface IMyTemplate {
    id: string;
    name: string;
    authorEmail: string;
    createdOn: string;
    fileUrl: string;
    type: ETypeTemplate;
}

export type TTypeStatusParticipant = keyof typeof ETypeStatusParticipant;
export type TTypeRightsParticipant = keyof typeof ETypeRightsParticipant;
export type TTypeTemplate = keyof typeof ETypeTemplate;

export enum ETypeStatusParticipant {
    ADMIN = "Админ",
    MEMBER = "Участник",
    INVITED = "Приглашен",
}
export enum ETypeRightsParticipant {
    ADMIN = "Админ",
    READ = "Только чтение",
    WRITE = "Чтение и изменение",
}
export enum EValueRightsParticipant {
    ADMIN = "ADMIN",
    READ = "READ",
    WRITE = "WRITE",
}
export enum ETypeTemplate {
    PROCESS = "Процесс",
    ASSET = "Элемент архитектуры",
    ASSIGNEE = "Роль",
}

export interface IPayloadUpdateAllowedDomain {
    name: string;
    apiKey: string;
    allowedDomain: string;
}

export interface ISettingsQuality {
    folderRequried: boolean;
    minimumDiagramScore: number;
    minumumPercentOfSystems: number;
    minumumPercentOfAssignees: number;
    minumumPercentOfDocuments: number;
}

export interface IPayloadSaveNewSettingsQuality {
    setting: ESettingsName;
    value: any;
    type?: ESettingsType;
    min?: number;
    max?: number;
    step?: number;
    default?: boolean;
}

export enum ETypeUpdateSettingsTeam {
    API = "API",
    NAME = "NAME",
    DOMAIN = "DOMAIN",
}
export enum ESettingsType {
    CHECKBOX = "checkbox",
    RANGE = "range",
}
export interface IAccessCategory {
    text: ETypeRightsParticipant;
    value: EValueRightsParticipant;
}
export enum ETypeTextTemplate {
    PROCESS = "Для процесса",
    ASSET = "Для элемента архитектуры",
    ASSIGNEE = "Для роли",
}
export enum ETypeValueTemplate {
    PROCESS = "PROCESS",
    ASSET = "ASSET",
    ASSIGNEE = "ASSIGNEE",
}
export enum ETypeValueRulle {
    ERROR = "Ошибка",
    INFO = "Небольшое замечание",
    WARNING = "Обратить внимание",
}
