<template>
    <span class="border-1 surface-border border-round surface-100 text-xs p-1 uppercase" :class="classes">
        <slot></slot>
    </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

interface IProps {
    icon?: string;
}
const props = defineProps<IProps>();

const classes = computed<string[]>(() => {
    const classes = [];
    if (props.icon) classes.push(props.icon);

    return classes;
});
</script>

<style scoped></style>
