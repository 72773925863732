<template>
    <div class="flex flex-column gap-1 relative">
        <Chips
            ref="tagRef"
            :modelValue="currentTags"
            input-class="w-full"
            :input-id="tagId"
            class="w-full"
            :placeholder="$t('add-tag')"
            :allowDuplicate="false"
            :pt="{
                container: () => ({
                    class: ['w-full gap-1'],
                }),
                token: () => ({
                    class: ['m-0'],
                }),
            }"
            @add="onAdd"
            @remove="onRemove"
            @input="onInput"
            @blur="onBlur"
        >
            <template #chip="slotProps">
                <span class="text-sm">{{ slotProps.value.text }}</span>
            </template>
        </Chips>
        <div class="menu" v-show="isShowMenu">
            <div class="menu__item" v-for="item in tagsForListbox" @click="item.command">{{ item.text }}</div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from "vue";
import { storeToRefs } from "pinia";

import type { ChipsAddEvent, ChipsRemoveEvent } from "primevue/chips";

import { useTagStore, type ITag } from "@/entities/Tag";

const tagStore = useTagStore();
const { tags } = storeToRefs(tagStore);

const currentTags = defineModel<ITag[]>({ required: true, default: [] });

const tagRef = ref();
const isShowMenu = ref(false);

const tagId = computed(() => `tagInput_${Math.random().toString(36).substring(2)}`);
const tagsForListbox = computed(() => {
    if (!tagRef.value?.inputValue) return [];

    const items = [];

    for (const item of tags.value) {
        if (item.text.toLowerCase().includes(String(tagRef.value.inputValue).toLowerCase())) {
            if (isCurrentTag(item)) continue;
            items.push({
                ...item,
                command: () => onSelectTag(item),
            });
        }
    }
    return items;
});

function onAdd(event: ChipsAddEvent): void {
    const lastValue: string = event.value.at(-1);

    currentTags.value.push({
        text: lastValue,
        id: Math.random(),
        style: "",
        color: "",
    });
}
function onRemove(event: ChipsRemoveEvent): void {
    const id: number = event.value[0].id;
    currentTags.value = currentTags.value.filter(item => item.id !== id);
}
function onSelectTag(tag: ITag) {
    const exist = isCurrentTag(tag);
    if (!exist) {
        currentTags.value.push(tag);
        clearInput(true);
    }
}
function onBlur() {
    setTimeout(() => {
        isShowMenu.value = false;
        clearInput();
    }, 100);
}
function onInput() {
    isShowMenu.value = tagsForListbox.value.length > 0;
}

function isCurrentTag(tag: ITag) {
    return currentTags.value.some(item => item.id === tag.id || item.text === tag.text);
}
function clearInput(isSetFocus = false) {
    tagRef.value.inputValue = "";
    const input: null | HTMLInputElement = document.querySelector(`#${tagId.value}`);
    if (input) {
        input.value = "";
        if (isSetFocus) input.focus();
    }
}
</script>

<style scoped lang="scss">
.menu {
    position: absolute;
    top: 100%;
    left: 0px;
    right: 0px;
    padding: 7px 5px;
    max-height: 130px;
    background-color: white;
    border-radius: 5px;
    margin-top: 1px;
    box-shadow:
        0px 4px 10px rgba(0, 0, 0, 0.03),
        0px 0px 2px rgba(0, 0, 0, 0.06),
        0px 2px 6px rgba(0, 0, 0, 0.12);
    z-index: 2;

    &__item {
        padding: 7px 10px;
        cursor: pointer;
        border-radius: 5px;

        &:hover {
            background-color: var(--surface-hover);
        }
    }
}
</style>
