<template>
    <div class="flex flex-column gap-3 align-items-start ml-2">
        <div class="flex gap-4 align-items-center">
            <Skeleton width="16rem" height="0.8rem" class="surface-300"></Skeleton>
            <div class="flex gap-2 text-sm align-items-center">
                <div class="flex gap-1 align-items-center">
                    <Skeleton width="1rem" height="0.6rem" class="surface-300"></Skeleton>
                    <Skeleton width="12rem" height="0.6rem" class="surface-300"></Skeleton>
                </div>
                <Skeleton width="7rem" height="0.6rem" class="surface-300"></Skeleton>
                <Skeleton width="7rem" height="0.6rem" class="surface-300"></Skeleton>
            </div>
        </div>
        <div class="flex gap-4 align-items-center">
            <div class="flex gap-2">
                <Skeleton width="5rem" height="1.2rem" class="surface-300"></Skeleton>
                <Skeleton width="6rem" height="1.2rem" class="surface-300"></Skeleton>
                <Skeleton width="5rem" height="1.2rem" class="surface-300"></Skeleton>
                <Skeleton width="6rem" height="1.2rem" class="surface-300"></Skeleton>
            </div>
            <Skeleton width="3rem" height="1.2rem" class="surface-300"></Skeleton>
            <Skeleton width="4rem" height="1.2rem" class="surface-300"></Skeleton>
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
