<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockPlansRef">
            <div v-html="data?.item?.headline"></div>
            <DataTable :value="data?.item?.features">
                <Column field="function" header="Функция" headerClass="font-bold" class="w-4">
                    <template #body="slotProps">
                        {{ slotProps.data.feature }}
                    </template>
                </Column>
                <Column field="personal" class="text-center">
                    <template #header>
                        <span class="w-full text-center">Personal</span>
                    </template>
                    <template #body="slotProps">
                        <div v-if="slotProps.data.personal === 'true' || slotProps.data.personal === 'false'">
                            <CheckMark v-if="slotProps.data.personal === 'true'" class="w-2rem" />
                        </div>
                        <div v-else>{{ slotProps.data.personal }}</div>
                    </template>
                </Column>
                <Column field="personalPro" class="text-center">
                    <template #header>
                        <span class="w-full text-center">Personal PRO</span>
                    </template>
                    <template #body="slotProps">
                        <div v-if="slotProps.data.personal_pro === 'true' || slotProps.data.personal_pro === 'false'">
                            <CheckMark v-if="slotProps.data.personal_pro === 'true'" class="w-2rem" />
                        </div>
                        <div v-else>{{ slotProps.data.personal_pro }}</div>
                    </template>
                </Column>
                <Column field="team" class="text-center">
                    <template #header>
                        <span class="w-full text-center">Team</span>
                    </template>
                    <template #body="slotProps">
                        <div v-if="slotProps.data.team === 'true' || slotProps.data.team === 'false'">
                            <CheckMark v-if="slotProps.data.team === 'true'" class="w-2rem" />
                        </div>
                        <div v-else>{{ slotProps.data.team }}</div>
                    </template>
                </Column>
                <Column field="enterprise" class="text-center">
                    <template #header>
                        <span class="w-full text-center">Enterprise</span>
                    </template>
                    <template #body="slotProps">
                        <div v-if="slotProps.data.enterprise === 'true' || slotProps.data.enterprise === 'false'">
                            <CheckMark v-if="slotProps.data.enterprise === 'true'" class="w-2rem" />
                        </div>
                        <div v-else>{{ slotProps.data.enterprise }}</div>
                    </template>
                </Column>
            </DataTable>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn } from "@/shared/ui/marketing";
import { CheckMark } from "@/shared/ui/icons";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockPlansRef = ref();

watch(blockPlansRef, () => {
    if (props.article) {
        emit("getHtml", blockPlansRef.value);
    }
});
</script>

<style scoped></style>
