import { type IBlock } from "@/entities/Interface";

export interface IDirectusResponse<Type> {
    data: Type;
}

export interface IDirectusNavigation {
    title: string;
    status: string;
    id: string;
    copyright: null | string;
    items: IDirectusNavigationItem[];
}

export interface IDirectusNavigationItem {
    id: string;
    sort: number;
    title: string;
    has_children: boolean;
    icon: null | string;
    label: string;
    type: null | EDirectusNavigationItemType;
    open_in_new_tab: boolean;
    external_url?: string;
    url: null | string;
    content: null | string;
    page: null | IDirectusNavigationItemPage;
    children: IDirectusNavigationItem[];
    parent: null | IDirectusNavigationItem;
    last?: boolean;
}

export enum EDirectusNavigationItemType {
    PAGE = "page",
    URL = "url",
}

export interface IDirectusNavigationItemPage {
    id: string;
    sort: number;
    title: string;
    permalink: string;
    blocks: IBlock[];
    seo: IDirectusPageSeo;
}

export interface IDirectusNavigationBlock {
    item: string;
    collection: string;
}

export interface IDirectusPageSeo {
    id: string;
    title: string;
    meta_desciription: string;
    canonical_url: string;
    no_index: boolean | null;
    no_follow: boolean | null;
    og_image: string;
    sitemap_change_frequency: null | string;
    sitemap_priority: null | string;
    og_title: string;
    og_description: string;
    og_type: string;
    og_sitename: string;
}

export interface IResponsePayCourse {
    id: number;
}

export interface IResponsePromocode {
    code: string;
    discount: number;
    id: number;
}

export interface IPost {
    id: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    content?: string;
    sort?: number;
    date_updated?: string;
    video_iframe_code?: string;
    video_url?: string;
    author: IAuthor;
    image?: ImagePost;
    seo?: ISeoPost;
    blocks: IBlock[];
    tag?: ITag;
}

export interface IAuthor {
    id: string;
    name: string;
    job_title: string;
    bio: string;
    image: ImagePost2;
}

export interface ImagePost2 {
    id: string;
    title: string;
    description?: string;
    width: number;
    height: number;
}

export interface ImagePost {
    id: string;
    title: string;
    description?: string;
    width: number;
    height: number;
}

export interface ISeoPost {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
    og_image: IOgImage;
}

export interface IOgImage {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IPostBlock {
    collection: string;
    sort: number;
    id: number;
    item: IItemBlockPost;
}

export interface IItemBlockPost {
    id: string;
    title: string;
    status?: string;
    headline?: string;
    content?: string;
    search?: boolean;
    sidebar_groupping?: boolean;
    elements?: number[];
    image?: string;
    background_color?: string;
    image_max_height?: number;
    image_position?: string;
    button_group?: string;
    logos?: number[];
    highlight?: string;
    features?: IFeaturePost[];
    counts?: ICountPost[];
    alternate_image_position?: boolean;
    show_step_number?: boolean;
    steps?: string[];
    testimonials?: number[];
    slides?: number[];
    sort: any;
    rows?: string[];
    alignment: any;
    members?: number[];
    items?: IItemBlockPost2[];
}

export interface IFeaturePost {
    feature?: string;
    personal?: string;
    personal_pro?: string;
    team?: string;
    enterprise?: string;
    Feature?: string;
    Miro?: boolean;
    STORM?: boolean;
    Visio?: boolean;
    DrawIo?: boolean;
    Bizagi?: boolean;
    Camunda?: boolean;
    Cawemo?: boolean;
    Aris?: boolean;
    BusinessStudio?: boolean;
}

export interface ICountPost {
    title: string;
    count: number;
}

export interface IItemBlockPost2 {
    name: string;
    description: string;
}

export interface ITag {
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline?: string;
    content?: string;
    seo?: ISeoPost2;
    posts: IPost2[];
    blocks: IBlock[];
}

export interface ISeoPost2 {
    id: string;
    og_image: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
}

export interface IPost2 {
    id: string;
    author: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    image?: string;
    content?: string;
    sort?: number;
    seo?: string;
    date_updated: string;
    tag: string;
    video_iframe_code?: string;
    video_url?: string;
    blocks: IBlock[];
}

export interface IBlockPost2 {
    Post_tags_id: string;
    collection: string;
    item: string;
    id: number;
}

export interface IGetTag {
    seo?: string;
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline?: string;
    content?: string;
    posts: string[];
    blocks: IBlock[];
}

export interface IgetBlogData {
    title: string;
    headline?: any;
    content?: any;
    id: number | string;
    featured_post?: IgetBlogFeaturedPost | undefined;
    seo: IgetBlogSeo2;
    blocks: IBlock[];
}

export interface IgetBlogFeaturedPost {
    id: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    content: string;
    sort: number;
    date_updated: string;
    video_iframe_code: any;
    video_url: any;
    author: IgetBlogAuthor;
    image: IgetBlogImage;
    seo: IgetBlogSeo;
    blocks: IBlock[];
    tag: IgetBlogTag;
}

export interface IgetBlogAuthor {
    id: string;
    name: string;
    job_title: string;
    image: string;
    bio: string;
}

export interface IgetBlogImage {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IgetBlogSeo {
    id: string;
    og_image: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
}

export interface IgetBlogBlock {
    item: string;
    collection: string;
    sort: number;
    id: number;
}

export interface IgetBlogTag {
    seo: string;
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    posts: string[];
    blocks: IBlock[];
}

export interface IgetBlogSeo2 {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: IgetBlogJsonData;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
    og_image: IgetBlogOgImage;
}

export interface IgetBlogJsonData {}

export interface IgetBlogOgImage {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface IgetBlogBlock2 {
    collection: string;
    item: IgetBlogItem;
}

export interface IgetBlogItem {
    image: any;
    id: string;
    background_color: string;
    title: string;
    headline: string;
    content: string;
    image_max_height: number;
}

export interface IGetArticleDaum {
    id: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    content: string;
    sort: number;
    date_updated: string;
    video_iframe_code: any;
    video_url: any;
    author: IGetArticleAuthor;
    image: IGetArticleImage2;
    seo?: IGetArticleSeo | null | undefined;
    blocks: IBlock[];
    tag: IGetArticleTag;
}

export interface IGetArticleAuthor {
    id: string;
    name: string;
    job_title: string;
    bio: string;
    image: IGetArticleImage;
}

export interface IGetArticleImage {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleImage2 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleSeo {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
    og_image: IGetArticleOgImage;
}

export interface IGetArticleOgImage {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleBlock {
    collection: string;
    sort: number;
    id: number;
    item: IGetArticleItem;
}

export interface IGetArticleItem {
    id: string;
    title: string;
    status: string;
    headline: any;
    content: any;
    search: any;
    sidebar_groupping: any;
    elements: IGetArticleElement[];
}

export interface IGetArticleElement {
    id: number;
    Bpmn_elements_id: IGetArticleBpmnElementsId;
}

export interface IGetArticleBpmnElementsId {
    id: string;
    date_updated: string;
    date_created: string;
    slug: string;
    original_name: string;
    base_class: string;
    bpmn_font_code: string;
    name: string;
    status: string;
    description: string;
    good_example_description: string;
    bad_example_description: string;
    sort: number;
    button_group: any;
    bad_example_img: IGetArticleBadExampleImg;
    good_example_img: IGetArticleGoodExampleImg;
}

export interface IGetArticleBadExampleImg {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface IGetArticleGoodExampleImg {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface IGetArticleTag {
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    seo: IGetArticleSeo2;
    posts: IGetArticlePost[];
    blocks: IBlock[];
}

export interface IGetArticleSeo2 {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
    og_image: IGetArticleOgImage2;
}

export interface IGetArticleOgImage2 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticlePost {
    id: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    content: string;
    sort: number;
    date_updated: string;
    video_iframe_code: any;
    video_url: any;
    author: IGetArticleAuthor2;
    image: IGetArticleImage4;
    seo: IGetArticleSeo3;
    blocks: IBlock[];
    tag: IGetArticleTag2;
}

export interface IGetArticleAuthor2 {
    id: string;
    name: string;
    job_title: string;
    bio: string;
    image: IGetArticleImage3;
}

export interface IGetArticleImage3 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleImage4 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleSeo3 {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
    og_image: IGetArticleOgImage3;
}

export interface IGetArticleOgImage3 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleBlock2 {
    collection: string;
    sort: number;
    id: number;
    item: IGetArticleItem2;
}

export interface IGetArticleItem2 {
    id: string;
    title: string;
    status: string;
    headline: any;
    content: any;
    search: any;
    sidebar_groupping: any;
    elements: IGetArticleElement2[];
}

export interface IGetArticleElement2 {
    id: number;
    Bpmn_elements_id: string;
}

export interface IGetArticleTag2 {
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    seo: IGetArticleSeo4;
    posts: IGetArticlePost2[];
    blocks: IBlock[];
}

export interface IGetArticleSeo4 {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
    og_image: IGetArticleOgImage4;
}

export interface IGetArticleOgImage4 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticlePost2 {
    id: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    content: string;
    sort: number;
    date_updated: string;
    video_iframe_code: any;
    video_url: any;
    author: IGetArticleAuthor3;
    image: IGetArticleImage5;
    seo: IGetArticleSeo5;
    blocks: IBlock[];
    tag: IGetArticleTag3;
}

export interface IGetArticleAuthor3 {
    id: string;
    name: string;
    job_title: string;
    image: string;
    bio: string;
}

export interface IGetArticleImage5 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleSeo5 {
    id: string;
    og_image: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
}

export interface IGetArticleBlock3 {
    item: string;
    collection: string;
    sort: number;
    id: number;
}

export interface IGetArticleTag3 {
    seo: string;
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    posts: string[];
    blocks: IBlock[];
}

export interface IGetArticleBlock4 {
    collection: string;
    id: number;
    Post_tags_id: IGetArticlePostTagsId;
    item: IGetArticleItem3;
}

export interface IGetArticlePostTagsId {
    seo: string;
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    posts: string[];
    blocks: IBlock[];
}

export interface IGetArticleItem3 {
    image: any;
    id: string;
    background_color: string;
    title: string;
    headline: string;
    content: string;
    image_max_height: number;
}

export interface IGetArticleBlock5 {
    collection: string;
    id: number;
    Post_tags_id: IGetArticlePostTagsId2;
    item: IGetArticleItem5;
}

export interface IGetArticlePostTagsId2 {
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    seo: IGetArticleSeo6;
    posts: IGetArticlePost3[];
    blocks: IBlock[];
}

export interface IGetArticleSeo6 {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
    og_image: IGetArticleOgImage5;
}

export interface IGetArticleOgImage5 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticlePost3 {
    id: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    content: string;
    sort: number;
    date_updated: string;
    video_iframe_code: any;
    video_url: any;
    author: IGetArticleAuthor4;
    image: IGetArticleImage6;
    seo: IGetArticleSeo7;
    blocks: IBlock[];
    tag: IGetArticleTag4;
}

export interface IGetArticleAuthor4 {
    id: string;
    name: string;
    job_title: string;
    image: string;
    bio: string;
}

export interface IGetArticleImage6 {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface IGetArticleSeo7 {
    id: string;
    og_image: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_index: boolean;
    json_schema: any;
    no_follow: boolean;
}

export interface IGetArticleBlock6 {
    item: string;
    collection: string;
    sort: number;
    id: number;
}

export interface IGetArticleTag4 {
    seo: string;
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    posts: string[];
    blocks: IBlock[];
}

export interface IGetArticleBlock7 {
    collection: string;
    id: number;
    Post_tags_id: IGetArticlePostTagsId3;
    item: IGetArticleItem4;
}

export interface IGetArticlePostTagsId3 {
    seo: string;
    id: string;
    icon: string;
    slug: string;
    title: string;
    headline: string;
    content: string;
    posts: string[];
    blocks: IBlock[];
}

export interface IGetArticleItem4 {
    image: any;
    id: string;
    background_color: string;
    title: string;
    headline: string;
    content: string;
    image_max_height: number;
}

export interface IGetArticleItem5 {
    id: string;
    background_color: string;
    title: string;
    headline: string;
    content: string;
    image_max_height: number;
    image: any;
}

export interface IgetBlogByTag {
    id: string;
    icon: string;
    slug: string;
    title: string;
    seo: IgetBlogByTagSeo;
    posts: IGetArticlePost3[];
    blocks: IBlock[];
}

export interface IgetBlogByTagSeo {
    id: string;
    sitemap_priority: any;
    og_sitename: string;
    og_type: string;
    og_title: string;
    sitemap_change_frequency: any;
    canonical_url: string;
    title: string;
    json_data: any;
    og_description: string;
    meta_desciription: string;
    no_follow: boolean;
    no_index: boolean;
    json_schema: any;
    og_image: IgetBlogByTagOgImage;
}

export interface IgetBlogByTagOgImage {
    id: string;
    title: string;
    description: string;
    width: number;
    height: number;
}

export interface ISearchPosts {
    id: string;
    type: string;
    date_published: string;
    status: string;
    title: string;
    slug: string;
    summary: string;
    content: string;
    sort: number;
    date_updated: string;
    video_iframe_code: any;
    video_url: any;
    author: IgetBlogAuthor;
    image: IGetArticleOgImage2;
    seo: IgetBlogByTagSeo;
    blocks: IBlock[];
    tag: ITag;
}
