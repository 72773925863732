import { RULES, EBpmnElementType } from "@/entities/Score";
import type { ILintRule } from "@/shared/lib/types/app";

export function lintGateway(gateways: any[]): ILintRule[] {
    const lintResult: ILintRule[] = [];

    for (const element of gateways) {
        const { bpmnElement } = element;
        const { id, $type } = bpmnElement;

        const isIncoming: boolean = "incoming" in bpmnElement;
        const isOutgoing: boolean = "outgoing" in bpmnElement;
        const isDefault: boolean = "default" in bpmnElement;
        const countIncoming: number = isIncoming ? bpmnElement.incoming.length : 0;
        const countOutgoing: number = isOutgoing ? bpmnElement.outgoing.length : 0;

        if (!isIncoming || !isOutgoing) {
            lintResult.push({ ...RULES.NOT_CONNECTED_14, id });
        }

        if (countIncoming > 1 && countOutgoing > 1) {
            lintResult.push({ ...RULES.DONT_USE_ONE_FORK, id });
        }

        if (countIncoming === 1 && countOutgoing === 1) {
            lintResult.push({ ...RULES.ONE_INCOMING_ONE_OUTGOING_THREAD, id });
        }

        if (
            countOutgoing > 1 &&
            !isDefault &&
            $type !== EBpmnElementType.PARALLEL_GATEWAY &&
            $type !== EBpmnElementType.EVENT_BASED_GATEWAY
        ) {
            lintResult.push({ ...RULES.NOT_OUTGOING_THREAD_BY_DEFAULT, id });
        }
    }

    return lintResult;
}
