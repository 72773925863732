import { computed, ref } from "vue";
import { defineStore } from "pinia";

import { useModdleStore } from "@/entities/BpmnModeler";
import {
    type ILintRuleByElement,
    type IScoringResult,
    lintLanes,
    lintGateway,
    lintEvent,
    lintActivity,
    lintProcesses,
    isLanes,
    isEvents,
    isGateways,
    isActivities,
    EBpmnElementType,
    ScoreApi,
} from "@/entities/Score";
import { ELintRuleType, type ILintRule } from "@/shared/lib/types/app";

import { useLoading, useNotification } from "@/shared/lib/composables";

export const useScoringStore = defineStore("scoring", () => {
    const moddleStore = useModdleStore();

    const { isLoading, startLoading, finishLoading } = useLoading();

    const { showError } = useNotification();

    const ERROR_BASE = 6;
    const WARNING_BASE = 3;
    const INFO_BASE = 1;
    const COEF = 6;

    const countOfElements = ref<number>(0);

    const lintResultByElementIds = ref<ILintRuleByElement[]>([]);

    const infos = ref<ILintRule[]>([]);
    const warnings = ref<ILintRule[]>([]);
    const errors = ref<ILintRule[]>([]);

    const score = ref<number>(0);

    const scoreResult = ref<IScoringResult>();

    const camundaErrors = ref<string[]>([]);

    const resultMinPercentOfAssignees = computed<number>(() => (scoreResult.value?.assigneePercent || 0) * 100);
    const resultMinPercentOfDocuments = computed<number>(() => (scoreResult.value?.assetDocumentPercent || 0) * 100);
    const resultMinPercentOfSystems = computed<number>(() => (scoreResult.value?.assetSystemPercent || 0) * 100);

    async function mainLint(xml: string, customLintDefinition: ILintRule[]): Promise<ILintRuleByElement[]> {
        const { rootElement: moderRootElement, countOfElements: moderCountOfElements } = await moddleStore.readModer(xml);

        countOfElements.value = moderCountOfElements;

        const planeElement = moderRootElement.diagrams[0].plane.planeElement;

        const processes: any[] = [];
        const participants: any[] = [];

        const activities: any[] = [];
        const gateWayes: any[] = [];
        const events: any[] = [];
        const lanes: any[] = [];

        const allLintResult: ILintRule[] = [];

        for (const element of moderRootElement.rootElements) {
            if (element.$type === EBpmnElementType.PROCESS) processes.push(element);

            if (element?.participants) {
                for (const participant of element.participants) {
                    participants.push(participant);
                }
            }
        }

        for (const diagram of moderRootElement.diagrams) {
            if (!diagram.plane?.planeElement) continue;

            for (const planeElement of diagram.plane.planeElement) {
                if (!planeElement?.bpmnElement) continue;

                const typeElement = planeElement.bpmnElement.$type;

                if (isActivities(typeElement)) activities.push(planeElement);
                if (isGateways(typeElement)) gateWayes.push(planeElement);
                if (isEvents(typeElement)) events.push(planeElement);
                if (isLanes(typeElement)) lanes.push(planeElement);
            }
        }

        allLintResult.push(...lintProcesses(processes, participants, planeElement));
        allLintResult.push(...lintActivity(activities));
        allLintResult.push(...lintEvent(events));
        allLintResult.push(...lintGateway(gateWayes));
        allLintResult.push(...lintLanes(lanes));

        const uniqElementIds: string[] = [...new Set(allLintResult.map(item => item.id || ""))];

        const workedLintResult: ILintRule[] = [];
        if (customLintDefinition.length) {
            for (const rule of allLintResult) {
                const customRule = customLintDefinition.find(item => item.ruleId === rule.ruleId);

                if (!customRule) {
                    workedLintResult.push(rule);
                    continue;
                }

                if (customRule.enable) {
                    rule.additionalInfo = customRule.additionalInfo;
                    rule.description = customRule.description;
                    rule.header = customRule.header;
                    rule.type = customRule.type;

                    workedLintResult.push(rule);
                }
            }
        } else {
            workedLintResult.push(...allLintResult);
        }

        const resultByElementIds: ILintRuleByElement[] = [];
        for (const uniqElementId of uniqElementIds) {
            const lintElements: ILintRule[] = [];

            for (const singleLintResult of workedLintResult) {
                if (singleLintResult.id === uniqElementId) {
                    lintElements.push(singleLintResult);
                }
            }

            if (lintElements.length) {
                resultByElementIds.push({
                    id: uniqElementId,
                    lintElements,
                    ref: undefined,
                });
            }
        }

        lintResultByElementIds.value = resultByElementIds;

        return resultByElementIds;
    }

    async function calculateScore(xml: string, customLintDefinition: ILintRule[]): Promise<void> {
        const lintResults = await mainLint(xml, customLintDefinition);

        infos.value = [];
        warnings.value = [];
        errors.value = [];

        for (const element of lintResults) {
            for (const lintElement of element.lintElements) {
                if (lintElement.type === ELintRuleType.INFO) infos.value.push(lintElement);
                if (lintElement.type === ELintRuleType.WARNING) warnings.value.push(lintElement);
                if (lintElement.type === ELintRuleType.ERROR) errors.value.push(lintElement);
            }
        }

        score.value = ERROR_BASE + WARNING_BASE + INFO_BASE;

        if (errors.value.length) {
            score.value = score.value - COEF * (countOfElements.value / 20) * (errors.value.length / countOfElements.value) * ERROR_BASE;
        }
        if (warnings.value.length) {
            score.value = score.value - COEF * (warnings.value.length / countOfElements.value) * WARNING_BASE;
        }
        if (infos.value) {
            score.value = score.value - COEF * (infos.value.length / countOfElements.value) * INFO_BASE;
        }

        score.value = Math.ceil(score.value * 10) / 10;

        if (score.value < 0) score.value = 0;
    }

    async function getScoringResultById(id: string): Promise<void> {
        try {
            scoreResult.value = undefined;
            scoreResult.value = await ScoreApi.getScoringResultByDiagramId(id);
        } catch (e) {
            showError(e);
        }
    }

    async function getValidationErrors(xml: string): Promise<void> {
        try {
            startLoading();
            camundaErrors.value = [];
            camundaErrors.value = await ScoreApi.validationErrors(xml);
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        infos,
        warnings,
        errors,
        score,
        countOfElements,
        lintResultByElementIds,
        resultMinPercentOfAssignees,
        resultMinPercentOfDocuments,
        resultMinPercentOfSystems,
        camundaErrors,
        mainLint,
        calculateScore,
        getScoringResultById,
        getValidationErrors,
    };
});
