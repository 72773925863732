<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockLogoCloudRef">
            <div v-html="data?.item?.headline"></div>
            <div class="flex flex-column gap-4 w-full">
                <Clients :data="data?.item?.logos" />
            </div>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn, Clients } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockLogoCloudRef = ref();

watch(blockLogoCloudRef, () => {
    if (props.article) {
        emit("getHtml", blockLogoCloudRef.value);
    }
});
</script>

<style scoped></style>
