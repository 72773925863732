<template>
    <div class="flex flex-column gap-2">
        <label for="sendMessageFormEmail" class="text-base font-bold">Укажите электронные почты коллег *</label>
        <div class="flex gap-2">
            <InputText
                v-model="emailInvitedColleague.email"
                id="sendMessageFormEmail"
                class="w-full"
                placeholder="kotov@bpmn2.ru"
                @keyup.enter="addTag"
                @input="inputUpdate"
            />
            <!-- <InputSearch /> -->
            <Button
                outlined
                label="Добавить"
                class="w-10rem"
                @click="addTag"
                :disabled="!!validateEmailColleague.email.$error || emailInvitedColleague.email.length == 0"
            />
        </div>
        <span class="text-sm text-red-400" v-if="validateEmailColleague.email.$error">
            {{ validateEmailColleague.email.$errors[0].$message }}
        </span>
        <div class="flex gap-2 flex-wrap">
            <Tag v-for="(tag, i) of modelValue" rounded severity="secondary">
                <div class="flex gap-2">
                    <span>{{ tag }}</span>
                    <i class="pi pi-times text-sm cursor-pointer" @click="delTag(i)"></i>
                </div>
            </Tag>
        </div>
    </div>
</template>

<script setup lang="ts">
import { reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { email, helpers } from "@vuelidate/validators";
import { notEmail } from "@/shared/lib/validators";
import { InputSearch } from "../input-search";

const modelValue = defineModel<string[]>();

const emailInvitedColleague = reactive({
    email: "",
});
const rulesEmailColleague = {
    email: { email: helpers.withMessage(notEmail, email) },
};
const validateEmailColleague = useVuelidate(rulesEmailColleague, emailInvitedColleague);

function addTag() {
    if (modelValue.value) {
        modelValue.value.push(emailInvitedColleague.email);
        emailInvitedColleague.email = "";
    }
}
function delTag(i: number) {
    if (modelValue.value) {
        modelValue.value.splice(i, 1);
    }
}
async function inputUpdate() {
    await validateEmailColleague.value.$validate();
}
</script>

<style scoped></style>
