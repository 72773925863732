<template>
    <WrapperBlockColumn class="mt-8">
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockRichTextRef">
            <Fieldset :legend="data.item.headline">
                <div v-html="data.item.content" />
            </Fieldset>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockRichTextRef = ref();

watch(blockRichTextRef, () => {
    if (props.article) {
        emit("getHtml", blockRichTextRef.value);
    }
});
</script>

<style scoped></style>
