<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockCountsRef">
            <div class="text-center" v-html="data?.item?.headline" v-if="data?.item?.headline"></div>
            <div class="grid gap-3 p-3 lg:p-0">
                <div
                    class="col border-1 border-round-2xl shadow-1 border-black-alpha-20 border-round-lg flex flex-column gap-4 justify-content-center align-items-center p-5"
                    v-for="item of data?.item?.counts"
                >
                    <h3 class="text-5xl" v-if="item?.postfix">{{ item.count.toLocaleString() + item?.postfix }}</h3>
                    <h3 class="text-5xl" v-else>{{ item.count.toLocaleString() }}</h3>
                    <div>{{ item.title }}</div>
                </div>
            </div>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });

const emit = defineEmits(["getHtml"]);
const blockCountsRef = ref();

watch(blockCountsRef, () => {
    if (props.article) {
        emit("getHtml", blockCountsRef.value);
    }
});
</script>

<style scoped></style>
