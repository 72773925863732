<template>
    <TabView v-model:active-index="activePanelIndex" :pt="{ panelContainer: 'px-0 scroll' }">
        <TabPanel header="Элементы управления">
            <div class="row">
                <div class="flex flex-column gap-3">
                    <b>Клавиатура</b>
                    <div class="flex flex-column">
                        <div
                            class="flex align-items-center gap-2 border-round py-1 px-2 hover:surface-hover"
                            v-for="item of keyboardEvents"
                        >
                            <Divider v-if="item.divider" class="m-0" />
                            <template v-else>
                                <span>{{ item.label }}</span>
                                <Shortcut class="ml-auto">{{ item.key }}</Shortcut>
                            </template>
                        </div>
                    </div>
                </div>
                <Divider layout="vertical" class="m-0"></Divider>
                <div class="flex flex-column justify-content-start gap-3">
                    <b>Панель процесса</b>
                    <div class="flex flex-column">
                        <div class="flex align-items-center gap-2 border-round py-1 px-2 hover:surface-hover" v-for="item of panelElements">
                            <Divider v-if="item.divider" class="m-0" />
                            <template v-else>
                                <Shortcut class="text-base" :icon="item.icon" />
                                <span>{{ item.label }}</span>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </TabPanel>
        <TabPanel header="Описание и примеры элементов BPMN">
            <Block_bpmn_elements v-if="pageData" :data="pageData.blocks?.[0]" :is-element-link="false" />
        </TabPanel>
    </TabView>
</template>

<script setup lang="ts">
import { onMounted, ref, shallowRef } from "vue";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { Shortcut } from "@/shared/ui/shortcut";

import { Block_bpmn_elements } from "@/widgets/marketing";

import { useInterfaceStore } from "@/entities/Interface";

interface IKeyboardEvent {
    label?: string;
    key?: string;
    divider?: boolean;
}
interface IPanelElement {
    label?: string;
    icon?: string;
    divider?: boolean;
}

const interfaceStore = useInterfaceStore();
const { pageData } = storeToRefs(interfaceStore);

const activePanelIndex = ref<number>(0);

const keyboardEvents = shallowRef<IKeyboardEvent[]>([
    {
        label: "Отменить последнее действие",
        key: "ctrl+z",
    },
    {
        label: "Выполнить последнее действие",
        key: "ctrl+shift+z",
    },
    { divider: true },
    {
        label: "Изменение масштаба",
        key: "ctrl+scroll",
    },
    { divider: true },
    {
        label: "Перемещение по вертикали",
        key: "shift+scroll",
    },
    {
        label: "Перемещение по горизонтали",
        key: "scroll",
    },
    { divider: true },
    {
        label: "Выделять элементы по одному в группу",
        key: "shift+click",
    },
    { divider: true },
    {
        label: "Изменение видимости всех оверлеев",
        key: "alt+a",
    },
    {
        label: "Изменение состояния свернутости всех оверлеев",
        key: "alt+shift+a",
    },
    { divider: true },
    {
        label: "Изменение видимости оверлеев типа «Роль»",
        key: "alt+1",
    },
    {
        label: "Изменение видимости оверлеев типа «Должность»",
        key: "alt+2",
    },
    {
        label: "Изменение видимости оверлеев типа «Системы»",
        key: "alt+3",
    },
    {
        label: "Изменение состояния свернутости оверлеев типа «Системы»",
        key: "alt+shift+3",
    },
    {
        label: "Изменение видимости оверлеев типа «Документы»",
        key: "alt+4",
    },
    {
        label: "Изменение состояния свернутости оверлеев типа Документы",
        key: "alt+shift+4",
    },
    { divider: true },
    {
        label: "Изменение видимости оверлеев типа «Описание»",
        key: "alt+5",
    },
    {
        label: "Изменение видимости оверлеев типа «Комментарии»",
        key: "alt+6",
    },
    {
        label: "Изменение видимости оверлеев типа «Длительность»",
        key: "alt+7",
    },
    { divider: true },
    {
        label: "Изменение видимости оверлеев типа «Связи (входящие)»",
        key: "alt+8",
    },
    {
        label: "Изменение видимости оверлеев типа «Связи (исходящие)»",
        key: "alt+9",
    },
]);
const panelElements = shallowRef<IPanelElement[]>([
    { label: "Запустить симуляцию токена", icon: PrimeIcons.PLAY_CIRCLE },
    { divider: true },
    { label: "Во весь экран", icon: PrimeIcons.ARROW_UP_RIGHT_AND_ARROW_DOWN_LEFT_FROM_CENTER },
    { divider: true },
    { label: "Открыть мини-карту", icon: PrimeIcons.MAP },
    { label: "Увеличить масштаб", icon: PrimeIcons.SEARCH_PLUS },
    { label: "Уменьшить масштаб", icon: PrimeIcons.SEARCH_MINUS },
    { label: "Отменить последнее действие", icon: PrimeIcons.UNDO },
    { label: "Вернуть последнее действие", icon: PrimeIcons.REFRESH },
    { divider: true },
    { label: "Изменить цвет выделенных элементов", icon: PrimeIcons.PALETTE },
    { label: "Копировать выделенные элементы", icon: PrimeIcons.COPY },
    { label: "Вставить скопированное (работает между вкладками)", icon: PrimeIcons.CLIPBOARD },
    { divider: true },
    { label: "Запустить оценку качества процесса", icon: PrimeIcons.CHECK_CIRCLE },
    { label: "Запустить автонумерацию элементов ", icon: PrimeIcons.LIST_CHECK },
    { label: "Очистить автонумерацию элементов", icon: PrimeIcons.BAN },
    { divider: true },
    { label: "Показать справочную информацию", icon: PrimeIcons.QUESTION_CIRCLE },
    { label: "Скрыть шапку диаграммы", icon: PrimeIcons.ANGLE_UP },
]);

onMounted(async () => {
    await interfaceStore.fetchAll("/bpmn/elements", { isSaveSeo: false, isGoToNotFound: false });
});
</script>

<style scoped>
.row {
    display: grid;
    grid-template-columns: 1fr 1rem 1fr;
    gap: 1rem;
}
</style>
