import { useConfirm } from "primevue/useconfirm";

interface IParameters {
    message: string;
    acceptCb: () => void;
    rejectCb?: () => void;
    hideCb?: () => void;
    rejectLabel?: string;
    acceptLabel?: string;
    header?: string;
}

export const useConfirmation = () => {
    const confirm = useConfirm();

    function showDeleteConfirm(payload: IParameters) {
        confirm.require({
            message: payload.message,
            header: payload.header || "Подтверждение",
            icon: "pi pi-exclamation-triangle",
            rejectClass: "p-button-secondary p-button-outlined",
            rejectLabel: payload.rejectLabel || "Отмена",
            acceptLabel: payload.acceptLabel || "Удалить",
            onHide: () => {
                if (payload?.hideCb) payload.hideCb();
            },
            accept: () => {
                payload.acceptCb();
            },
            reject: () => {
                if (payload.rejectCb) payload.rejectCb();
            },
        });
    }

    return {
        showDeleteConfirm,
    };
};
