<template>
    <Button label="Cкачать счет" @click="downloadInvoices" :disabled="disabledDownloadContract" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";
import { useSessionStore } from "@/entities/Session";
import { useInvoiceStore } from "@/entities/Invoice";
import { useDownloadInvoice } from "../model";
import type { IInvoice } from "@/entities/Invoice";
import type { IPayloadDownloadInvoice } from "../model";
import { generateInvoiceNumber } from "@/entities/Invoice";

const props = defineProps<{
    invoice: IInvoice;
}>();

const sessionStore = useSessionStore();
const invoiceStore = useInvoiceStore();
const downloadInvoice = useDownloadInvoice();
const { userData } = storeToRefs(sessionStore);
const { price } = storeToRefs(invoiceStore);

const invoiceNumber = computed(() => {
    return generateInvoiceNumber(userData.value.userId, props.invoice.quantityParticipants, props.invoice.quantityMonths);
});
const disabledDownloadContract = computed<boolean>(() => {
    return props.invoice.quantityMonths == 0 || props.invoice.quantityParticipants == 0 || !props.invoice.company;
});
const manager = computed<string>(() => {
    if (props.invoice.company != null) {
        if (props.invoice.company.data.type == "INDIVIDUAL") {
            return props.invoice.company.value;
        }
        return props.invoice.company.data.management.post + " " + props.invoice.company.data.management.name;
    }
    return " ";
});

function downloadInvoices() {
    if (
        props.invoice.quantityParticipants &&
        props.invoice.quantityMonths &&
        props.invoice.quantityMonths != 0 &&
        props.invoice.quantityParticipants != 0 &&
        props.invoice.company
    ) {
        const payload: IPayloadDownloadInvoice = {
            amount: price.value,
            inn: props.invoice.company.data.inn,
            invoiceNumber: invoiceNumber.value,
            membersCount: props.invoice.quantityParticipants,
            monthsCount: props.invoice.quantityMonths,
            legalEntityString: props.invoice.company.value,
            manager: manager.value,
        };
        downloadInvoice.downloadInvoices(payload);
    }
}
</script>

<style scoped></style>
