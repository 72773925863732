import { storeToRefs } from "pinia";

import { useCommentStore } from "@/entities/Comment";
import { useBpmnModelerOverlayStore, useBpmnModelerStore } from "@/entities/BpmnModeler";
import { useAssigneeStore } from "@/entities/Assignee";
import { useAssetStore } from "@/entities/Asset";
import { EOverlaySettingsName, useDiagramStore } from "@/entities/Process";
import { computed } from "vue";

export function useInstallationOverlays() {
    const commentStore = useCommentStore();
    const bpmnOverlayStore = useBpmnModelerOverlayStore();
    const bpmnModelerStore = useBpmnModelerStore();
    const assigneeStore = useAssigneeStore();
    const assetStore = useAssetStore();
    const diagramStore = useDiagramStore();

    const { commentIndicators } = storeToRefs(commentStore);
    const { modelerElementRegistry } = storeToRefs(bpmnModelerStore);
    const { assigneesByDiagram } = storeToRefs(assigneeStore);
    const { assetsByDiagram, customAssetsByDiagram } = storeToRefs(assetStore);
    const { overlaySettings } = storeToRefs(diagramStore);

    const isAssigneeOverlays = computed(() => {
        return (
            overlaySettings.value?.documents ||
            overlaySettings.value?.systems ||
            customAssetsByDiagram.value.some(item => item.overlaySettings)
        );
    });

    async function installationOverlays(): Promise<void> {
        await bpmnOverlayStore.clearOverlays(true);

        if (overlaySettings.value?.comments) {
            for (const row of commentIndicators.value) {
                const element = modelerElementRegistry.value.get(row.elementId);

                if (element) {
                    bpmnOverlayStore.addComment(row);
                }
            }
        }

        if (overlaySettings.value?.assignees) {
            for (const assignee of assigneesByDiagram.value) {
                if (!assignee.assigneeId) continue;

                const element = modelerElementRegistry.value.get(assignee.elementId);

                if (element) {
                    bpmnOverlayStore.addAssignee(assignee);
                }
            }
        }

        if (isAssigneeOverlays.value) {
            for (const asset of assetsByDiagram.value) {
                if (!asset.assetId) continue;

                const element = modelerElementRegistry.value.get(asset.fromItemId);

                if (element) {
                    bpmnOverlayStore.addAsset(asset);
                }
            }
        }
    }

    return {
        installationOverlays,
    };
}
