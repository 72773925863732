<template>
    <Dialog v-model:visible="visible" :draggable="false" modal header="Загрузка шаблона" class="w-3" :dismissableMask="true">
        <div class="flex flex-column gap-3">
            <div class="flex flex-column justify-content-center gap-2">
                <div class="flex justify-content-center">
                    <a
                        href="https://docs.google.com/document/d/1e9GZfWQDymoFcsF95yOTZdqkz4hXflzI2QyMUpqaFmY/edit#heading=h.bnj92bmrrns8"
                        target="_blank"
                    >
                        Посмотрите пример создания шаблона
                    </a>
                </div>
            </div>
            <FormColumn title="Имя шаблона" :error="validate.name.$errors[0]?.$message">
                <InputText
                    v-model="uploadTemplate.name"
                    placeholder="Введите имя шаблона"
                    :invalid="validate.name.$error"
                    class="w-full"
                ></InputText>
            </FormColumn>
            <FormColumn title="Тип шаблона" :error="validate.type.$errors[0]?.$message">
                <Dropdown
                    v-model="uploadTemplate.type"
                    :options="typeTemplate"
                    optionLabel="text"
                    optionValue="value"
                    placeholder="Выберите категорию доступа"
                    class="w-full"
                    :invalid="validate.type.$error"
                />
            </FormColumn>
            <FileUpload
                mode="advanced"
                :showUploadButton="false"
                accept=".doc,.docx,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                :fileLimit="1"
                @select="onSelectFile"
                chooseLabel="Загрузить файл"
                cancelLabel="Удалить выбранное"
                :pt="{
                    chooseButton: () => ({
                        class: ['p-button-outlined'],
                    }),
                    removeButton: () => ({
                        class: ['p-button-outlined'],
                    }),
                }"
            >
                <template #content="{ files }">
                    <div class="flex flex-column gap-2" v-if="files.length > 0">
                        <span>{{ files[0].name }}</span>
                        <span class="text-sm">{{ formatSize(files[0].size) }}</span>
                    </div>
                </template>
            </FileUpload>
            <span class="text-sm text-red-400" v-if="validate.file.$error">
                {{ validate.file.$errors[0].$message }}
            </span>
            <Button label="Сохранить шаблон" @click="saveTemplate" />
        </div>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, reactive } from "vue";
import useVuelidate from "@vuelidate/core";
import { useTeamStore, ETypeTextTemplate, ETypeValueTemplate } from "@/entities/Team";
import type { FileUploadSelectEvent } from "primevue/fileupload";
import { required, helpers } from "@vuelidate/validators";
import { requiredValue } from "@/shared/lib/validators";
import { usePrimeVue } from "primevue/config";
import { useDialog } from "@/shared/lib/composables";
import { FormColumn } from "@/shared/ui/form";

const teamStore = useTeamStore();
const $primevue = usePrimeVue();
const { visible, show, hide } = useDialog();

interface ITypeTemplate {
    text: ETypeTextTemplate;
    value: ETypeValueTemplate;
}
interface IPayloadUploadTemplate {
    file: File | null;
    name: string;
    type: string;
}

const visibleUplaodTemplate = ref<boolean>(false);
const uploadTemplate = reactive<IPayloadUploadTemplate>({
    file: null,
    name: "",
    type: "",
});
function onSelectFile(event: FileUploadSelectEvent) {
    uploadTemplate.file = event.files[0];
    uploadTemplate.name = event.files[0].name;
}
const typeTemplate = ref<ITypeTemplate[]>([
    {
        text: ETypeTextTemplate.PROCESS,
        value: ETypeValueTemplate.PROCESS,
    },
    {
        text: ETypeTextTemplate.ASSET,
        value: ETypeValueTemplate.ASSET,
    },
    {
        text: ETypeTextTemplate.ASSIGNEE,
        value: ETypeValueTemplate.ASSIGNEE,
    },
]);

const rules = {
    name: { required: helpers.withMessage(requiredValue, required) },
    type: { required: helpers.withMessage(requiredValue, required) },
    file: { required: helpers.withMessage(requiredValue, required) },
};
const validate = useVuelidate(rules, uploadTemplate);

function formatSize(bytes: any) {
    const k = 1024;
    const dm = 3;
    let sizes;
    if ($primevue.config.locale) {
        sizes = $primevue.config.locale.fileSizeTypes;
    }

    if (sizes) {
        if (bytes === 0) {
            return `0 ${sizes[0]}`;
        }

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        const formattedSize = parseFloat((bytes / Math.pow(k, i)).toFixed(dm));

        return `${formattedSize} ${sizes[i]}`;
    }
}

async function saveTemplate() {
    const isCorrect = await validate.value.$validate();
    if (isCorrect) {
        const payload = new FormData();
        if (uploadTemplate.file) payload.append("file", uploadTemplate.file);
        payload.append("name", uploadTemplate.name);
        payload.append("type", uploadTemplate.type);
        teamStore.saveTemplate(payload);
        hide();
        uploadTemplate.file = null;
        uploadTemplate.name = "";
        uploadTemplate.type = "";
        validate.value.$reset();
    }
}

defineExpose({
    show,
});
</script>

<style scoped></style>
