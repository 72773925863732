import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import type { Shape } from "bpmn-js/lib/model/Types";

import { useNotification } from "@/shared/lib/composables";

import { useBpmnModelerStore } from ".";
import { isSameArrays } from "@/shared/lib/utils/array";

interface ISelectElementOptions {
    isHideMenu: boolean;
}

export const useElementStore = defineStore("element", () => {
    const { showWarning } = useNotification();

    const selectedElements = ref<Shape[]>([]);

    const bpmnModelerStore = useBpmnModelerStore();
    const { modelerElementRegistry, modelerSelection, modelerContextPad } = storeToRefs(bpmnModelerStore);

    const hasSelectedElements = computed<boolean>(() => selectedElements.value.length > 0);

    function getElementById(elementId: string): undefined | Shape {
        if (!modelerElementRegistry.value) return undefined;
        return modelerElementRegistry.value.get(elementId);
    }

    function selectElement(elementId: string, options?: ISelectElementOptions): void {
        const element = getElementById(elementId);
        if (!element) return showWarning("Элемент не найден");

        if (modelerSelection.value.isSelected(element)) return;

        modelerSelection.value.select(element);
        if (options?.isHideMenu) modelerContextPad.value.close();
    }
    function deselectElement(elementId: string) {
        const element = modelerElementRegistry.value.get(elementId);
        if (!element) return;
        modelerSelection.value.deselect(element);
    }

    function setSelectedElements(elements: Shape[]) {
        const ids = elements.map(item => item.id);
        const selectedIds = selectedElements.value.map(item => item.id);

        const isSame = isSameArrays(ids, selectedIds);

        if (!isSame) selectedElements.value = JSON.parse(JSON.stringify(elements));
    }

    function clearSelectedElements() {
        selectedElements.value = [];
    }

    return {
        selectElement,
        deselectElement,

        hasSelectedElements,

        selectedElements,
        setSelectedElements,
        clearSelectedElements,

        getElementById,
    };
});
