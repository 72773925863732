import { ELintRuleType } from "@/shared/lib/types/app";

export const RULES = {
    NO_NAME: {
        ruleId: 1,
        type: ELintRuleType.INFO,
        header: "Нет имени",
        description: "Укажите имя элемента",
        additionalInfo: "https://storm.bpmn2.ru/bpmn/goodStyle#allElements",
        enable: true,
    },
    LONG_NAME: {
        ruleId: 2,
        type: ELintRuleType.INFO,
        header: "Слишком большое имя",
        description: "Похоже вы описываете задачу прямо в имени. Перенесите описание внутрь, нажмите 'карандаш'",
        additionalInfo: "",
        enable: true,
    },
    IMPERATIVE_VERB: {
        ruleId: 3,
        type: ELintRuleType.INFO,
        header: "Используйте глагол в повелительном наклонении в названии задачи",
        description: "Задачи на диаграмме должны указывать исполнителю что делать",
        additionalInfo: "",
        enable: true,
    },
    NO_INCOMING_THREADS: {
        ruleId: 4,
        type: ELintRuleType.ERROR,
        header: "Нет входящих потоков управления",
        description: "Должен быть хотя бы 1 входящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    NO_OUTGOING_THREADS: {
        ruleId: 5,
        type: ELintRuleType.INFO,
        header: "Нет выходящих потоков управления",
        description: "Должен быть хотя бы 1 выходящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    DONT_USE_CONDITIONAL_THREADS: {
        ruleId: 6,
        type: ELintRuleType.WARNING,
        header: "Не используйте потоки управления по условию",
        description: "Замените их на развилки",
        additionalInfo: "",
        enable: true,
    },
    MANY_INCOMING_THREADS: {
        ruleId: 7,
        type: ELintRuleType.INFO,
        header: "Много входящих потоков",
        description: "Должен быть 1 входящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    NOT_CONNECTED_8: {
        ruleId: 8,
        type: ELintRuleType.ERROR,
        header: "Не соединено",
        description: "Элемент должен иметь входящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    MANY_OUTGOING_THREADS: {
        ruleId: 9,
        type: ELintRuleType.WARNING,
        header: "Много выходящих потоков",
        description: "Должен быть 1 выходящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    NO_OUTGOING_THREAD: {
        ruleId: 10,
        type: ELintRuleType.ERROR,
        header: "Нет выходящего потока управления",
        description: "Добавьте выходящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    MANY_INCOMING_CONTROL_THREADS: {
        ruleId: 11,
        type: ELintRuleType.INFO,
        header: "Много входящих потоков управления",
        description: "Должен быть 1 входящий поток управления в завершающее событие",
        additionalInfo: "",
        enable: true,
    },
    E_MANY_OUTGOING_THREADS: {
        ruleId: 12,
        type: ELintRuleType.ERROR,
        header: "Много выходящих потоков",
        description: "Должен быть 1 выходящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    NOT_CONNECTED_13: {
        ruleId: 13,
        type: ELintRuleType.ERROR,
        header: "Не соединено",
        description: "Событие должно иметь входящий или исходящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    NOT_CONNECTED_14: {
        ruleId: 14,
        type: ELintRuleType.ERROR,
        header: "Не соединено",
        description: "Развилка должна иметь входящий и исходящий поток управления",
        additionalInfo: "",
        enable: true,
    },
    DONT_USE_ONE_FORK: {
        ruleId: 15,
        type: ELintRuleType.WARNING,
        header: "Не используйте одну развилку для сбора и разделения потоков",
        description: "Добавьте дополнительную развилку",
        additionalInfo: "",
        enable: true,
    },
    ONE_INCOMING_ONE_OUTGOING_THREAD: {
        ruleId: 16,
        type: ELintRuleType.ERROR,
        header: "1 входящий и 1 выходящий поток",
        description: "Откажитесь от развилки",
        additionalInfo: "",
        enable: true,
    },
    NOT_OUTGOING_THREAD_BY_DEFAULT: {
        ruleId: 17,
        type: ELintRuleType.WARNING,
        header: "Нет выходящего потока по-умолчанию",
        description: "Отметьте один из выходящих потоков как поток по-умолчанию",
        additionalInfo: "",
        enable: true,
    },
    NOT_INCOMING_AND_OUTGOING_THREAD: {
        ruleId: 18,
        type: ELintRuleType.ERROR,
        header: "Нет входящего и выходящего потока",
        description: "Промежуточное событие должно иметь входящий и выходящий поток",
        additionalInfo: "",
        enable: true,
    },
    DONT_USE_TRACKS: {
        ruleId: 19,
        type: ELintRuleType.INFO,
        header: "Не используйте дорожки",
        description: "",
        additionalInfo: "",
        enable: true,
    },
    NO_ACTION_IN_PROCESS: {
        ruleId: 20,
        type: ELintRuleType.ERROR,
        header: "В процессе нет действий",
        description: "Добавьте хотя бы одно действие",
        additionalInfo: "",
        enable: true,
    },
    SOME_START_EVENT: {
        ruleId: 21,
        type: ELintRuleType.INFO,
        header: "Несколько стартовых событий",
        description: "Замените на развилку",
        additionalInfo: "",
        enable: true,
    },
    MINIMUM_ONE_START_AND_END_EVENT: {
        ruleId: 22,
        type: ELintRuleType.ERROR,
        header: "Хотя бы 1 стартовое и завершающее событие",
        description: "Добавьте события",
        additionalInfo: "",
        enable: true,
    },
    TOP_DOWN_PROCESS: {
        ruleId: 23,
        type: ELintRuleType.WARNING,
        header: "Процесс сверху-вниз",
        description: "Процессы должен быть нарисован слева направо",
        additionalInfo: "",
        enable: true,
    },
    END_LEFT_START: {
        ruleId: 24,
        type: ELintRuleType.WARNING,
        header: "Завершение левее старта",
        description: "Процесс должен быть нарисован слева направо",
        additionalInfo: "",
        enable: true,
    },
    EMPTY_IN_PROCESS: {
        ruleId: 25,
        type: ELintRuleType.ERROR,
        header: "В процессе пусто",
        description: "Добавьте стартовое событие, задачу и завершающее событие",
        additionalInfo: "",
        enable: true,
    },
    NO_START_EVENT: {
        ruleId: 26,
        type: ELintRuleType.ERROR,
        header: "Нет стартового события",
        description: "Должно быть хотя бы одно стартовое событие",
        additionalInfo: "",
        enable: true,
    },
    MANY_TASKS_IN_PROCESS: {
        ruleId: 27,
        type: ELintRuleType.WARNING,
        header: "Слишком много задач в процессе",
        description: "Попробуйте уменьшить количество задач и/или разбить процесс на разные",
        additionalInfo: "",
        enable: true,
    },
    NO_END_EVENT: {
        ruleId: 28,
        type: ELintRuleType.ERROR,
        header: "Нет завершающего события",
        description: "Должно быть хотя бы одно завершающее событие",
        additionalInfo: "",
        enable: true,
    },
};
