type KeyHandler = (e: KeyboardEvent, h: IKeyHandler) => any;

export interface IKeyHandler {
    altKey?: boolean;
    ctrlKey?: boolean;
    shiftKey?: boolean;
    metaKey?: boolean;
    code: string | string[];
    func: KeyHandler;
}

export class KeyboardManager {
    private handlers: IKeyHandler[] = [];
    private debug: boolean = false;

    constructor(isDebug: boolean = false) {
        this.debug = isDebug;
        this.handlers = [];
    }

    private toggleDebug(): boolean {
        this.debug = !this.debug;
        return true;
    }

    addKeyHandler(
        code: string | string[],
        func: KeyHandler,
        altKey: boolean = false,
        ctrlKey: boolean = false,
        shiftKey: boolean = false,
        metaKey: boolean = false,
    ): KeyHandler {
        if (typeof code === "string") this.addHandler({ func, code, altKey, ctrlKey, shiftKey, metaKey });
        else {
            //if it's array, add it recursively
            code.forEach(x => {
                this.addHandler({ func, code: x, altKey, ctrlKey, shiftKey, metaKey });
            });
        }
        return func;
    }

    private addHandler(o: IKeyHandler): void {
        this.handlers.push(o);
    }

    addKeyHandlers(handlers: IKeyHandler[]): KeyHandler[] {
        let fs: KeyHandler[] = [];
        handlers.forEach(x => fs.push(this.addKeyHandler(x.code, x.func, x.altKey, x.ctrlKey, x.shiftKey, x.metaKey)));
        return fs;
    }

    removeKeyHandler(f: KeyHandler): void {
        //remove all keys with this handler
        this.handlers = this.handlers.filter(x => {
            return x.func !== f;
        });
    }

    removeKeyHandlers(fs: KeyHandler[]): void {
        fs.forEach(x => this.removeKeyHandler(x));
    }

    private keyDown(e: KeyboardEvent) {
        if (this.debug) {
            console.log("code", e.code);
            console.log("handlers", this.handlers);
            console.log("event", e);
        }

        for (let i = this.handlers.length - 1; i >= 0; i--) {
            let h = this.handlers[i];

            if (
                h.code === e.code &&
                h.altKey === e.altKey &&
                h.shiftKey === e.shiftKey &&
                h.ctrlKey === e.ctrlKey &&
                h.metaKey === e.metaKey
            ) {
                if (h.func(e, this.handlers[i])) {
                    e.preventDefault();
                    e.stopPropagation();
                    return;
                }
            }
        }
    }

    private clearAll() {
        this.handlers = [];
    }

    mount() {
        window.addEventListener("keydown", (e: KeyboardEvent) => this.keyDown(e));
        this.addKeyHandler("KeyD", this.toggleDebug, true);
    }

    unmount() {
        this.removeKeyHandler(this.toggleDebug);
        window.removeEventListener("keydown", this.keyDown);
        this.clearAll();
    }
}
