<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockCTARef">
            <div class="text-center" v-html="data?.item?.headline" v-if="data?.item?.headline"></div>
            <div class="text-center" v-html="data?.item?.content" v-if="data?.item?.content"></div>
            <ButtonGroup :data="data?.item?.button_group" />
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn, ButtonGroup } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });

const emit = defineEmits(["getHtml"]);
const blockCTARef = ref();

watch(blockCTARef, () => {
    if (props.article) {
        emit("getHtml", blockCTARef.value);
    }
});
</script>

<style scoped></style>
