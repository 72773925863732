<template>
    <FileUpload
        ref="fileInputRef"
        id="fileInputId"
        :file-limit="5"
        :multiple="true"
        :show-cancel-button="false"
        :show-upload-button="false"
        :disabled="isLoading || isDisabled"
        :accept="acceptFiles.join(',')"
        :pt="{
            root: {
                class: ['flex flex-column-reverse border-round', mainClassElement],
            },
            buttonbar: {
                class: ['p-0 bg-ghost gap-0 border-round-bottom border-none', mainClassElement, buttonbarClass],
            },
            content: {
                class: ['p-0 border-none border-round-top', mainClassElement, contentClass],
            },
        }"
        @select="onSelectFile"
        @remove="onRemoveFile"
    >
        <template #header="{ chooseCallback }">
            <template v-if="commentModel">
                <Divider class="mb-0 mt-2" />
                <div class="w-full flex justify-content-between align-items-center gap-2 p-1 pr-2">
                    <button
                        class="border-round hover:surface-200 bg-ghost border-none flex align-items-center justify-content-center p-2"
                        :disabled="files.length >= 5 || isLoading"
                        @click="chooseCallback"
                    >
                        <span class="pi pi-image"></span>
                    </button>
                    <button
                        class="cursor-pointer bg-ghost border-none flex align-items-center justify-content-center"
                        :disabled="isLoading || isDisabled"
                        @click="sendComment"
                    >
                        <span
                            class="pi text-xl"
                            :class="[isLoading ? 'pi-spin pi-spinner text-400' : 'pi-arrow-circle-up text-blue-500']"
                        ></span>
                    </button>
                </div>
            </template>
            <span v-else></span>
        </template>
        <template #content="{ files, removeFileCallback }">
            <Mention ref="suggestionRef" :suggestions="suggestions" :get-suggestion="getSuggestion">
                <Textarea
                    ref="textareaRef"
                    class="w-full border-none text-sm outline-none shadow-none"
                    :class="mainClassElement"
                    v-model="commentModel"
                    rows="1"
                    auto-resize
                    :disabled="isLoading || isDisabled"
                    :placeholder="$t('post-comment')"
                    @keypress.enter.exact.prevent="sendComment"
                />

                <template #no-result>
                    <div class="dim">
                        {{ loadingSuggestions ? "Loading..." : "No result" }}
                    </div>
                </template>

                <template #item-#="{ item }">
                    {{ item.label }}
                </template>
            </Mention>
            <div class="w-full flex flex-wrap p-2 pt-0 pl-3" v-if="files.length">
                <div
                    class="file pt-2 pr-2 relative"
                    v-for="(file, index) of files"
                    :key="file.name + file.type + file.size"
                    @click="clickToFile(file)"
                >
                    <div
                        class="relative w-full h-full border-round border-1 border-200 overflow-hidden"
                        @click="setGalleriaCustomIndex(index)"
                    >
                        <ImageStorm v-if="fileToIFile(file).objectURL" :src="fileToIFile(file).objectURL" :alt="file.name" />
                        <b class="extension" v-else>{{ fileToIFile(file).extension }}</b>
                    </div>
                    <button
                        class="icon-delete absolute top-0 right-0 text-white border-circle surface-900 border-2 border-white"
                        :disabled="isLoading || isDisabled"
                        @click.stop="removeFileCallback(index)"
                    >
                        <span class="pi pi-times text-sm"></span>
                    </button>
                </div>
            </div>
        </template>
    </FileUpload>
</template>

<script setup lang="ts">
import { ref, computed, watch } from "vue";

import type { FileUploadRemoveEvent, FileUploadSelectEvent } from "primevue/fileupload";
import FileUpload from "primevue/fileupload";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";

import { ImageStorm } from "@/shared/ui/image";
import { Mention } from "@/shared/ui/mention";
import { useGalleria, useNotification } from "@/shared/lib/composables";
import { fileToIFile, filesToItemsGalleria } from "@/shared/lib/utils/file";
import { acceptFiles } from "@/shared/constants/acceptFiles";

import { useSuggestions } from "@/entities/Comment";

const props = defineProps({
    isLoading: { type: Boolean, required: true },
    isTransparent: { type: Boolean, default: true },
    isBorder: { type: Boolean, default: false },
    isDisabled: { type: Boolean, default: false },
});
const emits = defineEmits(["sendComment", "onFocusout"]);

const commentModel = defineModel<string>("comment", { required: true });
const files = defineModel<File[]>("files", { required: true });

const { setGalleriaCustomIndex, setGalleriaFiles, toggleGalleriaVisible } = useGalleria();
const { isLoading: loadingSuggestions, suggestions, getSuggestion } = useSuggestions();
const { showError } = useNotification();

const inputRef = ref();
const textareaRef = ref();
const fileInputRef = ref();
const suggestionRef = ref();

const mainClassElement = computed(() => (props.isTransparent ? "bg-white" : "surface-100"));
const contentClass = computed<string>(() => {
    if (!commentModel.value && props.isBorder) {
        return "border-1";
    }

    return props.isBorder ? "border-x-1 border-top-1 border-noround-bottom border-200" : "";
});
const buttonbarClass = computed<string>(() => {
    if (!commentModel.value && props.isBorder) {
        return "border-none";
    }

    return props.isBorder ? "border-x-1 border-bottom-1 border-200 border-noround-top" : "";
});

function onSelectFile(event: FileUploadSelectEvent) {
    if (event.files.length > 5) {
        showError("Вы можете загрузить не более 5 файлов");
        fileInputRef.value.clear();
        files.value = [];
        return;
    }
    files.value = event.files;
    setGalleriaFiles(filesToItemsGalleria(event.files));
}
function onRemoveFile(event: FileUploadRemoveEvent) {
    files.value = event.files;
    setGalleriaFiles(filesToItemsGalleria(event.files));
}

function sendComment() {
    emits("sendComment");
    fileInputRef.value.clear();
}

function clickToFile(file: File) {
    if (file.type.includes("image")) {
        toggleGalleriaVisible();
    }
}

watch([inputRef, textareaRef], value => {
    if (value[0]) value[0].$el.focus();
    if (value[1]) value[1].$el.focus();
});
</script>

<style scoped lang="scss">
@import "primeflex/primeflex.scss";

.create-comment-icon {
    translate: -50% -50%;
}
.bg-ghost {
    background-color: transparent;
    background: transparent;
}
.icon-delete {
    display: none;
    padding: 3px;
    width: 24px;
    height: 24px;
}
.file {
    width: 62px;
    height: 62px;

    &:hover {
        .icon-delete {
            display: block;
        }
    }
}

.extension {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
}
</style>
