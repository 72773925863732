<template>
    <div
        class="p-0 flex flex-column border-round-lg border-1 surface-border text-color transition-all transition-duration-300 shadow-0 hover:shadow-5 overflow-hidden post"
    >
        <router-link
            :to="{ name: ERoutesName.ARTICLE, params: { slug: isSlug(post), id: post?.slug || 'slug' } }"
            v-if="post?.image"
            class="flex justify-content-center"
        >
            <img
                style="object-fit: fill"
                class="border-round-top size__img transition-duration-300 image"
                :src="IMG_DIRECTUS + post.image.id + '?format=webp&height=300&width=600&fit=cover&quality=100'"
                :alt="post.title"
                width="600"
                height="300"
                loading="lazy"
                :title="post.title"
            />
        </router-link>
        <div class="flex flex-column gap-3 p-3 h-full justify-content-between size__post">
            <div class="flex flex-column gap-3 text-left align-items-start">
                <router-link :to="{ name: ERoutesName.ARTICLE, params: { slug: isSlug(post), id: post?.slug || 'slug' } }">
                    <div class="text-2xl font-semibold" style="width: 100%">
                        {{ post?.title }}
                    </div>
                </router-link>
                <router-link :to="{ name: ERoutesName.ARTICLE, params: { slug: isSlug(post), id: post?.slug || 'slug' } }">
                    <div style="line-height: 1.44;" class="text-base max-w-full">{{ post?.summary }}</div>
                </router-link>
            </div>

            <div class="flex flex-column gap-2">
                <div class="text-left flex gap-2" v-if="post?.tag">
                    <Tag :tag="post.tag" :size="ESizeTag.SMALL" />
                </div>
                <Divider   :pt="{
                            root: () => ({
                                class: ['m-0'],
                            }),
                            
                        }" align="left" type="solid"> </Divider>
                <div class="flex align-items-center justify-content-between text-lg gap-2">
                    <div class="flex align-items-center gap-2">
                        <ImgUserSmall>
                            <img
                                style="max-height: 42px; max-width: 42px"
                                width="42"
                                loading="lazy"
                                height="42"
                                :src="IMG_DIRECTUS + post?.author?.image?.id + '?format=webp&height=200&width=200&fit=cover&quality=100'"
                                :alt="post?.author?.name"
                                :title="post?.author?.name"
                            />
                        </ImgUserSmall>
                        {{ post?.author?.name }}
                    </div>
                    <div>{{ formatDate(post?.date_published) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { IMG_DIRECTUS } from "@/shared/config";
import { formatDate } from "@/shared/lib/utils/date";
import { ImgUserSmall } from "../img";
import { Tag } from ".";
import { ERoutesName } from "@/app/providers";
import { ESizeTag } from "@/shared/lib/types";

defineProps({
    post: { type: Object },
});

function isSlug(post: any) {
    if (post?.tag?.slug) return post.tag.slug;
    else return "info";
}
</script>

<style scoped lang="scss">
.size {
    &__img {
        max-width: 600px;
        max-height: 300px;

        @media screen and (max-width: 767px) {
            object-fit: cover;
            width: 100%;
            height: auto;
        }
    }
    &__post {
        max-width: 600px;
        width: 100%;

        @media screen and (max-width: 767px) {
            width: 100%;
            height: auto;
        }
    }
}
.post {
    max-width: 600px;
    width: 100%;

    @media screen and (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
    }
}
.post:hover *.image {
    transform: scale(1.1);
}
</style>
