<template>
    <div class="w-full h-screen flex flex-column layout">
        <AppHeader class="fixed top-0 left-0 right-0 z-2" />
        <div class="w-full flex content px-1 py-2">
            <Sidebar :countApprovals="countApprovals" v-if="withSidebar" />
            <main class="mx-4 w-full p-3">
                <RouterView></RouterView>
            </main>
        </div>
    </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { ApprovalModel } from "@/entities/Approval";
import { useTagStore } from "@/entities/Tag";
import { useTeamStore } from "@/entities/Team";
import { usePaymentStore } from "@/entities/Payment";

import { AppHeader } from "@/widgets/header";
import { Sidebar } from "@/widgets/sidebar";

const approvalStore = ApprovalModel.useApprovalStore();
const tagStore = useTagStore();
const route = useRoute();
const teamStore = useTeamStore();
const paymentStore = usePaymentStore();

const { countApprovals } = storeToRefs(approvalStore);

const withSidebar = computed(() => {
    return route.meta.sidebar;
});

onMounted(async () => {
    await paymentStore.getCurrentPlan();
    const promises = [approvalStore.fetchAll(), teamStore.fetchSettings()];
    await Promise.all(promises);
});
</script>

<style lang="scss" scoped>
.content {
    margin-top: 5rem;
    min-height: calc(100vh - 5rem);
}
.layout {
    padding: 0 calc(20px - (100vw - 100%)) 0 0;
}
</style>
