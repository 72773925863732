<template>
    <div class="flex gap-2 px-2 py-1 border-round surface-50 border-1 border-200 justify-content-between">
        <div class="flex gap-2">
            <div class="flex gap-1">
                <ToggleTokenSimulator />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <Button
                    :icon="iconFullScreen"
                    :text="!menuSettings.isFullscreen"
                    class="navbar-button button_clean-outline"
                    severity="contrast"
                    v-tooltip.bottom="$t('fullscreen')"
                    @click="actionMenuStore.toggleFullscreen"
                />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <Button
                    :icon="PrimeIcons.MAP"
                    :text="!menuSettings.isMinimap"
                    class="navbar-button"
                    severity="contrast"
                    v-tooltip.bottom="$t('open-minimap')"
                    @click="actionMenuStore.toggleMinimap"
                />
                <ScaleIn />
                <ScaleOut />
                <Undo />
                <Redo />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <ChangeColor />
                <Copy />
                <Paste />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <ToggleCheckErrors />
                <Button
                    :icon="PrimeIcons.LIST_CHECK"
                    text
                    severity="contrast"
                    class="navbar-button"
                    :loading="isLoading"
                    v-tooltip.bottom="$t('auto-numbering')"
                    @click="async () => setAutoNumerate()"
                />
                <Button
                    :icon="PrimeIcons.BAN"
                    text
                    severity="contrast"
                    class="navbar-button"
                    v-tooltip.bottom="$t('clear-numbering')"
                    :loading="isLoading"
                    @click="async () => setAutoNumerate(true)"
                />
            </div>
            <Divider layout="vertical" class="m-0 p-0" />
            <div class="flex gap-1">
                <Help />
                <Button
                    :icon="PrimeIcons.ANGLE_UP"
                    :text="!menuSettings.isHiddenMenu"
                    severity="contrast"
                    class="navbar-button"
                    v-tooltip.bottom="'Скрыть шапку'"
                    @click="actionMenuStore.toggleHeaderVisible"
                />
            </div>
        </div>
        <div class="flex gap-1">
            <ToggleOverlays />
            <!-- <InputSearch placeholder="Поиск" style="max-height: 30px" :isShowShortcut="true" /> -->
        </div>
    </div>
</template>

<script setup lang="ts">
import { watch, onUnmounted, ref, computed } from "vue";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { useDiagramActionMenuStore, useDiagramStore } from "@/entities/Process";
import { useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";

import { ChangeColor } from "@/features/Diagram-editor/change-color";
import { ToggleCheckErrors } from "@/features/Diagram-editor/toggle-check-errors";
import { useInstallationOverlays } from "@/features/Overlay/installation";
import { useAutoNumerate } from "@/features/Diagram-editor/auto-numerate";
import { ToggleTokenSimulator } from "@/features/Diagram-editor/toggle-token-simulator";
import { ScaleIn, ScaleOut } from "@/features/Diagram-editor/scale";
import { Redo, Undo } from "@/features/Diagram-editor/command-stack";
import { Copy, Paste } from "@/features/Diagram-editor/copy-paste";
import { Help } from "@/features/Diagram-editor/show-help";
import { ToggleOverlays } from "@/features/Diagram-editor/toggle-overlays";

import { useLinterOverlay } from "@/widgets/app/process";
import { InputSearch } from "@/shared/ui/input-search";

const actionMenuStore = useDiagramActionMenuStore();
const bpmnOverlayStore = useBpmnModelerOverlayStore();
const diagramStore = useDiagramStore();

const { menuSettings } = storeToRefs(actionMenuStore);
const { overlaySettings } = storeToRefs(diagramStore);

const { linterErrors } = useLinterOverlay();
const { installationOverlays } = useInstallationOverlays();
const { isLoading, setAutoNumerate } = useAutoNumerate();

const searchModel = ref<string>("");

const iconFullScreen = computed<string>(() => {
    if (menuSettings.value.isFullscreen) return PrimeIcons.ARROW_DOWN_LEFT_AND_ARROW_UP_RIGHT_TO_CENTER;
    return PrimeIcons.ARROW_UP_RIGHT_AND_ARROW_DOWN_LEFT_FROM_CENTER;
});

watch(
    overlaySettings,
    () => {
        installationOverlays();
    },
    {
        deep: true,
    },
);

watch(
    () => menuSettings.value.isCheckErrors,
    async value => {
        await bpmnOverlayStore.clearOverlays(true);

        if (value) {
            await linterErrors();
        } else {
            installationOverlays();
        }
    },
);

onUnmounted(() => {
    actionMenuStore.resetMenu();
});
</script>

<style scoped lang="scss"></style>
