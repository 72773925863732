<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 3710 3710"
        style="enable-background: new 0 0 3710 3710"
        xml:space="preserve"
    >
        <g id="Background">
            <g>
                <g>
                    <rect style="fill: #ffffff" width="3710" height="3710" />
                </g>
            </g>
        </g>
        <g id="Illustration">
            <g>
                <path
                    style="fill: #f4f7fa"
                    d="M1623.626,3127.613c-694.61-105.193-1259.1-761.932-1156.545-1491.883
			C554.573,1013.2,1100.366,528.736,1754.878,482.987c47.393-3.322,95.333-4.349,143.717-2.945
			c365.129,10.547,689.544,156.592,929.481,377.593c238.809,219.94,393.961,514.119,422.211,822.748
			C3325.518,2501.521,2487.569,3258.454,1623.626,3127.613z"
                />
                <g>
                    <g>
                        <g>
                            <defs>
                                <path
                                    id="SVGID_1_"
                                    d="M2200.991,1602.407c-9.973,0.886-19.503,1.908-28.203,2.756c-26.999,2.631-86.191,19.014-106.599-7.876
							c-33.646-44.363,51.831-119.596,73.351-153.394c26.619-41.807,59.602-88.357,64.953-139.132
							c8.138-77.222-44.762-181.683-110.046-222.012c-90.05-55.628-215.846-17.773-296.752,36.839
							c-117.203,79.114-199.559,209.61-232.986,345.911c-36.03,146.916,13.659,298.986-0.582,447.891
							c-22.384,234.042-354.092-64.129-289.268,551.317c14.311,135.862,37.65,284.491,113.981,400.941
							c31.655,48.292,94.936,115.397,158.014,111.523c89.434-5.493,85.438-132.017,113.184-190.424
							c32.434-68.274,89.019-122.214,151.898-162.563c79.979-51.321,172.702-97.22,204.494-194.119
							c20.644-62.922-10.424-127.722-50.178-176.668c-21.909-26.976-68.607-57.819-61.606-97.565
							c5.808-32.969,52.132-57.189,76.286-74.569c74.292-53.458,146.523-111.056,212.588-174.461
							c59.29-56.903,159.562-143.222,151.445-235.692C2338.436,1596.69,2261.544,1597.029,2200.991,1602.407z"
                                />
                            </defs>

                            <linearGradient
                                id="SVGID_00000049910312543141552140000005694426148662439843_"
                                gradientUnits="userSpaceOnUse"
                                x1="2022.7823"
                                y1="301.7057"
                                x2="1199.776"
                                y2="6673.3271"
                            >
                                <stop offset="0" style="stop-color: #fcb148" />
                                <stop offset="0.0521" style="stop-color: #fdba46" />
                                <stop offset="0.1424" style="stop-color: #ffc244" />
                                <stop offset="0.3183" style="stop-color: #ffc444" />
                                <stop offset="0.4849" style="stop-color: #fdb946" />
                                <stop offset="0.7754" style="stop-color: #f99c4d" />
                                <stop offset="0.8658" style="stop-color: #f8924f" />
                                <stop offset="1" style="stop-color: #f8924f" />
                            </linearGradient>

                            <use
                                xlink:href="#SVGID_1_"
                                style="overflow: visible; fill: url(#SVGID_00000049910312543141552140000005694426148662439843_)"
                            />
                            <clipPath id="SVGID_00000001642568518215412680000011182053502892460459_">
                                <use xlink:href="#SVGID_1_" style="overflow: visible" />
                            </clipPath>
                            <g style="opacity: 0.8; clip-path: url(#SVGID_00000001642568518215412680000011182053502892460459_)">
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1724.353,3137.945c-0.913,0-1.833-0.275-2.634-0.845
								c-152.654-109.039-224.497-275.521-219.632-508.958c4.24-203.514,63.895-424.335,121.586-637.884
								c45.431-168.169,88.342-327.011,96.155-454.594c6.258-102.221,35.446-194.942,86.755-275.589
								c41.032-64.493,96.206-121.416,163.988-169.188c115.297-81.26,228.864-108.232,229.997-108.495
								c2.442-0.57,4.88,0.954,5.446,3.395c0.567,2.442-0.953,4.88-3.394,5.447c-1.12,0.26-113.214,26.925-227.081,107.257
								c-104.824,73.951-232.705,209.922-246.653,437.728c-7.867,128.503-50.895,287.778-96.452,456.405
								c-113.527,420.238-242.203,896.545,94.561,1137.089c2.039,1.457,2.511,4.292,1.055,6.332
								C1727.164,3137.284,1725.77,3137.945,1724.353,3137.945z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1727.96,1501.155c-0.461,0-0.931-0.071-1.393-0.22c-1.22-0.394-30.274-10.08-62.063-47.874
								c-28.987-34.464-65.861-100.07-76.912-216.894c-0.236-2.495,1.596-4.71,4.091-4.945c2.478-0.248,4.71,1.596,4.945,4.091
								c10.783,113.997,46.369,177.831,74.322,211.308c30.159,36.117,58.135,45.587,58.414,45.677
								c2.377,0.78,3.682,3.339,2.907,5.719C1731.648,1499.934,1729.872,1501.155,1727.96,1501.155z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1682.57,1786.392c-2.299,0-4.27-1.74-4.509-4.078c-0.254-2.492,1.56-4.72,4.054-4.976
								c0.774-0.08,78.64-8.453,180.684-53.484c94.086-41.518,228.589-123.606,335.051-278.886c1.417-2.067,4.239-2.596,6.31-1.177
								c2.067,1.418,2.594,4.243,1.177,6.31c-107.762,157.176-243.946,240.22-339.213,282.209
								c-103.286,45.521-182.302,53.979-183.089,54.058C1682.88,1786.385,1682.723,1786.392,1682.57,1786.392z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1518.741,2482.364c-0.501,0-1.01-0.084-1.508-0.26
								c-3.161-1.114-78.122-28.16-140.053-103.928c-36.386-44.516-60.475-96.61-71.598-154.834
								c-13.862-72.56-7.489-154.845,18.943-244.573c0.706-2.405,3.227-3.778,5.636-3.072c2.405,0.709,3.779,3.233,3.072,5.636
								c-58.764,199.486-4.861,322.607,50.706,390.767c60.291,73.956,135.56,101.177,136.314,101.444
								c2.362,0.836,3.602,3.427,2.767,5.79C1522.362,2481.198,1520.612,2482.364,1518.741,2482.364z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1545.142,2329.188c-2.243,0-4.193-1.662-4.494-3.946c-0.327-2.485,1.423-4.764,3.908-5.092
								c3.628-0.479,363.922-49.423,452.208-185.756c1.367-2.102,4.175-2.704,6.277-1.343c2.104,1.364,2.705,4.174,1.343,6.277
								c-38.131,58.879-127.017,108.731-264.192,148.174c-101.698,29.24-193.535,41.526-194.451,41.647
								C1545.539,2329.177,1545.34,2329.188,1545.142,2329.188z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                    <g>
                        <g>
                            <defs>
                                <path
                                    id="SVGID_00000161592839355110199810000007746727773611533247_"
                                    d="M2696.925,1818.067
							c-31.29-112.77-130.366-198.178-243.71-227.663c-69.012-17.953-153.258-24.023-223.646-11.77
							c-98.998,17.233-180.718,81.811-244.416,156.662c-74.844,87.948-81.85,196.939-108.602,305.202
							c-5.245,21.225-39.844,138.102-66.044,69.605c-36.533-95.517-118.006-78.671-118.022-78.668
							c-63.747,13.185-112.743,59.994-136.312,118.899c-62.959,157.347-43.498,361.744-30.183,526.672
							c6.263,77.578,15.913,154.744,27.416,231.655c2.845,19.015,16.499,34.627,34.977,39.94
							c18.477,5.314,38.356-0.648,50.862-15.252c13.004-15.189,26.628-29.134,39.239-41.406
							c121.289-118.037,311.201-135.505,471.441-142.567c93.559-4.123,170.644-10.071,252.163-60.309
							c33.873-20.875,70.686-46.512,86.39-84.663c14.206-34.508,16.969-93.607-4.425-125.7
							c-26.645-39.971-85.823-37.454-121.05-66.896c-56.918-47.57,91.95-80.679,121.935-90.318
							c47.808-15.37,79.503-53.494,115.318-86.555c27.359-25.253,53.081-52.736,72.933-84.369
							C2733.832,2053.942,2726.385,1924.238,2696.925,1818.067z"
                                />
                            </defs>

                            <linearGradient
                                id="SVGID_00000068644883031815856150000011872610320789649848_"
                                gradientUnits="userSpaceOnUse"
                                x1="2267.9417"
                                y1="999.9291"
                                x2="1655.7579"
                                y2="5739.3857"
                            >
                                <stop offset="0" style="stop-color: #ff9085" />
                                <stop offset="1" style="stop-color: #fb6fbb" />
                            </linearGradient>

                            <use
                                xlink:href="#SVGID_00000161592839355110199810000007746727773611533247_"
                                style="overflow: visible; fill: url(#SVGID_00000068644883031815856150000011872610320789649848_)"
                            />
                            <clipPath id="SVGID_00000073697778865020067570000002830238662982218386_">
                                <use xlink:href="#SVGID_00000161592839355110199810000007746727773611533247_" style="overflow: visible" />
                            </clipPath>
                            <g style="opacity: 0.8; clip-path: url(#SVGID_00000073697778865020067570000002830238662982218386_)">
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1534.62,2989.107c-0.649,0-1.306-0.14-1.932-0.434c-2.266-1.068-3.238-3.773-2.17-6.041
								c1.494-3.167,151.677-320.502,358.951-645.748c121.797-191.117,239.202-347.564,348.956-464.996
								c137.613-147.238,263.712-233.358,374.797-255.968c2.466-0.501,4.853,1.086,5.353,3.541c0.501,2.457-1.086,4.853-3.541,5.353
								c-109.2,22.227-233.677,107.439-369.976,253.271c-109.374,117.025-226.435,273.028-347.932,463.677
								c-206.968,324.764-356.904,641.577-358.395,644.74C1537.954,2988.144,1536.322,2989.107,1534.62,2989.107z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M2074.38,2080.987c-0.238,0-0.479-0.018-0.721-0.056c-2.476-0.396-4.162-2.723-3.767-5.197
								c0.684-4.28,67.559-428.792,1.154-500.426c-1.704-1.838-1.596-4.708,0.244-6.413c1.838-1.705,4.711-1.593,6.413,0.244
								c69.383,74.847,3.971,490.374,1.154,508.026C2078.499,2079.398,2076.571,2080.987,2074.38,2080.987z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M2867.959,1861.195c-0.821,0-1.653-0.222-2.401-0.69
								c-178.024-111.267-548.383-53.159-552.105-52.559c-2.48,0.396-4.803-1.284-5.203-3.759c-0.399-2.475,1.284-4.804,3.758-5.203
								c0.938-0.151,95.098-15.131,210.23-16.007c106.524-0.835,253.432,10.644,348.13,69.831c2.126,1.328,2.771,4.128,1.443,6.254
								C2870.951,1860.44,2869.472,1861.195,2867.959,1861.195z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1684.035,2699.274c-0.34,0-0.684-0.039-1.028-0.118c-2.441-0.566-3.962-3.004-3.398-5.446
								c48.586-209.971-133.6-516.103-135.445-519.17c-1.293-2.148-0.6-4.936,1.548-6.229c2.153-1.29,4.939-0.598,6.229,1.548
								c0.467,0.774,47.05,78.614,86.92,181.647c36.899,95.355,75.455,232.482,49.592,344.251
								C1687.967,2697.854,1686.099,2699.274,1684.035,2699.274z"
                                    />
                                </g>
                                <g>
                                    <path
                                        style="fill: #ffffff"
                                        d="M1834.662,2438.007c-1.904,0-3.679-1.21-4.308-3.119c-0.786-2.38,0.508-4.946,2.888-5.731
								c1.628-0.538,165.062-54.137,362.997-92.968c183.017-35.903,433.866-66.154,589.605-6.375
								c2.34,0.898,3.509,3.524,2.611,5.864c-0.898,2.342-3.521,3.507-5.864,2.611c-153.726-59.006-402.76-28.866-584.604,6.809
								c-197.384,38.722-360.28,92.144-361.902,92.678C1835.613,2437.933,1835.133,2438.007,1834.662,2438.007z"
                                    />
                                </g>
                            </g>
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <linearGradient
                            id="SVGID_00000042726213539922060040000010942908577914954924_"
                            gradientUnits="userSpaceOnUse"
                            x1="644.1376"
                            y1="1044.7147"
                            x2="788.6233"
                            y2="1172.9825"
                        >
                            <stop offset="0" style="stop-color: #40447e" />
                            <stop offset="0.9964" style="stop-color: #3c3b6b" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000042726213539922060040000010942908577914954924_)"
                            d="M753.607,1110.962l108.691-7.304
					l5.678,115.468H688.837l38.978-88.461C732.466,1120.109,742.2,1112.673,753.607,1110.962z"
                        />

                        <linearGradient
                            id="SVGID_00000099652368461917803030000017058662462144393405_"
                            gradientUnits="userSpaceOnUse"
                            x1="806.0535"
                            y1="1175.2932"
                            x2="1843.9928"
                            y2="1175.2932"
                        >
                            <stop offset="0" style="stop-color: #40447e" />
                            <stop offset="0.9964" style="stop-color: #3c3b6b" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000099652368461917803030000017058662462144393405_)"
                            d="M846.183,1117.719
					c4.13-9.452,13.635-15.402,23.942-14.989l897.072,35.998c11.95,0.485,22.744,7.279,28.35,17.844l48.447,91.304l-1037.939-38.333
					L846.183,1117.719z"
                        />

                        <linearGradient
                            id="SVGID_00000042725465995348944800000008458410866136939407_"
                            gradientUnits="userSpaceOnUse"
                            x1="-39.1558"
                            y1="2156.4233"
                            x2="417.9847"
                            y2="2156.4233"
                        >
                            <stop offset="0" style="stop-color: #aa80f9" />
                            <stop offset="0.9964" style="stop-color: #6165d7" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000042725465995348944800000008458410866136939407_)"
                            d="M522.149,1280.031v1733.53
					c0,38.367,26.354,71.71,63.682,80.575l187.787,44.589l205.671-1415.372l-199.752-549.23l-185.12,23.767
					C553.097,1203.195,522.149,1238.37,522.149,1280.031z"
                        />

                        <linearGradient
                            id="SVGID_00000001665603970101427230000017381745681610094465_"
                            gradientUnits="userSpaceOnUse"
                            x1="1254.9408"
                            y1="1705.2413"
                            x2="1511.3505"
                            y2="2966.5039"
                        >
                            <stop offset="0" style="stop-color: #aa80f9" />
                            <stop offset="0.9964" style="stop-color: #6165d7" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000001665603970101427230000017381745681610094465_)"
                            d="M721.176,1246.264v1819.253
					c0,41.898,35.572,74.983,87.899,95.952l1116.454-81.003c27.178-26.738,56.38-58.136,56.38-95.952V1295.339
					c0-38.61-30.399-70.375-68.972-72.071l-1116.453-49.074C755.438,1172.39,721.176,1205.179,721.176,1246.264z"
                        />
                        <g>
                            <g>
                                <linearGradient
                                    id="SVGID_00000159448716999897442370000000442859723019835785_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="605.5426"
                                    y1="2150.8816"
                                    x2="688.837"
                                    y2="2150.8816"
                                >
                                    <stop offset="0" style="stop-color: #311944" />
                                    <stop offset="1" style="stop-color: #893976" />
                                </linearGradient>
                                <path
                                    style="fill: url(#SVGID_00000159448716999897442370000000442859723019835785_)"
                                    d="M636.504,3048.063v-1786.33
							c0-30.96,23.339-62.366,52.333-75.965l-33.17,4.259c-29.539,10.831-50.124,39.091-50.124,71.705v1786.33
							c0,22.255,9.616,42.627,25.259,56.75l47.094,11.183C652.964,3103.133,636.504,3077.2,636.504,3048.063z"
                                />
                            </g>
                        </g>

                        <linearGradient
                            id="SVGID_00000005985251526789587420000013646003458288584868_"
                            gradientUnits="userSpaceOnUse"
                            x1="979.4185"
                            y1="2122.8594"
                            x2="1981.9095"
                            y2="2122.8594"
                        >
                            <stop offset="0" style="stop-color: #aa80f9" />
                            <stop offset="0.9964" style="stop-color: #6165d7" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000005985251526789587420000013646003458288584868_)"
                            d="M1912.937,1223.268l-933.519-41.034
					c0.258,11.136,1.008,22.451,2.389,33.943c25.092,208.815,219.881,242.17,294.869,474.739
					c68.713,213.106-69.484,263.738,11.795,451.15c76.165,175.62,218.4,179.313,303.715,401.023
					c37.435,97.283,18.547,118.651,50.128,221.152c27.017,87.691,77.616,191.2,175.945,299.243l96.73-7.018
					c37.717-2.736,66.92-34.135,66.92-71.951V1295.338C1981.91,1256.729,1951.51,1224.964,1912.937,1223.268z"
                        />

                        <linearGradient
                            id="SVGID_00000108274215096758310480000015695614856747391112_"
                            gradientUnits="userSpaceOnUse"
                            x1="1742.7112"
                            y1="1631.1307"
                            x2="1229.6387"
                            y2="1697.4767"
                        >
                            <stop offset="0" style="stop-color: #311944" />
                            <stop offset="1" style="stop-color: #893976" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000108274215096758310480000015695614856747391112_)"
                            d="M893.04,1433.53v491.983
					c0,34.917,28.306,63.223,63.222,63.223h824.508c34.917,0,63.223-28.306,63.223-63.223v-453.634
					c0-33.775-26.547-61.585-60.285-63.154L959.2,1370.375C923.169,1368.699,893.04,1397.459,893.04,1433.53z"
                        />
                        <path
                            style="fill: #d8dee8"
                            d="M1815.408,1925.512v-453.634c0-33.775-26.547-61.585-60.285-63.154l-808.875-37.622
					c-30.018,4.778-53.208,30.784-53.208,62.427v491.983c0,34.917,28.306,63.223,63.222,63.223h795.924
					C1787.103,1988.735,1815.408,1960.429,1815.408,1925.512z"
                        />
                        <path
                            style="opacity: 0.7; fill: #f4f7fa"
                            d="M1522.585,1476.073v29.674c0,12.832,10.153,23.363,22.977,23.832l194.096,7.101
					c13.504,0.494,24.72-10.32,24.72-23.832v-29.675c0-12.832-10.153-23.363-22.976-23.832l-194.096-7.101
					C1533.802,1451.746,1522.585,1462.56,1522.585,1476.073z"
                        />

                        <linearGradient
                            id="SVGID_00000127043794028191801070000008975308284600821131_"
                            gradientUnits="userSpaceOnUse"
                            x1="2025.6631"
                            y1="1178.9562"
                            x2="1869.3824"
                            y2="1326.3909"
                        >
                            <stop offset="0" style="stop-color: #ff9085" />
                            <stop offset="1" style="stop-color: #fb6fbb" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000127043794028191801070000008975308284600821131_)"
                            d="M1908.864,1313.484
					c0,16.692-11.552,30.224-25.801,30.224c-14.249,0-25.801-13.532-25.801-30.224c0-16.692,11.552-30.224,25.801-30.224
					C1897.313,1283.26,1908.864,1296.792,1908.864,1313.484z"
                        />
                        <g>
                            <linearGradient
                                id="SVGID_00000065752928979620335220000005451782229587939508_"
                                gradientUnits="userSpaceOnUse"
                                x1="1552.9916"
                                y1="3002.2759"
                                x2="1591.3245"
                                y2="3132.0183"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000065752928979620335220000005451782229587939508_)"
                                d="M1525.329,2445.178l-0.974-73.34
						c-0.339-25.567-21.341-46.019-46.908-45.679l-199.971,2.654c-25.567,0.34-46.019,21.342-45.679,46.909l1.014,76.381
						c0.343,25.843,21.777,46.4,47.612,45.664l199.93-5.695C1505.638,2491.351,1525.665,2470.47,1525.329,2445.178z"
                            />

                            <linearGradient
                                id="SVGID_00000101061072704978173100000013262537366027210932_"
                                gradientUnits="userSpaceOnUse"
                                x1="1608.0831"
                                y1="2986"
                                x2="1646.4161"
                                y2="3115.7424"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000101061072704978173100000013262537366027210932_)"
                                d="M1228.838,2152.858l1.26,94.896
						c0.34,25.567,21.341,46.018,46.908,45.679l199.971-2.654c25.567-0.339,46.019-21.341,45.679-46.908l-1.225-92.242
						c-0.336-25.328-20.964-45.683-46.293-45.683h-200.006C1249.323,2105.945,1228.496,2127.05,1228.838,2152.858z"
                            />

                            <linearGradient
                                id="SVGID_00000181797348622287957470000008676335828915890073_"
                                gradientUnits="userSpaceOnUse"
                                x1="1852.5981"
                                y1="2913.7573"
                                x2="1890.931"
                                y2="3043.4998"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000181797348622287957470000008676335828915890073_)"
                                d="M1560.684,2442.762
						c0.343,25.843,21.777,46.4,47.612,45.664l204.023-5.812c17.384-0.495,31.242-14.688,31.32-32.078
						c0.149-33.606,0.355-82.327,0.355-82.327c0-25.81-21.104-46.636-46.913-46.294l-191.666,2.545
						c-25.567,0.339-46.019,21.34-45.679,46.908L1560.684,2442.762z"
                            />

                            <linearGradient
                                id="SVGID_00000181063706397388663400000004270454128107527864_"
                                gradientUnits="userSpaceOnUse"
                                x1="1500.3644"
                                y1="3017.8264"
                                x2="1538.6973"
                                y2="3147.5688"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000181063706397388663400000004270454128107527864_)"
                                d="M1234.511,2580.083l1.013,76.272
						c0.347,26.119,22.219,46.78,48.315,45.639l199.89-8.735c24.997-1.093,44.604-21.849,44.272-46.868l-0.972-73.232
						c-0.343-25.843-21.778-46.4-47.612-45.665l-199.931,5.695C1254.201,2533.909,1234.175,2554.79,1234.511,2580.083z"
                            />

                            <linearGradient
                                id="SVGID_00000177456530984306829450000002638290699622701988_"
                                gradientUnits="userSpaceOnUse"
                                x1="1244.0353"
                                y1="3093.5591"
                                x2="1282.3683"
                                y2="3223.3013"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000177456530984306829450000002638290699622701988_)"
                                d="M893.04,2461.167
						c0,26.086,21.54,47.022,47.616,46.28l211.826-6.035c25.285-0.72,45.311-21.601,44.975-46.894l-1.041-78.328
						c-0.339-25.568-21.341-46.019-46.908-45.679l-221.3,2.938c-19.398,0.257-34.999,16.015-35.034,35.415
						C893.107,2406.537,893.04,2461.167,893.04,2461.167z"
                            />

                            <linearGradient
                                id="SVGID_00000019651287994948565510000006197426311410670257_"
                                gradientUnits="userSpaceOnUse"
                                x1="1445.1793"
                                y1="3034.1299"
                                x2="1483.5123"
                                y2="3163.8723"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000019651287994948565510000006197426311410670257_)"
                                d="M1530.924,2866.595l-1.224-92.177
						c-0.347-26.118-22.219-46.779-48.315-45.639l-199.89,8.735c-24.998,1.093-44.605,21.849-44.272,46.868l1.274,95.99
						c0.351,26.466,22.779,47.251,49.195,45.593l199.839-12.548C1512.164,2911.87,1531.252,2891.273,1530.924,2866.595z"
                            />

                            <linearGradient
                                id="SVGID_00000112606510956352075790000010436970793078080412_"
                                gradientUnits="userSpaceOnUse"
                                x1="1130.682"
                                y1="3127.0491"
                                x2="1169.0149"
                                y2="3256.7915"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000112606510956352075790000010436970793078080412_)"
                                d="M1201.895,2788.743
						c-0.347-26.119-22.219-46.78-48.315-45.639l-226.006,9.876c-18.915,0.827-33.854,16.339-33.954,35.273
						c-0.23,43.405-0.579,113.196-0.579,113.196c0,26.711,22.541,47.881,49.199,46.207l217.57-13.661
						c24.632-1.547,43.72-22.144,43.393-46.822L1201.895,2788.743z"
                            />

                            <linearGradient
                                id="SVGID_00000026858638881807806270000015441851436482606762_"
                                gradientUnits="userSpaceOnUse"
                                x1="1301.6459"
                                y1="3076.5361"
                                x2="1339.9788"
                                y2="3206.2786"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000026858638881807806270000015441851436482606762_)"
                                d="M1194.719,2248.223l-1.283-96.595
						c-0.336-25.328-20.964-45.683-46.293-45.683H939.338c-25.569,0-46.298,20.728-46.298,46.298v99.37
						c0,25.81,21.104,46.636,46.912,46.294l209.087-2.775C1174.607,2294.792,1195.058,2273.791,1194.719,2248.223z"
                            />

                            <linearGradient
                                id="SVGID_00000139262315506135221880000016604247143090805931_"
                                gradientUnits="userSpaceOnUse"
                                x1="1745.0752"
                                y1="2945.5242"
                                x2="1783.4082"
                                y2="3075.2666"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000139262315506135221880000016604247143090805931_)"
                                d="M1565.029,2770.057l1.192,89.737
						c0.351,26.466,22.779,47.251,49.195,45.592l185.181-11.627c24.396-1.532,43.397-21.764,43.397-46.207v-86.255
						c0-26.363-21.982-47.405-48.319-46.253l-186.373,8.145C1584.303,2724.281,1564.697,2745.038,1565.029,2770.057z"
                            />

                            <linearGradient
                                id="SVGID_00000079481177731992336510000009759805133526359483_"
                                gradientUnits="userSpaceOnUse"
                                x1="1800.038"
                                y1="2929.2849"
                                x2="1838.371"
                                y2="3059.0276"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000079481177731992336510000009759805133526359483_)"
                                d="M1843.993,2564.743
						c0-26.086-21.541-47.022-47.616-46.28l-189.019,5.386c-25.285,0.72-45.311,21.601-44.975,46.894l0.947,71.287
						c0.347,26.118,22.219,46.779,48.315,45.638l200.993-8.783c17.155-0.75,30.711-14.792,30.829-31.963
						C1843.697,2613.445,1843.993,2564.743,1843.993,2564.743z"
                            />

                            <linearGradient
                                id="SVGID_00000017510587529126522030000010061586944357840820_"
                                gradientUnits="userSpaceOnUse"
                                x1="1907.8097"
                                y1="2897.4431"
                                x2="1946.1427"
                                y2="3027.1858"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000017510587529126522030000010061586944357840820_)"
                                d="M1558.036,2243.401
						c0.34,25.567,21.341,46.019,46.909,45.679l207.094-2.749c17.614-0.234,31.778-14.535,31.81-32.15
						c0.071-39.53,0.144-101.938,0.144-101.938c0-25.569-20.728-46.298-46.298-46.298h-194.567
						c-25.81,0-46.636,21.104-46.293,46.913L1558.036,2243.401z"
                            />

                            <linearGradient
                                id="SVGID_00000083799775220480126540000010395484709774128562_"
                                gradientUnits="userSpaceOnUse"
                                x1="1188.7952"
                                y1="3109.8804"
                                x2="1227.1282"
                                y2="3239.6228"
                            >
                                <stop offset="0" style="stop-color: #311944" />
                                <stop offset="1" style="stop-color: #893976" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000083799775220480126540000010395484709774128562_)"
                                d="M1199.157,2582.499
						c-0.343-25.843-21.778-46.4-47.612-45.665l-223.657,6.372c-19.153,0.546-34.417,16.176-34.494,35.336
						c-0.149,37.47-0.354,92.165-0.354,92.165c0,26.363,21.982,47.404,48.319,46.253l214.564-9.376
						c24.998-1.093,44.604-21.849,44.272-46.868L1199.157,2582.499z"
                            />
                        </g>
                        <g>
                            <linearGradient
                                id="SVGID_00000139264098941659874100000008482678589651433103_"
                                gradientUnits="userSpaceOnUse"
                                x1="1691.6007"
                                y1="2075.0479"
                                x2="748.0178"
                                y2="3142.4749"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000139264098941659874100000008482678589651433103_)"
                                d="M1540.887,2446.3l-0.974-73.34
						c-0.34-25.567-21.341-46.019-46.908-45.679l-199.971,2.655c-25.567,0.339-46.019,21.341-45.679,46.908l1.014,76.381
						c0.343,25.843,21.778,46.4,47.612,45.665l199.931-5.696C1521.196,2492.473,1541.222,2471.593,1540.887,2446.3z"
                            />

                            <linearGradient
                                id="SVGID_00000029728235477351379660000006483376297811160722_"
                                gradientUnits="userSpaceOnUse"
                                x1="1584.7782"
                                y1="1980.6196"
                                x2="641.1959"
                                y2="3048.0461"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000029728235477351379660000006483376297811160722_)"
                                d="M1244.396,2153.98l1.26,94.896
						c0.339,25.568,21.34,46.019,46.908,45.679l199.971-2.654c25.567-0.339,46.019-21.34,45.679-46.908l-1.225-92.242
						c-0.336-25.328-20.964-45.683-46.293-45.683H1290.69C1264.88,2107.067,1244.054,2128.172,1244.396,2153.98z"
                            />

                            <linearGradient
                                id="SVGID_00000053547797539878958390000016851711515308169914_"
                                gradientUnits="userSpaceOnUse"
                                x1="1871.7246"
                                y1="2234.2742"
                                x2="928.1428"
                                y2="3301.7002"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000053547797539878958390000016851711515308169914_)"
                                d="M1576.241,2443.884
						c0.343,25.843,21.778,46.4,47.612,45.664l204.023-5.812c17.384-0.495,31.242-14.687,31.319-32.078
						c0.149-33.606,0.355-82.326,0.355-82.326c0-25.81-21.105-46.637-46.912-46.294l-191.667,2.544
						c-25.567,0.339-46.019,21.341-45.679,46.909L1576.241,2443.884z"
                            />

                            <linearGradient
                                id="SVGID_00000002370918828162136070000008857846012718654361_"
                                gradientUnits="userSpaceOnUse"
                                x1="1793.7386"
                                y1="2165.3372"
                                x2="850.1561"
                                y2="3232.7637"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000002370918828162136070000008857846012718654361_)"
                                d="M1250.068,2581.205l1.013,76.273
						c0.347,26.118,22.219,46.779,48.315,45.638l199.89-8.736c24.998-1.092,44.605-21.849,44.273-46.868l-0.972-73.233
						c-0.343-25.843-21.778-46.4-47.612-45.664l-199.93,5.695C1269.759,2535.031,1249.732,2555.912,1250.068,2581.205z"
                            />

                            <linearGradient
                                id="SVGID_00000041284903162973151480000003525858828772375428_"
                                gradientUnits="userSpaceOnUse"
                                x1="1506.3346"
                                y1="1911.2769"
                                x2="562.7525"
                                y2="2978.7031"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000041284903162973151480000003525858828772375428_)"
                                d="M908.597,2462.29
						c0,26.086,21.541,47.022,47.616,46.279l211.826-6.035c25.285-0.72,45.311-21.6,44.975-46.894l-1.04-78.328
						c-0.34-25.567-21.341-46.018-46.908-45.679l-221.3,2.938c-19.398,0.257-34.999,16.015-35.034,35.415
						C908.665,2407.659,908.597,2462.29,908.597,2462.29z"
                            />

                            <linearGradient
                                id="SVGID_00000171681177603987968510000006232878973775257513_"
                                gradientUnits="userSpaceOnUse"
                                x1="1900.6451"
                                y1="2259.8391"
                                x2="957.0644"
                                y2="3327.2639"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000171681177603987968510000006232878973775257513_)"
                                d="M1546.482,2867.718l-1.224-92.178
						c-0.347-26.118-22.219-46.779-48.315-45.638l-199.89,8.735c-24.998,1.092-44.605,21.849-44.272,46.868l1.274,95.991
						c0.352,26.466,22.78,47.25,49.196,45.592l199.839-12.549C1527.721,2912.992,1546.81,2892.395,1546.482,2867.718z"
                            />

                            <linearGradient
                                id="SVGID_00000069381734500183103440000001219911399598572974_"
                                gradientUnits="userSpaceOnUse"
                                x1="1719.5024"
                                y1="2099.7117"
                                x2="775.9195"
                                y2="3167.1387"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000069381734500183103440000001219911399598572974_)"
                                d="M1217.453,2789.865
						c-0.347-26.118-22.219-46.779-48.315-45.639l-226.006,9.877c-18.916,0.826-33.855,16.339-33.955,35.272
						c-0.23,43.406-0.579,113.197-0.579,113.197c0,26.711,22.541,47.88,49.199,46.207l217.571-13.661
						c24.632-1.547,43.72-22.143,43.392-46.821L1217.453,2789.865z"
                            />

                            <linearGradient
                                id="SVGID_00000019661046716007575850000012190606794388871861_"
                                gradientUnits="userSpaceOnUse"
                                x1="1399.5649"
                                y1="1816.8949"
                                x2="455.9833"
                                y2="2884.3206"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000019661046716007575850000012190606794388871861_)"
                                d="M1210.276,2249.345l-1.282-96.595
						c-0.336-25.328-20.964-45.683-46.294-45.683H954.895c-25.57,0-46.298,20.728-46.298,46.298v99.371
						c0,25.81,21.105,46.636,46.912,46.293l209.087-2.775C1190.164,2295.915,1210.615,2274.913,1210.276,2249.345z"
                            />

                            <linearGradient
                                id="SVGID_00000170963432241195112590000015438657767664662700_"
                                gradientUnits="userSpaceOnUse"
                                x1="2072.0903"
                                y1="2411.3928"
                                x2="1128.5081"
                                y2="3478.8191"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000170963432241195112590000015438657767664662700_)"
                                d="M1580.586,2771.18l1.192,89.737
						c0.352,26.466,22.779,47.251,49.195,45.592l185.181-11.627c24.394-1.532,43.396-21.764,43.396-46.207v-86.254
						c0-26.363-21.981-47.405-48.319-46.253l-186.373,8.145C1599.861,2725.404,1580.254,2746.16,1580.586,2771.18z"
                            />

                            <linearGradient
                                id="SVGID_00000073701574262928714840000008192271053102163641_"
                                gradientUnits="userSpaceOnUse"
                                x1="1970.5909"
                                y1="2321.6697"
                                x2="1027.0087"
                                y2="3389.0962"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000073701574262928714840000008192271053102163641_)"
                                d="M1859.55,2565.865
						c0-26.086-21.541-47.022-47.616-46.279l-189.018,5.385c-25.285,0.72-45.312,21.601-44.975,46.893l0.946,71.287
						c0.347,26.119,22.219,46.78,48.315,45.639l200.993-8.783c17.155-0.75,30.711-14.792,30.829-31.963
						C1859.254,2614.567,1859.55,2565.865,1859.55,2565.865z"
                            />

                            <linearGradient
                                id="SVGID_00000172411292696913835430000008563561762692413879_"
                                gradientUnits="userSpaceOnUse"
                                x1="1768.0555"
                                y1="2142.6331"
                                x2="824.4734"
                                y2="3210.0593"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000172411292696913835430000008563561762692413879_)"
                                d="M1573.594,2244.523
						c0.339,25.567,21.341,46.019,46.908,45.679l207.095-2.749c17.613-0.234,31.778-14.535,31.81-32.15
						c0.071-39.529,0.144-101.938,0.144-101.938c0-25.57-20.728-46.298-46.298-46.298h-194.567
						c-25.81,0-46.637,21.105-46.294,46.913L1573.594,2244.523z"
                            />

                            <linearGradient
                                id="SVGID_00000122711217258439817470000015043801904746938021_"
                                gradientUnits="userSpaceOnUse"
                                x1="1610.3645"
                                y1="2003.2385"
                                x2="666.7831"
                                y2="3070.6638"
                            >
                                <stop offset="0" style="stop-color: #40447e" />
                                <stop offset="0.9964" style="stop-color: #3c3b6b" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000122711217258439817470000015043801904746938021_)"
                                d="M1214.714,2583.621
						c-0.343-25.843-21.777-46.4-47.612-45.664l-223.657,6.372c-19.153,0.545-34.417,16.176-34.493,35.335
						c-0.149,37.47-0.355,92.166-0.355,92.166c0,26.363,21.981,47.405,48.319,46.253l214.564-9.377
						c24.998-1.093,44.605-21.849,44.272-46.868L1214.714,2583.621z"
                            />
                        </g>
                        <g style="opacity: 0.3">
                            <path
                                style="fill: #f4f7fa"
                                d="M1813.252,2107.067h-194.567c-25.81,0-46.637,21.106-46.294,46.913l1.202,90.544
						c0.339,25.566,21.341,46.019,46.908,45.678l207.095-2.749c17.613-0.234,31.778-14.535,31.81-32.15
						c0.071-39.529,0.143-101.937,0.143-101.937C1859.55,2127.796,1838.822,2107.067,1813.252,2107.067z"
                            />
                            <path
                                style="fill: #f4f7fa"
                                d="M1812.638,2323.038l-191.666,2.544c-25.568,0.34-46.019,21.341-45.679,46.908l0.948,71.394
						c0.343,25.843,21.778,46.4,47.612,45.664l204.023-5.812c17.384-0.496,31.242-14.688,31.319-32.079
						c0.149-33.606,0.355-82.326,0.355-82.326C1859.55,2343.521,1838.446,2322.696,1812.638,2323.038z"
                            />
                            <path
                                style="fill: #f4f7fa"
                                d="M1859.55,2848.674v-86.255c0-26.362-21.981-47.404-48.319-46.253l-179.844,7.859
						c2.794,12.01,6.31,25.226,10.928,40.215c13.138,42.642,31.877,89.029,58.944,137.854l114.896-7.214
						C1840.548,2893.349,1859.55,2873.117,1859.55,2848.674z"
                            />
                            <path
                                style="fill: #f4f7fa"
                                d="M1624.501,2688.77c0.899,0.013,1.791,0.06,2.7,0.02l200.993-8.782
						c17.155-0.751,30.711-14.792,30.83-31.962c0.23-33.479,0.526-82.181,0.526-82.181c0-26.087-21.54-47.023-47.616-46.28
						l-189.019,5.386c-12.705,0.362-24.076,5.819-32.21,14.363c0.493,1.256,0.993,2.483,1.482,3.754
						C1618.225,2610.754,1617.024,2641.712,1624.501,2688.77z"
                            />
                            <path
                                style="fill: #f4f7fa"
                                d="M1493.005,2327.281l-53.149,0.706c34.54,31.463,69.409,65.485,100.968,113.6l-0.911-68.627
						C1539.573,2347.392,1518.572,2326.941,1493.005,2327.281z"
                            />
                            <path
                                style="fill: #f4f7fa"
                                d="M1538.214,2244.992l-1.225-92.243c-0.336-25.326-20.964-45.682-46.293-45.682H1290.69
						c-5.126,0-10.042,0.863-14.648,2.4c3.407,10.412,7.509,21.25,12.43,32.598c29.929,69.009,70.061,111.47,112.93,151.044
						l91.133-1.209C1518.102,2291.561,1538.553,2270.56,1538.214,2244.992z"
                            />
                        </g>
                        <path
                            style="opacity: 0.4; fill: #ffffff"
                            d="M1780.77,1988.735c34.917,0,63.223-28.306,63.223-63.223v-453.634
					c0-33.775-26.547-61.585-60.285-63.154l-735.533-34.21c70.755,91.98,177.419,157.974,228.502,316.402
					c45.732,141.832-0.174,211.701-12.044,297.819H1780.77z"
                        />
                    </g>

                    <linearGradient
                        id="SVGID_00000139262029777498114320000000765618179005720237_"
                        gradientUnits="userSpaceOnUse"
                        x1="1333.8291"
                        y1="1314.9358"
                        x2="2204.8079"
                        y2="1314.9358"
                    >
                        <stop offset="0" style="stop-color: #aa80f9" />
                        <stop offset="0.9964" style="stop-color: #6165d7" />
                    </linearGradient>
                    <path
                        style="fill: url(#SVGID_00000139262029777498114320000000765618179005720237_)"
                        d="M1804.598,1332v1.782
				c0,15.248-13.18,27.082-28.411,26.427l-816.21-35.063c-14.441-0.62-26.358-13.147-26.358-27.977v-1.733
				c0-14.831,11.505-26.372,25.947-25.778l816.717,33.593C1791.513,1303.879,1804.598,1316.75,1804.598,1332z"
                    />

                    <linearGradient
                        id="SVGID_00000067953778231212159920000007836281931253106324_"
                        gradientUnits="userSpaceOnUse"
                        x1="933.6188"
                        y1="1314.554"
                        x2="1784.4979"
                        y2="1314.554"
                    >
                        <stop offset="0" style="stop-color: #40447e" />
                        <stop offset="0.9964" style="stop-color: #3c3b6b" />
                    </linearGradient>
                    <path
                        style="fill: url(#SVGID_00000067953778231212159920000007836281931253106324_)"
                        d="M1784.498,1331.267v1.78
				c0,15.232-12.012,27.053-27.218,26.399l-797.603-34.292c-14.435-0.62-26.058-13.155-26.058-27.984v-1.733
				c0-14.831,11.505-26.372,25.947-25.778l797.445,32.861C1772.225,1303.146,1784.498,1316.033,1784.498,1331.267z"
                    />
                    <g>
                        <path
                            style="fill: #bfc8d6"
                            d="M1789.681,641.818l-24.322,620.638l-1.363,35.16c-1.396,35.445-29.848,61.556-65.006,60.017
					l-754.181-34.095c41.063-4.538,43.211-41.131,43.211-41.131s1.743-641.995,1.778-664.076l52.51,58.214l39.818-43.827
					l41.651,45.985l15.016-16.299l25.66-27.904l42.22,46.423l41.548-44.586l42.798,46.832l42.47-44.937l43.385,47.208l43.372-45.327
					l43.985,47.661l44.359-45.722l44.598,48.083l45.328-46.122l45.22,48.547l46.35-46.53l45.889,48.98L1789.681,641.818z"
                        />
                        <path
                            style="fill: #ffffff"
                            d="M1796.611,641.818l-24.322,620.638l-1.363,35.16c-1.396,35.445-29.848,61.556-65.006,60.017
					l-754.181-34.095c41.063-4.538,43.211-41.131,43.211-41.131s1.744-641.995,1.778-664.076l52.51,58.214l39.818-43.827
					l41.651,45.985l15.016-16.299l25.66-27.904l42.22,46.423l41.548-44.586l42.798,46.832l42.47-44.937l43.385,47.208l43.372-45.327
					l43.985,47.661l44.359-45.722l44.598,48.083l45.328-46.122l45.22,48.547l46.35-46.53l45.889,48.98L1796.611,641.818z"
                        />
                        <g>
                            <linearGradient
                                id="SVGID_00000069364738526622098910000000287461177680008614_"
                                gradientUnits="userSpaceOnUse"
                                x1="1633.144"
                                y1="653.1434"
                                x2="1644.1782"
                                y2="1955.2977"
                            >
                                <stop offset="0.494" style="stop-color: #ebeff2" />
                                <stop offset="1" style="stop-color: #ffffff" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000069364738526622098910000000287461177680008614_)"
                                d="M1687.403,1019.462l-100.929-4.012
						c-6.716-0.267-12.158,4.958-12.362,11.854l-1.386,46.895c-0.218,7.371,5.641,13.786,12.828,14.042l100.629,3.577
						c6.783,0.241,12.284-4.956,12.528-11.848l1.641-46.31C1700.615,1026.217,1694.717,1019.753,1687.403,1019.462z"
                            />

                            <linearGradient
                                id="SVGID_00000158030406566631178150000018445832792756238238_"
                                gradientUnits="userSpaceOnUse"
                                x1="1629.4398"
                                y1="662.5323"
                                x2="1640.2489"
                                y2="1938.1348"
                            >
                                <stop offset="0.494" style="stop-color: #ebeff2" />
                                <stop offset="1" style="stop-color: #ffffff" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000158030406566631178150000018445832792756238238_)"
                                d="M1684.072,1111.011l-100.504-3.767
						c-6.629-0.248-11.963,4.864-12.164,11.647l-1.435,48.547c-0.217,7.344,5.674,13.782,12.863,14.051l100.186,3.747
						c6.713,0.251,12.132-4.839,12.373-11.633l1.715-48.385C1697.37,1117.782,1691.408,1111.286,1684.072,1111.011z"
                            />

                            <linearGradient
                                id="SVGID_00000155121999914400300430000010613201487517028521_"
                                gradientUnits="userSpaceOnUse"
                                x1="1641.8485"
                                y1="649.079"
                                x2="1653.0688"
                                y2="1973.2046"
                            >
                                <stop offset="0.494" style="stop-color: #ebeff2" />
                                <stop offset="1" style="stop-color: #ffffff" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000155121999914400300430000010613201487517028521_)"
                                d="M1705.523,887.752l3.071-86.64
						c0.268-7.556-5.623-13.992-12.977-14.182l-102.065-2.636c-6.838-0.177-12.413,5.304-12.623,12.395l-2.558,86.51
						c-0.22,7.444,5.604,13.826,12.804,14.027l101.549,2.822C1699.63,900.241,1705.272,894.825,1705.523,887.752z"
                            />
                        </g>
                        <g>
                            <linearGradient
                                id="SVGID_00000045584721128833806980000012579906460044570775_"
                                gradientUnits="userSpaceOnUse"
                                x1="1285.8861"
                                y1="636.9099"
                                x2="1296.5542"
                                y2="1895.8539"
                            >
                                <stop offset="0.494" style="stop-color: #ebeff2" />
                                <stop offset="1" style="stop-color: #ffffff" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000045584721128833806980000012579906460044570775_)"
                                d="M1479.958,1011.217l-382.564-15.207
						c-6.069-0.241-10.878,4.74-10.923,11.303l-0.346,50.261c-0.048,7.012,5.371,13.105,11.864,13.336l381.37,13.556
						c6.504,0.231,11.732-4.863,11.905-11.613l1.23-47.739C1492.683,1017.823,1486.972,1011.496,1479.958,1011.217z"
                            />

                            <linearGradient
                                id="SVGID_00000114051327177829650350000017813179878849993368_"
                                gradientUnits="userSpaceOnUse"
                                x1="1283.7982"
                                y1="660.2584"
                                x2="1293.9591"
                                y2="1859.3651"
                            >
                                <stop offset="0.494" style="stop-color: #ebeff2" />
                                <stop offset="1" style="stop-color: #ffffff" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000114051327177829650350000017813179878849993368_)"
                                d="M1477.504,1103.268l-380.923-14.278
						c-5.99-0.225-10.704,4.649-10.748,11.102l-0.344,49.896c-0.048,6.987,5.401,13.101,11.897,13.344l379.717,14.202
						c6.437,0.241,11.588-4.749,11.759-11.404l1.261-48.955C1490.311,1109.89,1484.539,1103.532,1477.504,1103.268z"
                            />

                            <linearGradient
                                id="SVGID_00000165217241178046373620000006865117161108951737_"
                                gradientUnits="userSpaceOnUse"
                                x1="1291.2318"
                                y1="633.8536"
                                x2="1302.072"
                                y2="1913.1278"
                            >
                                <stop offset="0.494" style="stop-color: #ebeff2" />
                                <stop offset="1" style="stop-color: #ffffff" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000165217241178046373620000006865117161108951737_)"
                                d="M1496.177,882.198l2.237-86.804
						c0.191-7.4-5.512-13.699-12.562-13.881l-386.694-9.987c-6.175-0.16-11.098,5.059-11.144,11.802l-0.599,86.904
						c-0.049,7.079,5.337,13.141,11.841,13.321l384.763,10.694C1490.638,894.432,1495.999,889.125,1496.177,882.198z"
                            />
                        </g>
                    </g>

                    <linearGradient
                        id="SVGID_00000061433667693991647250000000190263200791990929_"
                        gradientUnits="userSpaceOnUse"
                        x1="951.7387"
                        y1="988.0134"
                        x2="1772.2892"
                        y2="988.0134"
                    >
                        <stop offset="0.494" style="stop-color: #ebeff2" />
                        <stop offset="1" style="stop-color: #ffffff" />
                    </linearGradient>
                    <path
                        style="opacity: 0.5; fill: url(#SVGID_00000061433667693991647250000000190263200791990929_)"
                        d="M1772.289,1262.455
				l-1.363,35.16c-1.396,35.445-29.848,61.556-65.006,60.017l-754.181-34.095c41.063-4.538,43.211-41.131,43.211-41.131
				s1.744-641.995,1.778-664.076l52.51,58.214l39.818-43.827l41.651,45.985l15.016-16.299
				c29.851,26.332,58.139,62.583,79.477,112.387c9.587,22.419,17.776,47.554,24.016,75.768c2.916,13.169,5.866,25.604,8.852,37.409
				c12.5,49.067,25.769,86.787,40.88,116.048c19.163,37.126,41.297,60.645,68.39,76.463c15.321,8.961,32.259,15.479,51.172,20.572
				c21.137,5.75,44.79,9.784,71.432,13.549c23.054,3.278,48.331,6.397,76.268,10.317c1.655,0.232,3.312,0.466,4.967,0.735
				c49.865,7.677,91.485,24.391,124.271,46.385C1732.966,1197.175,1758.857,1229.199,1772.289,1262.455z"
                    />
                    <g style="opacity: 0.7">
                        <linearGradient
                            id="SVGID_00000000202161553134576900000002568938491201254784_"
                            gradientUnits="userSpaceOnUse"
                            x1="1305.2722"
                            y1="1878.4436"
                            x2="1389.0895"
                            y2="1878.4436"
                        >
                            <stop offset="0" style="stop-color: #aa80f9" />
                            <stop offset="0.9964" style="stop-color: #6165d7" />
                        </linearGradient>

                        <circle
                            style="fill: url(#SVGID_00000000202161553134576900000002568938491201254784_)"
                            cx="1347.181"
                            cy="1878.444"
                            r="41.909"
                        />

                        <linearGradient
                            id="SVGID_00000047779456028881107110000017387354203829171110_"
                            gradientUnits="userSpaceOnUse"
                            x1="1107.9943"
                            y1="1595.4147"
                            x2="1586.3678"
                            y2="1595.4147"
                        >
                            <stop offset="0" style="stop-color: #aa80f9" />
                            <stop offset="0.9964" style="stop-color: #6165d7" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000047779456028881107110000017387354203829171110_)"
                            d="M1347.181,1527.175
					c-87.561,0-167.626,32.051-229.135,85.047c-12.622,10.875-13.527,30.102-1.746,41.883l1.183,1.182
					c10.481,10.481,27.321,11.247,38.538,1.557c51.284-44.299,118.074-71.125,191.161-71.125
					c73.088,0,139.877,26.826,191.161,71.125c11.217,9.689,28.057,8.923,38.538-1.557l1.182-1.183
					c11.781-11.781,10.876-31.008-1.746-41.883C1514.807,1559.226,1434.742,1527.175,1347.181,1527.175z"
                        />

                        <linearGradient
                            id="SVGID_00000153705566838567343160000003244463898703527869_"
                            gradientUnits="userSpaceOnUse"
                            x1="1177.2222"
                            y1="1678.3094"
                            x2="1517.1395"
                            y2="1678.3094"
                        >
                            <stop offset="0" style="stop-color: #aa80f9" />
                            <stop offset="0.9964" style="stop-color: #6165d7" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000153705566838567343160000003244463898703527869_)"
                            d="M1347.181,1624.749
					c-60.49,0-116.001,21.205-159.586,56.552c-13.044,10.578-13.872,30.227-1.997,42.102l0.203,0.203
					c10.407,10.407,26.878,10.978,38.34,1.747c33.668-27.112,76.446-43.36,123.039-43.36c46.594,0,89.371,16.249,123.039,43.36
					c11.463,9.231,27.934,8.66,38.34-1.746l0.203-0.204c11.875-11.875,11.047-31.524-1.997-42.102
					C1463.182,1645.954,1407.671,1624.749,1347.181,1624.749z"
                        />

                        <linearGradient
                            id="SVGID_00000095338677522384948150000000898953355080972429_"
                            gradientUnits="userSpaceOnUse"
                            x1="1246.9272"
                            y1="1762.1199"
                            x2="1447.4344"
                            y2="1762.1199"
                        >
                            <stop offset="0" style="stop-color: #aa80f9" />
                            <stop offset="0.9964" style="stop-color: #6165d7" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000095338677522384948150000000898953355080972429_)"
                            d="M1255.401,1793.205
					c10.301,10.302,26.285,11.463,38.481,3.495c15.322-10.011,33.63-15.832,53.299-15.832s37.978,5.82,53.299,15.832
					c12.196,7.969,28.179,6.807,38.481-3.495l0.133-0.134c12.629-12.628,10.642-33.629-4.111-43.694
					c-25.013-17.066-55.236-27.053-87.803-27.053c-32.568,0-62.789,9.987-87.803,27.052c-14.753,10.065-16.74,31.066-4.111,43.695
					L1255.401,1793.205z"
                        />
                    </g>
                </g>
                <g>
                    <linearGradient
                        id="SVGID_00000049184261581989058600000016691459075329164477_"
                        gradientUnits="userSpaceOnUse"
                        x1="2294.7788"
                        y1="2102.2583"
                        x2="745.3521"
                        y2="1960.4285"
                        gradientTransform="matrix(0.9966 -0.0282 0.1345 0.99 474.0173 -191.4288)"
                    >
                        <stop offset="0" style="stop-color: #311944" />
                        <stop offset="1" style="stop-color: #893976" />
                    </linearGradient>
                    <path
                        style="fill: url(#SVGID_00000049184261581989058600000016691459075329164477_)"
                        d="M2879.214,2097.298
				c2.612,19.267-8.874,36.013-25.58,37.217l-1149.65,84.917c-21.281,1.532-47.922-16.705-50.813-38.563l-106.592-805.904
				c-2.878-21.762,19.361-40.772,40.832-40.486l1156.553-5.449c16.862,0.217,32.741,16.093,35.342,35.282L2879.214,2097.298z"
                    />

                    <linearGradient
                        id="SVGID_00000109737948362503682430000005256598723972506032_"
                        gradientUnits="userSpaceOnUse"
                        x1="929.8579"
                        y1="2012.6169"
                        x2="2977.3936"
                        y2="2088.7834"
                        gradientTransform="matrix(0.9975 -0.0369 0.1345 0.99 472.6763 -185.2697)"
                    >
                        <stop offset="0.0036" style="stop-color: #e38ddd" />
                        <stop offset="1" style="stop-color: #9571f6" />
                    </linearGradient>
                    <path
                        style="fill: url(#SVGID_00000109737948362503682430000005256598723972506032_)"
                        d="M2901.42,2098.362
				c2.627,19.368-8.925,36.203-25.726,37.412l-1162.926,83.709c-21.392,1.54-41.339-15.174-44.245-37.146l-107.173-810.048
				c-2.894-21.874,12.462-39.534,34.044-39.247l1170.022-6.887c16.958,0.217,32.927,16.176,35.544,35.466L2901.42,2098.362z"
                    />
                    <g style="opacity: 0.7">
                        <path
                            style="fill: #f4f7fa"
                            d="M2010.665,1939.823l-201.334,7.8c-9.119,0.353-18.053-6.521-19.839-15.28l-9.803-48.062
					c-1.79-8.78,4.243-16.194,13.392-16.473l202.002-6.152c8.756-0.267,17.304,6.529,19.01,15.098l9.336,46.912
					C2025.13,1932.215,2019.393,1939.484,2010.665,1939.823z"
                        />
                        <path
                            style="fill: #f4f7fa"
                            d="M2267.195,1929.884l-190.491,7.38c-8.623,0.334-17.071-6.36-18.76-14.878l-9.268-46.742
					c-1.693-8.538,4.011-15.743,12.662-16.006l191.106-5.82c8.289-0.252,16.381,6.367,17.996,14.706l8.838,45.654
					C2280.889,1922.498,2275.457,1929.564,2267.195,1929.884z"
                        />
                        <path
                            style="fill: #f4f7fa"
                            d="M2510.226,1920.469l-180.501,6.993c-8.166,0.316-16.166-6.206-17.765-14.497l-8.776-45.493
					c-1.603-8.31,3.798-15.316,11.99-15.565l181.069-5.515c7.858-0.239,15.53,6.212,17.061,14.333l8.38,44.462
					C2523.21,1913.29,2518.059,1920.166,2510.226,1920.469z"
                        />
                        <path
                            style="fill: #f4f7fa"
                            d="M2740.796,1911.537l-171.277,6.635c-7.744,0.3-15.331-6.059-16.848-14.135l-8.322-44.309
					c-1.52-8.093,3.602-14.911,11.37-15.148l171.802-5.232c7.46-0.227,14.743,6.065,16.197,13.979l7.956,43.33
					C2753.123,1904.554,2748.234,1911.249,2740.796,1911.537z"
                        />
                    </g>
                    <g>
                        <linearGradient
                            id="SVGID_00000024724311281025910050000003260517505124504765_"
                            gradientUnits="userSpaceOnUse"
                            x1="771.9957"
                            y1="1725.2875"
                            x2="1500.0315"
                            y2="2138.0059"
                            gradientTransform="matrix(0.9975 -0.0369 0.1345 0.99 472.6763 -185.2697)"
                        >
                            <stop offset="0" style="stop-color: #f57e53" />
                            <stop offset="0.0045" style="stop-color: #f58152" />
                            <stop offset="0.042" style="stop-color: #f99a4d" />
                            <stop offset="0.084" style="stop-color: #fcad49" />
                            <stop offset="0.1333" style="stop-color: #feba46" />
                            <stop offset="0.1962" style="stop-color: #ffc244" />
                            <stop offset="0.3183" style="stop-color: #ffc444" />
                            <stop offset="0.455" style="stop-color: #fdb946" />
                            <stop offset="0.6941" style="stop-color: #f99c4c" />
                            <stop offset="0.9964" style="stop-color: #f36f56" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000024724311281025910050000003260517505124504765_)"
                            d="M1888.718,1748.029l-96.332,2.602
					c-17.669,0.478-34.153-14.072-36.576-32.332l-13.213-99.568c-2.422-18.249,10.122-33.337,27.82-33.53l96.497-1.055
					c17.31-0.189,33.386,14.355,35.777,32.321l13.051,98.027C1918.136,1732.471,1905.999,1747.563,1888.718,1748.029z"
                        />

                        <linearGradient
                            id="SVGID_00000080171689543138507530000010286453907949024947_"
                            gradientUnits="userSpaceOnUse"
                            x1="1031.3245"
                            y1="1908.3086"
                            x2="1173.3981"
                            y2="1908.3086"
                            gradientTransform="matrix(0.9966 -0.0282 0.1345 0.99 474.0173 -191.4288)"
                        >
                            <stop offset="0" style="stop-color: #ff9085" />
                            <stop offset="1" style="stop-color: #fb6fbb" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000080171689543138507530000010286453907949024947_)"
                            d="M1880.726,1738.745l-82.757,2.159
					c-16.327,0.426-31.472-12.962-33.713-29.842l-11.342-85.45c-2.239-16.874,9.281-30.757,25.633-30.952l82.88-0.983
					c16.039-0.19,30.854,13.193,33.069,29.835l11.218,84.279C1907.931,1724.441,1896.741,1738.327,1880.726,1738.745z
					 M1779.11,1598.897c-14.077,0.172-23.991,12.129-22.063,26.653l11.338,85.415c1.929,14.53,14.964,26.06,29.021,25.699
					l82.76-2.125c13.795-0.354,23.438-12.313,21.529-26.655l-11.222-84.312c-1.908-14.336-14.672-25.862-28.486-25.692
					L1779.11,1598.897z"
                        />
                        <g>
                            <g>
                                <path style="fill: #f4f7fa" d="M1756.386,1636.167l137.491-2.179L1756.386,1636.167z" />

                                <linearGradient
                                    id="SVGID_00000163770956526427358110000014570612106476928391_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="1033.6482"
                                    y1="1879.8083"
                                    x2="1171.1005"
                                    y2="1879.8083"
                                    gradientTransform="matrix(0.9975 -0.0369 0.1345 0.99 472.6763 -185.2697)"
                                >
                                    <stop offset="0" style="stop-color: #ff9085" />
                                    <stop offset="1" style="stop-color: #fb6fbb" />
                                </linearGradient>
                                <polygon
                                    style="fill: url(#SVGID_00000163770956526427358110000014570612106476928391_)"
                                    points="1894.155,1636.083 
							1756.669,1638.291 1756.105,1634.042 1893.597,1631.892 						"
                                />
                            </g>
                            <g>
                                <path style="fill: #f4f7fa" d="M1760.65,1668.292l137.446-2.607L1760.65,1668.292z" />

                                <linearGradient
                                    id="SVGID_00000052073709867875639070000013831306705875348151_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="1033.5481"
                                    y1="1912.0388"
                                    x2="1171.0132"
                                    y2="1912.0388"
                                    gradientTransform="matrix(0.9975 -0.0369 0.1345 0.99 472.6763 -185.2697)"
                                >
                                    <stop offset="0" style="stop-color: #ff9085" />
                                    <stop offset="1" style="stop-color: #fb6fbb" />
                                </linearGradient>
                                <polygon
                                    style="fill: url(#SVGID_00000052073709867875639070000013831306705875348151_)"
                                    points="1898.374,1667.782 
							1760.933,1670.417 1760.369,1666.167 1897.816,1663.589 						"
                                />
                            </g>
                            <g>
                                <path style="fill: #f4f7fa" d="M1764.915,1700.422l137.401-3.035L1764.915,1700.422z" />

                                <linearGradient
                                    id="SVGID_00000069387502628465148330000015171887500722332598_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="1033.4476"
                                    y1="1944.2748"
                                    x2="1170.9259"
                                    y2="1944.2748"
                                    gradientTransform="matrix(0.9975 -0.0369 0.1345 0.99 472.6763 -185.2697)"
                                >
                                    <stop offset="0" style="stop-color: #ff9085" />
                                    <stop offset="1" style="stop-color: #fb6fbb" />
                                </linearGradient>
                                <polygon
                                    style="fill: url(#SVGID_00000069387502628465148330000015171887500722332598_)"
                                    points="1902.594,1699.485 
							1765.197,1702.548 1764.634,1698.298 1902.035,1695.292 						"
                                />
                            </g>
                        </g>
                        <g>
                            <path
                                style="fill: #fec045"
                                d="M1856.355,1721.936l-38.382,0.936c-9.788,0.239-18.911-7.858-20.257-17.991l-9.881-74.389
						c-1.345-10.13,5.585-18.526,15.384-18.658l38.426-0.521c9.707-0.131,18.73,7.989,20.068,18.046l9.824,73.856
						C1872.875,1713.274,1866.051,1721.7,1856.355,1721.936z"
                            />

                            <linearGradient
                                id="SVGID_00000036933465989971998860000001623197633190280365_"
                                gradientUnits="userSpaceOnUse"
                                x1="1063.5511"
                                y1="1908.4934"
                                x2="1141.8621"
                                y2="1908.4934"
                                gradientTransform="matrix(0.9966 -0.0282 0.1345 0.99 474.0173 -191.4288)"
                            >
                                <stop offset="0" style="stop-color: #ff9085" />
                                <stop offset="1" style="stop-color: #fb6fbb" />
                            </linearGradient>
                            <path
                                style="fill: url(#SVGID_00000036933465989971998860000001623197633190280365_)"
                                d="M1856.635,1724.042l-38.382,0.944
						c-10.942,0.269-21.085-8.729-22.59-20.06l-9.882-74.404c-1.504-11.325,6.202-20.657,17.156-20.803l38.428-0.514
						c10.85-0.145,20.876,8.88,22.371,20.12l9.823,73.841C1875.055,1714.411,1867.473,1723.776,1856.635,1724.042z
						 M1803.499,1613.947c-8.693,0.12-14.805,7.527-13.612,16.513l9.879,74.374c1.194,8.989,9.241,16.133,17.924,15.922
						l38.384-0.928c8.604-0.208,14.627-7.641,13.439-16.57l-9.825-73.87c-1.187-8.925-9.15-16.09-17.764-15.97L1803.499,1613.947z"
                            />
                        </g>
                    </g>

                    <linearGradient
                        id="SVGID_00000158726757581796984730000016097558548170052230_"
                        gradientUnits="userSpaceOnUse"
                        x1="935.485"
                        y1="2281.0718"
                        x2="2956.3181"
                        y2="2356.2451"
                        gradientTransform="matrix(0.9975 -0.0369 0.1345 0.99 472.6763 -185.2697)"
                    >
                        <stop offset="0" style="stop-color: #fb6fbb" />
                        <stop offset="1" style="stop-color: #ff9085" />
                    </linearGradient>
                    <path
                        style="opacity: 0.15; fill: url(#SVGID_00000158726757581796984730000016097558548170052230_)"
                        d="M2093.091,1991.797
				c-98.263,17.669-162.523,21.945-239.048,0.931c-106.444-29.23-184.406-92.878-228.515-135.359l42.995,324.968
				c2.907,21.972,22.853,38.686,44.245,37.146l1162.926-83.709c16.802-1.209,28.353-18.044,25.726-37.412l-15.889-117.153
				c-176.771-102.761-308.311-110.69-398.001-97.171C2377.585,1900.611,2321.161,1950.787,2093.091,1991.797z"
                    />
                    <g style="opacity: 0.42">
                        <linearGradient
                            id="SVGID_00000000213184856247258350000017285023440896782768_"
                            gradientUnits="userSpaceOnUse"
                            x1="1019.9909"
                            y1="2311.0918"
                            x2="1315.3903"
                            y2="2311.0918"
                            gradientTransform="matrix(0.9966 -0.0282 0.1345 0.99 474.0173 -191.4288)"
                        >
                            <stop offset="0" style="stop-color: #fb6fbb" />
                            <stop offset="1" style="stop-color: #ff9085" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000000213184856247258350000017285023440896782768_)"
                            d="M2090.163,2087.023l-274.148,16.847
					c-5.323,0.327-10.28-3.916-11.012-9.43l-6.028-45.425c-0.732-5.512,3.032-10.262,8.358-10.556l274.333-15.1
					c5.041-0.278,9.746,3.877,10.461,9.232l5.891,44.129C2098.733,2082.077,2095.201,2086.713,2090.163,2087.023z"
                        />

                        <linearGradient
                            id="SVGID_00000049909346152663595250000013757718535395024309_"
                            gradientUnits="userSpaceOnUse"
                            x1="1455.0311"
                            y1="2298.1511"
                            x2="1816.766"
                            y2="2298.1511"
                            gradientTransform="matrix(0.9966 -0.0282 0.1345 0.99 474.0173 -191.4288)"
                        >
                            <stop offset="0" style="stop-color: #fb6fbb" />
                            <stop offset="1" style="stop-color: #ff9085" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000049909346152663595250000013757718535395024309_)"
                            d="M2588.559,2057.685l-341.346,20.969
					c-4.987,0.307-9.656-3.81-10.37-9.146l-5.885-43.966c-0.714-5.335,2.788-9.925,7.779-10.2l341.58-18.798
					c4.65-0.255,9.018,3.743,9.712,8.886l5.715,42.382C2596.437,2052.957,2593.206,2057.4,2588.559,2057.685z"
                        />
                    </g>

                    <linearGradient
                        id="SVGID_00000139978596205175033800000013523391632031578530_"
                        gradientUnits="userSpaceOnUse"
                        x1="1803.7214"
                        y1="1881.0383"
                        x2="1989.7689"
                        y2="1881.0383"
                        gradientTransform="matrix(0.9739 -0.1443 0.2507 0.9798 303.8133 -34.2391)"
                    >
                        <stop offset="0" style="stop-color: #ff9085" />
                        <stop offset="1" style="stop-color: #fb6fbb" />
                    </linearGradient>
                    <path
                        style="opacity: 0.26; fill: url(#SVGID_00000139978596205175033800000013523391632031578530_)"
                        d="M2715.4,1532.821
				c6.945,51.309-29.101,93.851-80.416,95.006c-51.211,1.152-98.239-39.368-105.133-90.49
				c-6.891-51.107,28.921-93.612,80.081-94.952C2661.198,1441.042,2708.458,1481.525,2715.4,1532.821z"
                    />
                    <g style="opacity: 0.53">
                        <path
                            style="fill: #f4f7fa"
                            d="M1970.79,1672.633c-5.449,6.29-15.683,6.602-22.867,0.636
					c-7.223-5.998-8.566-16.011-2.992-22.304c5.544-6.258,15.797-6.417,22.895-0.417
					C1974.886,1656.517,1976.209,1666.377,1970.79,1672.633z"
                        />
                        <g>
                            <path
                                style="fill: #f4f7fa"
                                d="M2029.312,1732.791c-1.502,0.082-3.083-0.41-4.385-1.494c-2.613-2.177-3.114-5.908-1.119-8.327
						c29.409-35.639,21.995-90.807-16.77-124.863c-2.631-2.312-3.132-6.041-1.119-8.322c2.008-2.277,5.758-2.243,8.371,0.068
						c43.563,38.519,51.921,100.691,18.941,140.989C2032.241,1732.053,2030.816,1732.709,2029.312,1732.791z"
                            />
                            <path
                                style="fill: #f4f7fa"
                                d="M2010.333,1716.971c-1.526,0.072-3.131-0.434-4.453-1.535c-2.652-2.208-3.157-5.968-1.127-8.388
						c22.027-26.255,16.53-67.175-12.386-92.25c-2.665-2.312-3.17-6.07-1.127-8.385c2.039-2.313,5.841-2.303,8.488,0.007
						c33.884,29.575,40.344,77.66,14.588,108.618C2013.309,1716.249,2011.861,1716.899,2010.333,1716.971z"
                            />
                            <path
                                style="fill: #f4f7fa"
                                d="M1991.07,1700.914c-1.551,0.061-3.181-0.459-4.523-1.577c-2.692-2.242-3.2-6.029-1.135-8.45
						c14.32-16.784,10.782-43.126-7.938-59.146c-2.701-2.312-3.209-6.098-1.135-8.448c2.07-2.347,5.926-2.366,8.609-0.056
						c11.615,10.006,19.019,23.304,20.927,37.513c1.907,14.21-1.89,27.768-10.755,38.247
						C1994.094,1700.208,1992.622,1700.852,1991.07,1700.914z"
                            />
                        </g>
                    </g>
                </g>
                <g>
                    <g>
                        <linearGradient
                            id="SVGID_00000148620073272179148510000016505741527955951510_"
                            gradientUnits="userSpaceOnUse"
                            x1="1747.8617"
                            y1="3082.3235"
                            x2="1797.5443"
                            y2="3082.3235"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #ffc444" />
                            <stop offset="0.9964" style="stop-color: #f36f56" />
                        </linearGradient>
                        <polygon
                            style="fill: url(#SVGID_00000148620073272179148510000016505741527955951510_)"
                            points="2873.82,3083.184 
					2916.252,3103.911 2923.503,3084.737 2894.914,3060.736 				"
                        />
                        <path
                            style="fill: #26264f"
                            d="M2916.913,3087.292c0.676-3.097,3.562-5.261,6.716-4.936c3.107,0.32,6.502,1.322,8.933,3.877
					c4.643,4.88,3.271,12.377,0.74,25.869c-2.407,12.836-3.725,19.331-9.317,23.168c-5.598,3.839-12.295,3.059-17.125,2.518
					c-4.428-0.494-11.438-1.278-13.554-6.104c-0.843-1.923-1.099-4.92,0.378-7.177c2.969-4.539,10.081-1.716,14.354-6.421
					c0.676-0.744,1.188-1.573,2.581-7.63C2913.802,3096.627,2915.554,3093.525,2916.913,3087.292z"
                        />

                        <linearGradient
                            id="SVGID_00000137108662010043016940000003946250636252126595_"
                            gradientUnits="userSpaceOnUse"
                            x1="1731.2172"
                            y1="3162.8538"
                            x2="1712.6891"
                            y2="2972.2795"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #40447e" />
                            <stop offset="0.9964" style="stop-color: #3c3b6b" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000137108662010043016940000003946250636252126595_)"
                            d="M2798.137,2610.602
					c-14.008,9.825-104.361,75.466-115.139,193.552c-8.265,90.552,35.347,156.2,48.165,174.879
					c52.224,76.105,125.961,102.061,148.856,109.144c7.689-7.354,15.378-14.708,23.069-22.062
					c-68.58-42.462-106.761-120.787-97.804-200.616c8.788-78.313,61.506-145.283,135.783-172.514
					c3.908-8.768,18.017-42.975,2.978-69.48C2924.667,2589.355,2860.985,2575.195,2798.137,2610.602z"
                        />
                        <path
                            style="fill: #26264f"
                            d="M3228.174,3206.764l4.048,6.478c0,11.059,0.18,20.397-10.121,16.375
					c-10.044-3.92-36.404-9.341-42.238-11.719c-12.415-5.061-24.347-13.693-25.371-19.472c-1.025-5.778,34.31,0,45.343,0.242
					C3210.867,3198.909,3228.174,3206.764,3228.174,3206.764z"
                        />

                        <linearGradient
                            id="SVGID_00000060025264017676573550000006677857900926681756_"
                            gradientUnits="userSpaceOnUse"
                            x1="2052.7822"
                            y1="3183.8455"
                            x2="2100.9695"
                            y2="3183.8455"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #ffc444" />
                            <stop offset="0.9964" style="stop-color: #f36f56" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000060025264017676573550000006677857900926681756_)"
                            d="M3212.755,3156.57l14.173,53.547
					c0,0-9.101,5.37-24.898-8.73l-23.289-44.817H3212.755z"
                        />

                        <linearGradient
                            id="SVGID_00000143601145917002402250000007139568166417312390_"
                            gradientUnits="userSpaceOnUse"
                            x1="1748.446"
                            y1="3161.1785"
                            x2="1729.918"
                            y2="2970.6047"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #40447e" />
                            <stop offset="0.9964" style="stop-color: #3c3b6b" />
                        </linearGradient>
                        <path
                            style="opacity: 0.3; fill: url(#SVGID_00000143601145917002402250000007139568166417312390_)"
                            d="M2941.067,2692.986
					c3.909-8.769,18.017-42.976,2.978-69.48c-18.368-32.371-76.544-46.701-136.084-17.962c13.579,38.808,9.311,60.127,0.101,72.554
					c-14.104,19.028-40.422,18.285-45.659,38.709c-5.926,23.11,24.101,38.362,17.866,60.547
					c-5.593,19.902-31.729,14.656-48.637,37.719c-20.287,27.672-15.009,79.355,9.926,107.198
					c19.272,21.519,40.572,17.949,52.607,42.681c9.459,19.44-1.426,26.324,6.948,43.673c14.82,30.703,55.488,22.748,78.413,54.591
					c3.979,5.528,6.753,11.349,8.698,17.112c4.954-4.738,9.909-9.474,14.862-14.211c-68.578-42.461-106.761-120.787-97.803-200.617
					C2814.072,2787.187,2866.789,2720.217,2941.067,2692.986z"
                        />

                        <linearGradient
                            id="SVGID_00000078009540758317638110000005210910595553576862_"
                            gradientUnits="userSpaceOnUse"
                            x1="1672.1786"
                            y1="2880.5981"
                            x2="2102.9548"
                            y2="2880.5981"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #40447e" />
                            <stop offset="0.9964" style="stop-color: #3c3b6b" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000078009540758317638110000005210910595553576862_)"
                            d="M2798.137,2622.114
					c29.731,73.234,102.364,212.006,164.592,304.539c75.458,112.208,155.89,196.487,222.511,256.666
					c4.573,1.261,11.479,2.582,19.852,1.984c10.913-0.777,19.178-4.444,23.822-6.948c-4.863-21.872-13.433-54.545-28.785-92.31
					c-41.293-101.577-86.826-129.741-129.035-198.514c-89.459-145.771-37.437-267.807-97.786-291.005
					C2949.042,2587.199,2876.884,2542.849,2798.137,2622.114z"
                        />

                        <linearGradient
                            id="SVGID_00000018198000881202758950000014733414707656275849_"
                            gradientUnits="userSpaceOnUse"
                            x1="1772.2041"
                            y1="2149.8276"
                            x2="1651.7711"
                            y2="2687.1428"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #ab316d" />
                            <stop offset="1" style="stop-color: #792d3d" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000018198000881202758950000014733414707656275849_)"
                            d="M2885.656,2005.132
					c1.657-14.01,1.086-8.206,2.743-22.217c9.1,4.539,116.719,60.532,132.908,183.014c12.054,91.193-31.401,182.73-111.168,234.247
					c-155.048-112.351-179.754-147.89-173.368-159.473c9.734-17.659,89.084,25.146,146.9-16.545
					c30.01-21.639,39.214-55.221,42.651-67.765C2947.595,2078.782,2891.962,2012.42,2885.656,2005.132z"
                        />

                        <linearGradient
                            id="SVGID_00000083072903432000194710000005660791209069496235_"
                            gradientUnits="userSpaceOnUse"
                            x1="1551.688"
                            y1="2102.6653"
                            x2="1431.255"
                            y2="2639.98"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #ab316d" />
                            <stop offset="1" style="stop-color: #792d3d" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000083072903432000194710000005660791209069496235_)"
                            d="M2201.135,2207.7
					c10.294-7.427,20.586-14.854,30.88-22.28c27.129,19.654,70.662,45.941,129.389,61.239c68.033,17.723,108.109,4.3,203.94-2.429
					c142.281-9.992,213.421-14.986,268.526,15.851c91.833,51.389,130.426,163.268,141.606,195.68
					c12.057,34.954,18.409,71.3,25.145,109.844c8.738,50,12.954,75.246,5.471,87.322c-26.592,42.913-157.617,35.945-243.554-30.607
					c-69.871-54.109-51.719-102.417-123.618-157.745c-64.481-49.619-113.686-37.396-194.715-64.848
					C2378.303,2377.401,2289.697,2328.271,2201.135,2207.7z"
                        />

                        <linearGradient
                            id="SVGID_00000183941277475738724180000003238325185453849740_"
                            gradientUnits="userSpaceOnUse"
                            x1="582.8859"
                            y1="1737.9237"
                            x2="601.7446"
                            y2="1905.6676"
                            gradientTransform="matrix(0.9803 -0.1976 0.1976 0.9803 1958.0664 340.4276)"
                        >
                            <stop offset="0" style="stop-color: #ffc444" />
                            <stop offset="0.9964" style="stop-color: #f36f56" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000183941277475738724180000003238325185453849740_)"
                            d="M2877.234,1928.36
					c0,0,21.697,21.512,22.73,45.605c1.034,24.093-16.472,32.685-16.472,32.685L2877.234,1928.36z"
                        />

                        <linearGradient
                            id="SVGID_00000094613921074344964700000003529354244287830968_"
                            gradientUnits="userSpaceOnUse"
                            x1="1018.7031"
                            y1="2052.8857"
                            x2="1069.3241"
                            y2="2244.4512"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #ffc444" />
                            <stop offset="0.9964" style="stop-color: #f36f56" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000094613921074344964700000003529354244287830968_)"
                            d="M2224.06,2191.16l-17.497-21.895
					l-24.815-23.108c0,0-7.61,6.538,8.753,23.081c3.102,3.136,3.758,6.86,3.321,10.351c-0.869,6.915-7.367,11.741-14.332,11.507
					l-41.084,0.309c0,0,23.555,22.335,62.729,16.296L2224.06,2191.16z"
                        />

                        <linearGradient
                            id="SVGID_00000043419265341525996600000017179983412671276722_"
                            gradientUnits="userSpaceOnUse"
                            x1="1534.7689"
                            y1="2230.2371"
                            x2="1648.3374"
                            y2="2230.2371"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #ffc444" />
                            <stop offset="0.9964" style="stop-color: #f36f56" />
                        </linearGradient>
                        <path
                            style="fill: url(#SVGID_00000043419265341525996600000017179983412671276722_)"
                            d="M2664.397,2236.608
					c-4.377,0.187-5.065,6.387-0.828,7.498c26.312,6.897,67.137,16.414,80.995,13.132c4.038-0.956,25.83-7.43,28.342-8.509
					c6.12-2.629-9.617-6.845-15.297-10.324l-6.25-1.243c-4.046-2.479-6.024-7.291-4.891-11.898l3.934-15.983l-25.268-6.711
					l-6.18,18.44c-2.718,8.111-10.156,13.708-18.702,14.072L2664.397,2236.608z"
                        />
                        <g>
                            <g>
                                <linearGradient
                                    id="SVGID_00000026123487739161389760000007846818703013690265_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="1590.0354"
                                    y1="2184.2034"
                                    x2="1652.3146"
                                    y2="2210.0811"
                                    gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                                >
                                    <stop offset="0" style="stop-color: #ffc444" />
                                    <stop offset="0.9964" style="stop-color: #f36f56" />
                                </linearGradient>
                                <path
                                    style="fill: url(#SVGID_00000026123487739161389760000007846818703013690265_)"
                                    d="M2728.168,2218.981
							c-1.971-1.081-17.987-3.028-20.195-11.64c-2.998-11.691,7.51-25.922,8.819-28.212c5.481-9.586,6.858-10.881,9.407-12.506
							l3.537-2.073c0,0,0.205-0.077,0.418-0.148c2.257-0.747,9.894-0.805,11.432-0.441c4.915,1.167,9.72,21.407,7.575,49.82
							c0.181,1.089,0.354,2.186,0.519,3.293C2750.114,2219.949,2728.022,2219.994,2728.168,2218.981z"
                                />
                                <path
                                    style="fill: #26264f"
                                    d="M2713.104,2162.742c1.846,5.363,9.163,10.903,12.43,13.376
							c0.479,0.362,1.861,1.586,3.113,3.558c0.182,0.287,0.341,0.622,0.479,1.052c1.806,5.598-7.173,11.934-5.525,14.459
							c1.196,1.832,7.252-0.445,7.93,1.128c0.638,1.482-4.418,4.405-3.694,6.177c0.547,1.339,3.644,0.195,5.534,2.271
							c0.941,1.032,0.64,1.828,1.592,4.943c0.54,1.761,1.305,3.694,1.907,4.772c2.929,5.243,11.512,7.328,13.219,5.295
							c0.475-0.568,0.09-1.061,0.605-2.987c0,0,0.479-1.784,1.774-3.832c1.851-2.925,6.207-4.948,10.623-7.98
							c8.18-5.614,10.479-10.504,11.174-12.159c0.078-0.185,0.146-0.356,0.208-0.512c0.118-0.281,0.22-0.563,0.306-0.844
							c1.849-5.983-2.883-12.431-5.433-15.435c-3.652-4.26-9.797-10.584-18.805-16.217c-2.451-1.533-26.282-16.243-35.021-8.971
							C2712.399,2153.435,2711.85,2159.098,2713.104,2162.742z"
                                />

                                <linearGradient
                                    id="SVGID_00000135657930410117840150000004729515790869685382_"
                                    gradientUnits="userSpaceOnUse"
                                    x1="1586.8344"
                                    y1="2191.9099"
                                    x2="1649.1104"
                                    y2="2217.7864"
                                    gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                                >
                                    <stop offset="0" style="stop-color: #ffc444" />
                                    <stop offset="0.9964" style="stop-color: #f36f56" />
                                </linearGradient>
                                <path
                                    style="fill: url(#SVGID_00000135657930410117840150000004729515790869685382_)"
                                    d="M2726.816,2193.188
							c2.227-2.919,5.959-0.045,5.895,3.796c-0.065,3.84-3.875,7.749-7.468,6.908
							C2721.649,2203.053,2723.819,2197.117,2726.816,2193.188z"
                                />
                            </g>
                        </g>

                        <linearGradient
                            id="SVGID_00000091699608742767720190000011583373664118062241_"
                            gradientUnits="userSpaceOnUse"
                            x1="1609.1073"
                            y1="2115.5366"
                            x2="1488.6749"
                            y2="2652.8486"
                            gradientTransform="matrix(1 0 0 1 1125.9585 0)"
                        >
                            <stop offset="0" style="stop-color: #ab316d" />
                            <stop offset="1" style="stop-color: #792d3d" />
                        </linearGradient>
                        <path
                            style="opacity: 0.3; fill: url(#SVGID_00000091699608742767720190000011583373664118062241_)"
                            d="M2897.394,2627.807
					c-22.706-27.536,10.793-81.908-21.175-113.816c-23.188-23.145-49.439-3.147-82.052-26.468
					c-43.357-31.003-18.706-89.318-58.231-105.874c-41.775-17.497-79.084,23.201-132.344,2.646
					c-35.101-13.546-28.861-35.069-63.525-55.583c-60.122-35.579-113.651,8.6-195.868-2.646c-13.497-1.846-29.074-5.372-46.284-12
					c53.995,46.715,104.673,71.564,146.29,85.662c81.029,27.451,130.233,15.228,194.715,64.847
					c71.899,55.328,53.747,103.636,123.618,157.745c85.937,66.551,216.962,73.521,243.555,30.607
					c3.098-5.002,4.167-12.31,3.762-22.651C2941.427,2650.016,2910.389,2643.566,2897.394,2627.807z"
                        />
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>
