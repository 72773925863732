import { ProcessApi } from "@/entities/Process/api";
import type { IDiagram, IProcess, IUploadDiagram } from "@/entities/Process/model";
import { useLoading, useNotification } from "@/shared/lib/composables";

export function useUploadDiagrams() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();

    async function uploadDiagram(data: IUploadDiagram): Promise<IDiagram | boolean> {
        try {
            startLoading();
            return await ProcessApi.uploadDiagram(data);
        } catch (e: any) {
            showError(e?.message || e);
            return false;
        } finally {
            finishLoading();
        }
    }

    async function uploadVersion(diagramId: string, data: IUploadDiagram): Promise<IProcess | boolean> {
        try {
            startLoading();
            return await ProcessApi.uploadVersion(diagramId, data);
        } catch (e: any) {
            showError(e?.message || e);
            return false;
        } finally {
            finishLoading();
        }
    }

    function getNameDigram(text: string): string {
        return text.split(".").slice(0, -1).join();
    }

    return {
        isLoading,
        uploadDiagram,
        uploadVersion,
        getNameDigram,
    };
}
