import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

const MAX_SCALE: number = 4;
const MIN_SCALE: number = 0.2;

const scaleValue = ref<number>(0.5);

export function useScaleDiagram() {
    const modelerStore = useBpmnModelerStore();
    const { modelerZoomScroll, modelerCanvas } = storeToRefs(modelerStore);

    const disabledScaleIn = computed<boolean>(() => scaleValue.value === MAX_SCALE);
    const disabledScaleOut = computed<boolean>(() => scaleValue.value === MIN_SCALE);

    function setScaleValue(): void {
        scaleValue.value = modelerCanvas.value.zoom(false);
    }

    function scale(increase: boolean = true): void {
        modelerZoomScroll.value.stepZoom(increase ? +1 : -1);
        setScaleValue();
    }

    watch(modelerCanvas, value => {
        if (value) setScaleValue();
    });

    return {
        scale,
        setScaleValue,
        disabledScaleIn,
        disabledScaleOut,
    };
}
