import { OPENED_SIDEBAR_MENU_KEY } from "@/shared/config";
import { useLocalStorage } from "@/shared/lib/browser";
import { ref } from "vue";

export * from "./types";

const items = ref<string[]>([]);

export function useSidebar() {
    const { value, setLSValue } = useLocalStorage<string[]>(OPENED_SIDEBAR_MENU_KEY);

    function saveStateItem(name: string, childrenRef: any): void {
        if (!childrenRef.value) return;

        const isOpened = childrenRef.value.classList.contains("hidden");

        if (isOpened) {
            saveName(name);
        } else {
            deleteName(name);
        }
    }

    function saveName(name: string): void {
        if (!items.value.includes(name)) items.value.push(name);
        setLSValue(items.value);
    }
    function deleteName(name: string): void {
        const index = items.value.indexOf(name);
        if (index >= 0) items.value.splice(index, 1);

        setLSValue(items.value);
    }

    return {
        openedItems: value,
        saveStateItem,
        saveName,
    };
}
