import { transations } from "./translations";

export default function customTranslate(template: string, replacements: any) {
    replacements = replacements || {};

    // Translate
    template = template in transations ? transations[template] : template;

    // Replace
    return template.replace(/{([^}]+)}/g, function (_: any, key: any) {
        return replacements[key] || "{" + key + "}";
    });
}
