<template>
    <div class="picture flex flex-column gap-4">
        <h1 v-if="isInTeam" class="text-center">
            Станет доступно после перехода<br />
            на тариф «TEAM»
        </h1>
        <div v-if="isInTeam" class="picture__image mx-auto">
            <PaymentWaiting />
            <UpgradePlan size="large" />
        </div>
        <CreateTeamForm v-else />
    </div>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import PaymentWaiting from "./icons/PaymentWaiting.vue";

import { useSessionStore } from "@/entities/Session";

import { UpgradePlan } from "@/features/Payment/upgrade-plan";
import { CreateTeamForm } from "@/features/Team/create";

const sessionStore = useSessionStore();
const { isInTeam } = storeToRefs(sessionStore);
</script>

<style scoped lang="scss">
.picture {
    width: 100%;
    max-width: 600px;
    margin: auto;

    &__image {
        width: 100%;
        max-width: 400px;
    }
}
</style>
