export const acceptFiles: string[] = [
    ".doc",
    ".docx",
    ".txt",
    ".json",
    ".xml",
    ".gif",
    ".jpeg",
    ".jpg",
    ".png",
    ".gif",
    ".xls",
    ".xlsx",
    ".pdf",
];
