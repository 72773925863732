<template>
    <Wrapper class="surface-ground" v-if="article">
        <Black_backgroup_hero :article="false">
            <div class="w-full flex flex-column text-white">
                <div class="gap-3">
                    <div class="flex text-base flex-column gap-3 md:flex-row md:justify-content-between">
                        <Breadcrumb
                            :model="breadcrumbValue"
                            :pt="{
                                root: () => ({
                                    class: ['breadcrumb p-0 '],
                                }),
                                separatorIcon: () => ({
                                    style: {
                                        color: 'white',
                                    },
                                }),
                            }"
                        >
                            <template #item="{ item }">
                                <router-link v-if="item.route" :to="item.route" class="text-white flex align-items-center">
                                    <span :class="[item.icon, 'text-lg']" />
                                    <span class="text-lg">{{ item.label }}</span>
                                </router-link>
                                <span v-else class="text-white text-lg">
                                    {{ item.label }}
                                </span>
                            </template>
                        </Breadcrumb>

                        <div class="md:block hidden gap-2">
                            <span class="pi pi-clock"></span> ~{{ timeRead.toFixed(0) }}
                            {{ declensionNumerals(Number(timeRead.toFixed(0)), ["минута", "минуты", "минут"]) }}
                        </div>
                    </div>
                    <h1 class="text-5xl mt-5 font-bold text-center md:text-left" v-html="article?.title" id="title"></h1>
                    <div
                        class="flex mt-5 flex-column gap-3 align-items-start md:justify-content-between md:flex-row md:align-items-center align-items-center text-xl"
                    >
                        <div class="flex align-items-center gap-2">
                            <ImgUserSmall>
                                <img
                                    style="max-height: 42px; max-width: 42px"
                                    width="42"
                                    loading="lazy"
                                    height="42"
                                    :src="
                                        IMG_DIRECTUS +
                                        article?.author?.image?.id +
                                        '?format=webp&height=200&width=200&fit=cover&quality=100'
                                    "
                                    :alt="article?.author?.name"
                                    :title="article?.author?.name"
                                />
                            </ImgUserSmall>
                            <span class="">{{ article?.author?.name }}</span>
                        </div>
                        <SharePanel />
                        <div class="text-base text-left md:text-right">
                            <div>Дата публикации: {{ formatDate(article?.date_published) }}</div>
                            <div>Дата обновления: {{ formatDate(article?.date_updated) }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Black_backgroup_hero>
        <WrapperBlockColumn>
            <div class="flex flex-column w-full gap-5">
                <div class="w-full border-round-md flex align-items-start gap-5">
                    <div class="w-full bg-white border-round-xl p-5" id="content"></div>
                    <div class="text-sm py-5 px-3 bg-white border-round-xl flex flex-column navbar__right sticky">
                        <div class="mb-3 text-2xl font-bold">В этой статье</div>
                        <router-link
                            id="navbarRigth"
                            class="border-left-2 border-300 pl-2"
                            v-for="(item, i) of navbar"
                            :to="'#' + item.id"
                            :class="{
                                'pl-4': item.tagName == 'H2',
                                'pl-5': item.tagName == 'H3',
                                'pl-6': item.tagName == 'H4',
                                'pt-2': i != 0,
                                'border-primary text-primary': activeIdAnchor == item.id,
                            }"
                        >
                            {{ item.label }}
                        </router-link>
                    </div>
                </div>
                <div class="flex flex-column gap-4 bg-white border-round-xl p-3" v-if="postText && relatedPublications.length > 0">
                    <h2 class="text-left w-full text-3xl">Похожие публикации</h2>
                    <div
                        class="flex w-full flex-wrap xl:flex-nowrap xl:justify-content-between justify-content-center gap-3"
                        v-if="postText"
                    >
                        <PostBig v-for="post of relatedPublications" :key="post?.id" :post="post" />
                    </div>
                </div>
            </div>
        </WrapperBlockColumn>
        <div class="w-full bg-white navbar__top">
            <Dropdown
                :options="navbar"
                optionLabel="label"
                :placeholder="selectedAnchor"
                class="w-full"
                :pt="{
                    item: () => ({
                        class: ['p-2 white-space-normal'],
                    }),
                }"
            >
                <template #option="item">
                    <router-link
                        id="navbarRigth"
                        class="border-left-2 border-300 pl-2"
                        :to="'#' + item.option.id"
                        :class="{
                            'pl-4': item.option.tagName == 'H2',
                            'pl-6': item.option.tagName == 'H3',
                            'border-primary text-primary': activeIdAnchor == item.option.id,
                        }"
                    >
                        {{ item.option.label }}
                    </router-link>
                </template>
            </Dropdown>
        </div>
    </Wrapper>
    <template v-for="wrappper of article?.blocks" :key="wrappper?.item?.id">
        <component :is="blocks[wrappper.collection]" :data="wrappper" :article="true" @getHtml="saveHtml"> </component>
    </template>
</template>

<script setup lang="ts">
import { Wrapper, WrapperBlockColumn, ImgUserSmall, SharePanel } from "@/shared/ui/marketing";
import { computed, onMounted, ref, watch } from "vue";
import { useInterfaceStore } from "@/entities/Interface";
import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { IMG_DIRECTUS } from "@/shared/config";
import { formatDate } from "@/shared/lib/utils/date";
import { PostBig } from "@/shared/ui/marketing";
import { declensionNumerals } from "@/shared/lib/utils/formater";
import {
    Block_hero,
    Block_logo_cloud,
    Block_counts,
    Block_steps,
    Block_feature_slider,
    Block_testimonials,
    Block_CTA,
    Block_columns,
    Block_plans,
    Block_team,
    Block_tabs,
    Block_courses,
    Black_backgroup_hero,
    Block_compare,
    Block_bpmn_elements,
    Block_quote,
    Block_rich_text,
} from "@/widgets/marketing";

const interfaceStore = useInterfaceStore();
const { article, postText, blogTags, activeIdAnchor } = storeToRefs(interfaceStore);
const route = useRoute();
const blocks: any = {
    Block_hero,
    Block_logo_cloud,
    Block_counts,
    Block_steps,
    Block_feature_slider,
    Block_testimonials,
    Block_CTA,
    Block_columns,
    Block_plans,
    Block_team,
    Block_tabs,
    Black_backgroup_hero,
    Block_compare,
    Block_bpmn_elements,
    Block_courses,
    Block_quote,
    Block_rich_text,
};
const articleBlocksRender = ref<any>([]);

const selectedAnchor = computed(() => {
    if (navbar.value) {
        if (activeIdAnchor.value) {
            const label = navbar.value.filter((item: any) => item.id == activeIdAnchor.value);
            return label[0]?.label;
        } else return navbar.value[0].label;
    }
});
const breadcrumbValue = computed(() => {
    const items: any = [
        {
            icon: "pi pi-home",
            route: "/",
        },
        {
            label: "Блог",
            route: "/blog",
        },
    ];

    if (route.params.slug && blogTags.value) {
        const item = blogTags.value.filter((item: any) => item.slug == route.params.slug);
        items.push({ label: item[0].title, route: "/blog/" + item[0].slug });
    }
    if (article.value) {
        items.push({ label: article.value.title });
    }
    return items;
});
const timeRead = computed(() => {
    if (article.value) return article.value?.content.length / 800;
    else return 0;
});

const content = computed(() => {
    if (article.value) {
        const html = new DOMParser().parseFromString(article.value.content, "text/html");
        const title = html.body.querySelectorAll("h2,h3,h4");
        for (let i = 0; i < title.length; i++) {
            title[i].id = "s" + i;
            title[i].className += "section";
        }

        const searchComponent = html.body.querySelectorAll("p");
        let i = 0;

        for (let item of searchComponent) {
            const form = /{{\d+}}/gi;
            if (item.textContent && form.test(item.textContent)) {
                if (articleBlocksRender.value[i]) {
                    item.innerHTML = "";
                    item.appendChild(articleBlocksRender.value[i]);
                }
                i = i + 1;
            }
        }
        const links = html.body.querySelectorAll("a");
        for (let link of links) {
            link.style.color = "blue";
            link.style.textDecoration = "underline";
        }
        const imgs = html.body.querySelectorAll("img");
        for (let img of imgs) {
            img.style.maxHeight = "350px";
            img.style.maxWidth = "900px";
            img.style.objectFit = "contain";
        }
        const ps = html.body.querySelectorAll("p");
        for (let paragraph of ps) {
            paragraph.style.fontSize = "15px";
            paragraph.style.lineHeight = "1.44";
          
        }
        const lists = html.body.querySelectorAll("li");
        for (let list of lists) {
            list.style.fontSize = "15px";
            list.style.lineHeight = "1.44";
          
        }

        const quotas = html.body.querySelectorAll("blockquote");
        for (let qouta of quotas) {
            qouta.style.fontSize = "1.4em";
            qouta.style.width = "100%";
            qouta.style.margin = "30px auto";
            qouta.style.fontStyle = "italic";
            qouta.style.padding = "1.2em 15px 1.2em 15px";
            qouta.style.borderLeft = "8px solid #6366f1";
            qouta.style.lineHeight="1.6";
            qouta.style.position="relative";
            qouta.style.background="#EDEDED"
        }

        return html.body;
    }
});
const navbar = computed(() => {
    if (article.value) {
        const html = new DOMParser().parseFromString(article.value?.content, "text/html");
        const title: any = html.body.querySelectorAll("h2,h3,h4");
        const nav = [];
        if (article.value) {
            nav.push({ label: article.value.title, id: "title" });
            for (let i = 0; i < title.length; i++) {
                nav.push({ label: title[i].innerText, id: "s" + i, tagName: title[i].tagName });
            }
        }
        return nav;
    }
});
const relatedPublications = computed(() => {
    if (postText.value) {
        return postText.value.filter((item: any) => item.type == "blog" && item.id != article.value?.id).slice(0, 2);
    } else return [];
});

function saveHtml(value: any) {
    articleBlocksRender.value.push(value);
}

watch(
    content,
    () => {
        if (content.value) {
            const cont = document.getElementById("content");
            if (cont) {
                cont.replaceChildren(content.value);
                interfaceStore.startObserver();
            }
        }
    },
    { flush: "post" },
);
onMounted(() => {
    if (content.value) {
        const cont = document.getElementById("content");
        if (cont) {
            cont.replaceChildren(content.value);
            interfaceStore.startObserver();
        }
    }
});
</script>

<style scoped lang="scss">
.navbar {
    &__right {
        min-width: 25%;
        top: 70px;

        @media screen and (max-width: 1000px) {
            display: none !important;
        }
    }
    &__left {
        position: fixed;
        top: 100px;
        left: 3%;
        max-width: 250px;
        width: 10%;
        display: flex;
        flex-direction: column;

        @media screen and (max-width: 1800px) {
            display: none;
        }
    }
    &__top {
        position: fixed;
        top: 70px;
        max-width: 100%;
        display: none;
        &_panel {
            min-width: 30px !important;
            width: 100px !important;
        }

        @media screen and (max-width: 1000px) {
            display: block;
        }
    }
}

.breadcrumb {
    background: none;
    background-color: none;
    border-radius: none;
    border: none;
}
.p {
    font-size: 125px;
}

.post {
    &__all {
        display: flex;
        flex-direction: column;
    }
}
p {
    font-size: 15px;
    line-height: 1.44;
}
li {
    font-size: 15px;
    line-height: 1.44;
}

</style>
