import type { VNodeRef } from "vue";
import { type ILintRule } from "@/shared/lib/types/app";

export enum EBpmnElementType {
    PROCESS = "bpmn:Process",
    SUB_PROCESS = "bpmn:SubProcess",
    AD_HOC_SUB_PROCESS = "bpmn:AdHocSubProcess",
    START_EVENT = "bpmn:StartEvent",
    END_EVENT = "bpmn:EndEvent",
    EVENT_BASED_GATEWAY = "bpmn:EventBasedGateway",
    INTERMEDIATE_EVENT = "bpmn:IntermediateEvent",
    INTERMEDIATE_CATCH_EVENT = "bpmn:IntermediateCatchEvent",
    INTERMEDIATE_THROW_EVENT = "bpmn:IntermediateThrowEvent",
    BOUNDARY_EVENT = "bpmn:BoundaryEvent",
    TASK = "bpmn:Task",
    MANUAL_TASK = "bpmn:ManualTask",
    SERVICE_TASK = "bpmn:ServiceTask",
    RECEIVE_TASK = "bpmn:ReceiveTask",
    SCRIPT_TASK = "bpmn:ScriptTask",
    SEND_TASK = "bpmn:SendTask",
    USER_TASK = "bpmn:UserTask",
    BUSINESS_RULE_TASK = "bpmn:BusinessRuleTask",
    ASSOCIATION = "bpmn:Association",
    CALL_ACTIVITY = "bpmn:CallActivity",
    COMPLEX_GATEWAY = "bpmn:ComplexGateway",
    DATA_INPUT = "bpmn:DataInput",
    DATA_INPUT_ASSOCIATION = "bpmn:DataInputAssociation",
    DATA_OBJECT = "bpmn:DataObject",
    DATA_OBJECT_REFERENCE = "bpmn:DataObjectReference",
    DATA_OUTPUT = "bpmn:DataOutput",
    DATA_OUTPUT_ASSOCIATION = "bpmn:DataOutputAssociation",
    DATA_STORE_REFERENCE = "bpmn:DataStoreReference",
    EXCLUSIVE_GATEWAY = "bpmn:ExclusiveGateway",
    GATEWAY = "bpmn:Gateway",
    GROUP = "bpmn:Group",
    INCLUSIVE_GATEWAY = "bpmn:InclusiveGateway",
    LANE = "bpmn:Lane",
    MESSAGE_FLOW = "bpmn:MessageFlow",
    PARALLEL_GATEWAY = "bpmn:ParallelGateway",
    PARTICIPANT = "bpmn:Participant",
    SEQUENCE_FLOW = "bpmn:SequenceFlow",
    TEXT_ANNOTATION = "bpmn:TextAnnotation",
    TRANSACTION = "bpmn:Transaction",
    LABEL = "label",
}

export interface ILintRuleByElement {
    id: string;
    ref: undefined | VNodeRef;
    lintElements: ILintRule[];
}

export interface IScoringResult {
    assetDocumentPercent: number;
    assetSystemPercent: number;
    assigneePercent: number;
}
