import { ref } from "vue";
import { storeToRefs } from "pinia";

import type OverlayPanel from "primevue/overlaypanel";

import { useBpmnModelerStore } from "@/entities/BpmnModeler";

const LINES = [
    "bpmn:SequenceFlow",
    "bpmn:Association",
    "bpmn:Lane",
    "bpmn:TextAnnotation",
    "bpmn:DataInputAssociation",
    "bpmn:MessageFlow",
];

const color = ref<null | string>(null);
const colorPickerRef = ref<OverlayPanel>();

export function useChangeElementColor() {
    const bpmnModelerStore = useBpmnModelerStore();
    const { modelerSelection, modelerModeling } = storeToRefs(bpmnModelerStore);

    function toggleColorPicker(event: Event): void {
        colorPickerRef.value?.toggle(event);

        color.value = null;

        for (let item of modelerSelection.value._selectedElements) {
            if (item.di?.fill || item.di?.stroke) {
                color.value = String(item.di?.fill || item.di?.stroke).toUpperCase();
                break;
            }
        }
    }
    function setColor(colorValue: null | string): void {
        color.value = colorValue && colorValue === color.value ? null : colorValue;

        if (color.value) {
            for (let item of modelerSelection.value._selectedElements) {
                const option = LINES.includes(item.type) ? { stroke: color.value } : { fill: color.value };
                modelerModeling.value.setColor(item, option);
            }
        } else {
            for (let item of modelerSelection.value._selectedElements) {
                modelerModeling.value.setColor(item, {
                    fill: color.value,
                    stroke: color.value,
                });
            }
        }
    }

    return {
        color,
        colorPickerRef,
        toggleColorPicker,
        setColor,
    };
}
