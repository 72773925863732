<template>
    <SelectButton
        v-model="view"
        :options="viewList"
        option-value="value"
        :allowEmpty="false"
        :pt="{
            button: 'shadow-none',
        }"
    >
        <template #option="slotProps">
            <i class="pi" :class="slotProps.option.icon" v-tooltip.top="slotProps.option.description"></i>
        </template>
    </SelectButton>
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { useProcessView } from "..";

const processViewStore = useProcessView();
const { viewList, view } = storeToRefs(processViewStore);
</script>

<style scoped lang="scss">
:deep(.p-highlight) {
    background-color: #6b7280;
    border-color: #6b7280;
}
</style>
