<template>
    <WrapperBlockColumn class="flex flex-column gap-6 align-items-center text-center">
        <div
            class="flex flex-column gap-7 py-5 justify-content-center text-center md:text-left w-full"
            :class="data.item?.image_position == 'left' ? 'md:flex-row-reverse' : 'md:flex-row'"
            v-show="visibleBlock"
            ref="blockHeroRef"
        >
            <div class="flex flex-column gap-5 justify-content-center">
                <div class="flex flex-column gap-3">
                    <div :class="!!data.item?.image ? '' : ''" v-html="data.item?.headline"></div>
                    <div :class="!!data.item?.image ? '' : ''" v-html="data.item?.content"></div>
                </div>
                <ButtonGroup :data="data.item?.button_group" />
            </div>
            <Img v-if="!!data.item?.image">
                <img
                    :src="IMG_DIRECTUS + data.item?.image?.id + '?format=webp'"
                    :alt="data.item?.headline"
                    :height="data.item?.image?.height"
                    :width="data.item?.image?.width"
                    loading="eager"
                    fetchpriority="high"
                    :title="data.item?.headline"
                    @load="visible = true"
                />
            </Img>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { WrapperBlockColumn, Img, ButtonGroup } from "@/shared/ui/marketing";
import { IMG_DIRECTUS } from "@/shared/config";
import type { IBlock } from "@/entities/Interface";

// const props = defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockHeroRef = ref();

const visible = ref<boolean>(false);
const visibleBlock = computed(() => {
    if (!!props.data.item.image) {
        return visible.value;
    } else {
        return true;
    }
});

watch(blockHeroRef, () => {
    if (props.article) {
        emit("getHtml", blockHeroRef.value);
    }
});
</script>

<style scoped></style>
