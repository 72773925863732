import { ProcessApi } from "@/entities/Process/api";
import { useProcessStore, type EProcessStatus } from "@/entities/Process/model";
import { useLoading, useNotification } from "@/shared/lib/composables";

export function useChangeStatus() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();
    const processStore = useProcessStore();

    async function changeStatus(diagramId: string, status: EProcessStatus): Promise<void> {
        try {
            startLoading();

            await ProcessApi.changeStatus(diagramId, status);
            processStore.setStatusById(diagramId, status);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        changeStatus,
    };
}
