<template>
    <Wrapper>
        <component
            v-for="wrappper of pageData?.blocks"
            :is="blocks[wrappper.collection]"
            :data="wrappper"
            :key="wrappper?.item?.id"
        ></component>
    </Wrapper>
</template>

<script setup lang="ts">
import {
    Block_hero,
    Block_logo_cloud,
    Block_counts,
    Block_steps,
    Block_feature_slider,
    Block_testimonials,
    Block_CTA,
    Block_columns,
    Block_plans,
    Block_team,
    Block_tabs,
    Block_courses,
    Black_backgroup_hero,
    Block_compare,
    Block_bpmn_elements,
    Block_quote,
    Block_rich_text,
} from "./blocks";
import { Wrapper } from "@/shared/ui/marketing";
import { useInterfaceStore } from "@/entities/Interface";
import { storeToRefs } from "pinia";

const interfaceStore = useInterfaceStore();
const { pageData } = storeToRefs(interfaceStore);

const blocks: any = {
    Block_hero,
    Block_logo_cloud,
    Block_counts,
    Block_steps,
    Block_feature_slider,
    Block_testimonials,
    Block_CTA,
    Block_columns,
    Block_plans,
    Block_team,
    Block_tabs,
    Black_backgroup_hero,
    Block_compare,
    Block_bpmn_elements,
    Block_courses,
    Block_quote,
    Block_rich_text,
};
</script>

<style scoped></style>
