<template>
    <div class="w-full h-screen flex flex-column">
        <template v-if="!menuSettings.isHiddenMenu">
            <AppHeader v-if="isAuth" class="fixed top-0 left-0 right-0 z-2">
                <template v-if="diagramAccessAllowed">
                    <ProcessHeaderSkeleton v-if="loading" />
                    <ProcessHeaderInfo v-else />
                </template>
                <template v-else>
                    <h2>{{ $t("no-access") }}</h2>
                </template>
            </AppHeader>
            <MainHeader v-else>
                <template v-if="diagramAccessAllowed">
                    <ProcessHeaderSkeleton v-if="loading" />
                    <ProcessHeaderInfo v-else />
                </template>
                <template v-else>
                    <h2>{{ $t("no-access") }}</h2>
                </template>
            </MainHeader>
        </template>
        <div
            class="diagram-container w-full flex h-full"
            :class="{
                content: isAuth && !menuSettings.isHiddenMenu,
                'content_no-header': menuSettings.isHiddenMenu,
                'pt-6': !isAuth && !diagramAccessAllowed,
            }"
        >
            <main class="overflow-auto w-full">
                <template v-if="loading">
                    <div class="w-full text-center">
                        <ProgressSpinner />
                    </div>
                </template>
                <template v-else>
                    <RouterView v-if="diagramAccessAllowed"></RouterView>
                    <ProcessAccessDenied v-else :diagramid="diagramId" />
                </template>
            </main>
        </div>
    </div>
    <CommentMenu />
</template>

<script setup lang="ts">
import { onBeforeMount, computed, watch } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { setTitlePage } from "@/shared/lib/browser";

import { useDiagramStore, ProcessHeaderSkeleton, ProcessAccessDenied, useDiagramActionMenuStore } from "@/entities/Process";
import { useSessionStore } from "@/entities/Session";
import { useTeamStore } from "@/entities/Team";
import { useCommentStore } from "@/entities/Comment";
import { useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";
import { useAssigneeStore } from "@/entities/Assignee";
import { usePaymentStore } from "@/entities/Payment";

import { CommentMenu } from "@/features/Comment/menu";

import { AppHeader, MainHeader } from "@/widgets/header";
import { ProcessHeaderInfo } from "@/widgets/app/process";
import { useAssetStore } from "@/entities/Asset";

const diagramStore = useDiagramStore();
const sessionStore = useSessionStore();
const teamStore = useTeamStore();
const commentStore = useCommentStore();
const bpmnOverlay = useBpmnModelerOverlayStore();
const assigneeStore = useAssigneeStore();
const assetStore = useAssetStore();
const actionMenuStore = useDiagramActionMenuStore();
const paymentStore = usePaymentStore();

const { diagram, diagramAccessAllowed, isLoading: loading } = storeToRefs(diagramStore);
const { isAuth } = storeToRefs(sessionStore);
const { menuSettings } = storeToRefs(actionMenuStore);

const route = useRoute();

const diagramId = computed<undefined | string>(() => route.params.id as string);

async function fetchData(): Promise<void> {
    diagramStore.resetDiagram();
    bpmnOverlay.clearOverlayData();

    const promises = [teamStore.fetchSettings()];

    if (diagramId.value) {
        promises.push(
            diagramStore.fetchById(diagramId.value),
            diagramStore.fetchOverlaySettingsById(diagramId.value),
            commentStore.getCountByDiagram(diagramId.value),
            commentStore.getCommentsBy(diagramId.value),
            assigneeStore.fetchByDiagramId(diagramId.value),
            assetStore.fetchByDiagramId(diagramId.value),
            assetStore.fetchCustomByDiagramId(diagramId.value),
            paymentStore.getCurrentPlan(),
        );
    }

    await Promise.all(promises);

    if (diagram.value) {
        setTitlePage(diagram.value.name);
    }
}

watch(
    () => route.params.id,
    async (newValue, oldValue) => {
        if (newValue && oldValue) {
            await fetchData();
        }
    },
);

onBeforeMount(async () => {
    await fetchData();
});
</script>

<style lang="scss" scoped>
.content {
    margin-top: 5rem;
    min-height: calc(100vh - 5rem);
}
.content_no-header {
    margin-top: 0rem;
    min-height: 100vh;
}
</style>
