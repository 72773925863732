<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockStepsRef">
            <div v-html="data?.item?.headline"></div>
            <WrapperBlockRow
                v-for="(step, i) of data?.item?.steps"
                :key="step.id"
                :class="data?.item?.alternate_image_position && i % 2 === 0 ? 'md:flex-row-reverse' : 'md:flex-row'"
                class="text-cetner"
            >
                <div class="flex flex-column gap-2 justify-content-center w-full">
                    <div v-html="step?.headline"></div>
                    <div v-html="step?.content"></div>
                </div>

                <ImgSecondary v-if="step.image || step.video">
                    <img
                        v-if="step.image && step.type == 'image'"
                        loading="lazy"
                        :style="'max-height:' + step.image_max_height + 'px'"
                        :width="step.image.width"
                        :height="step.image.height"
                        class="border-round-2xl"
                        :src="IMG_DIRECTUS + step.image.id + '?format=webp&height=900'"
                        :alt="step?.headline"
                        :title="step?.headline"
                    />

                    <video
                        v-if="step.video && step.type == 'video'"
                        :style="'max-height: 400px'"
                        class="max-w-full"
                        loop
                        autoplay
                        muted
                        :src="IMG_DIRECTUS + step.video.id + '.mp4'"
                    />
                </ImgSecondary>
            </WrapperBlockRow>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn, WrapperBlockRow, ImgSecondary } from "@/shared/ui/marketing";
import { IMG_DIRECTUS } from "@/shared/config";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockStepsRef = ref();

watch(blockStepsRef, () => {
    if (props.article) {
        emit("getHtml", blockStepsRef.value);
    }
});
</script>

<style scoped></style>
