export interface INotice {
    id: number;
    objectType: string;
    objectToClickId: ETypeNotice | null;
    objectName: string;
    objectContent: string;
    what: string;
    when: string;
    who: string;
    readed: boolean;
}

export enum ETypeNotice {
    DIAGRAM = "diagram",
    VERSION = "version",
    COMMENT_NOTIFICATION = "comment-notification",
    SECURIES_DIAGRAM = "securies-diagram",
    APPROVAL = "approval",
    SECURIES_FOLDER = "securies-folder",
    USER = "user",
    AD = "ad",
}

export enum ETypeWhat {
    "mark-all-read" = "Отметить всё прочтёнными",
    "created-notification" = "создана",
    "joined-the-team" = "присоединился к команде",
    "created-approval" = "запрошено",
    "finish-approval" = "завершено",
}
