<template>
    <component
        :is="route ? RouterLink : 'a'"
        :to="{ ...userRouteData }"
        v-styleclass="{
            selector: '@next',
            enterFromClass: 'hidden',
            enterActiveClass: 'slidedown',
            leaveToClass: 'hidden',
            leaveActiveClass: 'slideup',
        }"
        :activeClass="cannotByActive ? '' : 'item_active'"
        class="item"
        :class="[disabled ? 'item_disabled' : 'item_normal']"
        v-tooltip.right="disabled ? 'В разработке' : ''"
        @click="onSaveStateItem"
    >
        <i class="mr-2" :class="icon" />
        <span class="font-medium">{{ label }}</span>
        <Badge v-if="badge" class="ml-auto" severity="secondary">{{ badge }}</Badge>
        <i v-if="isChildren" class="ml-auto mr-1" :class="PrimeIcons.CHEVRON_DOWN" />
    </component>
    <ul
        v-if="isChildren"
        ref="childrenRef"
        class="list-none py-0 pl-4 pr-0 m-0 overflow-y-hidden transition-all transition-duration-400 ease-in-out"
        :class="{ hidden: !isShowChildren }"
    >
        <li v-for="item of children">
            <SidebarItem v-bind="item" />
        </li>
    </ul>
    <span v-else class="hidden"></span>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { RouterLink, useRoute } from "vue-router";

import { PrimeIcons } from "primevue/api";

import { type IMenuItem, useSidebar } from "../model";

const { name: routeName, query: routeQuery } = useRoute();
const { openedItems, saveStateItem, saveName } = useSidebar();

const props = defineProps<IMenuItem>();

const childrenRef = ref();

const isChildren = computed<boolean>(() => !!props.children);
const isShowChildren = computed<boolean>(() => {
    function checkChildren(children: IMenuItem[]): boolean {
        let status = false;
        for (const item of children) {
            if (item.children) {
                status = checkChildren(item.children);
                if (status) return status;
            } else {
                if (item.route?.name === routeName) return true;
            }
        }

        return false;
    }

    if (!isChildren.value) return false;

    const status: boolean = checkChildren(props.children || []);
    return status || openedItems.includes(props.label);
});

const userRouteData = computed(() => {
    if (routeName !== props.route?.name) return props.route;

    const userRoute = { name: props.route?.name, query: { ...routeQuery, ...props.route?.query } };
    return userRoute;
});

function onSaveStateItem(): void {
    saveStateItem(props.label, childrenRef);
}

onMounted(() => {
    if (isShowChildren.value) saveName(props.label);
});
</script>

<style scoped lang="scss">
@import "primeflex/primeflex.scss";

.cursor-default {
    cursor: default;
}

.item {
    @include styleclass("flex align-items-center px-3 border-round border-1 border-transparent transition-colors transition-duration-150");
    padding-bottom: 8px;
    padding-top: 8px;

    &_normal {
        @include styleclass("text-700 cursor-pointer");

        &:hover:not(.item_active) {
            @include styleclass("surface-200");
        }
    }

    &_disabled {
        @include styleclass("text-300 cursor-default");
    }

    &_active {
        @include styleclass("border-primary text-primary");
    }
}
</style>
