import type { AxiosResponse } from "axios";

import { httpClient } from "@/shared/api";

import type { IScoringResult } from "..";

export class ScoreApi {
    static async getScoringResultByDiagramId(diagramId: string): Promise<IScoringResult> {
        const response: AxiosResponse = await httpClient.get(`/scoring/diagram/${diagramId}`);
        return response.data;
    }
    static async validationErrors(xml: string): Promise<string[]> {
        const response: AxiosResponse = await httpClient.post("/diagram/validate", { body: xml });
        return response.data;
    }
}
