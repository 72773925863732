<template>
    <Wrapper>
        <WrapperBlockColumn>
            <WrapperBorder class="flex flex-column gap-5">
                <div class="flex justify-content-center">
                    <div class="flex justify-content-center surface-100 gap-2 overlay-panel__external">
                        <span
                            v-for="item of periods"
                            class="p-2 cursor-pointer font-medium overlay-panel__internal"
                            :class="{ 'bg-white': item.value == selectedPeriod.value }"
                            @click="selectedPeriod = item"
                        >
                            {{ item.title }}
                        </span>
                    </div>
                </div>
                <div class="flex gap-4">
                    <div class="flex flex-column gap-5 w-full p-0">
                        <PlanUpgradeTarif
                            v-for="item of tarifs"
                            :id="item.title"
                            :tarif="item"
                            class="border-2"
                            :class="[selectedTarif?.title == item.title ? 'border-black-alpha-90' : 'border-white']"
                            @click="selectedTarif = item"
                        />
                    </div>
                    <div class="flex flex-column border-round-2xl w-full p-0 shadow-3">
                        <div class="p-4 text-white bg-black-alpha-90 border-round-top flex flex-column gap-3">
                            <div class="flex text-white justify-content-between">
                                <h1>{{ selectedTarif.title }}</h1>
                                <div class="" v-if="selectedTarif.title != EPlan.ENTERPRISE">
                                    <Tag v-if="upgradePrice.totalPrice == upgradePrice.discountedPrice">
                                        <h2>{{ moneyFormat(upgradePrice.priceWithoutDiscount) + "₽" }}</h2>
                                    </Tag>
                                    <div class="flex gap-2" v-else>
                                        <Tag severity="secondary">
                                            <h2 class="line-through text-base">
                                                {{ moneyFormat(upgradePrice.priceWithoutDiscount) + "₽" }}
                                            </h2>
                                        </Tag>
                                        <Tag>
                                            <h2>{{ moneyFormat(Number(upgradePrice.totalPrice.toFixed(0))) + "₽" }}</h2>
                                        </Tag>
                                    </div>
                                </div>
                                <Tag v-else severity="Primary">
                                    <h2>По запросу</h2>
                                </Tag>
                            </div>
                            <div class="">
                                {{ selectedTarif.fullDiscription }}
                            </div>
                        </div>
                        <div class="size">
                            <div class="flex flex-column list">
                                <li class="p-1 flex items-center gap-2" v-for="item in featuresValid.valid">
                                    <i class="pi pi-check text-green-500 text-lg" />
                                    <span class="text-surface-800 dark:text-surface-100 leading-6"> {{ item.name }}</span>
                                </li>
                                <li class="p-1 flex items-center gap-2" v-for="item in featuresValid.notValid">
                                    <i class="pi pi-times text-300 text-lg" />
                                    <span class="text-300 dark:text-surface-100 leading-6"> {{ item.name }}</span>
                                </li>
                            </div>
                            <div class="flex flex-column gap-3">
                                <InputNumber
                                    v-if="selectedTarif.title == EPlan.TEAM"
                                    v-model="membersTeam"
                                    showButtons
                                    buttonLayout="horizontal"
                                    :step="1"
                                    :min="upgradePrice.totalMembers"
                                    :max="100"
                                    :suffix="declensionNumerals(membersTeam, [' пользователь', ' пользователя', ' пользователей'])"
                                >
                                    <template #incrementbuttonicon>
                                        <span class="pi pi-plus" />
                                    </template>
                                    <template #decrementbuttonicon>
                                        <span class="pi pi-minus" />
                                    </template>
                                </InputNumber>
                                <span v-if="selectedTarif.title != EPlan.ENTERPRISE">
                                    <Button
                                        v-if="selectedTarif.title != EPlan.PERSONAL_PRO"
                                        outlined
                                        severity="contrast"
                                        label="Получить счет для оплаты от ЮЛ"
                                        class="w-full mb-2"
                                        @click="showGenerateInvoice"
                                    />
                                    <Button severity="contrast" :label="price" class="w-full" @click="paymentUpgrade" />
                                </span>
                                <span v-else>
                                    <Button severity="contrast" label="Связаться с нами" class="w-full" @click="showContactUsForm" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </WrapperBorder>
        </WrapperBlockColumn>
    </Wrapper>
    <ArsenalPay ref="arsenalPayRef" :header="'Оформление подписки'" />
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch } from "vue";
import { storeToRefs } from "pinia";

import { PlanUpgradeTarif } from ".";
import { Wrapper, WrapperBlockColumn, WrapperBorder } from "@/shared/ui/marketing";
import { PrimeIcons } from "primevue/api";
import { EPlan, EBillingInterval } from "@/shared/lib/types";

import {
    usePaymentStore,
    EUpgradePlan,
    type IPaymentUpgrade,
    type IPayloadUpgradePrice,
    type IFeaturesValid,
    type ITarifItem,
    type ITarif,
    type IPeriod,
    ETypePeriod,
} from "@/entities/Payment";

import { declensionNumerals, moneyFormat } from "@/shared/lib/utils/formater";
import { ArsenalPay } from "@/features/Payment/arsenal-pay";
import { GenerateInvoice } from "../../generate-invoice";
import { useDialog } from "primevue/usedialog";
import { MODAL_PARAMS } from "@/shared/constants/modal";
import { ContactUsForm } from "@/widgets/marketing";

const dialog = useDialog();
const paymentStore = usePaymentStore();
const { upgradePrice } = storeToRefs(paymentStore);

const arsenalPayRef = ref();
const membersTeam = ref<number>(0);
const selectedPeriod = ref<IPeriod>({
    title: ETypePeriod.YEAR,
    value: EBillingInterval.YEARLY,
});

const periods = ref<IPeriod[]>([
    {
        title: ETypePeriod.MONTH,
        value: EBillingInterval.MONTHLY,
    },
    {
        title: ETypePeriod.YEAR,
        value: EBillingInterval.YEARLY,
    },
]);
const tarifs: ITarif = {
    [EPlan.PERSONAL_PRO]: {
        icon: PrimeIcons.USER,
        title: EPlan.PERSONAL_PRO,
        planValue: EUpgradePlan.PERSONAL_PRO,
        discriptionTitle: "Для опытных пользователей, которые хотят сделать еще больше",
        discription: "+ Безлимитная возможность делиться процессами",
        discriptionPrice: "От 340₽ в месяц",
        fullDiscription: "Для опытных пользователей, которые хотят сделать еще больше",
    },
    [EPlan.TEAM]: {
        icon: PrimeIcons.USERS,
        title: EPlan.TEAM,
        planValue: EUpgradePlan.TEAM,
        discriptionTitle: "Для работы над процессами и архитектурой всей командой",
        discription: "+ Папки, роли, регламенты",
        discriptionPrice: "От 680₽ в месяц за пользователя",
        fullDiscription: "Для работы над процессами и архитектурой всей командой",
    },
    [EPlan.ENTERPRISE]: {
        icon: PrimeIcons.BUILDING,
        title: EPlan.ENTERPRISE,
        planValue: EUpgradePlan.ENTERPRISE,
        discriptionTitle: "Установка на ваших серверах, с API и настройками безопасности",
        discription: "+ Установка на свои сервера, API, Oauth2",
        discriptionPrice: "По запросу",
        fullDiscription: "Установка на ваших серверах, с API и настройками безопасности",
    },
};
const selectedTarif = ref<ITarifItem>(tarifs[EPlan.TEAM]);

const price = computed<string>(() => {
    if (selectedTarif.value.title == EPlan.PERSONAL_PRO) {
        return "Оплатить картой " + moneyFormat(Number(upgradePrice.value.totalPrice.toFixed(0))) + "₽";
    } else if (selectedTarif.value.title == EPlan.TEAM) {
        return (
            "Оплатить картой " +
            moneyFormat(Number(upgradePrice.value.totalPrice.toFixed(0))) +
            "₽ за " +
            membersTeam.value +
            " " +
            declensionNumerals(membersTeam.value, [" пользователя", " пользователей", " пользователей"])
        );
    }
    return "Оплатить по карте";
});
const featuresValid = computed<IFeaturesValid>(() => {
    return paymentStore.getFeaturesByTarif(selectedTarif.value.title);
});

async function calculateUpgradePrice(): Promise<void> {
    const payload: IPayloadUpgradePrice = {
        billingInterval: selectedPeriod.value.value,
        locale: "ru",
        membersCount: selectedTarif.value.title === EPlan.TEAM ? membersTeam.value : 1,
        plan: selectedTarif.value.planValue,
    };
    await paymentStore.calculateUpgradePrice(payload);
}
function showGenerateInvoice(): void {
    dialog.open(GenerateInvoice, {
        props: {
            header: "Генерация счета",
            ...MODAL_PARAMS,
            style: { width: "100%", maxWidth: "600px" },
        },
    });
}
function showContactUsForm(): void {
    dialog.open(ContactUsForm, {
        props: {
            header: "Связаться с нами",
            ...MODAL_PARAMS,
            style: { width: "100%", maxWidth: "1100px" },
        },
    });
}

async function paymentUpgrade(): Promise<void> {
    const payload: IPayloadUpgradePrice = {
        billingInterval: selectedPeriod.value.value,
        membersCount: selectedTarif.value.title === EPlan.TEAM ? membersTeam.value : 1,
        plan: selectedTarif.value.planValue,
    };
    let res: IPaymentUpgrade | undefined = await paymentStore.paymentUpgrade(payload);
    if (res) {
        arsenalPayRef.value.show(res);
    }
}

watch([selectedTarif, selectedPeriod, membersTeam], () => {
    if (selectedTarif.value.title != EPlan.ENTERPRISE) {
        calculateUpgradePrice();
    }
});

onMounted(async () => {
    await calculateUpgradePrice();
    membersTeam.value = upgradePrice.value.totalMembers;
});
</script>

<style scoped lang="scss">
@import "primeflex/primeflex.scss";

.overlay-panel {
    &__external {
        border-radius: 20px;
        padding: 5px;
    }
    &__internal {
        border-radius: 15px;
    }
}
.size {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    gap: 1rem;
    padding: 1.5rem;
}
.list {
    flex-wrap: wrap;
    max-height: 150px;
    @media screen and (max-width: 1050px) {
        flex-wrap: nowrap;
        max-height: 300px;
    }
}
:deep(.p-inputnumber-button-down) {
    @include styleclass("bg-black-alpha-90");
}
:deep(.p-inputnumber-button-up) {
    @include styleclass("bg-black-alpha-90");
}
:deep(.p-inputnumber-input) {
    @include styleclass("flex w-full justify-content-center text-center");
}
</style>
