<template>
    <Button
        :icon="PrimeIcons.REFRESH"
        text
        severity="contrast"
        class="navbar-button text-600"
        v-tooltip.bottom="'Выполнить последнее действие'"
        :disabled="disabledRedo"
        @click="redo"
    />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { useCommandStack } from "../model";

const { disabledRedo, redo } = useCommandStack();
</script>

<style scoped></style>
