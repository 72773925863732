import type { EProcessStatus } from "@/entities/Process";
import type { IRequestFilter } from "@/shared/lib/types";

export type TAssetTypeName = keyof typeof EAssetTypeName;
export type TAssetStatusName = keyof typeof EAssetStatusName;

export enum EAssetType {
    DOCUMENT = "DOCUMENT",
    SYSTEM = "SYSTEM",
    CLIENT = "CLIENT",
    COMMUNICATION = "COMMUNICATION",
    ENTITY = "ENTITY",
    ACTION = "ACTION",
    UNSPECIFIED = "UNSPECIFIED",
    OTHER = "OTHER",
    CUSTOM = "CUSTOM",
}

export enum EAssetTypeName {
    DOCUMENT = "Документ",
    SYSTEM = "Система",
    CLIENT = "Клиент",
    COMMUNICATION = "Коммуникация",
    ENTITY = "Сущность",
    ACTION = "Действие",
    UNSPECIFIED = "Не указано",
    OTHER = "Прочее",
    CUSTOM = "Пользовательские",
}

export enum EAssetTypeIcon {
    DOCUMENT = "pi-file",
    SYSTEM = "pi-microchip",
    COMMUNICATION = "pi-envelope",
    CLIENT = "pi-user",
    ENTITY = "pi-database",
    ACTION = "pi-pen-to-square",
    OTHER = "pi-th-large",
    UNSPECIFIED = "pi-question",
    CUSTOM = "",
}

export enum EAssetStatus {
    NEW = "NEW",
    TRIAL = "TRIAL",
    PRODUCTION = "PRODUCTION",
    DECOMMISSIONING = "DECOMMISSIONING",
    ARCHIVE = "ARCHIVE",
}
export enum EAssetStatusName {
    NEW = "Новый",
    TRIAL = "Внедряется",
    PRODUCTION = "В эксплуатации",
    DECOMMISSIONING = "Вывод из эксплуатации",
    ARCHIVE = "Архив",
}

export interface IAssetFilter extends IRequestFilter {
    type?: EAssetType;
    diagramId?: string;
}

export interface IAssetResponse {
    content: IAsset[];
    totalElements: number;
    totalPages: number;
}

export interface IAssetGeneral {
    id: undefined | number;
    name: string;
    icon: string;
    type: undefined | EAssetType | ICustomAsset;
    externalId: null | string;
    externalUrl: null | string;
    status: undefined | EAssetStatus;
    color: string;
    customAssetDefinition: undefined | ICustomAsset;
}

export interface IAsset extends IAssetGeneral {
    id: number;
    assetEdges: IAssetEdge[];
    createdOn: Date;
    description: null | string;
    updatedBy: string;
    updatedOn: Date;
    type: EAssetType;
    status: EAssetStatus;
}

export interface ICustomAssetGeneral {
    id: undefined | string;
    name: string;
    icon: string;
    description: null | string;
    code: string;
    joinType: undefined | ECustomAssetJoinType;
    dataType: EDataTypeCustomAsset;
}

export interface ICustomAsset extends ICustomAssetGeneral {
    id: string;
    createdOn: Date;
    overlaySettings: null | boolean;
    updatedOn: Date;
    joinType: ECustomAssetJoinType;
}

export enum EDataTypeCustomAsset {
    STRING = "STRING",
    NUMBER = "NUMBER",
    DATE = "DATE",
}
export enum ECustomAssetJoinType {
    BOTH = "BOTH",
    PROCESS = "PROCESS",
    ACTIVITY = "ACTIVITY",
}
export enum ECustomAssetJoinTypeName {
    BOTH = "Процесс и задача",
    PROCESS = "Процесс",
    ACTIVITY = "Задача",
}

export interface IAssetEdgeGeneral {
    assetId: number;
    description: null | string;
    fromDiagramId: string;
    fromItemId: string;
    fromItemName: string;
}
export interface IAssetEdge extends IAssetEdgeGeneral {
    id: string;
    assetColor: string;
    assetDescription: null | string;
    assetIcon: string;
    assetLink: null;
    assetLinkReg: null;
    assetName: string;
    assetType: EAssetType;
    assetTypeStr: null;
    createdOn: Date;
    diagramName: string;
    diagramStatus: EProcessStatus;
    linkedTasks: string[];
    updatedBy: string;
    updatedOn: Date;
}
