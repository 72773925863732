<template>
    <div class="flex gap-3 align-items-center justify-content-center">
        <a aria-label="Поделиться в телеграмм" :href="'https://t.me/share/url?url=' + urlStr" target="_blank">
            <Button aria-label="Поделиться в телеграмм" class="text-xl p-0 border-none m-0" outlined>
                <telegram />
            </Button>
        </a>
        <a aria-label="Поделиться в VK" :href="'https://vk.com/share.php?url=' + urlStr" target="_blank">
            <Button aria-label="Поделиться в VK" class="text-xl p-0 border-none m-0" outlined>
                <vk />
            </Button>
        </a>
        <a aria-label="Поделиться в Facebook" :href="'https://www.facebook.com/sharer/sharer.php?u=' + urlStr" target="_blank">
            <Button aria-label="Поделиться в Facebook" class="text-xl p-0 border-none m-0" outlined>
                <facebook />
            </Button>
        </a>
        <a aria-label="Поделиться в Linkedin" :href="'https://www.linkedin.com/shareArticle?mini=true&url=' + urlStr" target="_blank">
            <Button aria-label="Поделиться в Linkedin" class="text-xl p-0 border-none m-0" outlined>
                <linkedin />
            </Button>
        </a>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { telegram, vk, facebook, linkedin } from "@/shared/assets/images/shareIcons";

const urlStr = computed(() => {
    return window.location.href;
});
</script>

<style scoped></style>
