import { EBpmnElementType, isActivities, RULES } from "@/entities/Score";
import type { ILintRule } from "@/shared/lib/types/app";

export function lintProcesses(processes: any[], participants: any[], planeElement: any[]): ILintRule[] {
    function lintProcessesEntity(entity: any): void {
        const { id, $type } = entity;

        let countOfStartEvent: number = 0;
        let countOfEndEvent: number = 0;

        let startEvent = null;
        let endEvent = null;
        const activities: any[] = [];

        let elements: any[] = [];

        if ($type === EBpmnElementType.PROCESS || $type === EBpmnElementType.SUB_PROCESS || $type === EBpmnElementType.PARTICIPANT) {
            elements = ($type === EBpmnElementType.PARTICIPANT ? entity.processRef?.flowElements : entity?.flowElements) || [];

            for (const flowElement of elements) {
                const { $type: flowType } = flowElement;

                if (flowType === EBpmnElementType.START_EVENT) {
                    countOfStartEvent += 1;
                    if (!startEvent) startEvent = flowElement;
                }
                if (flowType === EBpmnElementType.END_EVENT) {
                    countOfEndEvent += 1;
                    if (!endEvent) endEvent = flowElement;
                }

                if (isActivities(flowType)) {
                    activities.push(flowElement);
                }
            }
        }

        // Ошибки размещения стартового и заверщающего событий
        if (planeElement.length && startEvent && endEvent) {
            for (const element of planeElement) {
                if (startEvent.id === element.bpmnElement.id) startEvent.di = element;
                if (endEvent.id === element.bpmnElement.id) endEvent.di = element;

                if (startEvent.di && endEvent.di) {
                    break;
                }
            }

            if (startEvent.di.bounds.y < endEvent.di.bounds.y) {
                lintResult.push({ ...RULES.TOP_DOWN_PROCESS, id });
            }
            if (startEvent.di.bounds.x > endEvent.di.bounds.x) {
                lintResult.push({ ...RULES.END_LEFT_START, id });
            }
        }

        // Ошибка пустого процесса
        if (elements.length === 0) {
            lintResult.push({ ...RULES.EMPTY_IN_PROCESS, id });
        }

        // Ошибка стартового события
        if (!startEvent) {
            lintResult.push({ ...RULES.NO_START_EVENT, id });
        }
        if (countOfStartEvent > 1) {
            lintResult.push({ ...RULES.SOME_START_EVENT, id });
        }

        // Ошибка завершающего события
        if (!endEvent) {
            lintResult.push({ ...RULES.NO_END_EVENT, id });
        }

        // Ошибка отсутствия действий
        if ((countOfStartEvent > 0 || countOfEndEvent) && activities.length === 0) {
            lintResult.push({ ...RULES.NO_ACTION_IN_PROCESS, id });
        }

        // Ошибка отсутствия стартового и завершающего события
        if (countOfStartEvent === 0 && countOfEndEvent === 0) {
            lintResult.push({ ...RULES.MINIMUM_ONE_START_AND_END_EVENT, id });
        }

        // Ошибка количества задач
        if (activities.length > 40) {
            lintResult.push({ ...RULES.MANY_TASKS_IN_PROCESS, id });
        }
    }

    const lintResult: ILintRule[] = [];

    if (!participants.length) {
        for (const element of processes) {
            lintProcessesEntity(element);

            if (!element?.flowElements) continue;

            for (const flowElement of element.flowElements) {
                if (flowElement.$type === EBpmnElementType.SUB_PROCESS) {
                    lintProcessesEntity(flowElement);
                }
            }
        }
    }

    for (const element of participants) {
        if (!element?.processRef) continue;

        lintProcessesEntity(element);

        if (!element.processRef?.flowElements) continue;

        for (const flowElement of element.processRef.flowElements) {
            if (flowElement.$type === EBpmnElementType.SUB_PROCESS) {
                lintProcessesEntity(flowElement);
            }
        }
    }

    return lintResult;
}
