<template>
    <Button
        :icon="PrimeIcons.SEARCH_MINUS"
        text
        severity="contrast"
        class="navbar-button"
        v-tooltip.bottom="'Отдалить'"
        :disabled="disabledScaleOut"
        @click="scale(false)"
    />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { useScaleDiagram } from "../model";

const { disabledScaleOut, scale } = useScaleDiagram();
</script>

<style scoped></style>
