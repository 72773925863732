<template>
    <div class="flex gap-3 align-items-center">
        <Skeleton width="1.6rem" height="1.6rem" />
        <Skeleton :width="widthRem" height="1rem" />
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

import { randomInteger } from "@/shared/lib/utils/number";

const widthRem = computed(() => `${randomInteger(10, 20)}rem`);
</script>

<style scoped></style>
