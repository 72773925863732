import ResizeBehavior from "bpmn-js/lib/features/modeling/behavior/ResizeBehavior";
import { is } from "bpmn-js/lib/util/ModelUtil";

class CustomResizeBehavior extends ResizeBehavior {
    constructor(eventBus: any) {
        super(eventBus);

        eventBus.on("resize.start", /* any priority lower than 1500 */ 1400, function (event: any) {
            const context = event.context;
            const shape = context.shape;

            if (is(shape, "bpmn:Participant")) {
                context.resizeConstraints = {};
            }
        });
    }
}

CustomResizeBehavior.$inject = ["eventBus"];

export default CustomResizeBehavior;
