<template>
    <router-link
        v-if="notice?.objectToClickId"
        :to="{ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: notice?.objectToClickId } }"
        class="flex align-items-center gap-3 text-sm border-bottom-1 surface-border py-2 mr-2"
    >
        <span :class="iconTypeNotice" class="text-3xl"></span>
        <div class="flex flex-column justify-content-between gap-1 w-full">
            <div class="flex justify-content-between align-items-center">
                <div class="flex align-items-center gap-2">
                    <span class="text-sm font-bold word-break">{{ headerNotice }}</span>
                </div>
                <span class="text-xs text-400 white-space-nowrap">{{ timeAgo(notice.when) }}</span>
            </div>
            <span>{{ notice.objectContent }}</span>
            <div class="flex gap-2">
                <Avatar :username="notice.who" :key="notice.id" :size-img="ESizeImg.SMALL" />
                <span class="text-xs">{{ notice.who }}</span>
            </div>
        </div>
    </router-link>
    <div v-else class="flex align-items-center gap-3 text-sm border-bottom-1 surface-border py-2 mr-2">
        <span :class="iconTypeNotice" class="text-3xl"></span>
        <div class="flex flex-column justify-content-between gap-1 w-full">
            <div class="flex justify-content-between align-items-center">
                <div class="flex align-items-center gap-2 max-w-full">
                    <span class="text-sm font-bold word-break">{{ headerNotice }}</span>
                </div>
                <span class="text-xs text-400 white-space-nowrap">{{ timeAgo(notice.when) }}</span>
            </div>
            <span>{{ notice.objectContent }}</span>
            <div class="flex gap-2">
                <Avatar :username="notice.who" :key="notice.id" :size-img="ESizeImg.SMALL" />
                <span class="text-xs">{{ notice.who }}</span>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { Avatar } from "@/shared/ui/avatar";
import { ESizeImg } from "@/shared/lib/types";
import { ERoutesName } from "@/app/providers";
import { timeAgo } from "@/shared/lib/utils/date";
import { ETypeNotice, ETypeWhat, type INotice } from "../model";
import { PrimeIcons } from "primevue/api";

const props = defineProps<{
    notice: INotice;
}>();

interface INoticeSettings {
    [key: string]: {
        icon: string;
        header: string;
    };
}

const noticeSettings: INoticeSettings = {
    [ETypeNotice.DIAGRAM]: {
        icon: PrimeIcons.BOX,
        header: "Диаграмма ",
    },
    [ETypeNotice.VERSION]: {
        icon: PrimeIcons.EXPAND,
        header: "Версия ",
    },
    [ETypeNotice.COMMENT_NOTIFICATION]: {
        icon: PrimeIcons.COMMENT,
        header: "Комментарий к ",
    },
    [ETypeNotice.SECURIES_DIAGRAM]: {
        icon: PrimeIcons.SHARE_ALT,
        header: "Доступ к процессу ",
    },
    [ETypeNotice.APPROVAL]: {
        icon: PrimeIcons.THUMBS_UP,
        header: "Согласование ",
    },
    [ETypeNotice.SECURIES_FOLDER]: {
        icon: PrimeIcons.FOLDER,
        header: "Доступ к папке ",
    },
    [ETypeNotice.USER]: {
        icon: PrimeIcons.USER,
        header: "Пользователь ",
    },
    [ETypeNotice.AD]: {
        icon: PrimeIcons.MEGAPHONE,
        header: "Сообщение от Stormbpmn",
    },
};

const iconTypeNotice = computed<string>(() => {
    const objectType: ETypeNotice = props.notice.objectType as ETypeNotice;
    return objectType in noticeSettings ? noticeSettings[objectType].icon : PrimeIcons.MEGAPHONE;
});

const headerNotice = computed<string>(() => {
    const objectType: ETypeNotice = props.notice.objectType as ETypeNotice;
    const what = props.notice.what as keyof typeof ETypeWhat;
    if (objectType in noticeSettings && objectType != ETypeNotice.AD) {
        return noticeSettings[objectType].header + props.notice.objectName + " " + ETypeWhat[what];
    }
    return noticeSettings[ETypeNotice.AD].header;
});
</script>

<style scoped lang="scss">
.word-break {
    word-break: break-word;
    hyphens: auto;
}
</style>
