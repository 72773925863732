import { ref } from "vue";
import { defineStore } from "pinia";
import { InvoiceApi } from "../api";
import { useNotification } from "@/shared/lib/composables";
import type { ICalcPricePayload, ISendFormContactSale, IGetCompany, ICalcPrice } from "./types";

export const useInvoiceStore = defineStore("invoice", () => {
    const { showError, showSuccess } = useNotification();

    const price = ref<number>(0);
    const countUser = ref<number>(0);
    const suggestions = ref<any>();

    function resetPrice() {
        price.value = 0;
    }

    async function calcPrice(payload: ICalcPricePayload) {
        try {
            const res: ICalcPrice = await InvoiceApi.calcPrice(payload);
            price.value = res.totalPrice;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function minCountUser(payload: ICalcPricePayload) {
        try {
            const res: ICalcPrice = await InvoiceApi.calcPrice(payload);
            countUser.value = res.totalMembers;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getCompany(query: string) {
        try {
            const res: IGetCompany = await InvoiceApi.getCompany(query);
            suggestions.value = res.suggestions;
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function downloadInvoicesCourse(payloadCourse: any) {
        try {
            return await InvoiceApi.downloadInvoicesCourse(payloadCourse);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function sendFormContactSale(form: ISendFormContactSale): Promise<boolean> {
        try {
            await InvoiceApi.sendFormContactSale(form);
            showSuccess("Спасибо за обращение. В ближайшее время мы с Вами свяжемся!");
            return true;
        } catch (e: any) {
            showError(e?.message || e);
            return false;
        }
    }

    return {
        price,
        suggestions,
        countUser,

        resetPrice,
        calcPrice,
        getCompany,
        downloadInvoicesCourse,
        sendFormContactSale,
        minCountUser,
    };
});
