import { httpClient } from "@/shared/api";
import type { AxiosResponse } from "axios";
import type { IPayloadDownloadInvoice } from "../model";

export class InvoiceDownloadApi {
    static async downloadInvoices(payload: IPayloadDownloadInvoice) {
        const response: AxiosResponse = await httpClient.post("/payment/companyInvoice?format=pdf", payload, {
            responseType: "arraybuffer",
        });
        return response.data;
    }
}
