import { httpDirectus } from "@/shared/api";
import { httpClient } from "@/shared/api";
import type {
    IDirectusNavigation,
    IDirectusResponse,
    IResponsePayCourse,
    IResponsePromocode,
    IPost,
    IGetTag,
    IgetBlogData,
    IGetArticleDaum,
    IgetBlogByTag,
    ISearchPosts,
} from "@/shared/lib/types";
import type { AxiosResponse } from "axios";
import type { IPayloadCourses, IGetElement, IGetContactUs } from "../model/types";

export class InterfaceApi {
    static async getHeader(): Promise<IDirectusResponse<IDirectusNavigation>> {
        const response: AxiosResponse = await httpDirectus.get("/Navigation/header?fields=*.*.*.*");
        return response.data;
    }
    static async getFooter(): Promise<IDirectusResponse<IDirectusNavigation>> {
        const response: AxiosResponse = await httpDirectus.get("/Navigation/footer?fields=*.*.*.*");
        return response.data;
    }
    static async applyPromocode(promocode: string): Promise<IDirectusResponse<IResponsePromocode[]>> {
        const response: AxiosResponse = await httpDirectus.get(`/Promocode?filter[code][_eq]=${promocode}&fields=*`);
        return response.data;
    }
    static async payCoursePersonal(payload: IPayloadCourses): Promise<IDirectusResponse<IResponsePayCourse>> {
        const response: AxiosResponse = await httpDirectus.post("/Class_submission", payload);
        return response.data;
    }
    static async getInvoiceMail(payload: IPayloadCourses): Promise<IDirectusResponse<IResponsePayCourse>> {
        const response: AxiosResponse = await httpDirectus.post("/Class_submission", payload);
        return response.data;
    }
    static async getPosts(): Promise<IDirectusResponse<IPost[]>> {
        const response: AxiosResponse = await httpDirectus.get(
            "/Posts?sort=-date_published&fields=tag.slug,tag.title,image.*,date_published,author.name,author.image.*,slug,title,type,video_url,summary,video_length,id,date_created",
        );
        return response.data;
    }
    static async getTags(): Promise<IDirectusResponse<IGetTag[]>> {
        const response: AxiosResponse = await httpDirectus.get("/Post_tags");
        return response.data;
    }
    static async getBlog(): Promise<IDirectusResponse<IgetBlogData>> {
        const response: AxiosResponse = await httpDirectus.get("/Pages_blog?fields=*.*.*");
        return response.data;
    }
    static async getArticle(id: string): Promise<IDirectusResponse<IGetArticleDaum[]>> {
        const response: AxiosResponse = await httpDirectus.get(`/Posts?filter[slug][_eq]=${id}&sort=-date_published&fields=*.*.*.*.*.*`);
        return response.data;
    }
    static async getBlogByTag(tagId: string): Promise<IDirectusResponse<IgetBlogByTag[]>> {
        const response: AxiosResponse = await httpDirectus.get(
            `Post_tags?filter[slug][_eq]=${tagId}&sort=-posts.date_published&fields=*,seo.*.*,posts.image,posts.tag.*,posts.date_published,posts.author.name,posts.image.*,posts.author.image.*,posts.slug,posts.title,posts.type,posts.id,posts.video_url,posts.summary,posts.video_length,posts.date_created,blocks.*.*`,
        );
        return response.data;
    }
    static async searchPosts(string: string): Promise<IDirectusResponse<ISearchPosts[]>> {
        const response: AxiosResponse = await httpDirectus.get(
            `Posts?filter[content][_icontains]=${string}&sort=-date_published&fields=*.*.*.*.*`,
        );
        return response.data;
    }
    static async getElement(slug: string): Promise<IDirectusResponse<IGetElement[]>> {
        const response: AxiosResponse = await httpDirectus.get(`Bpmn_elements?filter[slug][_eq]=${slug}&fields=*.*.*.*.*`);
        return response.data;
    }
    static async getContactUs(): Promise<IDirectusResponse<IGetContactUs>> {
        const response: AxiosResponse = await httpDirectus.get(`Pages_contact_us?fields=*.*.*.*.*`);
        return response.data;
    }
}
