export const colors: string[] = [
    "#FFFFFF",
    "#FFFECD",
    "#E7FCCB",
    "#B5E3D7",
    "#C7F4FF",
    "#CCDBFF",
    "#D4CFFF",
    "#E9D6FF",
    "#FFCFF9",
    "#FFCCE2",
    "#FFC2C2",
    "#FFDCC2",
    "#F0F0F0",
    "#FFFDA0",
    "#93E070",
    "#6BC7AE",
    "#A3EEFF",
    "#93B4FF",
    "#AEA3FF",
    "#D1ABFF",
    "#FFA1F2",
    "#FFA4CC",
    "#FF7575",
    "#FFB884",
    "#CFCFCF",
    "#FFFC5A",
    "#29BB33",
    "#3FA88C",
    "#5FE1FF",
    "#6C98FF",
    "#8270FF",
    "#AD68FF",
    "#FF5AEB",
    "#FF69AA",
    "#FF4242",
    "#FF9E56",
    "#A9A9A9",
    "#DBCD63",
    "#63CF6A",
    "#5A9C8A",
    "#66BBCF",
    "#6E8BCF",
    "#7C71CF",
    "#996BCF",
    "#CF63C1",
    "#CF7DA1",
    "#CF5353",
    "#CF8F60",
    "#696969",
    "#BB9F29",
    "#1B6B12",
    "#0E7070",
    "#2BA0BB",
    "#315BBB",
    "#4433BB",
    "#6F2FBB",
    "#BB29AA",
    "#BB3C74",
    "#BB1E1E",
    "#BB6627",
];
