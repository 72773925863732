<template>
    <OverlayPanel ref="colorPickerRef">
        <div class="flex flex-column gap-2 align-items-center">
            <div class="grid">
                <span
                    class="grid__item border-round border-1 border-100 transition-duration-150"
                    v-for="item in colors"
                    :style="{ 'background-color': item }"
                    :class="{ grid__item_active: color === item }"
                    @click="() => setColor(item)"
                ></span>
            </div>
            <span
                class="h-1rem w-full border-round border-1 border-200 transition-duration-150"
                :style="{ 'background-color': color || '' }"
            ></span>
            <Button
                :label="$t('clear-color')"
                icon="pi pi-eraser"
                severity="secondary"
                outlined
                size="small"
                @click="() => setColor(null)"
            />
        </div>
    </OverlayPanel>
</template>

<script setup lang="ts">
import { colors } from "@/shared/constants/colors";

import { useChangeElementColor } from "../";

const { color, colorPickerRef, setColor } = useChangeElementColor();
</script>

<style scoped lang="scss">
.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 5px;

    &__item {
        width: 25px;
        height: 25px;
        cursor: pointer;

        &:hover:not(&_active) {
            scale: 1.2;
        }

        &_active {
            border-radius: 20px !important;
            scale: 0.8;
        }
    }
}
</style>
