<template>
    <div class="flex flex-column gap-2">
        <div v-if="title" class="flex gap-2 align-items-center">
            <label v-bind="labelProps" class="font-bold">{{ title }}</label>
            <slot name="label-additional"></slot>
        </div>
        <slot></slot>
        <small class="text-red-500" v-if="error">{{ error }}</small>
    </div>
</template>

<script setup lang="ts">
import { computed, type Ref } from "vue";

interface IFormColumnProps {
    id?: string;
    title?: string;
    error?: string | Ref<string>;
}
const props = defineProps<IFormColumnProps>();

const labelProps = computed(() => {
    if (props.id) return { for: props.id };
    return {};
});
</script>

<style scoped></style>
