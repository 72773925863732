import type { EProcessStatus } from "@/entities/Process";
import type { IRequestFilter } from "@/shared/lib/types";

export interface IAssigneeFilter extends IRequestFilter {
    diagramId?: string;
}

export interface IAssigneeResponse {
    content: IAssignee[];
    empty: boolean;
    first: boolean;
    last: boolean;
    number: number;
    numberOfElements: number;
    pageable: any;
    size: number;
    sort: any;
    totalElements: number;
    totalPages: number;
}

export interface IAssigneeGeneral {
    id: undefined | number;
    name: string;
    type: EAssigneeType;
    hourRate: null | number;
    color: undefined | string;
}
export interface IAssignee extends IAssigneeGeneral {
    createBy: string;
    createdOn: Date;
    description: null | string;
    diagramEdges: IAssigneeEdge[];
    diagramEdgesCount: IDiagramEdgeCount[];
    id: number;
    orgItemEdges: IOrgItemEdge[];
    updateBy: string;
    updatedOn: Date;
}
export enum EAssigneeType {
    HUMAN = "HUMAN",
    SYSTEM = "SYSTEM",
    UNSPECIFIED = "UNSPECIFIED",
}
export enum EAssigneeTypeName {
    HUMAN = "Человек",
    SYSTEM = "Система",
    UNSPECIFIED = "Не указано",
}
export enum EAssigneeTypeIcon {
    HUMAN = "pi-user",
    SYSTEM = "pi-android",
    UNSPECIFIED = "pi-question",
}

export interface IAssigneeEdgeGeneral {
    assigneeId: null | number;
    description: null | string;
    diagramId: string;
    elementId: string;
    elementName: string;
    updateDescription: boolean;
}
export interface IAssigneeEdge extends IAssigneeEdgeGeneral {
    assetList: null;
    assigneeEdgeId: number;
    assigneeId: number;
    assigneeName: string;
    assigneeType: EAssigneeType;
    color: string;
    createdOn: Date;
    diagramElementId: null;
    diagramName: string;
    diagramStatus: EProcessStatus;
    duration: null | number;
    externalLink: null | string;
    updatedBy: string;
    updatedOn: Date;
    versionNumber: number;
}
export interface IDiagramEdgeCount {
    count: number;
    diagramId: string;
    diagramName: string;
}
export interface IOrgItemEdge {}
