<template>
    <Button
        :icon="icon"
        :text="!menuSettings.isToken"
        class="navbar-button button_clean-outline"
        severity="contrast"
        v-tooltip.right="tooltip"
        @click="toggle"
    />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { useDiagramActionMenuStore } from "@/entities/Process";
import { useBpmnModelerStore } from "@/entities/BpmnModeler";

const actionMenuStore = useDiagramActionMenuStore();
const modelerStore = useBpmnModelerStore();

const { menuSettings } = storeToRefs(actionMenuStore);
const { simulationSupport } = storeToRefs(modelerStore);

const icon = computed<string>(() => (menuSettings.value.isToken ? PrimeIcons.STOP_CIRCLE : PrimeIcons.PLAY_CIRCLE));
const tooltip = computed<string>(() => (menuSettings.value.isToken ? "Выключить симуляцию токена" : "Включить симуляцию токена"));

function toggle() {
    actionMenuStore.toggleMenuSettings("isToken");

    simulationSupport.value.toggleSimulation();
}
</script>

<style scoped></style>
