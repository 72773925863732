import { httpClient } from "@/shared/api";
import type { IApproval, ICreateApproval } from "../model";
import type { Axios, AxiosResponse } from "axios";

export class ApprovalApi {
    static async fetchAllMy(): Promise<IApproval[]> {
        const response: AxiosResponse = await httpClient.get("/approval/my");
        return response.data;
    }
    static async fetchAllMyByDiagramId(diagramId: string): Promise<IApproval[]> {
        const response: AxiosResponse = await httpClient.get(`/approval/${diagramId}`);
        return response.data;
    }
    static async fetchAllByDiagramId(diagramId: string): Promise<IApproval[]> {
        const response: AxiosResponse = await httpClient.get(`/approval/all/${diagramId}`);
        return response.data;
    }
    static async setDecision(approval: IApproval): Promise<void> {
        await httpClient.post("/approval/decision", approval);
    }
    static async create(payload: ICreateApproval): Promise<void> {
        await httpClient.post("/approval", payload);
    }
    static async delete(approvalId: string): Promise<void> {
        await httpClient.delete(`/approval/${approvalId}`);
    }
}
