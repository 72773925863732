<template>
    <TabView :pt="{ panelContainer: 'px-0' }">
        <TabPanel>
            <template #header>
                <div class="text-left align-items-center">Отправить сообщение</div>
            </template>
            <div class="flex flex-column gap-3">
                <div class="flex flex-column gap-2">
                    <FormColumn title="Имя *" :error="validate.name.$errors[0]?.$message">
                        <InputText v-model="sendMessageForm.name" id="sendMessageFormName" class="w-full" placeholder="Денис" />
                    </FormColumn>
                    <FormColumn title="Email *" :error="validate.workemail.$errors[0]?.$message">
                        <InputText
                            v-model="sendMessageForm.workemail"
                            id="sendMessageFormEmail"
                            class="w-full"
                            placeholder="kotov@bpmn2.ru"
                        />
                    </FormColumn>
                    <FormColumn title="Телефон *" :error="validate.phone.$errors[0]?.$message">
                        <InputMask
                            v-model="sendMessageForm.phone"
                            id="sendMessageFormPhone"
                            mask="+7 (999) 999-9999"
                            placeholder="+7 (987) 654-3210"
                            class="w-full"
                        />
                    </FormColumn>
                    <FormColumn title="Количество сотрудников *" :error="validate.companySize.$errors[0]?.$message">
                        <div class="flex gap-3">
                            <div class="flex align-items-center gap-2">
                                <RadioButton v-model="sendMessageForm.companySize" inputId="sendMessageForm1" value="1" />
                                <label for="sendMessageForm1">1-100</label>
                            </div>
                            <div class="flex align-items-center gap-2">
                                <RadioButton v-model="sendMessageForm.companySize" inputId="sendMessageForm100" value="100+" />
                                <label for="sendMessageForm100">101-1000</label>
                            </div>
                            <div class="flex align-items-center gap-2">
                                <RadioButton v-model="sendMessageForm.companySize" inputId="sendMessageForm1000" value="1000+" />
                                <label for="sendMessageForm1000">1001+</label>
                            </div>
                        </div>
                    </FormColumn>
                    <FormColumn title="О чём Вы хотите узнать? *" :error="validate.questionType.$errors[0]?.$message">
                        <Dropdown
                            v-model="sendMessageForm.questionType"
                            :options="selection"
                            optionLabel="text"
                            optionValue="value"
                            placeholder="Выберите категорию вопроса"
                            class="w-full"
                            :pt="{
                                wrapper: 'max-h-full',
                            }"
                        />
                    </FormColumn>
                </div>
                <Button label="Отправить сообщение" severity="contrast" @click="sendFormContactSale"></Button>
            </div>
        </TabPanel>
        <TabPanel>
            <template #header>
                <div class="text-left align-items-center">Назначить встречу</div>
            </template>
            <div style="width: 100%; height: 100%" id="my-cal-inline"></div>
        </TabPanel>
    </TabView>
</template>

<script setup lang="ts">
import { ref, reactive, onMounted, inject, Ref } from "vue";
import { useVuelidate } from "@vuelidate/core";
import { useInvoiceStore } from "@/entities/Invoice";
import { required, email, helpers } from "@vuelidate/validators";
import { notEmail, requiredValue } from "@/shared/lib/validators";
import type { ISendFormContactSale } from "@/entities/Invoice";
import { FormColumn } from "@/shared/ui/form";
import {} from "vue";

declare const Cal: any;
const invoiceStore = useInvoiceStore();

interface ISelection {
    text: string;
    value: string;
}

const dialogRef = inject<Ref>("dialogRef");
const selection = ref<ISelection[]>([
    {
        text: "Запустить тестовую лицензию",
        value: "trial",
    },
    {
        text: "Запланировать презентацию",
        value: "demo",
    },
    {
        text: "Помочь с выбором тарифа",
        value: "plan",
    },
    {
        text: "Нужен счет для оплаты",
        value: "invoice",
    },
    {
        text: "Заявка для некомерческой организации",
        value: "non-profit",
    },
    {
        text: "Заявка для образовательной организации",
        value: "education",
    },
    {
        text: "Прочее",
        value: "other",
    },
]);
const sendMessageForm = reactive<ISendFormContactSale>({
    name: "",
    workemail: "",
    phone: "",
    companySize: "",
    questionType: "",
});
const rules = {
    name: { required: helpers.withMessage(requiredValue, required) },
    workemail: { required: helpers.withMessage(requiredValue, required), email: helpers.withMessage(notEmail, email) },
    phone: { required: helpers.withMessage(requiredValue, required) },
    companySize: { required: helpers.withMessage(requiredValue, required) },
    questionType: { required: helpers.withMessage(requiredValue, required) },
};
const validate = useVuelidate(rules, sendMessageForm);

async function sendFormContactSale() {
    const isCorrect = await validate.value.$validate();
    if (isCorrect) {
        const res = await invoiceStore.sendFormContactSale(sendMessageForm);
        if (res) {
            sendMessageForm.name = "";
            sendMessageForm.workemail = "";
            sendMessageForm.phone = "";
            sendMessageForm.companySize = "";
            sendMessageForm.questionType = "";
            validate.value.$reset();
        }
        if (dialogRef) {
            dialogRef.value.close();
        }
    }
}

onMounted(() => {
    (function (C: any, A: any, L: any) {
        let p = function (a: any, ar: any) {
            a.q.push(ar);
        };
        let d = C.document;
        C.Cal =
            C.Cal ||
            function () {
                let cal = C.Cal;
                let ar = arguments;
                if (!cal.loaded) {
                    cal.ns = {};
                    cal.q = cal.q || [];
                    d.head.appendChild(d.createElement("script")).src = A;
                    cal.loaded = true;
                }
                if (ar[0] === L) {
                    const api: any = function () {
                        p(api, arguments);
                    };
                    const namespace = ar[1];
                    api.q = api.q || [];
                    if (typeof namespace === "string") {
                        cal.ns[namespace] = cal.ns[namespace] || api;
                        p(cal.ns[namespace], ar);
                        p(cal, ["initNamespace", namespace]);
                    } else p(cal, ar);
                    return;
                }
                p(cal, ar);
            };
    })(window, "https://app.cal.com/embed/embed.js", "init");
    Cal("init", { origin: "https://cal.com" });
    Cal("inline", {
        elementOrSelector: "#my-cal-inline",
        calLink: "david-merabishvili/45",
        layout: "month_view",
    });
    Cal("ui", { styles: { branding: { brandColor: "#e16259" } }, hideEventTypeDetails: true, layout: "month_view" });
});
</script>

<style scoped></style>
