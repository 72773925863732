import EditorActions from "diagram-js/lib/features/editor-actions/EditorActions";
import Keyboard, { Listener } from "diagram-js/lib/features/keyboard/Keyboard";
import { isCmd } from "diagram-js/lib/features/keyboard/KeyboardUtil";
import KeyboardBindings from "diagram-js/lib/features/keyboard/KeyboardBindings";

class BpmnKeyboardBindings extends KeyboardBindings {
    selection: any;
    copyPaste: any;
    moddle: any;
    clipboard: any;

    constructor(eventBus: any, keyboard: any) {
        super(eventBus, keyboard);
    }

    registerBindings(keyboard: Keyboard, editorActions: EditorActions): void {
        function addListener(action: string, fn: Listener) {
            if (editorActions.isRegistered(action)) {
                keyboard.addListener(fn);
            }
        }

        addListener("undo", context => {
            const event = context.keyEvent;
            const { code, shiftKey } = event;

            if (isCmd(event) && !shiftKey && code === "KeyZ") {
                editorActions.trigger("undo", null);
                return true;
            }
        });

        addListener("redo", context => {
            const event = context.keyEvent;
            const { code, shiftKey, ctrlKey, altKey } = event;

            if ((isCmd(event) && code === "KeyY") || (ctrlKey && shiftKey && !altKey && code === "KeyZ")) {
                editorActions.trigger("redo", null);
                return true;
            }
        });

        addListener("copy", context => {
            const event = context.keyEvent;
            const { code } = event;

            if (isCmd(event) && code === "KeyC") {
                editorActions.trigger("copy", null);

                return true;
            }
        });

        addListener("paste", context => {
            const event = context.keyEvent;
            const { code } = event;

            if (isCmd(event) && code === "KeyV") {
                editorActions.trigger("paste", null);

                return true;
            }
        });

        addListener("stepZoom", function (context) {
            const event = context.keyEvent;
            const { code } = event;

            if (isCmd(event) && code === "Equal") {
                editorActions.trigger("stepZoom", { value: 1 });

                return true;
            }
        });

        addListener("stepZoom", function (context) {
            const event = context.keyEvent;
            const { code } = event;

            if (isCmd(event) && code === "Minus") {
                editorActions.trigger("stepZoom", { value: -1 });

                return true;
            }
        });

        addListener("zoom", function (context) {
            const event = context.keyEvent;
            const { code } = event;

            if (isCmd(event) && code === "Digit0") {
                editorActions.trigger("zoom", { value: 1 });

                return true;
            }
        });

        addListener("removeSelection", function (context) {
            const event = context.keyEvent;
            const { code } = event;

            if (["Backspace", "Delete", "Del"].includes(code)) {
                editorActions.trigger("removeSelection", null);

                return true;
            }
        });
    }
}

BpmnKeyboardBindings.$inject = ["eventBus", "keyboard"];

export default BpmnKeyboardBindings;
