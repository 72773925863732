<template>
    <Button
        :icon="PrimeIcons.COPY"
        text
        severity="contrast"
        class="navbar-button"
        :disabled="!hasSelectedElements"
        v-tooltip.bottom="'Скопировать выделенное'"
        @click="copy"
    />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { useElementStore } from "@/entities/BpmnModeler";

import { useCopyPaste } from "../model";

const elementStore = useElementStore();
const { hasSelectedElements } = storeToRefs(elementStore);

const { copy } = useCopyPaste();
</script>

<style scoped></style>
