import { httpClient } from "@/shared/api";
import { httpDadata } from "@/shared/api/http-client/dadata";
import type { AxiosResponse } from "axios";
import type { ICalcPricePayload, ISendFormContactSale, IGetCompany, ICalcPrice } from "../model";

export class InvoiceApi {
    static async calcPrice(payload: ICalcPricePayload): Promise<ICalcPrice> {
        const response: AxiosResponse = await httpClient.post("payment/calculateInvoice", payload);
        return response.data;
    }
    static async getCompany(query: string): Promise<IGetCompany> {
        const response: AxiosResponse = await httpDadata.post("/suggest/party", { query: query, count: 20 });
        return response.data;
    }
    static async downloadInvoicesCourse(payload: any) {
        const response: AxiosResponse = await httpClient.post("/payment/companyCourseInvoice", payload, { responseType: "arraybuffer" });
        return response.data;
    }
    static async sendFormContactSale(form: ISendFormContactSale): Promise<void> {
        await httpClient.post("/payment/contactSales", form);
    }
}
