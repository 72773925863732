<template>
    <div class="flex flex-column gap-1 border-round border-1 surface-border p-3">
        <div v-if="diagram" class="p-3 flex flex-column gap-3 align-items-start">
            <div class="flex flex-column gap-2 align-items-start">
                <ProcessStatus :status="diagram.status" />
                <h2 class="font-medium">{{ diagram.name }}</h2>
            </div>
            <div class="flex flex-column gap-2">
                <div class="flex gap-2">
                    <span class="pi pi-calendar"></span>
                    <span>{{ formatDateTime(diagram.createdOn) }}</span>
                </div>
                <div class="flex gap-2">
                    <span class="pi pi-user"></span>
                    <span>{{ diagram.authorUsername }}</span>
                </div>
            </div>
            <RouterLink :to="{ name: ERoutesName.APP_DIAGRAM_ITEM, params: { id: diagram.id } }" target="_blank">
                <Button :label="$t('see-diagram')" size="small" outlined />
            </RouterLink>
        </div>
        <div v-else-if="loading" class="p-3 flex flex-column gap-3 align-items-start">
            <div class="flex flex-column gap-1 align-items-start">
                <Skeleton width="5rem" height="0.8rem" />
                <Skeleton width="12rem" height="2rem" />
            </div>
            <div class="flex flex-column gap-1 align-items-start">
                <Skeleton width="8rem" height="1rem" />
                <Skeleton width="8.5rem" height="1rem" />
            </div>
            <Skeleton width="15rem" height="1.5rem" />
        </div>
        <Message v-else :closable="false" severity="error"> Нет доступа </Message>
        <CreatedInStorm class="mb-2" />
    </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from "vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { useProcessStore } from "@/entities/Process/model";
import { formatDateTime } from "@/shared/lib/utils/date";
import { CreatedInStorm } from "@/shared/ui/created-in-storm";

import { ProcessStatus } from "@/entities/Process";
import { ERoutesName } from "@/app/providers";

const processStore = useProcessStore();
const { diagram, loading } = storeToRefs(processStore);

const route = useRoute();

const diagramId = computed(() => route.params.diagramId as string);

onMounted(async () => {
    await processStore.fetchById(diagramId.value);
});
</script>

<style scoped></style>
