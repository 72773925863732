export interface IApproval {
    approvalTime: null | Date | string;
    approvalTimeStr: null | string;
    approverEmail: string;
    approverName: null | string;
    comment: null | string;
    createdBy: string;
    createdOn: null | Date | string;
    createdOnStr: null | string;
    diagramId: string;
    diagramName: string;
    diagramVersion: null | number | string;
    id: string;
    status: null | EApprovalResult;
}

export interface ICreateApproval {
    diagramId: string;
    users: string[];
}

export enum EApprovalResult {
    DECLINED = "DECLINED",
    ACCEPTED = "ACCEPTED",
}
