<template>
    <Wrapper>
        <Black_backgroup_hero v-if="blog" :data="blog.blocks[0]">
            <Breadcrumb
                :model="breadcrumbValue"
                :pt="{
                    root: () => ({
                        class: ['breadcrumb p-0'],
                    }),
                    separatorIcon: () => ({
                        style: {
                            color: 'white',
                        },
                    }),
                }"
            >
                <template #item="{ item }">
                    <router-link v-if="item.route" :to="item.route" class="text-white flex align-items-center">
                        <span :class="[item.icon, 'text-lg']" />
                        <span class="text-lg">{{ item.label }}</span>
                    </router-link>
                    <span v-else class="text-white text-lg">
                        {{ item.label }}
                    </span>
                </template>
            </Breadcrumb>
        </Black_backgroup_hero>
        <WrapperBlockColumn class="text-color text-3xl" v-if="blog">
            <div class="flex flex-column w-full gap-3">
                <div class="flex gap-3">
                    <InputSearch
                        ref="inputSearchRef"
                        class="w-full"
                        placeholder="Поиск по постам"
                        :isShowShortcut="true"
                        @search="updateInput"
                        @clearSearch="clearSearchPosts"
                    />
                    <Button label="Найти" class="w-8rem" @click="searchPosts" />
                </div>
                <Divider
                    :pt="{
                        root: () => ({
                            class: ['m-0'],
                        }),
                    }"
                    align="left"
                    type="solid"
                >
                </Divider>
                <div class="flex gap-3 flex-wrap" v-if="blogTags && blogTags?.length > 0">
                    <router-link :to="{ name: ERoutesName.BLOG }" class="text-white">
                        <Button
                            icon="pi pi-list"
                            outlined
                            :label="route.name == ERoutesName.BLOG ? 'Все статьи' : 'Все статьи'"
                            :text="route.name != ERoutesName.BLOG"
                            @click="clearSearchPosts"
                        />
                    </router-link>
                    <Tag v-for="tag of blogTags" :tag="tag" :size="ESizeTag.BIG" />
                </div>
                <Divider
                    :pt="{
                        root: () => ({
                            class: ['m-0'],
                        }),
                    }"
                    align="left"
                    type="solid"
                >
                </Divider>
                <div class="w-full flex flex-column align-items-center" v-if="seachNotResult">
                    <h2 class="text-4xl">Поиск не дал результатов</h2>
                    <img
                        class="no-posts"
                        height="500px"
                        width="500px"
                        loading="lazy"
                        alt="Поиск не дал результатов"
                        title="Поиск не дал результатов"
                        src="@/shared/assets/images/png/seachNotResult.png"
                    />
                </div>
                <div class="flex flex-column gap-4" v-else>
                    <FeaturedPost v-if="featuredPost" />

                    <div class="flex flex-column gap-3" v-if="postText && lastPostText.length > 0">
                        <h2 class="text-left w-full text-4xl">Последние публикации</h2>
                        <div
                            class="flex w-full flex-wrap xl:flex-nowrap xl:justify-content-between justify-content-center gap-3"
                            v-if="postText"
                        >
                            <PostBig v-for="post of lastPostText" :key="post?.id" :post="post" />
                        </div>
                    </div>
                    <div class="flex flex-column w-full gap-4" v-if="postVideo.length > 0">
                        <h2 class="text-left w-full text-4xl">Видео</h2>
                        <Carousel
                            :value="postVideo"
                            :numVisible="2"
                            :numScroll="1"
                            :responsiveOptions="responsiveOptions"
                            class="max-w-full"
                            :autoplayInterval="10000"
                            circular
                            :showIndicators="false"
                        >
                            <template #item="slotProps">
                                <PostVideo :key="slotProps.data.id" :post="slotProps.data" />
                            </template>
                        </Carousel>
                    </div>

                    <div class="flex flex-column w-full gap-4" v-if="postText.length > 0">
                        <h2 class="text-left w-full text-4xl">
                            {{ !isSearch ? "Все публикации" : "Найдено статей: " + postText.length }}
                        </h2>
                        <div class="post__all" v-if="postText">
                            <PostBig v-for="post of postText" :key="post.id" :post="post" />
                        </div>
                    </div>
                    <div v-if="postText.length == 0">
                        <h4 class="block text-center">Пишем первую статью, скоро будет готово</h4>
                        <div class="flex justify-content-center flex-wrap">
                            <img
                                class="no-posts flex align-items-center justify-content-center"
                                loading="lazy"
                                height="500"
                                width="500"
                                alt="Голова мастера Токена"
                                title="Голова мастера Токена"
                                src="@/shared/assets/images/png/no_posts.png"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </WrapperBlockColumn>
    </Wrapper>
</template>

<script setup lang="ts">
import { Wrapper, WrapperBlockColumn } from "@/shared/ui/marketing";
import { ref, computed, watch } from "vue";
import { useInterfaceStore } from "@/entities/Interface";
import { storeToRefs } from "pinia";
import { FeaturedPost, PostBig, PostVideo, Tag } from "@/shared/ui/marketing";
import { Black_backgroup_hero } from "./blocks";
import { useRoute, useRouter } from "vue-router";
import { ERoutesName } from "@/app/providers";
import { ESizeTag } from "@/shared/lib/types";
import { useNotification } from "@/shared/lib/composables";
import { InputSearch } from "@/shared/ui/input-search";

const interfaceStore = useInterfaceStore();
const { blogTags, blog, postText, postVideo, featuredPost, lastPostText, seachNotResult } = storeToRefs(interfaceStore);
const route = useRoute();
const router = useRouter();
const { showError } = useNotification();
const piecePosts = ref<string>("");
const isSearch = ref<boolean>(false);
const inputSearchRef = ref();
const responsiveOptions = ref([
    {
        breakpoint: "1400px",
        numVisible: 2,
        numScroll: 1,
    },
    {
        breakpoint: "1199px",
        numVisible: 1,
        numScroll: 1,
    },
    {
        breakpoint: "767px",
        numVisible: 1,
        numScroll: 1,
    },
    {
        breakpoint: "575px",
        numVisible: 1,
        numScroll: 1,
    },
]);

interface IBreadcrumbValue {
    icon?: string;
    route?: string;
    label?: string;
}

const breadcrumbValue = computed(() => {
    const items: IBreadcrumbValue[] = [
        {
            icon: "pi pi-home",
            route: "/",
        },
        {
            label: "Блог",
            route: "/blog",
        },
    ];

    if (route.params.slug) {
        if (blogTags.value) {
            const item = blogTags.value.filter((item: any) => item.slug == route.params.slug);
            items.push({ label: item[0].title });
        }
    }
    return items;
});

function updateInput(inputValue: string) {
    piecePosts.value = inputValue;
}

async function clearSearchPosts() {
    await interfaceStore.stopSearch();
    await Promise.all([interfaceStore.getBlog(), interfaceStore.getPosts()]);
    router.push({ name: ERoutesName.BLOG });
}

async function searchPosts() {
    if (piecePosts.value.length > 50) {
        showError("Большая строка для поиска, попробуйте поменьше");
    } else if (piecePosts.value) {
        await interfaceStore.searchPosts(piecePosts.value);
        router.push({ name: ERoutesName.BLOG });
        isSearch.value = true;
    }
}
</script>

<style scoped lang="scss">
.breadcrumb {
    background: none;
    background-color: none;
    border-radius: none;
    border: none;
}
.post {
    &__all {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 1rem;
        @media screen and (max-width: 767px) {
            display: flex;
            flex-direction: column;
        }
    }
}
.no-posts {
    max-height: 400px;
    max-width: 400px;
}
p {
    font-size: 15px;
    line-height: 1.44;
}
li {
    font-size: 15px;
    line-height: 1.44;
}
</style>
