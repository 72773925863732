<template>
    <WrapperBlockColumn v-if="props.data">
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockBpmnEelementsRef">
            <div class="mt-5">
                <div v-html="data?.item?.headline"></div>
                <div v-html="data?.item?.content"></div>
            </div>
            <InputSearch ref="inputSearchRef" placeholder="Введите элемент" :isShowShortcut="true" class="w-full" @search="updateInput" />
            <div class="grid w-full gap-3">
                <div class="flex justify-content-start w-full p-0">Элементов : {{ elements?.length }}</div>
                <div class="hidden md:block col-3 p-0" v-if="data?.item?.sidebar_groupping">
                    <div class="flex flex-column gap-2 border-1 border-black-alpha-20 border-round-lg p-3">
                        <template v-for="(name, index) of baseClass">
                            <Button
                                class="flex justify-content-between text-600 border-none shadow-none px-0 py-2"
                                @click="selectGroup(name.name)"
                                :class="[name.name == selectedGroup ? 'bg-indigo-100 text-indigo-500' : 'bg-white hover:surface-100']"
                            >
                                <h2 class="lg:text-2xl text-base">{{ name.name }}</h2>
                                <span class="bg-primary border-circle flex align-items-center justify-content-center count">{{
                                    name.count
                                }}</span>
                            </Button>
                            <Divider v-if="index < baseClass.length - 1" class="m-0 border-black-alpha-20" />
                        </template>
                    </div>
                </div>
                <div class="col flex flex-column gap-3 p-0">
                    <Element v-for="item of elements" :element="item" :key="item.id" :is-element-link="isElementLink" />
                </div>
            </div>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { WrapperBlockColumn, Element } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";
import { InputSearch } from "@/shared/ui/input-search";
import { type IElement } from "@/entities/Interface";

interface IProps {
    data: IBlock;
    article?: boolean;
    isElementLink?: boolean;
}

const props = withDefaults(defineProps<IProps>(), { article: false, isElementLink: true });
const emit = defineEmits(["getHtml"]);

const blockBpmnEelementsRef = ref();
const selectedGroup = ref<string>("");
const elements = ref<IElement[]>([]);
const inputSearchRef = ref();

const baseClass = computed(() => {
    const nameClass: any = [];
    if (props.data.item.elements) {
        props.data.item.elements.map((item: any) => {
            const filterName = nameClass.find((name: any) => name.name == item.Bpmn_elements_id.base_class);
            if (!filterName) nameClass.push({ name: item.Bpmn_elements_id.base_class, count: 1 });
            else {
                filterName.count += 1;
            }
        });
    }
    return nameClass;
});

function selectGroup(nameGroup: string) {
    if (props.data.item.elements) {
        inputSearchRef.value.inputReset();
        selectedGroup.value = nameGroup;
        elements.value = props.data.item.elements.filter((item: any) => item.Bpmn_elements_id.base_class == selectedGroup.value);
    }
}
function updateInput(payload: string) {
    if (props.data.item.elements) {
        props.data.item.elements.sort((a, b) => a.Bpmn_elements_id.base_class.localeCompare(b.Bpmn_elements_id.base_class));
        if (payload) {
            selectedGroup.value = "";
            const lowerNameElement = payload.toLowerCase();
            elements.value = props.data.item.elements.filter(
                (item: any) =>
                    item.Bpmn_elements_id.name.toLowerCase().includes(lowerNameElement) ||
                    item.Bpmn_elements_id.base_class.toLowerCase().includes(lowerNameElement) ||
                    item.Bpmn_elements_id.original_name.toLowerCase().includes(lowerNameElement) ||
                    item.Bpmn_elements_id.description.toLowerCase().includes(lowerNameElement),
            );
        } else {
            elements.value = props.data.item.elements;
        }
    }
}

watch(blockBpmnEelementsRef, () => {
    if (props.article) {
        emit("getHtml", blockBpmnEelementsRef.value);
    }
});
onMounted(() => {
    if (props.data.item.elements) {
        elements.value = props.data.item.elements;
    } else elements.value = [];
});
</script>

<style scoped>
.count {
    min-width: 2rem;
    max-width: 2rem;
    min-height: 2rem;
    max-height: 2rem;
}
</style>
