<template>
    <div class="wrapper-img flex justify-content-center">
        <slot></slot>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped lang="scss">
.wrapper-img {
    min-width: 50%;
    max-width: 50%;
    width: 50%;
    max-height: 400px;

    @media screen and (max-width: 767px) {
        min-width: 100%;
        max-width: 100%;
        max-height: 100%;
    }
}
</style>
