import { EBpmnElementType, RULES } from "@/entities/Score";
import type { ILintRule } from "@/shared/lib/types/app";

export function lintActivity(activities: any[]): ILintRule[] {
    const lintResult: ILintRule[] = [];

    for (const element of activities) {
        const { bpmnElement } = element;
        const { id, $type, name = null } = bpmnElement;

        const isEventSubProcess: boolean = ($type === EBpmnElementType.SUB_PROCESS && bpmnElement?.triggeredByEvent) || false;
        const isCompensation: boolean = bpmnElement?.isForCompensation || false;

        const isIncoming: boolean = "incoming" in bpmnElement;
        const isOutgoing: boolean = "outgoing" in bpmnElement;
        const countIncoming: number = isIncoming ? bpmnElement.incoming.length : 0;
        const countOutgoing: number = isOutgoing ? bpmnElement.outgoing.length : 0;

        if (!name) {
            lintResult.push({ ...RULES.NO_NAME, id });
        }

        if ((name?.length || 0) > 200) {
            lintResult.push({ ...RULES.LONG_NAME, id });
        }

        // ГЛАГОЛ ПОВЕЛИТЕЛЬНОГО НАКЛОНЕНИЯ

        if (!isIncoming && !isEventSubProcess && !isCompensation) {
            lintResult.push({ ...RULES.NO_INCOMING_THREADS, id });
        }

        if (!isOutgoing && !isEventSubProcess && !isCompensation) {
            lintResult.push({ ...RULES.NO_OUTGOING_THREADS, id });
        }

        if (isOutgoing) {
            for (const flow of bpmnElement.outgoing) {
                if ("conditionExpression" in flow) lintResult.push({ ...RULES.DONT_USE_CONDITIONAL_THREADS, id: flow.id });
            }
        }

        if (countIncoming > 1) {
            lintResult.push({ ...RULES.MANY_INCOMING_THREADS, id });
        }

        if (!isIncoming && !isOutgoing && !isEventSubProcess && !isCompensation) {
            lintResult.push({ ...RULES.NOT_CONNECTED_8, id });
        }

        if (countOutgoing > 1) {
            lintResult.push({ ...RULES.MANY_OUTGOING_THREADS, id });
        }
    }

    return lintResult;
}
