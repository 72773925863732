<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockFeatureSliderRef">
            <div v-if="data.item.headline" v-html="data.item.headline" />
            <Carousel
                :value="data?.item?.slides"
                :numVisible="1"
                :numScroll="1"
                class="max-w-full flex gap-3"
                :autoplayInterval="5000"
                circular
                :showIndicators="false"
                :showNavigators="false"
                :page="pageInterprice"
                @update:page="updateSlide"
            >
                <template #header>
                    <div class="grid flex gap-3 justify-content-center align-items-center">
                        <Button
                            class="p-2"
                            severity="secondary"
                            :text="i != pageInterprice"
                            raised
                            v-for="(item, i) of data?.item?.slides"
                            @click="updateSlide(i)"
                            >{{ item.Block_feature_slides_id.name }}</Button
                        >
                    </div>
                </template>
                <template #item="slotProps">
                    <div class="flex justify-content-center">
                        <ImgSecondary>
                            <img
                                style="max-height: 400px"
                                loading="lazy"
                                :src="IMG_DIRECTUS + slotProps.data.Block_feature_slides_id.image.id + '?format=webp'"
                                :width="slotProps.data.Block_feature_slides_id.image.width"
                                :height="slotProps.data.Block_feature_slides_id.image.height"
                                :alt="slotProps.data.Block_feature_slides_id.name"
                                :title="slotProps.data.Block_feature_slides_id.name"
                            />
                        </ImgSecondary>
                    </div>
                </template>
            </Carousel>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn, ImgSecondary } from "@/shared/ui/marketing";
import { IMG_DIRECTUS } from "@/shared/config";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });

const emit = defineEmits(["getHtml"]);
const blockFeatureSliderRef = ref();

const pageInterprice = ref<number>(0);

function updateSlide(value: number) {
    pageInterprice.value = value;
}

watch(blockFeatureSliderRef, () => {
    if (props.article) {
        emit("getHtml", blockFeatureSliderRef.value);
    }
});
</script>

<style scoped></style>
