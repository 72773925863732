import { ApprovalApi } from "@/entities/Approval";
import type { IApproval } from "@/entities/Approval/model";
import { useNotification } from "@/shared/lib/composables";

export function useSetDecision() {
    const { showError } = useNotification();

    async function setDecision(approval: IApproval) {
        try {
            await ApprovalApi.setDecision(approval);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    return {
        setDecision,
    };
}
