<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockTabsRef">
            <div class="flex flex-column gap-3">
                <div v-html="data?.item?.headline"></div>
                <div v-html="data?.item?.content"></div>
            </div>
            <Accordion :multiple="true" :activeIndex="0" class="w-full text-left">
                <AccordionTab v-for="item of data?.item?.items" :header="item.name">
                    <div v-html="item.description"></div>
                </AccordionTab>
            </Accordion>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { WrapperBlockColumn } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockTabsRef = ref();

watch(blockTabsRef, () => {
    if (props.article) {
        emit("getHtml", blockTabsRef.value);
    }
});
</script>

<style scoped></style>
