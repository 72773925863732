import { useDiagramStore } from "@/entities/Process";
import { useLoading, useNotification } from "@/shared/lib/composables";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { CommentApi } from "../..";
import type { IOption } from "@/shared/lib/types";

export function useSuggestions() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();

    const diagramStore = useDiagramStore();
    const { diagram } = storeToRefs(diagramStore);

    const suggestions = ref<IOption[]>([]);

    async function getSuggestion() {
        try {
            startLoading();

            const values: string[] = await CommentApi.getSuggestionsByDiagramId(diagram.value?.id || "");
            suggestions.value = values.map(item => ({ label: item, value: item }));
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,

        suggestions,
        getSuggestion,
    };
}
