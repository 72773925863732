import { EBillingInterval, EPlan } from "@/shared/lib/types";

export enum ETypePeriod {
    MONTH = "Ежемесячно",
    YEAR = "Ежегодно",
}

export enum EUpgradePlan {
    PERSONAL_PRO = "Personal pro",
    TEAM = "Team",
    ENTERPRISE = "ENTERPRISE",
}

export interface IPlan {
    externalId: string | null;
    createdOn: string | null;
    validTo: string | null;
    amount: number;
    teamName: string | null;
    card: any;
    invoiceStatus: string | null;
    membersCount: number | null;
    currentMembersCount?: number | null;
    paymentPlan: EPlan;
    paymentInterval: string | null;
}

export interface IUpgradePrice {
    totalPrice: number;
    pricePerMember: number;
    totalMembers: number;
    discountedPrice: number;
    priceWithoutDiscount: number;
}

export interface IPayloadUpgradePrice {
    billingInterval: string;
    locale?: string;
    membersCount: number;
    plan: EUpgradePlan;
}

export interface IPaymentUpgrade {
    userId?: number;
    destination: string;
    amount: string | number;
    widget: number;
    nonce?: string;
    signParam?: string;
    widgetSign?: string;
}

export interface IFeatures {
    name: string;
    plans: EPlan[];
}

export interface IFeaturesValid {
    valid: IFeatures[];
    notValid: IFeatures[];
}

export interface ITarif {
    [key: string]: ITarifItem;
}
export interface ITarifItem {
    icon: string;
    title: EPlan;
    planValue: EUpgradePlan;
    discriptionTitle: string;
    discription: string;
    discriptionPrice: string;
    fullDiscription: string;
}

export enum ESubscribeStatus {
    NEW = "NEW",
    PROCESSED = "OK",
    IN_PROCESS = "HOLD",
}
export interface IPeriod {
    title: ETypePeriod;
    value: EBillingInterval;
}
