import { useLoading, useNotification } from "@/shared/lib/composables";
import { defineStore, storeToRefs } from "pinia";
import { ref } from "vue";
import type { IAssignee, IAssigneeFilter, IAssigneeResponse } from ".";
import { AssigneeApi, type IAssigneeEdge } from "..";

import { useSessionStore } from "@/entities/Session";

export const useAssigneeStore = defineStore("assignee", () => {
    const { showError } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();

    const sessionStore = useSessionStore();
    const { isTeamPlan } = storeToRefs(sessionStore);

    const assigneeByFilter = ref<IAssignee[]>([]);
    async function fetchForFilter(): Promise<void> {
        try {
            startLoading();
            assigneeByFilter.value = await AssigneeApi.fetchForFilter();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    const assigneesByDiagram = ref<IAssigneeEdge[]>([]);
    async function fetchByDiagramId(diagramId: string): Promise<void> {
        try {
            startLoading();
            assigneesByDiagram.value = await AssigneeApi.fetchByDiagramId(diagramId);
        } catch (e) {
            assigneesByDiagram.value = [];
            showError(e);
        } finally {
            finishLoading();
        }
    }
    function replaceAssigneeByDiagram(assigneeEdge: IAssigneeEdge): void {
        const index: number = assigneesByDiagram.value.findIndex(item => item.assigneeEdgeId === assigneeEdge.assigneeEdgeId);
        if (index >= 0) {
            assigneesByDiagram.value.splice(index, 1, assigneeEdge);
        } else {
            assigneesByDiagram.value.push(assigneeEdge);
        }
    }

    const assignee = ref<IAssignee>();
    const assignees = ref<IAssignee[]>([]);
    const assigneesRecords = ref<number>(0);
    async function fetchAll(filter: IAssigneeFilter = { page: 0, size: 10 }, isShort: boolean = false): Promise<void> {
        try {
            if (!isTeamPlan.value) return;

            startLoading();

            if ("name" in filter && !filter.name) delete filter.name;

            const res: IAssigneeResponse = isShort ? await AssigneeApi.fetchAllShort(filter) : await AssigneeApi.fetchAll(filter);
            assignees.value = res.content;
            assigneesRecords.value = res.totalElements;
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }
    function replaceAssignee(assigneeData: IAssignee): void {
        const index = assignees.value.findIndex(item => item.id === assigneeData.id);
        if (index >= 0) {
            assignees.value.splice(index, 1, assigneeData);
        } else {
            assigneesRecords.value += 1;
            assignees.value.unshift(assigneeData);
            if (assigneesRecords.value > 10) assignees.value.pop();
        }

        if (assignee.value) {
            assignee.value = assigneeData;
        }
    }
    async function fetchOneById(id: number): Promise<void> {
        try {
            startLoading();

            assignee.value = await AssigneeApi.fetchOneById(id);
        } catch (e) {
            showError(e);
            assignee.value = undefined;
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        assigneeByFilter,
        fetchForFilter,

        assigneesByDiagram,
        fetchByDiagramId,
        replaceAssigneeByDiagram,

        assigneesRecords,
        assignees,
        fetchAll,

        replaceAssignee,

        assignee,
        fetchOneById,
    };
});
