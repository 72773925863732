<template>
    <div v-show="menuSettings.isCamundaPanel" id="properties"></div>
</template>

<script setup lang="ts">
import { useDiagramActionMenuStore } from "@/entities/Process";
import { storeToRefs } from "pinia";

const diagramActionMenuStore = useDiagramActionMenuStore();
const { menuSettings } = storeToRefs(diagramActionMenuStore);
</script>

<style scoped></style>
