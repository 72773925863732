export async function copyToClipboard(text: string) {
    if (navigator.clipboard) {
        const clipboardData = navigator.clipboard;
        clipboardData.writeText(text);
    } else {
        const input = document.createElement("input");
        input.value = text;
        document.body.appendChild(input);
        input.select();
        document.execCommand("copy");
        document.body.removeChild(input);
    }
}
