<template>
    <Button
        :icon="PrimeIcons.UNDO"
        text
        severity="contrast"
        class="navbar-button text-600"
        v-tooltip.bottom="'Отменить последнее действие'"
        :disabled="disabledUndo"
        @click="undo"
    />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { useCommandStack } from "../model";

const { disabledUndo, undo } = useCommandStack();
</script>

<style scoped></style>
