import { EBpmnElementType } from "@/entities/Score";
import RuleProvider from "diagram-js/lib/features/rules/RuleProvider";

class ResizeTask extends RuleProvider {
    taskResizingEnabled = false;

    constructor(eventBus: any, taskResizingEnabled: boolean = false) {
        super(eventBus);

        this.taskResizingEnabled = taskResizingEnabled;

        this.addRule("shape.resize", 1500, data => {
            const isActivity: boolean =
                data.shape.businessObject &&
                (data.shape.businessObject.$instanceOf(EBpmnElementType.TASK) ||
                    data.shape.businessObject.$instanceOf(EBpmnElementType.CALL_ACTIVITY) ||
                    data.shape.businessObject.$instanceOf(EBpmnElementType.SUB_PROCESS));

            if (this.taskResizingEnabled && isActivity) {
                if (data.newBounds) {
                    data.newBounds.width = Math.max(100, data.newBounds.width);
                    data.newBounds.height = Math.max(80, data.newBounds.height);
                }
                return true;
            }
        });
    }
}

ResizeTask.$inject = ["eventBus", "config.taskResizingEnabled"];

export default ResizeTask;
