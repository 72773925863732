<template>
    <div class="display-grid">
        <slot></slot>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.display-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-gap: 1rem;
}
</style>
