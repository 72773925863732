export const DEFAULT_SEO_CONFIG = {
    title: "Stormbpmn: инструмент для моделирования бизнес-процессов",
    meta_desciription:
        "Лучший способ описывать бизнес-процессы и договариваться с коллегами. Узнайте об основных преимуществах моделирования процессов в BPMN и stormbpmn.com",
    og_description: 
        "Лучший способ описывать бизнес-процессы и договариваться с коллегами. Узнайте об основных преимуществах моделирования процессов в BPMN и stormbpmn.com",
    og_title: "Stormbpmn: инструмент для моделирования бизнес-процессов",
    og_image: "https://cms.stormbpmn.com/assets/39a05ba8-e476-46eb-a7e4-72b68776e3ad",
    no_follow: "",
    no_index: "",
    og_url: "https://stormbpmn.com",
    og_type: "website",
    canonical_url: "https://stormbpmn.com",
    og_sitename: "Stormbpmn - система моделирования бизнес-процессами"
};
