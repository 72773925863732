import { createRouter, createWebHistory } from "vue-router";
import { routes } from "./routes";
import { beforeEach, afterEach } from "./guards";

export const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes,
    strict: true,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return {
                el: to.hash,
                behavior: "smooth",
                top: 60,
            };
        } else {
            return { top: 0 };
        }
    },
});

router.beforeEach(beforeEach);
router.afterEach(afterEach);

export * from "./types";
