<template>
    <Wrapper class="surface-ground" v-if="element">
        <Black_backgroup_hero :article="false">
            <Breadcrumb
                :model="breadcrumbValue"
                :pt="{
                    root: () => ({
                        class: ['breadcrumb p-0 h-full'],
                    }),
                    separatorIcon: () => ({
                        style: {
                            color: 'white align-items-center',
                        },
                    }),
                }"
            >
                <template #item="{ item }">
                    <router-link v-if="item.route" :to="item.route" class="text-white flex align-items-center">
                        <span :class="[item.icon, 'text-lg']" />
                        <span class="text-lg">{{ item.label }}</span>
                    </router-link>
                    <span v-else class="text-white text-lg">
                        {{ item.label }}
                    </span>
                </template>
            </Breadcrumb>
            <div class="text-white flex gap-3 align-items-center">
                <span class="text-8xl" :class="element?.bpmn_font_code"></span>
                <div class="flex flex-column text-left">
                    <h1>{{ element?.name }} <br />{{ element?.original_name }}</h1>
                    <h2>{{ element?.description }}</h2>
                </div>
            </div>
            <SharePanel />
        </Black_backgroup_hero>
        <WrapperBlockColumn>
            <div class="w-full bg-white border-round-xl p-5">
                <div class="flex flex-column gap-3">
                    <div>
                        <h2 class="text-2xl">Название элемента на русском языке</h2>
                        <span class="text-3xl">{{ element?.name }}</span>
                    </div>
                    <div>
                        <h2 class="text-2xl">Название элемента на английском языке</h2>
                        <span class="text-3xl">{{ element?.original_name }}</span>
                    </div>
                    <div>
                        <h2 class="text-2xl">Описание элемента</h2>
                        <span class="text-3xl">{{ element?.description }}</span>
                    </div>
                    <div>
                        <h2 class="text-2xl">Внешний вид элемента</h2>
                        <span class="text-8xl" :class="element?.bpmn_font_code"></span>
                    </div>
                    <div class="flex flex-column gap-3">
                        <h2 class="text-red-600 font-bold text-2xl">Плохой пример использования</h2>
                        <div class="h-full">
                            <img
                                v-if="element?.bad_example_img?.id"
                                loading="lazy"
                                :src="IMG_DIRECTUS + element?.bad_example_img?.id + '?format=webp' || ''"
                                :height="element?.bad_example_img?.height"
                                :width="element?.bad_example_img?.width"
                                :alt="'Плохой пример использования элемента BPMN ' + element?.name"
                                :title="'Плохой пример использования элемента BPMN ' + element?.name"
                            />
                        </div>
                        <div class="text-red-600 text-2xl">{{ element?.bad_example_description }}</div>
                    </div>
                    <div class="flex flex-column gap-3">
                        <h2 class="text-green-600 font-bold text-2xl">Хороший пример использования</h2>
                        <div class="h-full w-full">
                            <img
                                v-if="element?.good_example_img?.id"
                                loading="lazy"
                                :src="IMG_DIRECTUS + element?.good_example_img?.id + '?format=webp'"
                                :height="element?.good_example_img?.height"
                                :width="element.good_example_img?.width"
                                :alt="'Хороший пример использования элемента BPMN ' + element?.name"
                                :title="'Хороший пример использования элемента BPMN ' + element?.name"
                            />
                        </div>
                        <div class="text-green-600 text-2xl">{{ element?.good_example_description }}</div>
                    </div>
                </div>
            </div>
        </WrapperBlockColumn>
    </Wrapper>
</template>

<script setup lang="ts">
import { Wrapper, WrapperBlockColumn, SharePanel } from "@/shared/ui/marketing";
import { Black_backgroup_hero } from "./blocks";
import { useInterfaceStore } from "@/entities/Interface";
import { storeToRefs } from "pinia";
import { IMG_DIRECTUS } from "@/shared/config";
import { computed, watch } from "vue";

const interfaceStore = useInterfaceStore();
const { element } = storeToRefs(interfaceStore);

const breadcrumbValue = computed(() => {
    const items: any = [
        {
            icon: "pi pi-home",
            route: "/",
        },
        {
            label: "Все элементы",
            route: "/bpmn/elements",
        },
    ];
    if (element.value) {
        items.push({ label: element.value.name });
    }
    return items;
});
</script>

<style scoped>
.breadcrumb {
    background: none;
    background-color: none;
    border-radius: none;
    border: none;
}
</style>
