<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockTestimonialsRef">
            <div v-if="data.item.headline" v-html="data.item.headline"></div>
            <div class="py-5 w-12 flex gap-3 border-y-1 border-black-alpha-20 justify-content-center">
                <Carousel
                    :value="data?.item?.testimonials"
                    :numVisible="1"
                    :numScroll="1"
                    class="max-w-full"
                    :autoplayInterval="12000"
                    :circular="data.item.testimonials && data.item.testimonials.length > 1"
                    :showIndicators="false"
                >
                    <template #item="slotProps">
                        <UsersGrade class="align-content-center justify-content-center" :user="slotProps.data" :key="slotProps.data.id" />
                    </template>
                </Carousel>
            </div>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn, UsersGrade } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockTestimonialsRef = ref();

watch(blockTestimonialsRef, () => {
    if (props.article) {
        emit("getHtml", blockTestimonialsRef.value);
    }
});
</script>

<style scoped></style>
