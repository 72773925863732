<template>
    <div
        style="overflow: hidden"
        class="transition-all transition-duration-300 shadow-0 hover:shadow-5 col m-5 p-0 flex flex-column border-round-md border-1 surface-border text-color"
    >
        <div class="video">
            <a :href="post?.video_url" target="blank">
                <img
                    class="border-round-top size__img transition-duration-300 image"
                    :src="youtubeThimbnail"
                    :alt="post?.title"
                    width="600"
                    height="300"
                    loading="lazy"
                    :title="post?.title"
                />
            </a>
        </div>
        <div class="flex flex-column gap-3 p-3 h-full justify-content-between">
            <div class="flex flex-column gap-3 text-left align-items-start">
                <div class="text-2xl font-semibold" style="width: 100%">
                    {{ post?.title }}
                </div>
            </div>
            <Divider
                :pt="{
                    root: () => ({
                        class: ['m-0'],
                    }),
                }"
                align="left"
                type="solid"
            >
            </Divider>
            <div class="flex align-items-center justify-content-between text-lg gap-2">
                <div class="flex align-items-center gap-2">
                    <span class="pi pi-clock"></span>
                    <span>{{ post?.video_length }} {{ declensionNumerals(post?.video_length, ["минута", "минуты", "минут"]) }} </span>
                </div>
                <div>{{ formatDate(post?.date_published) }}</div>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { formatDate } from "@/shared/lib/utils/date";
import { declensionNumerals } from "@/shared/lib/utils/formater";

import { computed } from "vue";

const props = defineProps({
    post: { type: Object },
});

const videoId = computed(() => {
    var str = props.post?.video_url;

    return str.substring(str.indexOf("v") + 2, str.indexOf("v") + 13);
});

const youtubeThimbnail = computed(() => {
    return "https://img.youtube.com/vi/" + videoId.value + "/maxresdefault.jpg";
});
</script>

<style scoped lang="scss">
.video:hover *.image {
    transform: scale(1.1);
}
.video {
    overflow: hidden;
    max-width: 720px;
    max-height: 315px;
}

.video img {
    max-width: 600px;
    max-height: 290px;
    object-fit: cover;
}
</style>
