import { storeToRefs } from "pinia";

import type { IApproval } from "@/entities/Approval/model";
import { useLoading, useNotification } from "@/shared/lib/composables";
import { useSessionStore } from "@/entities/Session";
import { ApprovalApi, ApprovalModel } from "@/entities/Approval";

export function useDeleteApproval() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError, showSuccess } = useNotification();

    const sessionStore = useSessionStore();
    const { userData } = storeToRefs(sessionStore);

    const approvalStore = ApprovalModel.useApprovalStore();

    async function deleteApproval(approval: IApproval): Promise<void> {
        try {
            startLoading();

            if (
                !approval.status &&
                (approval.createdBy === userData.value.username || approval.approverEmail === userData.value.username)
            ) {
                await ApprovalApi.delete(approval.id);

                await approvalStore.fetchMyByDiagramId(approval.diagramId);
                await approvalStore.fetchAllByDiagramId(approval.diagramId);

                showSuccess("Удалено");
            } else {
                throw new Error("Невозможно удалить");
            }
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        deleteApproval,
    };
}
