import { computed, onMounted, ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";

import type { ICreateFolder, IFolder, IFolderQuery, IRenameFolder } from ".";
import { FolderApi } from "../api";

import { walkTreeData } from "@he-tree/vue";

import { useNotification, useLoading } from "@/shared/lib/composables";
import { useLocalStorage } from "@/shared/lib/browser";

import { useSessionStore } from "@/entities/Session";
import { SELECTED_FOLDER_KEY } from "@/shared/config";

export const useFolderStore = defineStore("folder", () => {
    const { showError } = useNotification();
    const { isLoading, finishLoading, startLoading } = useLoading();

    const sessionStore = useSessionStore();
    const { isTeamPlan } = storeToRefs(sessionStore);

    const foldersSource = ref<IFolder[]>([]);
    const folders = ref<IFolder[]>([]);
    const foldersForSearch = ref<IFolder[]>();
    const childrenFolders = ref(<IFolder[]>[]);
    const selectedFolder = ref<IFolder>();

    const hasChildren = computed(() => childrenFolders.value.length > 0);

    const timer = ref();

    function getNodeByID(folders: IFolder[], id: number): IFolder | null {
        let found: IFolder | null = null;

        walkTreeData(folders, (node: IFolder) => {
            if (node.id === id) {
                found = node;
                return false;
            }
        });
        return found;
    }
    function filterFolder(folders: IFolder[], callback: (folder: IFolder) => boolean): IFolder[] {
        return folders.reduce((list: IFolder[], entry: IFolder) => {
            let clone: IFolder | null = null;
            if (callback(entry)) {
                clone = Object.assign({}, entry);
            } else if (entry.children) {
                let children = filterFolder(entry.children, callback);
                if (children.length) {
                    clone = Object.assign({}, entry, {
                        children: children,
                    });
                }
            }

            if (!!clone) {
                list.push(clone);
            }
            return list;
        }, []);
    }
    function clearChildrenFolders() {
        childrenFolders.value = [];
    }
    function selectFolder(folder: IFolder | null) {
        const { setLSValue } = useLocalStorage<null | IFolder>(SELECTED_FOLDER_KEY);

        selectedFolder.value = folder || undefined;
        setLSValue(folder);
    }
    function resetSelectedFolder(): void {
        selectedFolder.value = undefined;
    }

    async function search(text: undefined | string): Promise<void> {
        if (timer.value) {
            clearTimeout(timer.value);
            timer.value = null;
        }

        if (!text) return await fetchAll();

        timer.value = setTimeout(() => {
            folders.value = filterFolder(foldersSource.value, function (item) {
                return item.text.toLowerCase().includes(text.toLowerCase());
            });
        }, 300);
    }

    async function nodeClick(nodeId: number, isFetchChildren: boolean = true) {
        const node: IFolder | null = getNodeByID(folders.value, nodeId);

        clearChildrenFolders();

        if (node) {
            selectFolder(node);
            if (isFetchChildren && node.children.length > 0) await fetchChildrenById(nodeId);
        }
    }

    async function fetchAll(isSetSelected: boolean = false, query?: IFolderQuery) {
        try {
            if (!isTeamPlan.value) return;

            const { value: localFolder } = useLocalStorage<null | IFolder>(SELECTED_FOLDER_KEY);

            startLoading();

            const data: IFolder[] = await FolderApi.fetchAll(query);

            folders.value = data;
            foldersSource.value = JSON.parse(JSON.stringify(data));

            if (!selectedFolder.value || isSetSelected) {
                selectFolder(localFolder ? localFolder : folders.value[0]);
                clearChildrenFolders();
            } else {
                selectFolder(null);
            }
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchForSearch() {
        try {
            startLoading();

            const data: IFolder[] = await FolderApi.fetchAll();
            foldersForSearch.value = data;
            foldersSource.value = JSON.parse(JSON.stringify(data));
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchChildrenById(id: number) {
        try {
            startLoading();
            childrenFolders.value = await FolderApi.fetchChildrenById(id);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function create(data: ICreateFolder): Promise<null | IFolder> {
        try {
            startLoading();

            const folder: IFolder = await FolderApi.create(data);
            selectFolder(folder);
            clearChildrenFolders();

            return folder;
        } catch (e: any) {
            showError(e?.message || e);
            return null;
        } finally {
            finishLoading();
        }
    }
    async function rename(data: IRenameFolder): Promise<void> {
        try {
            startLoading();

            await FolderApi.rename(data);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        folders,
        foldersForSearch,
        selectedFolder,
        childrenFolders,
        hasChildren,
        fetchAll,
        fetchForSearch,
        fetchChildrenById,
        create,
        rename,
        nodeClick,
        getNodeByID,
        search,
        selectFolder,
        resetSelectedFolder,
        clearChildrenFolders,
    };
});
