export enum EBillingInterval {
    MONTHLY = "Monthly",
    YEARLY = "Yearly",
}
export enum EPlan {
    PERSONAL = "PERSONAL",
    PERSONAL_PRO = "PERSONALPRO", // без нижнего подчкеркивания - не опечатка
    TEAM_TRIAL = "TEAM_TRIAL",
    TEAM = "TEAM",
    ENTERPRISE = "ENTERPRISE",
}
