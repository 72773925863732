import { useConfirmation, useNotification } from "@/shared/lib/composables";

import { CommentApi, useCommentStore } from "@/entities/Comment";
import { EOverlayType, useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";

import { useInstallationOverlays } from "@/features/Overlay/installation";

export function useRemoveComment() {
    const { showDeleteConfirm } = useConfirmation();
    const { showError } = useNotification();
    const { installationOverlays } = useInstallationOverlays();

    const commentStore = useCommentStore();
    const bpmnOverlaysStore = useBpmnModelerOverlayStore();

    async function removeComment(diagramId: string, elementId: null | string, commentId: string) {
        showDeleteConfirm({
            message: "Удалить выбранный комментарий?",
            acceptCb: async () => {
                try {
                    await CommentApi.removeById(diagramId, elementId, commentId);
                    commentStore.removeById(commentId);

                    if (elementId) {
                        const countData = commentStore.getCountComments(elementId);

                        commentStore.replaceCommentIndicator({ elementId, ...countData });

                        if (countData.count > 0) {
                            bpmnOverlaysStore.chnageDataCommentByElement({ elementId, ...countData });
                        } else {
                            await bpmnOverlaysStore.deleteOverlaysBy({ elementId, type: EOverlayType.COMMENT });
                            installationOverlays();
                        }
                    }
                } catch (e) {
                    showError(e);
                }
            },
        });
    }

    return {
        removeComment,
    };
}
