<template>
    <div class="flex flex-column gap-3 align-items-start">
        <div class="w-full flex flex-column gap-2 align-items-center">
            <h1>Оцените все функции системы</h1>
            <span class="text-green-500 px-2 py-1 bg-green-50 border-1 border-green-300 border-round">14 дней бесплатно</span>
        </div>
        <div class="w-full flex flex-column gap-3">
            <FormColumn title="Название команды" :error="validate.name.$errors[0]?.$message">
                <InputText v-model="state.name" placeholder="Введите название команды" />
            </FormColumn>
            <FormColumn>
                <AddEmail v-model="state.users" />
            </FormColumn>
        </div>
        <Button class="w-full" label="Попробовать бесплатно 14 дней" size="large" :loading="isLoading" @click="createTeam" />
    </div>
</template>

<script setup lang="ts">
import { onMounted, inject, Ref } from "vue";
import { storeToRefs } from "pinia";

import { FormColumn } from "@/shared/ui/form";
import { AddEmail } from "@/shared/ui/add-email";

import { useSessionStore } from "@/entities/Session";

import { useCreateTeam } from "../model";

const { state, validate, isLoading, resetForm, create } = useCreateTeam();

const sessionStore = useSessionStore();
const { userData } = storeToRefs(sessionStore);

const dialogRef = inject<Ref>("dialogRef");

async function createTeam() {
    await create(() => {
        if (dialogRef) dialogRef.value.close();
    });
}

onMounted(() => {
    resetForm();
    state.value.name = `Команда ${userData.value.username}`;
});
</script>

<style scoped></style>
