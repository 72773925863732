<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockColumnsRef">
            <h2 v-html="data?.item?.headline"></h2>
            <WrapperBlockRow><Tarif v-for="tarif of data?.item?.rows" :tarif="tarif"></Tarif></WrapperBlockRow>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn, WrapperBlockRow, Tarif } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockColumnsRef = ref();

watch(blockColumnsRef, () => {
    if (props.article) {
        emit("getHtml", blockColumnsRef.value);
    }
});
</script>

<style scoped></style>
