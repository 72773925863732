<template>
    <Button
        :icon="PrimeIcons.BARS"
        text
        class="h-2rem button_clean-outline text-500"
        label="Оверлеи"
        severity="contrast"
        :pt="{ label: 'font-normal' }"
        @click="e => overlayPanel?.toggle(e)"
    />

    <OverlayPanel ref="overlayPanel">
        <div v-if="!isTeamPlan" class="flex flex-column gap-2 mb-5">
            <Message class="m-0" severity="warn" :closable="false"> Доступно на тарифе TEAM </Message>
            <UpgradePlan />
        </div>
        <div :class="{ 'opacity-30': !isTeamPlan }">
            <div class="overlay-list">
                <div class="flex flex-column gap-2" v-if="overlaySettings">
                    <div class="mb-2 flex gap-2 align-items-center">
                        <span :class="PrimeIcons.BARS"></span>
                        <b class="text-lg">Системные</b>
                    </div>
                    <template v-for="item of visibilityOverlays" :key="item.name">
                        <Divider v-if="item.divider" class="m-1" />
                        <div v-else-if="item.key" class="flex align-items-center gap-3">
                            <div class="flex align-items-center gap-2">
                                <Checkbox
                                    v-model="overlaySettings[item.key]"
                                    :inputId="item.key"
                                    :name="item.key"
                                    :binary="true"
                                    :disabled="!isTeamPlan"
                                    v-tooltip.left="'Изменить видимость оверлея'"
                                />
                                <span class="text-lg" :class="item.icon ? item.icon : PrimeIcons.MINUS"></span>
                                <label :for="item.key" class="white-space-normal"> {{ getNameSetting(item.key) }} </label>
                            </div>
                            <div class="ml-auto flex gap-2 align-items-center">
                                <Checkbox
                                    v-if="item.collapse"
                                    v-model="collapseOverlays[item.key]"
                                    :binary="true"
                                    :disabled="!isTeamPlan"
                                    v-tooltip.left="'Изменить состояние свернутости оверлея'"
                                />
                                <Shortcut>Alt+{{ item.hotKey }}</Shortcut>
                            </div>
                        </div>
                    </template>
                </div>
                <div class="flex flex-column gap-2" v-if="customAssetsByDiagram">
                    <div class="mb-2 flex gap-2 align-items-center">
                        <span class="text-lg" :class="PrimeIcons.BOX"></span>
                        <b class="text-lg">Кастомные</b>
                        <Checkbox
                            class="ml-auto"
                            v-model="collapseOverlays.custom"
                            :binary="true"
                            v-tooltip.left="'Изменить состояние свернутости оверлея'"
                            :disabled="!isTeamPlan"
                        />
                    </div>
                    <div class="flex align-items-center" v-for="item of customAssetsByDiagram" :key="item.id">
                        <Checkbox
                            v-model="item.overlaySettings"
                            :inputId="item.id"
                            :name="item.code"
                            :binary="true"
                            :disabled="!isTeamPlan"
                        />
                        <label :for="item.id" class="ml-2 white-space-normal"> {{ item.name }} </label>
                    </div>
                    <div class="flex flex-column gap-2" v-if="customAssetsByDiagram.length === 0">
                        <CustomInfoMessage class="text-sm text-center">
                            <div class="flex flex-column gap-2">
                                Описывайте аспекты процессов, которые интересны именно вам
                                <a
                                    href="https://docs.stormbpmn.com/features/5_elements-architecture.html#%D0%BA%D0%B0%D1%81%D1%82%D0%BE%D0%BC%D0%BD%D1%8B%D0%B5-%D1%8D%D0%BB%D0%B5%D0%BC%D0%B5%D0%BD%D1%82%D1%8B-%D0%B0%D1%80%D1%85%D0%B8%D1%82%D0%B5%D0%BA%D1%82%D1%83%D1%80%D1%8B"
                                    class="text-center text-blue-500 p-2 hover:bg-blue-100 border-round"
                                    target="_blank"
                                >
                                    Узнать подробнее
                                </a>
                            </div>
                        </CustomInfoMessage>
                    </div>
                </div>
            </div>
            <Divider />
            <div class="flex flex-column gap-2">
                <div class="flex align-items-center gap-2">
                    Изменить видимость всех оверлеев
                    <Shortcut class="ml-auto">alt+a</Shortcut>
                </div>
                <div class="flex align-items-center gap-2">
                    Изменить состояние свернутости всех оверлеев
                    <Shortcut class="ml-auto">alt+shift+a</Shortcut>
                </div>
            </div>
        </div>
    </OverlayPanel>
</template>

<script setup lang="ts">
import { onUnmounted, ref, watch } from "vue";
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";
import type OverlayPanel from "primevue/overlaypanel";

import { Shortcut } from "@/shared/ui/shortcut";
import { IKeyHandler, KeyboardManager } from "@/shared/lib/browser";

import { useAssetStore } from "@/entities/Asset";
import { useDiagramStore } from "@/entities/Process";
import { useSessionStore } from "@/entities/Session";

import { UpgradePlan } from "@/features/Payment/upgrade-plan";
import { useToggleOverlays } from "../model";
import { CustomInfoMessage } from "@/shared/ui/message";

const keyboardManager = new KeyboardManager();

const assetStore = useAssetStore();
const diagramStore = useDiagramStore();
const sessionStore = useSessionStore();

const { customAssetsByDiagram } = storeToRefs(assetStore);
const { overlaySettings } = storeToRefs(diagramStore);
const { isTeamPlan } = storeToRefs(sessionStore);

const { getNameSetting, visibilityOverlays, collapseOverlays } = useToggleOverlays();

const overlayPanel = ref<OverlayPanel>();

watch(
    isTeamPlan,
    value => {
        if (!value) return;

        keyboardManager.mount();

        const handlers: IKeyHandler[] = [];
        for (const item of visibilityOverlays.value) {
            if (item.key) {
                handlers.push({
                    code: `Digit${item.hotKey}`,
                    func: () => {
                        overlaySettings.value[item.key as string] = !overlaySettings.value[item.key as string];
                        return true;
                    },
                    altKey: true,
                });

                if (item.collapse) {
                    handlers.push({
                        code: `Digit${item.hotKey}`,
                        func: () => {
                            collapseOverlays[item.key as string] = !collapseOverlays[item.key as string];
                            return true;
                        },
                        altKey: true,
                        shiftKey: true,
                    });
                }
            }
        }

        handlers.push({
            code: "KeyA",
            func: () => {
                const keys: string[] = Object.keys(overlaySettings.value);
                const status: boolean = overlaySettings.value[keys[0]];

                for (const key of keys) {
                    overlaySettings.value[key] = !status;
                }

                for (const item of customAssetsByDiagram.value) {
                    item.overlaySettings = !status;
                }
            },
            altKey: true,
        });
        handlers.push({
            code: "KeyA",
            func: () => {
                const keys: string[] = Object.keys(collapseOverlays);
                const status: boolean = collapseOverlays.systems;

                for (const key of keys) {
                    collapseOverlays[key] = !status;
                }
            },
            altKey: true,
            shiftKey: true,
        });

        keyboardManager.addKeyHandlers(handlers);
    },
    {
        immediate: true,
    },
);
onUnmounted(() => {
    keyboardManager.unmount();
});
</script>

<style scoped>
.overlay-list {
    max-width: 700px;
    display: grid;
    grid-template-columns: 3fr 2fr;
    gap: 1rem;
}
</style>
