import { EProcessStatus, EProcessStatusName, EProcessType } from "../model";
import { ESeverity } from "@/shared/lib/types";

export type TProcessStatus = keyof typeof EProcessStatus;
export type TProcessType = keyof typeof EProcessType;

export function getStatusBy(status: TProcessStatus) {
    return EProcessStatusName[status];
}
export function getSeverityStatusBy(status: TProcessStatus) {
    const value = EProcessStatus[status];

    if (value === EProcessStatus.NEW) return ESeverity.SECONDARY;
    if (value === EProcessStatus.IN_PROGRESS) return ESeverity.PRIMARY;
    if (value === EProcessStatus.APPROVAL) return ESeverity.INFO;
    if (value === EProcessStatus.DONE) return ESeverity.SUCCESS;

    return ESeverity.SECONDARY;
}

export function getProcessTypeBy(type: TProcessType) {
    return EProcessType[type];
}
export function getSeverityProcessTypeBy(type: TProcessType) {
    const value = getProcessTypeBy(type);

    if (value === EProcessType.ASIS) return ESeverity.INFO;
    if (value === EProcessType.TOBE) return ESeverity.WARNING;

    return ESeverity.PRIMARY;
}

export * from "./extentions";
