<template>
    <router-link :to="{ name: ERoutesName.BLOG_SUBTITLE, params: { filter: 'slug', slug: tag?.slug } }">
        <Button
            :label="tag?.title"
            @click="getBlogByTag"
            :text="tag?.slug != route.params.slug && size == ESizeTag.BIG"
            severity="secondary"
            :class="{ 'p-1': size == ESizeTag.SMALL }"
        ></Button>
    </router-link>
</template>

<script setup lang="ts">
import { useInterfaceStore } from "@/entities/Interface";
import { ERoutesName } from "@/app/providers";
import { useRoute } from "vue-router";
import { ESizeTag } from "@/shared/lib/types";

const props = defineProps({
    tag: { type: Object },
    size: { type: String },
});
const route = useRoute();
const interfaceStore = useInterfaceStore();

function getBlogByTag() {
    interfaceStore.getBlogByTag(String(props.tag?.slug));
}
</script>

<style scoped></style>
