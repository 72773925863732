<template>
    <Dialog v-model:visible="visible" header="Изменение статуса" :draggable="false" modal position="top">
        <div class="pt-3 flex gap-2">
            <Button
                v-for="btn of processStatuses"
                :label="btn.label"
                :outlined="selectedStatus != btn.value"
                @click="selectedStatus = btn.value"
                :severity="getSeverityStatusBy(btn.value)"
            />
        </div>
        <template v-if="isApprovalSelected">
            <Divider />
            <CreateApproval v-if="diagramId" :diagram-id="diagramId" />
            <Divider v-if="!!myApprovalByDiagram" />
            <SetDecision :diagram-id="diagramId" />
            <Divider />
            <ApprovalList :diagram-id="diagramId" @delete-approval="deleteApproval" />
        </template>
        <template #footer>
            <div class="flex gap-2">
                <Button label="Отмена" outlined @click="hide" />
                <Button label="Установить статус" :disabled="!selectedStatus" :loading="isLoading" @click="changeStatusByDiagram" />
            </div>
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

import Dialog from "primevue/dialog";

import { useDialog } from "@/shared/lib/composables";
import { EProcessStatus, useProcessStore } from "@/entities/Process/model";
import { ApprovalList, ApprovalModel } from "@/entities/Approval";

import { CreateApproval } from "@/features/Approval/create";
import { SetDecision } from "@/features/Approval/set-decision";
import { useDeleteApproval } from "@/features/Approval/delete";

import { useChangeStatus } from "..";
import { getSeverityStatusBy } from "@/entities/Process";

const { visible, show, hide } = useDialog();
const { deleteApproval } = useDeleteApproval();

const { isLoading, changeStatus } = useChangeStatus();

const processStore = useProcessStore();
const approvalStore = ApprovalModel.useApprovalStore();

const { processStatuses } = storeToRefs(processStore);
const { myApprovalByDiagram } = storeToRefs(approvalStore);

const diagramId = ref<string>();
const selectedStatus = ref<EProcessStatus>();

const isApprovalSelected = computed(() => {
    return selectedStatus.value === EProcessStatus.APPROVAL;
});

async function changeStatusByDiagram() {
    if (diagramId.value && selectedStatus.value) {
        await changeStatus(diagramId.value, selectedStatus.value);
        hide();
    }
}

function beforeShow(id: string) {
    selectedStatus.value = undefined;
    diagramId.value = id;

    show();
}

defineExpose({
    show: (diagramId: string) => beforeShow(diagramId),
});
</script>

<style scoped>
:deep(.p-highlight) {
    background-color: #6b7280;
    border-color: #6b7280;
}
</style>
