<template>
    <WrapperBlockColumn
        :color="data?.item?.background_color || 'rgb(38, 43, 63)'"
        class="max-w-full py-7 flex flex-column gap-6 align-items-center text-center"
    >
        <div class="wrapper__column flex flex-column gap-3 w-full" v-show="visibleBlock" ref="blackBackgroupHeroRef">
            <slot />

            <div class="m-0 lg:m-0 flex flex-column gap-3" v-if="data">
                <div v-html="data?.item?.headline"></div>
                <div v-html="data?.item?.content"></div>
            </div>
            <div v-if="data?.item?.image">
                <img
                    :src="IMG_DIRECTUS + data?.item?.image?.id + '?format=webp'"
                    loading="lazy"
                    :style="'max-height:' + data?.item?.image_max_height + 'px'"
                    :height="data?.item?.image?.height"
                    :width="data?.item?.image?.width"
                    :alt="data?.item?.image?.description"
                    :title="data?.item?.image?.description"
                    @load="visible = true"
                />
            </div>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { WrapperBlockColumn } from "@/shared/ui/marketing";
import { IMG_DIRECTUS } from "@/shared/config";
import type { IBlock } from "@/entities/Interface";

const props = withDefaults(defineProps<{ data?: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blackBackgroupHeroRef = ref();

const visible = ref<boolean>(false);
const visibleBlock = computed(() => {
    if (props.data) {
        if (!!props.data.item.image) {
            return visible.value;
        } else {
            return true;
        }
    }
});

watch(blackBackgroupHeroRef, () => {
    if (props.article) {
        emit("getHtml", blackBackgroupHeroRef.value);
    }
});
</script>

<style scoped>
.wrapper__column {
    max-width: 1240px;
}

.background {
    background: rgb(38, 43, 63);
}
</style>
