<template>
    <div
        class="w-full flex flex-column-reverse xl:flex-row border-2 border-primary border-round-lg transition-all transition-duration-300 shadow-0 hover:shadow-5 post"
    >
        <div class="flex flex-column justify-content-between p-3 w-full">
            <div class="text-left flex flex-column gap-3 text-color">
                <router-link :to="{ name: ERoutesName.ARTICLE, params: { slug: featuredPost?.tag?.slug, id: featuredPost?.slug } }">
                    <div class="text-2xl font-semibold">{{ featuredPost?.title }}</div>
                </router-link>
                <router-link :to="{ name: ERoutesName.ARTICLE, params: { slug: featuredPost?.tag?.slug, id: featuredPost?.slug } }">
                    <div style="line-height: 1.44;" class="text-base">{{ featuredPost?.summary }}</div>
                </router-link>
            </div>
            <div class="flex flex-column gap-2">
                <div class="text-left flex gap-2">
                    <Tag :tag="featuredPost?.tag" :size="ESizeTag.SMALL" />
                </div>
                <Divider   :pt="{
                            root: () => ({
                                class: ['m-0'],
                            }),
                            
                        }" align="left" type="solid"> </Divider>
                <div class="flex justify-content-between align-items-center text-lg">
                    <div class="flex flex  justify-content-center align-items-center gap-2">
                        <ImgUserSmall>
                            <img 
                            :src="IMG_DIRECTUS + featuredPost?.author?.image + '?format=webp&height=200&width=200&quality=100'" 
                            width="42"
                            loading="lazy"
                            height="42"
                            :alt="featuredPost?.author?.name"
                            :title="featuredPost?.author?.name"  />
                        </ImgUserSmall>
                        {{ featuredPost?.author?.name }}
                    </div>
                    <div>{{ formatDate(featuredPost?.date_published) }}</div>
                </div>
            </div>
        </div>
        <router-link
            class="w-full overflow-hidden max-w p-0"
            :to="{ name: ERoutesName.ARTICLE, params: { slug: featuredPost?.tag?.slug, id: featuredPost?.slug } }"
        >
            <img
                style="object-fit: fill"
                :alt="featuredPost?.title"
                width="1200"
                height="630"
                loading="eager"
                fetchpriority="high"
                :title="featuredPost?.title"
                :src="IMG_DIRECTUS + featuredPost?.image?.id + '?format=webp&width=600&height=315&quality=100'"
                class="image transition-all transition-duration-300"
            />
        </router-link>
    </div>
</template>

<script setup lang="ts">
import { useInterfaceStore } from "@/entities/Interface";
import { storeToRefs } from "pinia";
import { IMG_DIRECTUS } from "@/shared/config";
import { ERoutesName } from "@/app/providers";
import { formatDate } from "@/shared/lib/utils/date";
import { ImgUserSmall } from "../img";
import { Tag } from ".";
import { ESizeTag } from "@/shared/lib/types";

const interfaceStore = useInterfaceStore();
const { featuredPost } = storeToRefs(interfaceStore);
</script>

<style scoped lang="scss">
.post {
    overflow: hidden;
    @media screen and (max-width: 1024px) {
        min-width: 100%;
        max-width: 100%;
    }
}
.post:hover *.image {
    transform: scale(1.1);
}
</style>
