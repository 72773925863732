import { ref, type Ref } from "vue";

interface ILoading {
    isLoading: Ref<boolean>;
    startLoading: () => void;
    finishLoading: () => void;
}

export const useLoading = (): ILoading => {
    const isLoading = ref<boolean>(false);

    const timer = ref();

    function startLoading(): void {
        isLoading.value = true;
    }
    function finishLoading(): void {
        if (timer.value) {
            clearTimeout(timer.value);
        }

        timer.value = setTimeout(() => {
            isLoading.value = false;
        }, 200);
    }

    return {
        isLoading,
        startLoading,
        finishLoading,
    };
};
