import ReplaceElementBehavior, {
    ElementRegistry,
    Injector,
    Selection,
} from "bpmn-js/lib/features/modeling/behavior/ReplaceElementBehaviour";
import Modeling from "bpmn-js/lib/features/modeling/Modeling";
import BpmnReplace from "bpmn-js/lib/features/replace/BpmnReplace";
import BpmnRules from "bpmn-js/lib/features/rules/BpmnRules";

class StormReplaceElementBehavior extends ReplaceElementBehavior {
    constructor(
        bpmnReplace: BpmnReplace,
        bpmnRules: BpmnRules,
        elementRegistry: ElementRegistry,
        injector: Injector,
        modeling: Modeling,
        selection: Selection,
    ) {
        super(bpmnReplace, bpmnRules, elementRegistry, injector, modeling, selection);

        this.postExecuted("shape.replace", function (event) {
            const oldShape = event.context.oldShape;
            const newShape = event.context.newShape;

            modeling.resizeLane(
                newShape,
                {
                    x: oldShape.x,
                    y: oldShape.y,
                    width: oldShape.width,
                    height: oldShape.height,
                },
                true,
            );
        });
    }
}

StormReplaceElementBehavior.$inject = ["bpmnReplace", "bpmnRules", "elementRegistry", "injector", "modeling", "selection"];

export default StormReplaceElementBehavior;
