<template>
    <Dialog v-model:visible="visible" modal :draggable="false" :style="{ width: '35vw' }" header="Скачать процесс">
        <TabView>
            <TabPanel header="Изображение">
                <FormColumn title="">
                    <div class="flex gap-2">
                        <Button @click="downloadSVG" outlined label="SVG" />
                        <Button @click="downloadXML" outlined severity="secondary" label="BPMN" />
                        <Button @click="downloadPNG" outlined severity="success" label="PNG" />
                    </div>
                </FormColumn>
            </TabPanel>
            <TabPanel header="Регламенты">
                <FormColumn title="">
                    <div class="">
                        <Button @click="uploadTemplate" outlined label="Загрузить свой шаблон" />
                    </div>
                    <Dropdown
                        v-model="templateForDownload"
                        :options="myTemplates"
                        optionLabel="name"
                        placeholder="Выберите регламент для скачивания"
                    />
                    <div class="">
                        <Button @click="downloadTemplate" outlined label="Скачать регламент" :disabled="!templateForDownload" />
                    </div>
                </FormColumn>
            </TabPanel>
        </TabView>
    </Dialog>
    <UploadTemplate ref="uploadTemplateRef" />
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { FormColumn } from "@/shared/ui/form";
import { useDialog } from "@/shared/lib/composables";
import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import { useDiagramStore } from "@/entities/Process";
import { storeToRefs } from "pinia";
import { useTeamStore } from "@/entities/Team";
import { type IMyTemplate } from "@/entities/Team";
import { UploadTemplate } from "@/widgets/template";
import { BASE_URL } from "@/shared/config";
import { downloadFile } from "@/shared/lib/browser";

const bpmnModelerStore = useBpmnModelerStore();
const diagramStore = useDiagramStore();
const teamStore = useTeamStore();
const { myTemplates } = storeToRefs(teamStore);
const { diagram } = storeToRefs(diagramStore);
const { visible, show, hide } = useDialog();
const templateForDownload = ref<IMyTemplate>();
const uploadTemplateRef = ref();

function downloadTemplate() {
    if (templateForDownload.value && templateForDownload.value.fileUrl) {
        const downloadUrl = BASE_URL + templateForDownload.value.fileUrl;
        downloadFile(downloadUrl, templateForDownload.value?.name);
    }
}
function uploadTemplate() {
    uploadTemplateRef.value.show();
}

async function downloadSVG() {
    const svg = await bpmnModelerStore.saveSVG();
    const fileType = "application/svg";
    const name = (diagram.value?.name || "Draft") + ".svg";
    download(svg, fileType, name);
}

async function downloadXML() {
    const xml = await bpmnModelerStore.saveXML();
    const fileType = "application/bpmn20-xml";
    const name = (diagram.value?.name || "Draft") + ".bpmn";
    download(xml, fileType, name);
}
async function downloadPNG() {
    const svg = await bpmnModelerStore.saveSVG();
    const fileName = (diagram.value?.name || "Draft") + ".png";
    const domUrl = window.URL || window.webkitURL || window;
    if (!domUrl) {
        throw new Error("(browser doesnt support this)");
    }

    const match_heigh = svg.match(/height=\"(\d+)/m);
    const height = match_heigh && match_heigh[1] ? parseInt(match_heigh[1], 10) : 200;
    const match_width = svg.match(/width=\"(\d+)/m);
    const width = match_width && match_width[1] ? parseInt(match_width[1], 10) : 200;
    const fill = "#ffffff";

    if (!svg.match(/xmlns=\"/im)) {
        svg.replace("<svg ", '<svg xmlns="http://www.w3.org/2000/svg" ');
    }

    let canvas = document.createElement("canvas");
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    const svgBlob = new Blob([svg], {
        type: "image/svg+xml;charset=utf-8",
    });

    const url = domUrl.createObjectURL(svgBlob);
    const img = new Image();

    img.onload = function () {
        if (ctx) {
            ctx.drawImage(img, 0, 0);
        }

        if (fill) {
            const styled = document.createElement("canvas");
            styled.width = canvas.width;
            styled.height = canvas.height;
            const styledCtx = styled.getContext("2d");
            if (styledCtx) {
                styledCtx.save();
                styledCtx.fillStyle = fill;
                styledCtx.fillRect(0, 0, canvas.width, canvas.height);
                styledCtx.strokeRect(0, 0, canvas.width, canvas.height);
                styledCtx.restore();
                styledCtx.drawImage(canvas, 0, 0);
            }
            canvas = styled;
        }

        domUrl.revokeObjectURL(url);

        const downloadLink = document.createElement("a");
        downloadLink.download = fileName;
        downloadLink.innerHTML = "Get BPMN PNG";
        downloadLink.href = canvas.toDataURL();
        downloadLink.onclick = function (event: any) {
            document.body.removeChild(event.target);
        };
        downloadLink.style.visibility = "hidden";
        document.body.appendChild(downloadLink);
        downloadLink.click();
    };
    img.src = url;
}
async function download(text: string, fileType: string, name: string) {
    const blob = new Blob([text], {
        type: fileType,
    });
    const element = document.createElement("a");
    element.download = name;
    element.href = window.URL.createObjectURL(blob);
    element.dataset.downloadurl = [fileType, element.download, element.href].join(":");
    element.click();
    element.remove();
    hide();
}

defineExpose({
    show,
});

onMounted(() => {
    teamStore.getMyTemplates();
});
</script>

<style scoped></style>
