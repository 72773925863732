import { useDialog } from "primevue/usedialog";
import { HelpModal } from "../ui";
import { MODAL_PARAMS } from "@/shared/constants/modal";

export function useDiagramHelp() {
    const dialog = useDialog();

    function openHelp(): void {
        dialog.open(HelpModal, {
            props: {
                header: "Справка по элементам управления",
                ...MODAL_PARAMS,
                style: { width: "100%", maxWidth: "1200px" },
            },
        });
    }

    return {
        openHelp,
    };
}
