import { computed, ref } from "vue";
import { defineStore } from "pinia";

import BpmnModdle from "bpmn-moddle";

interface IReadMoreResult {
    rootElement: any;
    countOfElements: number;
}

export const useModdleStore = defineStore("moddle-store", () => {
    const moddle = ref(new BpmnModdle());

    async function readModer(xml: string): Promise<IReadMoreResult> {
        const data: any = await moddle.value.fromXML(xml);

        const rootElement = data.rootElement;
        const countOfElements = rootElement ? rootElement.diagrams[0].plane.planeElement.length : 0;

        return {
            rootElement,
            countOfElements,
        };
    }

    return {
        readModer,
    };
});
