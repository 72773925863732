import { useI18n } from "vue-i18n";

import { useConfirmation, useNotification } from "@/shared/lib/composables";
import { extractIds } from "@/shared/lib/utils/array";

import { ProcessApi } from "@/entities/Process/api";
import { type IProcess, useProcessStore } from "@/entities/Process/model";

export function useChangeVisibility() {
    const { showError } = useNotification();
    const { showDeleteConfirm } = useConfirmation();
    const { t } = useI18n();
    const processStore = useProcessStore();

    function changeVisibility(processes: IProcess[]) {
        const text = t("are-you-really-whant-change-visibility");
        showDeleteConfirm({
            message: text,
            acceptCb: () => change(extractIds(processes)),
            acceptLabel: t("confrim"),
        });
    }

    async function change(payload: string[]) {
        try {
            await ProcessApi.changeVisibility(payload);
            processStore.localeChangePublicByIds(payload);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    return {
        changeVisibility,
        change,
    };
}
