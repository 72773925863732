import {
    type TTypeStatusParticipant,
    type TTypeRightsParticipant,
    type TTypeTemplate,
    ETypeStatusParticipant,
    ETypeRightsParticipant,
    ETypeTemplate,
} from "../model";

export function getStatusParticipant(type: TTypeStatusParticipant) {
    return ETypeStatusParticipant[type];
}

export function getRightsParticipant(type: TTypeRightsParticipant) {
    return ETypeRightsParticipant[type];
}

export function getTypeTemplate(type: TTypeTemplate) {
    return ETypeTemplate[type];
}
