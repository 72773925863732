import RuleProvider from "diagram-js/lib/features/rules/RuleProvider";

class ResizeEvent extends RuleProvider {
    eventResizingEnabled: boolean = false;

    constructor(eventBus: any, eventResizingEnabled: boolean = false) {
        super(eventBus);
        this.eventResizingEnabled = eventResizingEnabled;

        this.addRule("shape.resize", 1500, data => {
            if (this.eventResizingEnabled && data.shape.businessObject && data.shape.businessObject.$instanceOf("bpmn:Event")) {
                if (data.newBounds) {
                    data.newBounds.width = Math.max(36, data.newBounds.width);
                    data.newBounds.height = Math.max(36, data.newBounds.height);
                }
                return true;
            }
        });
    }
}

ResizeEvent.$inject = ["eventBus", "config.eventResizingEnabled"];

export default ResizeEvent;
