import { TOKEN_DADATA,DADATA_CAPCHA_URL } from "@/shared/config";

import axios, { type AxiosError, type AxiosResponse } from "axios";
import { setupCache } from "axios-cache-interceptor";

const dadata = axios.create({
    baseURL: DADATA_CAPCHA_URL,
});

dadata.interceptors.request.use(
    config => {
        config.headers.Authorization = "Token " + TOKEN_DADATA;
        return config;
    },
    error => {
        return Promise.reject(error);
    },
);

const httpDadata = setupCache(dadata, {
    debug(msg) {
        console.log(msg);
    },
    ttl: 1000 * 30,
});

dadata.interceptors.response.use(
    (response: AxiosResponse) => {
        return response;
    },
    (error: AxiosError) => {
        const { response } = error;

        if (response?.data) {
            const oMessage = typeof response.data === "string" ? { message: response.data } : { ...response.data };
            throw { status: response.status, ...oMessage };
        }

        return Promise.reject(error);
    },
);

export { httpDadata };
