<template>
    <Dropdown
        v-model="tag"
        :options="tagByFilter"
        :loading="isLoading"
        showClear
        :filter="tagByFilter.length > 5"
        filterPlaceholder="Поиск"
        option-label="text"
        option-value="text"
        placeholder="Не выбрано"
        emptyFilterMessage="Поиск не дал результатов"
    />
</template>

<script setup lang="ts">
import { onMounted } from "vue";
import { storeToRefs } from "pinia";

import Dropdown from "primevue/dropdown";

import { useTagStore } from "..";

const tagStore = useTagStore();
const { tagByFilter, isLoading } = storeToRefs(tagStore);

const tag = defineModel();

onMounted(() => {
    tagStore.fetchForFilter();
});
</script>

<style scoped></style>
