export function generateInvoiceNumber(userId: string | null, quantityParticipants: number | null, quantityMonths: number | null) {
    const date = new Date();
    return (
        (userId || "") +
        date.getFullYear() +
        "." +
        date.getMonth() +
        1 +
        "." +
        date.getDate() +
        "." +
        (quantityParticipants || 0) +
        "." +
        (quantityMonths || 0)
    );
}
