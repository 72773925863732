import type { AxiosResponse } from "axios";
import type { IFolder, ICreateFolder, IRenameFolder, IMoveFolder, IFolderQuery } from "../model";
import { httpClient } from "@/shared/api";

export class FolderApi {
    static async fetchAll(query?: IFolderQuery): Promise<IFolder[]> {
        const response: AxiosResponse = await httpClient.get("/folder/recursive", { params: query });
        return response.data;
    }
    static async fetchChildrenById(id: number): Promise<IFolder[]> {
        const response: AxiosResponse = await httpClient.get("/folder/only-child/" + id);
        return response.data;
    }
    static async create(payload: ICreateFolder): Promise<IFolder> {
        const response: AxiosResponse = await httpClient.post("/folder", payload);
        return response.data;
    }
    static async rename(payload: IRenameFolder): Promise<void> {
        await httpClient.post(`/folder/${payload.id}/rename`, null, {
            params: {
                newName: payload.newName,
            },
        });
    }
    static async move(payload: IMoveFolder): Promise<void> {
        await httpClient.post("/folder/move", null, {
            params: payload,
        });
    }
    static async delete(folderId: number | null, team?: boolean): Promise<void> {
        await httpClient.delete(`/folder/${folderId}`, { params: team });
    }
}
