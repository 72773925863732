<template>
    <div class="flex flex-column align-items-start gap-3">
        <div class="w-full flex flex-column gap-1">
            <Email v-model="emails" placeholder="Введите электронные адреса коллег">
                <template #label
                    ><label class="mb-1">
                        {{ $t("approval-list") }}
                        <Tag
                            value="Бесплатно"
                            severity="info"
                            icon="pi pi-info-circle"
                            v-tooltip.bottom="
                                'Диаграмма будет переведена в статус На согласовании. В этом статусе запрещено изменение модели. Сменить статус можно только после того, как по всем согласованиям получено решение.'
                            " /></label
                ></template>
            </Email>
        </div>
        <Button
            :label="$t('send-approvals')"
            size="small"
            severity="success"
            outlined
            :disabled="!emails.length"
            :loading="isLoading"
            @click="onCreateApproval"
        />
    </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import Button from "primevue/button";
import Tag from "primevue/tag";

import { Email } from "@/shared/ui/email";

import { useApprovalStore } from "@/entities/Approval/model";

import { useCreateApproval } from "..";

const { isLoading, createApproval } = useCreateApproval();

const approvalStore = useApprovalStore();

const props = defineProps({
    diagramId: { type: String, required: true },
});

const emails = ref<string[]>([]);

async function onCreateApproval() {
    if (props.diagramId) {
        await createApproval({
            diagramId: props.diagramId,
            users: emails.value,
        });

        emails.value = [];

        await approvalStore.fetchMyByDiagramId(props.diagramId);
        await approvalStore.fetchAllByDiagramId(props.diagramId);
    }
}
</script>

<style scoped></style>
