<template>
    <Dialog v-model:visible="visible" :header="$t('create-new-folder')" :draggable="false" modal :style="{ width: '35vw' }" position="top">
        <div class="p-2">
            <InputText
                v-model.trim="state.name"
                class="w-full"
                @keypress.enter="createFolder"
                autofocus
                :placeholder="$t('enter-folder-name')"
            />
        </div>
        <template #footer>
            <Button label="Отмена" outlined @click="hide" />
            <Button label="Сохранить" :loading="isLoading" :disabled="!state.name" @click="createFolder" />
        </template>
    </Dialog>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { storeToRefs } from "pinia";

import Dialog from "primevue/dialog";
import Button from "primevue/button";
import InputText from "primevue/inputtext";

import type { IPromise } from "@/shared/lib/types";
import { useDialog } from "@/shared/lib/composables";

import { folderModel } from "@/entities/Folder";
import type { ICreateFolder, IFolder } from "@/entities/Folder/model";

interface IBeforeShowProps {
    forSearch?: boolean;
    promiseValue?: IPromise;
}

const folderStore = folderModel.useFolderStore();
const { isLoading } = storeToRefs(folderStore);

const { visible, show, hide } = useDialog();

const state = ref<ICreateFolder>({
    name: "",
    pid: null,
});
const promises = ref<IPromise>();

const isforSearch = ref<boolean>(false);

function beforeShow(pid?: number, params?: IBeforeShowProps) {
    state.value.name = "";
    state.value.pid = pid || null;
    isforSearch.value = params?.forSearch || false;

    promises.value = params?.promiseValue;

    show();
}

async function createFolder() {
    const folder: IFolder | null = await folderStore.create({ ...state.value });

    if (isforSearch.value) {
        await folderStore.fetchForSearch();
    } else {
        await folderStore.fetchAll();
    }
    hide();

    if (folder && promises.value) {
        promises.value.resolve(folder);
    }
}

defineExpose({
    show: (pid?: number, params?: IBeforeShowProps) => beforeShow(pid, params),
});
</script>

<style scoped></style>
