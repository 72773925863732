<template>
    <div class="w-full flex flex-column gap-1">
        <label>
            <slot name="label"></slot>
        </label>
        <Chips
            v-model="localEmails"
            separator=","
            class="w-full"
            inputClass="w-full"
            :allowDuplicate="false"
            :placeholder="placeholder"
            @add="addEmail"
            @remove="removeEmail"
            :pt="{
                container: () => ({
                    class: ['w-full gap-1'],
                }),
                token: () => ({
                    class: ['m-0'],
                }),
            }"
        />
        <i class="text-sm text-color-secondary" v-if="caption"><slot name="caption">Нажмите "Enter", чтобы добавить пользователя</slot></i>
    </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";

import Chips, { type ChipsAddEvent } from "primevue/chips";

import { useNotification } from "@/shared/lib/composables";
import { emailValidator } from "@/shared/lib/validators";

const { showWarning } = useNotification();

const emails = defineModel({ type: Array, default: () => [], required: true });
defineProps({
    placeholder: { type: String, required: true },
    caption: { type: Boolean, default: true },
});

const localEmails = ref<string[]>([]);

function addEmail(event: ChipsAddEvent) {
    const email = event.value.at(-1);

    if (email) {
        if (!emailValidator(email)) {
            localEmails.value.pop();
            return showWarning("Вы ввели не электронную почту");
        }
    }

    emails.value = localEmails.value;
}
function removeEmail() {
    emails.value = localEmails.value;
}

watch(emails, value => {
    if (value.length === 0) {
        localEmails.value = [];
    }
});
</script>

<style scoped></style>
