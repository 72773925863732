<template>
    <Button
        :icon="PrimeIcons.SEARCH_PLUS"
        text
        severity="contrast"
        class="navbar-button"
        v-tooltip.bottom="'Приблизить'"
        :disabled="disabledScaleIn"
        @click="scale(true)"
    />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { useScaleDiagram } from "../model";

const { disabledScaleIn, scale } = useScaleDiagram();
</script>

<style scoped></style>
