<template>
    <Menubar
        class="z-2 max-w-full fixed max-h-5rem h-5rem menu gap-2"
        :model="headerItems"
        breakpoint="1000px"
        :pt="{ root: 'w-full flex justify-content-between menu', end: 'end__visible' }"
    >
        <template #start>
            <div class="flex align-items-center justify-content-start gap-2">
                <RouterLink :to="{ name: isAuth ? ERoutesName.APP_MY_PROCESSES : ERoutesName.MARKETING }">
                    <img
                        title="На главную"
                        alt="Логотип stormbpmn.com"
                        loading="lazy"
                        width="40"
                        height="40"
                        src="@/shared/assets/images/svg/storm_logo.svg"
                        class="h-2rem block xl:block lg:hidden"
                    />
                    <img
                        title="На главную"
                        alt="Логотип stormbpmn.com"
                        loading="lazy"
                        width="40"
                        height="40"
                        src="@/shared/assets/images/svg/favicon.svg"
                        class="h-2rem hidden xl:hidden lg:block"
                    />
                </RouterLink>
            </div>
        </template>
        <template #item="{ item, root }">
            <div class="text__size">
                <div v-if="item.has_children">
                    <div class="flex align-items-center gap-2 px-1 xl:px-3 cursor-pointer">
                        <span class="material-icons" v-if="item.icon">{{ item.icon }}</span>
                        <a v-if="item.type == 'url'" :href="item.url" :target="item.open_in_new_tab ? '_blank' : '_self'">
                            {{ item.label }}
                            <i
                                v-if="item.has_children"
                                :class="['pi pi-angle-down', { 'pi-angle-down': root, 'pi-angle-right': !root }]"
                            ></i>
                        </a>
                        <div class="flex align-items-center gap-2 py-2" v-else>
                            <router-link v-if="item.page" :to="item.page.permalink">
                                <span>{{ item.label }}</span>
                                <Divider />
                            </router-link>
                            <span v-else>{{ item.label }} </span>
                            <i v-if="item.has_children" :class="['pi pi-angle-down', { 'pi-angle-down': root, 'pi-angle-right': !root }]">
                            </i>
                        </div>
                    </div>
                </div>

                <div class="flex align-items-center" :class="{ 'px-4': !root }" v-else>
                    <a v-if="item.type == 'url'" :href="item.url" :target="item.open_in_new_tab ? '_blank' : '_self'">
                        <div class="flex align-items-center gap-2 py-2 px-1 xl:px-3">
                            <span class="material-icons text-xl" v-if="item?.icon">{{ item.icon }}</span>
                            <span class="border-round-lg content text-center">{{ item.label }}</span>
                        </div>
                    </a>
                    <router-link v-else :to="item.page?.permalink || ''">
                        <div class="flex align-items-center gap-2 py-2 md:px-3 px-1">
                            <span class="material-icons text-xl" v-if="item?.icon">{{ item.icon }}</span>
                            <span class="border-round-lg">{{ item.label }}</span>
                        </div>
                    </router-link>
                </div>
            </div>
            <div class="item__visible" v-if="item.last">
                <div class="flex flex-column align-items-center gap-2" v-if="!userData?.accessToken">
                    <router-link :to="{ name: ERoutesName.LOGIN }" class="w-full">
                        <Button label="Войти" text class="w-full" />
                    </router-link>
                    <router-link :to="{ name: ERoutesName.SIGN_UP }" class="w-full">
                        <Button label="Зарегистрироваться бесплатно" class="w-full" />
                    </router-link>
                </div>
                <div class="flex align-items-center gap-2" v-else>
                    <div class="min-w-max flex gap-3 align-items-center select-none">
                        <div
                            class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer"
                            :class="{ 'surface-hover': menuProfileRef?.overlayVisible }"
                            aria-haspopup="true"
                            aria-controls="overlay_menu_profile"
                            @click="toggleMenuProfile"
                        >
                            <Avatar :username="userData.username" icon="pi pi-user" shape="circle" />
                            <span>{{ userData.username }}</span>
                            <span class="pi pi-angle-down"></span>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #end>
            <div class="flex align-items-center gap-1 xl:gap-2" v-if="!userData?.accessToken">
                <router-link :to="{ name: ERoutesName.LOGIN }">
                    <Button label="Войти" text class="text__size" />
                </router-link>
                <router-link :to="{ name: ERoutesName.SIGN_UP }">
                    <Button label="Зарегистрироваться бесплатно" class="p-2 text__size" />
                </router-link>
            </div>
            <div
                class="p-2 hover:surface-hover border-round flex gap-2 align-items-center cursor-pointer"
                :class="{ 'surface-hover': menuProfileRef?.overlayVisible }"
                aria-haspopup="true"
                aria-controls="overlay_menu_profile"
                @click="toggleMenuProfile"
                v-else
            >
                <Avatar :username="userData.username" icon="pi pi-user" shape="circle" />
                <span>{{ userData.username }}</span>
                <span class="pi pi-angle-down"></span>
            </div>
            <Menu ref="menuProfileRef" id="overlay_menu_profile" :model="menuProfileItems" :popup="true">
                <template #item="{ item, props }">
                    <router-link
                        class="flex align-items-center"
                        :class="item?.class"
                        v-bind="props?.action"
                        :to="item.link || ''"
                        v-if="!item.hidden"
                    >
                        <span :class="item.icon" />
                        <span class="ml-2">{{ item.label }}</span>
                    </router-link>
                </template>
            </Menu>
        </template>
    </Menubar>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";

import { ERoutesName } from "@/app/providers";

import { useInterfaceStore } from "@/entities/Interface";
import { useSessionStore } from "@/entities/Session";
import { storeToRefs } from "pinia";
import { Avatar } from "@/shared/ui/avatar";

const interfaceStore = useInterfaceStore();
const sessionStore = useSessionStore();
const { headerItem, menuProfileItems } = storeToRefs(interfaceStore);
const { userData, isAuth } = storeToRefs(sessionStore);

const menuProfileRef = ref();
const headerItems = computed(() => {
    const newHeader = headerItem.value.map((item: any) => {
        if (item.has_children) {
            item.items = item.children;
        }
        return item;
    });
    return newHeader;
});

function toggleMenuProfile(event: Event): void {
    menuProfileRef.value.toggle(event);
}
</script>

<style lang="scss">
.content {
    min-width: 20px;
}

.end__visible {
    display: none !important;

    @media screen and (min-width: 1000px) {
        display: block !important;
    }
}

.item__visible {
    display: none;

    @media screen and (max-width: 1000px) {
        display: block;
    }
}

.text__size {
    font-size: 0.75rem;

    @media screen and (min-width: 1410px) {
        font-size: 1rem;
    }
}
.p-menubar .p-menuitem {
    position: static;
}

.menu {
    padding: 0 calc(20px - (100vw - 100%)) 0 20px;
}
</style>
