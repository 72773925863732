import { ref, computed } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRouter } from "vue-router";

import * as Sentry from "@sentry/vue";

import { useLocalStorage } from "@/shared/lib/browser";
import { TOKEN_KEY, USER_DATA_KEY } from "@/shared/config";
import { useNotification } from "@/shared/lib/composables";
import { useLoading } from "@/shared/lib/composables";

import { type IUserData, UserDataDto, SessionApi, type IRequestLogin } from "..";
import { usePaymentStore } from "@/entities/Payment";

import { ERoutesName } from "@/app/providers";

export const useSessionStore = defineStore("session", () => {
    const router = useRouter();
    const { showError } = useNotification();
    const { isLoading: loading, startLoading, finishLoading } = useLoading();

    const paymentStore = usePaymentStore();
    const { isTeamPlan, isEnterprisePlan } = storeToRefs(paymentStore);

    const { value: tokenValue, setLSValue: setLSToken } = useLocalStorage(TOKEN_KEY);
    const { value: userDataValue, setLSValue: setLSUserData } = useLocalStorage(USER_DATA_KEY);

    const token = ref(tokenValue);
    const userData = ref<IUserData>(userDataValue as IUserData);

    const isAuth = computed<boolean>(() => !!token.value);
    const isInTeam = computed<boolean>(() => userData.value?.inTeam || false);

    const isFullAccess = computed<boolean>(() => userData.value?.fullAccess || false);
    const isTeamAccess = computed<boolean>(() => isInTeam.value || isTeamPlan.value);

    async function setToken(value: string) {
        setLSToken(value);
        token.value = value;
    }
    function setUserData(value: IUserData) {
        setLSUserData(value);
        userData.value = value;

        if (isAuth.value) {
            setSentrySettings();
        } else {
            Sentry.getCurrentScope().clear();
        }
    }
    function setSentrySettings() {
        Sentry.setUser({ email: userData.value.username });
        Sentry.setTag("userId", userData.value.userId);
        Sentry.setTag("email", userData.value.username);
        Sentry.setTag("plan", userData.value.plan);
        Sentry.setTag("teamId", userData.value.teamId);
        Sentry.setTag("teamName", userData.value.teamName);
    }
    async function login(payload: IRequestLogin) {
        try {
            startLoading();
            const res: IUserData = await SessionApi.signIn(payload);
            saveUserData(res);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function saveUserData(payload: IUserData) {
        await setToken(payload.accessToken);
        setUserData(payload);

        router.push({
            name: ERoutesName.APP_MY_PROCESSES,
        });
    }
    function logout(): void {
        setToken("");
        setUserData({ ...new UserDataDto({}) });

        router.push({
            path: "/",
        });
    }

    return {
        isAuth,
        isFullAccess,
        isTeamAccess,
        isEnterprisePlan,
        isTeamPlan,
        isInTeam,
        token,
        userData,
        loading,
        login,
        logout,
        setSentrySettings,
        saveUserData,
    };
});
