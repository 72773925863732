<template>
    <div
        class="inline-flex justify-content-start gap-2 border-round text-sm surface-100 border-1 border-200 px-2 py-1 cursor-default"
        v-tooltip.top="{
            value: isTooltip ? label : '',
            showDelay: 200,
            hideDelay: 100,
        }"
    >
        <span v-if="showIcon" :class="PrimeIcons.FOLDER"></span>
        <span class="no-wrap">
            {{ label }}
        </span>
    </div>
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";
import { computed } from "vue";

interface IProps {
    label: string;
    showIcon?: boolean;
}

const props = withDefaults(defineProps<IProps>(), { showIcon: true });

const isTooltip = computed(() => props.label.length > 25);
</script>

<style scoped lang="scss">
.word-break-all {
    word-break: break-all;
}
.no-wrap {
    max-width: 150px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.cursor-default {
    cursor: default;
}
</style>
