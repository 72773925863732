import ResizeTask from "./ResizeTask";
import ResizeEvent from "./ResizeEvent";
import SizeConstrains from "./SizeConstrains";

export default {
    __init__: ["resizeTask", "resizeEvent", "sizeConstrains"],
    resizeTask: ["type", ResizeTask],
    resizeEvent: ["type", ResizeEvent],
    sizeConstrains: ["type", SizeConstrains],
};
