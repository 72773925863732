import { httpClient } from "@/shared/api";
import type { AxiosResponse } from "axios";
import type {
    IAsset,
    IAssetFilter,
    IAssetGeneral,
    IAssetResponse,
    IAssetEdge,
    ICustomAsset,
    ICustomAssetGeneral,
    IAssetEdgeGeneral,
} from "..";

export class AssetApi {
    static async fetchForFilter(): Promise<IAsset[]> {
        const response: AxiosResponse = await httpClient.get("/diagram-search/asset-list");
        return response.data;
    }
    static async fetchAll(filter: IAssetFilter): Promise<IAssetResponse> {
        const response: AxiosResponse = await httpClient.get("/asset", { params: filter });
        return response.data;
    }
    static async fetchCustom(): Promise<ICustomAsset[]> {
        const response: AxiosResponse = await httpClient.get("/custom-asset");
        return response.data;
    }
    static async fetchCustomByDiagramId(diagramId: string): Promise<ICustomAsset[]> {
        const response: AxiosResponse = await httpClient.get(`/custom-asset/by-diagram/${diagramId}`);
        return response.data;
    }
    static async fetchOneById(id: number): Promise<IAsset> {
        const response: AxiosResponse = await httpClient.get(`/asset/${id}`);
        return response.data;
    }
    static async fetchByDiagramId(diagramId: string): Promise<IAssetEdge[]> {
        const response: AxiosResponse = await httpClient.get(`/asset/edge/${diagramId}`);
        return response.data;
    }
    static async searchByName(name: string): Promise<IAsset[]> {
        const response: AxiosResponse = await httpClient.get("/asset/search", { params: { name } });
        return response.data;
    }

    static async create(payload: IAssetGeneral): Promise<IAsset> {
        const response: AxiosResponse = await httpClient.post("/asset", payload);
        return response.data;
    }
    static async createCustom(payload: ICustomAssetGeneral): Promise<ICustomAsset> {
        const response: AxiosResponse = await httpClient.post("/custom-asset", payload);
        return response.data;
    }
    static async createEdge(payload: IAssetEdgeGeneral): Promise<IAssetEdge> {
        const response: AxiosResponse = await httpClient.post("/asset/edge", payload);
        return response.data;
    }
    static async createEdgeMassive(payload: IAssetEdgeGeneral[]): Promise<IAssetEdge[]> {
        const response: AxiosResponse = await httpClient.post("/asset/edge/massive", payload);
        return response.data;
    }

    static async delete(id: number): Promise<void> {
        await httpClient.delete(`/asset/${id}`);
    }
    static async deleteCustom(id: number): Promise<void> {
        await httpClient.delete(`/custom-asset/${id}`);
    }
    static async deleteEdgeById(id: string): Promise<void> {
        await httpClient.delete(`/asset/edge/${id}`);
    }

    static async checkUsage(id: number): Promise<IAssetEdge[]> {
        const response: AxiosResponse = await httpClient.post(`/asset/checkUsage/${id}`);
        return response.data;
    }
}
