import { defineStore } from "pinia";
import { InvoiceDownloadApi } from "../api";
import { useNotification } from "@/shared/lib/composables";
import { DOWNLOAD_NAME } from "@/shared/config";
import type { IPayloadDownloadInvoice } from "./types";

export const useDownloadInvoice = defineStore("download-invoice", () => {
    const { showError } = useNotification();

    async function downloadInvoices(payload: IPayloadDownloadInvoice) {
        try {
            const res = await InvoiceDownloadApi.downloadInvoices(payload);
            const anchorElement = document.createElement("a");
            document.body.appendChild(anchorElement);
            let blob = new Blob([res], {
                type: "application/pdf",
            });
            let url = window.URL.createObjectURL(blob);
            anchorElement.href = url;
            anchorElement.download = DOWNLOAD_NAME + "_" + payload.legalEntityString + ".pdf";
            anchorElement.click();
            window.URL.revokeObjectURL(url);
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    return {
        downloadInvoices,
    };
});
