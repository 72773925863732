<template>

        <Button v-if="isInTeam" class="w-full" label="Улучшить тариф" v-bind="bindButton" @click="openUpgradeModal" />

    <Button v-else class="px-2" label="Попробовать бесплатно 14 дней" v-bind="bindButton" @click="openModal" />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";
import { useSessionStore } from "@/entities/Session";
import { useDialog } from "primevue/usedialog";
import { PlanUpgrade } from "@/widgets/app/plan";
import { useCreateTeam } from "@/features/Team/create";
import { MODAL_PARAMS } from "@/shared/constants/modal";
import { computed } from "vue";

const sessionStore = useSessionStore();
const { isInTeam } = storeToRefs(sessionStore);

const { openModal } = useCreateTeam();
const dialog = useDialog();
enum ESize {
    small = "small",
    large = "large",
}
interface IProps {
    size?: any;
}

const props = defineProps<IProps>();



function openUpgradeModal(): void {
    dialog.open(PlanUpgrade, {
        props: {
            header: "Улучшение тарифа",
            showHeader: false,
            contentStyle: { paddingTop: "20px"},
            ...MODAL_PARAMS,
            style: { width: "100%", maxWidth: "1100px" },
        },
    });
}



const bindButton = computed(() => (props.size ? { size: props.size } : {}));
</script>

<style scoped>

</style>
