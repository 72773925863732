<template>
    <TreeSelect
        v-model="selected"
        :options="treeSelectOptions"
        :disabled="!isTeamPlan"
        selectionMode="single"
        placeholder="Не выбрано"
        @node-select="selectFolder"
        @node-unselect="unSelectFolder"
    >
        <template #value="slotProps">
            <span v-if="selectedNode">{{ selectedNode.text }}</span>
            <span v-else>{{ slotProps.placeholder }}</span>
        </template>
    </TreeSelect>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { storeToRefs } from "pinia";

import type { TreeNode } from "primevue/treenode";

import { useFolderStore } from "../model";
import { useSessionStore } from "@/entities/Session";

const folderStore = useFolderStore();
const { foldersForSearch } = storeToRefs(folderStore);

const sessionStore = useSessionStore();
const { isTeamPlan } = storeToRefs(sessionStore);

const folder = defineModel();

const selected = ref();
const selectedNode = ref();

const treeSelectOptions = computed<TreeNode[]>(() => (foldersForSearch.value ? foldersForSearch.value : []));

function selectFolder(node: TreeNode) {
    folder.value = node.id;
    selectedNode.value = node;
}
function unSelectFolder() {
    folder.value = null;
    selectedNode.value = null;
}

watch(
    isTeamPlan,
    async value => {
        if (value) {
            await folderStore.fetchForSearch();

            if (foldersForSearch.value) {
                selectedNode.value = folderStore.getNodeByID(foldersForSearch.value, folder.value as number);
                selected.value = { [folder.value as number]: true };
            }
        }
    },
    {
        immediate: true,
    },
);
</script>

<style scoped></style>
