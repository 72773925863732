import { useDialog } from "primevue/usedialog";
import { PlanAdvertising } from "../ui";
import { MODAL_PARAMS } from "@/shared/constants/modal";

export function useUpgradePlan() {
    const dialog = useDialog();

    function openModalAdvertising() {
        dialog.open(PlanAdvertising, {
            props: {
                header: " ",
                ...MODAL_PARAMS,
            },
        });
    }

    return {
        openModalAdvertising,
    };
}
