import { EOverlayType, useBpmnModelerOverlayStore } from "..";

class CustomContextPad {
    create;
    elementFactory;
    translate;
    autoPlace;
    contextPad;
    static $inject: string[];

    constructor(config: any, contextPad: any, create: any, elementFactory: any, injector: any, translate: any) {
        this.create = create;
        this.elementFactory = elementFactory;
        this.translate = translate;
        this.contextPad = contextPad;

        if (config.autoPlace !== false) {
            this.autoPlace = injector.get("autoPlace", false);
        }

        contextPad.registerProvider(this);
    }

    getContextPadEntries(element: any) {
        const bpmnModelerOverlayStore = useBpmnModelerOverlayStore();

        return {
            "click.open-comment": {
                group: "activity",
                className: "pi pi-comment",
                title: "Комментрировать",
                action: {
                    click: (event: any, element: any) => {
                        this.contextPad.close();
                        bpmnModelerOverlayStore.createOverlayComment(element);
                    },
                },
            },
        };
    }
}

CustomContextPad.$inject = ["config", "contextPad", "create", "elementFactory", "injector", "translate"];

export default {
    __init__: ["CustomContextPad"],
    CustomContextPad: ["type", CustomContextPad],
};
