import { default as ru } from "./languages/ru.json";
import { default as en } from "./languages/en.json";

import { getCurrentLanguage } from "@/entities/language"

import { createI18n } from "vue-i18n";

const messages = Object.assign({ ru, en });

export const i18n = createI18n({
    legacy: false,
    allowComposition: true,
    locale: getCurrentLanguage(),
    fallbackLocale: import.meta.env.VITE_I18N_FALLBACK_LOCALE,
    messages,
});
