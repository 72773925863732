<template>
    <Button
        :icon="PrimeIcons.PALETTE"
        text
        class="navbar-button"
        severity="contrast"
        :disabled="!hasSelectedElements"
        v-tooltip.bottom="'Изменить цвет выделенного'"
        @click="toggleColorPicker"
    />
    <ChangeColorOverlay />
</template>

<script setup lang="ts">
import { storeToRefs } from "pinia";

import { PrimeIcons } from "primevue/api";

import { useElementStore } from "@/entities/BpmnModeler";

import { useChangeElementColor, ChangeColorOverlay } from "../";

const elementStore = useElementStore();
const { hasSelectedElements } = storeToRefs(elementStore);

const { toggleColorPicker } = useChangeElementColor();
</script>

<style scoped></style>
