<template>
    <Button :icon="PrimeIcons.CLIPBOARD" text severity="contrast" class="navbar-button" v-tooltip.bottom="'Вставить'" @click="paste" />
</template>

<script setup lang="ts">
import { PrimeIcons } from "primevue/api";

import { useCopyPaste } from "../model";

const { paste } = useCopyPaste();
</script>

<style scoped></style>
