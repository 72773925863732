import { markRaw, ref } from "vue";

import { useLoading, useNotification } from "@/shared/lib/composables";
import { requiredValue } from "@/shared/lib/validators";
import { MODAL_PARAMS } from "@/shared/constants/modal";

import { useDialog } from "primevue/usedialog";

import { helpers, required } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

import { EValueRightsParticipant, ITeamForm, TeamApi } from "@/entities/Team";
import { usePaymentStore } from "@/entities/Payment";

import Form from "../ui/Form.vue";

const state = ref<ITeamForm>({
    name: "",
    rights: EValueRightsParticipant.READ,
    users: [],
});

const rules = {
    name: { required: helpers.withMessage(requiredValue, required) },
};

const validate = useVuelidate(rules, state);

export function useCreateTeam() {
    const { showError, showSuccess } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();

    const paymentStore = usePaymentStore();

    const dialog = useDialog();

    async function create(cb: Function): Promise<void> {
        try {
            const isValid = await validate.value.$validate();

            if (isValid) {
                startLoading();

                await TeamApi.create(state.value);

                await paymentStore.getCurrentPlan();

                cb();

                showSuccess("Команда создана");
            }
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    function openModal(): void {
        dialog.open(Form, {
            props: {
                header: " ",
                ...MODAL_PARAMS,
            },
        });
    }

    function resetForm(): void {
        state.value.name = "";
        state.value.users = [];

        validate.value.$reset();
    }

    return {
        isLoading,

        state,
        validate,
        create,
        resetForm,

        openModal,
    };
}
