import { useBpmnModelerStore } from "@/entities/BpmnModeler";
import { EBpmnElementType } from "@/entities/Score";
import { MAX_COUNT_BY_AUTONUMERATE } from "@/shared/config";
import { useLoading, useNotification } from "@/shared/lib/composables";
import { declensionNumerals } from "@/shared/lib/utils/formater";
import type { Shape } from "bpmn-js/lib/model/Types";
import { storeToRefs } from "pinia";

export function useAutoNumerate() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showWarning } = useNotification();

    const bpmnModeler = useBpmnModelerStore();

    const { modelerElementRegistry, modelerModeling } = storeToRefs(bpmnModeler);

    function sortElements(elements: any[]): void {
        if (!elements.length) return;

        elements.sort((a, b) => {
            const centerAX = a.x + a.width / 2;
            const centerAY = a.y + a.height / 2;

            const centerBX = b.x + b.width / 2;
            const centerBY = b.y + b.height / 2;

            if (centerAX > centerBX) return 1;
            if (centerAX < centerBX) return -1;

            return centerAY - centerBY;
        });
    }

    function setAutoNumerate(clear: boolean = false): void {
        function updateNameByElement(element: any, numbering: string): void {
            const name = element.businessObject?.name ? element.businessObject.name.replace(/^[\d{1}\s*\.{1}]+/g, "") : "";

            modelerModeling.value.updateProperties(element, {
                name: clear ? name : `${numbering}. ${name}`,
            });
        }
        function numerateChildren(children: any[] = [], textNumerate: string): void {
            sortElements(children);

            let indexChildren = 1;
            for (const element of children) {
                setTimeout(() => {
                    if (element.type === EBpmnElementType.LABEL) return;
                    if (element.type === EBpmnElementType.SEQUENCE_FLOW) return;

                    const numbering = `${textNumerate}.${indexChildren}`;

                    if (element.type.includes("Gateway")) {
                        const outgoing = element.outgoing;

                        let indexOutgoing = 1;
                        for (const element of outgoing) {
                            updateNameByElement(element, `${textNumerate}.${indexChildren}.${indexOutgoing}`);

                            indexOutgoing++;
                        }
                    }

                    if ([EBpmnElementType.SUB_PROCESS, EBpmnElementType.AD_HOC_SUB_PROCESS].includes(element.type) && element.collapsed) {
                        const rootElement = modelerElementRegistry.value.find(
                            (el: any) =>
                                !el.collapsed && el.businessObject.name === element.businessObject.name && el.id.includes(element.id),
                        );
                        numerateChildren(rootElement.children, numbering);
                    }

                    updateNameByElement(element, numbering);

                    numerateChildren(element.children, numbering);

                    indexChildren++;
                });
            }
        }

        const elements = modelerElementRegistry.value.filter((element: Shape) => {
            const type: string = element.type;
            return ![EBpmnElementType.LABEL, EBpmnElementType.ASSOCIATION, EBpmnElementType.SEQUENCE_FLOW].includes(
                type as EBpmnElementType,
            );
        });

        const count: number = elements.length;

        if (count > MAX_COUNT_BY_AUTONUMERATE) {
            return showWarning(
                `Автонумерация доступна для диаграмм, в которых не более ${MAX_COUNT_BY_AUTONUMERATE} ${declensionNumerals(MAX_COUNT_BY_AUTONUMERATE, ["элемента", "элементов", "элементов"])}. У вас ${count}`,
            );
        }

        startLoading();

        const pools: Shape[] = modelerElementRegistry.value.filter((element: Shape) => element.type === EBpmnElementType.PARTICIPANT);

        sortElements(pools);

        for (let index = 0; index < pools.length; index++) {
            setTimeout(() => {
                const element = pools[index];
                const numbering = `${index + 1}`;

                updateNameByElement(element, numbering);

                numerateChildren(element.children, numbering);
            });
        }

        finishLoading();
    }

    return {
        isLoading,

        setAutoNumerate,
    };
}
