export function extractIds(data: any) {
    if (!data) return [];
    if (Array.isArray(data)) {
        return data.map(item => item.id);
    }
    return [data.id];
}

export function isSameArrays(firstArray: any[], secondaryArray: any[]): boolean {
    if (firstArray.length !== secondaryArray.length) return false;

    for (const element of firstArray) {
        if (!secondaryArray.every(item => item === element)) return false;
    }

    return true;
}
