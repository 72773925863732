import { ref, computed } from "vue";
import { storeToRefs } from "pinia";

import { useDiagramStore } from "@/entities/Process";
import { useCommentStore, type IComment } from "@/entities/Comment";

import { useRemoveComment } from "../../remove";
import { useToggleImportant } from "../../toggle-important";
import { useCommentEdit } from "../../edit";

const commentMenuRef = ref();
const selectedComment = ref<IComment>();

export function useCommentMenu() {
    const diagramStore = useDiagramStore();
    const commentStore = useCommentStore();

    const { diagram } = storeToRefs(diagramStore);

    const { removeComment } = useRemoveComment();
    const { toggleImportant } = useToggleImportant();
    const { showEditForm } = useCommentEdit();

    const commentMenuModel = computed(() => [
        {
            label: "Редактировать",
            command: () => {
                if (selectedComment.value) {
                    showEditForm(selectedComment.value);
                }
            },
        },
        {
            label: selectedComment.value?.important ? "Отменить важность" : "Сделать важным",
            command: () => {
                if (selectedComment.value) {
                    toggleImportant(selectedComment.value.id);
                }
            },
        },
        {
            label: "Удалить",
            command: () => {
                if (diagram.value && selectedComment.value) {
                    removeComment(diagram.value.id, selectedComment.value.elementId, selectedComment.value.id);
                }
            },
        },
    ]);

    function toggleCommentMenu(comment: IComment, event: Event) {
        selectedComment.value = comment;
        commentMenuRef.value.toggle(event);
    }
    function hideCommentMenu() {
        commentMenuRef.value.hide();
        selectedComment.value = undefined;
    }

    return {
        commentMenuModel,
        commentMenuRef,
        toggleCommentMenu,
        hideCommentMenu,
    };
}
