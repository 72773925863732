<template>
    <div
        class="px-2 border-round-sm border-1 border-blue-300 bg-blue-50 text-blue-500 hover:border-blue-500 text-xs cursor-pointer select-none"
    >
        <slot></slot>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
