import { computed, ref } from "vue";
import { defineStore, storeToRefs } from "pinia";

import { type ILintRule, ELintRuleType } from "@/shared/lib/types/app";
import { useLoading, useNotification } from "@/shared/lib/composables";

import { useSessionStore } from "@/entities/Session";
import { RULES } from "@/entities/Score";
import {
    type ITeam,
    type ITeamSetting,
    type ITeamListSearch,
    type IDiagramOption,
    type IMyTemplate,
    type IPayloadUpdateAllowedDomain,
    type ISettingsQuality,
    type IPayloadSaveNewSettingsQuality,
    type ISendInvitedColleague,
    ESettingsName,
    ETypeUpdateSettingsTeam,
    TeamApi,
} from "@/entities/Team";

export const useTeamStore = defineStore("team", () => {
    const sessionStore = useSessionStore();
    const { isTeamAccess } = storeToRefs(sessionStore);

    const { showError, showSuccess } = useNotification();
    const { isLoading, startLoading, finishLoading } = useLoading();

    const teamByFilter = ref<ITeamListSearch[]>([]);
    const team = ref<ITeam>();
    const teamSettings = ref<ITeamSetting[]>([]);
    const diagramOption = ref<IDiagramOption[]>();
    const myTemplates = ref<IMyTemplate[]>([]);
    const settingsQuality = ref<ISettingsQuality>({
        folderRequried: false,
        minimumDiagramScore: 0,
        minumumPercentOfSystems: 0,
        minumumPercentOfAssignees: 0,
        minumumPercentOfDocuments: 0,
    });
    const rullesSettingsTeam = ref<ILintRule[]>(Object.values(RULES));

    const scoringSettings = computed(() => {
        if (teamSettings.value) {
            const findedSetting = teamSettings.value.find(item => item.setting === ESettingsName.SCORING);
            if (findedSetting) return JSON.parse(findedSetting.value);
        }
        return [];
    });
    const minDiagramScore = computed(() => getSettingValueByName(ESettingsName.MIN_DIAGRAM_SCORE));
    const minPercentOfAssignees = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_ASSIGNEES));
    const minPercentOfSystems = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_SYSTEMS));
    const minPercentOfDocuments = computed(() => getSettingValueByName(ESettingsName.MIN_PERCENT_OF_DOCUMENTS));

    function getSettingValueByName(name: ESettingsName): number {
        if (teamSettings.value) {
            const finded = teamSettings.value.find(item => item.setting === name);
            if (finded) {
                const value = JSON.parse(finded.value);
                return Number.isInteger(value) ? value : 0;
            }
        }

        return 0;
    }

    function setupSettingsQuality(quality: ITeamSetting[]): void {
        for (const set of quality) {
            if (set.setting == ESettingsName.MIN_PERCENT_OF_SYSTEMS) {
                settingsQuality.value.minumumPercentOfSystems = Number(set.value) || 0;
            }

            if (set.setting == ESettingsName.FOLDER_REQURIED) {
                settingsQuality.value.folderRequried = set.value == "true";
            }

            if (set.setting == ESettingsName.MIN_DIAGRAM_SCORE) {
                settingsQuality.value.minimumDiagramScore = Number(set.value) || 0;
            }

            if (set.setting == ESettingsName.MIN_PERCENT_OF_ASSIGNEES) {
                settingsQuality.value.minumumPercentOfAssignees = Number(set.value) || 0;
            }

            if (set.setting == ESettingsName.MIN_PERCENT_OF_DOCUMENTS) {
                settingsQuality.value.minumumPercentOfDocuments = Number(set.value) || 0;
            }

            if (set.setting == ESettingsName.SCORING) {
                rullesSettingsTeam.value = rullesSettingsTeam.value.map((item: ILintRule) => {
                    for (const rule of JSON.parse(set.value)) {
                        if (rule.ruleId == item.ruleId) {
                            return rule;
                        }
                    }
                    return item;
                });
            }
        }
    }

    async function fetchForFilter(): Promise<void> {
        try {
            startLoading();
            teamByFilter.value = await TeamApi.fetchForFilter();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchTeam(): Promise<void> {
        try {
            team.value = await TeamApi.fetchMy();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchSettings(): Promise<void> {
        try {
            if (isTeamAccess.value) {
                const response: ITeamSetting[] = await TeamApi.fetchSettings();
                teamSettings.value = response;
                setupSettingsQuality(response);
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function addUserTeam(payload: ISendInvitedColleague) {
        try {
            if (team.value) {
                await TeamApi.addUserTeam(team.value.id, payload);
                showSuccess("Пользователю отправленно приглашение!");
                await fetchTeam();
                return true;
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getOnlyDiagrams(): Promise<void> {
        try {
            if (isTeamAccess.value) {
                diagramOption.value = await TeamApi.getOnlyDiagrams();
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function getMyTemplates(): Promise<void> {
        try {
            if (isTeamAccess.value) {
                myTemplates.value = await TeamApi.getMyTemplates();
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function deleteTemplate(id: string) {
        try {
            await TeamApi.deleteTemplate(id);
            await getMyTemplates();
            showSuccess("Шаблон успешно удален");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function saveTemplate(payload: any) {
        try {
            await TeamApi.saveTemplate(payload);

            await getMyTemplates();
            showSuccess("Шаблон успешно загружен");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function updateSettingsTeam(payload: IPayloadUpdateAllowedDomain, type: string) {
        try {
            team.value = await TeamApi.updateSettingsTeam(payload);
            if (type == ETypeUpdateSettingsTeam.API) {
                showSuccess("API ключ успешно обновлен");
            } else if (type == ETypeUpdateSettingsTeam.NAME) {
                showSuccess("Название успешно обновлено");
            } else if (type == ETypeUpdateSettingsTeam.DOMAIN) {
                showSuccess("Домен успешно обновлен");
            }
        } catch (e: any) {
            showError(e?.message || e);
        }
    }
    async function saveNewSettingsQuality(payload: IPayloadSaveNewSettingsQuality[]) {
        try {
            const response: ITeamSetting[] = await TeamApi.saveNewSettingsQuality(payload);
            teamSettings.value = response;
            setupSettingsQuality(response);
            showSuccess("Настройки успешно сохранены");
        } catch (e: any) {
            showError(e?.message || e);
        }
    }

    return {
        isLoading,
        team,
        teamByFilter,
        teamSettings,
        scoringSettings,
        minDiagramScore,
        minPercentOfAssignees,
        minPercentOfDocuments,
        minPercentOfSystems,
        diagramOption,
        rullesSettingsTeam,
        myTemplates,
        settingsQuality,
        fetchForFilter,
        fetchTeam,
        fetchSettings,
        addUserTeam,
        getOnlyDiagrams,
        getMyTemplates,
        deleteTemplate,
        saveTemplate,
        updateSettingsTeam,
        saveNewSettingsQuality,
    };
});
