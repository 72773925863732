<template>
    <Dialog v-model:visible="visible" :draggable="false" modal dismissableMask :header="header">
        <div id="arsenalpay-widget"></div>
    </Dialog>
</template>

<script setup lang="ts">
import { useDialog } from "@/shared/lib/composables";
import { IPaymentUpgrade } from "@/entities/Payment";

defineProps({
    header: String,
});

declare const ArsenalpayWidget: any;

const { visible, show, hide } = useDialog();

function beforeShow(payload: IPaymentUpgrade) {
    let recaptchaScript = document.createElement("script");
    recaptchaScript.src = "https://arsenalpay.ru/widget/script.js";
    document.head.appendChild(recaptchaScript);

    recaptchaScript.onload = async function () {
        let arsenal = new ArsenalpayWidget();
        arsenal.widget = payload.widget;
        arsenal.destination = payload.destination;
        arsenal.amount = payload.amount;
        arsenal.userId = payload.userId;
        arsenal.nonce = payload.nonce;
        arsenal.widgetSign = payload.widgetSign;
        arsenal.element = "arsenalpay-widget";
        await arsenal.render();
    };

    show();
}

defineExpose({
    show: (payload: IPaymentUpgrade) => beforeShow(payload),
});
</script>

<style scoped></style>
