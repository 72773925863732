import { BASE_URL } from "@/shared/config";
import type { IFile, IGalleriaItem, IReadFile } from "../../types";

export async function readFile(file: File): Promise<IReadFile> {
    return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (event: ProgressEvent<FileReader>) => {
            const result = event?.target?.result;
            resolve({
                name: file.name,
                result: result,
            });
        };
        reader.readAsText(file);
    });
}

export function filesToItemsGalleria(files: File[]): IGalleriaItem[] {
    return files.map(item => {
        const iFile = fileToIFile(item);
        return {
            url: iFile.objectURL,
            preview: iFile.objectURL,
            extension: iFile.name.split(".").at(-1) || "",
        };
    });
}

export function fileToIFile(file: File) {
    const newFile: IFile = file as IFile;
    newFile.extension = file.name.split(".").at(-1) || "";
    return newFile;
}

export const setFileUrl = (path: string) => {
    return BASE_URL + path;
};
