export interface IPayloadCourses {
    class: number | undefined;
    corporate_inn: string | null;
    type: string;
    email_for_invoice: string | null;
    total_price: number;
    promocode: string | null;
    students: IStudents[];
}
export interface IStudents {
    email: String;
}

export interface ISelectedTariff {
    current_count: number;
    max_students: number;
    id: number;
    course: ICourse;
    plan: IPlan;
}

export interface ICourse {
    user_created: string;
    user_updated: string;
    date_created: string;
    date_updated: string;
    end_date: string;
    start_date: string;
    title: string;
    status: string;
    description: string;
    email_segment_id: number;
    sort: any;
    id: number;
    classes: number[];
}

export interface IPlan {
    user_created: string;
    user_updated: string;
    date_created: string;
    date_updated: string;
    title: string;
    price: number;
    corporate_price: number;
    features: string[];
    description: string;
    course: number;
    id: number;
}

export interface ISeo {
    title: string | null;
    meta_desciription: string | null;
    robots: string | null;
    og_title: string | null;
    og_description: string | null;
    og_image: any | null;
    og_url: string | null;
    og_type: any;
    canonical_url: any;
    og_sitename: string;
    json_schema: boolean;
    json_data: string | null;
}

export interface IBlock {
    collection: string;
    item: IItem;
    courses?: any;
}

export interface IItem {
    id: string;
    title: string;
    headline?: string;
    content?: string;
    image_position?: string;
    image?: IImage;
    button_group?: IButtonGroup;
    logos?: ILogo[];
    counts?: ICount[];
    alternate_image_position?: boolean;
    show_step_number?: boolean;
    steps?: IStep[];
    testimonials?: ITestimonial[];
    slides?: ISlide[];
    rows?: IRow[];
    sort: any;
    features?: IFeatureItem[];
    elements?: IElement[];
    highlight?: string;
    members?: IMember[];
    tabs?: ITabs[];
    search: boolean;
    sidebar_groupping: boolean;
    background_color: string;
    image_max_height: number;
    items: ITabs[];
    courses?: ISingleCourse[];
}

export interface ISingleCourse {
    id: number;
    Block_courses_id: Blockcoursesid2;
    Courses_id: Coursesid2;
}
export interface Coursesid2 {
    id: number;
    status: string;
    sort?: any;
    date_created: string;
    date_updated: string;
    start_date: string;
    end_date: string;
    description: string;
    title: string;
    email_segment_id: number;
    classes: Class[];
}
export interface Class {
    id: number;
    max_students: number;
    current_count: number;
    plan: Plan;
    course: Coursesid;
}
export interface Plan {
    id: number;
    user_created: string;
    date_created: string;
    user_updated: string;
    date_updated: string;
    title: string;
    price: number;
    corporate_price: number;
    course: number;
    description: string;
    features: string[];
}
export interface Blockcoursesid2 {
    id: string;
    title: string;
    headline: string;
    content?: any;
    courses: Course[];
}
export interface Course {
    id: number;
    Block_courses_id: Blockcoursesid;
    Courses_id: Coursesid;
}
export interface Coursesid {
    id: number;
    status: string;
    sort?: any;
    user_created: string;
    date_created: string;
    user_updated: string;
    date_updated: string;
    start_date: string;
    end_date: string;
    description: string;
    title: string;
    email_segment_id: number;
    classes: number[];
}
export interface Blockcoursesid {
    id: string;
    title: string;
    headline: string;
    content?: any;
    courses: number[];
}

export interface IImage {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface IButtonGroup {
    id: string;
    alignment: string;
    buttons: IButton[];
}

export interface IButton {
    id: string;
    sort: number;
    type: string;
    external_url: string;
    label: string;
    color: string;
    variant: string;
    page?: IPage;
}

export interface IPage {
    id: string;
    sort: number;
    title: string;
    permalink: string;
    seo: string;
    blocks: number[];
}

export interface ILogo {
    id: number;
    Block_logo_cloud_id: IBlockLogoCloudId;
    directus_files_id: IDirectusFilesId;
}

export interface IBlockLogoCloudId {
    id: string;
    title: string;
    headline: string;
    logos: ILogos;
}

export interface ILogos {
    id: number;
    Block_logo_cloud_id: string;
    directus_files_id: string;
}

export interface IDirectusFilesId {
    id: string;
    title: string;
    description: any;
    width: any;
    height: any;
}

export interface ICount {
    title: string;
    count: number;
    postfix?: string;
}

export interface IStep {
    id: string;
    sort: any;
    title: string;
    content: string;
    headline: string;
    image: IImageSteps;
    image_max_height: number;
    video: IImageSteps;
    button_group: any;
    type: string;
    block_steps: IBlockSteps;
}

export interface IImageSteps {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface IBlockSteps {
    id: string;
    title: string;
    headline: string;
    alternate_image_position: boolean;
    show_step_number: boolean;
    steps: IStepBlockStep[];
}

export interface IStepBlockStep {
    id: string;
    sort: any;
    title: string;
    content: string;
    image: string;
    button_group: any;
    block_steps: string;
    headline: string;
}

export interface ITestimonial {
    id: number;
    Block_testimonials_id: IBlockTestimonialsId;
    Testimonials_id: ITestimonialsId;
}

export interface IBlockTestimonialsId {
    id: string;
    title: string;
    headline: string;
    testimonials: ITestimonialBlockTestimonialsId[];
}

export interface ITestimonialBlockTestimonialsId {
    id: number;
    Block_testimonials_id: string;
    Testimonials_id: string;
}

export interface ITestimonialsId {
    title: string;
    content: string;
    subtitle: string;
    company: string;
    link: any;
    image: IImageTestimonials;
    company_logo: ICompanyLogoTestimonials;
}

export interface IImageTestimonials {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface ICompanyLogoTestimonials {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface ISlide {
    id: number;
    Block_feature_slider_id: IBlockFeatureSliderId;
    Block_feature_slides_id: IBlockFeatureSlidesId;
}

export interface IBlockFeatureSliderId {
    id: string;
    title: string;
    headline: any;
    slides: ISlideBlockFeatureSliderId[];
}

export interface ISlideBlockFeatureSliderId {
    id: number;
    Block_feature_slider_id: string;
    Block_feature_slides_id: string;
}

export interface IBlockFeatureSlidesId {
    id: string;
    title: string;
    name: string;
    image: IImageBlockFeatureSlidesId;
}

export interface IImageBlockFeatureSlidesId {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface IRow {
    id: string;
    sort: number;
    date_created: string;
    date_updated: string;
    title: string;
    headline: string;
    content: string;
    image_position: string;
    highlight: boolean;
    features?: IFeature[];
    text_before_features?: string;
    headline_description: string;
    block_columns: IBlockColumns;
    image: any;
    button_group: IButtonGroupRow;
}

export interface IFeature {
    title: string;
    description: string;
}

export interface IFeatureItem {
    feature: string;
    personal: string;
    personal_pro: string;
    team: string;
    enterprise: string;
}

export interface IBlockColumns {
    id: string;
    title: string;
    headline: string;
    rows: IRowBlockColumns[];
}

export interface IButtonGroupRow {
    id: string;
    alignment: string;
    buttons: IButtonRow[];
}
export interface IRowBlockColumns {
    id: string;
    sort: number;
    user_created: string;
    date_created: string;
    user_updated: string;
    date_updated: string;
    block_columns: string;
    title: string;
    headline: string;
    content: string;
    image: any;
    image_position: string;
    button_group: string;
    highlight: boolean;
    features?: IFeatureRowBlockColumns[];
    text_before_features?: string;
    headline_description: string;
}

export interface IButtonRow {
    id: string;
    sort: number;
    type: string;
    external_url: string;
    label: string;
    color: string;
    variant: string;
    page: any;
}

export interface IFeatureRowBlockColumns {
    title: string;
    description: string;
}

export interface IElement {
    id: number;
    Bpmn_elements_id: IBpmnElementsId;
    Block_bpmn_elements_id: IBlockBpmnElementsId;
}

export interface IBpmnElementsId {
    id: string;
    date_updated: string;
    date_created: string;
    slug: string;
    original_name: string;
    base_class: string;
    bpmn_font_code: string;
    name: string;
    status: string;
    bad_example_description?: string;
    good_example_description?: string;
    description: string;
    sort: any;
    button_group: any;
    bad_example_img?: IBadExampleImg;
    good_example_img?: IGoodExampleImg;
}

export interface IBadExampleImg {
    id: string;
    title: string;
    description?: string;
    width?: number;
    height?: number;
}

export interface IGoodExampleImg {
    id: string;
    title: string;
    description?: string;
    width: number;
    height: number;
}

export interface IBlockBpmnElementsId {
    id: string;
    title: string;
    status: string;
    search: boolean;
    sidebar_groupping: boolean;
    elements: IElement2[];
}

export interface IElement2 {
    Bpmn_elements_id: string;
    Block_bpmn_elements_id: string;
    id: number;
}

export interface IMember {
    id: number;
    sort: number;
    Team_id: ITeamId;
    Block_team_id: IBlockTeamId;
}

export interface ITeamId {
    id: string;
    date_updated: any;
    date_created: string;
    job_title: string;
    name: string;
    status: string;
    bio?: string;
    sort: any;
    image: IImage;
}

export interface IBlockTeamId {
    id: string;
    title: string;
    content: string;
    headline: string;
    members: IMemberBlockTeamId[];
}

export interface IMemberBlockTeamId {
    Team_id: string;
    Block_team_id: string;
    id: number;
    sort: number;
}

export interface ITabs {
    name: string;
    description: string;
}

export enum EPostType {
    BLOG = "blog",
    VIDEO = "video",
}

export interface IGetElement {
    id: string;
    date_updated: string;
    date_created: string;
    slug: string;
    original_name: string;
    base_class: string;
    bpmn_font_code: string;
    name: string;
    status: string;
    bad_example_description: string;
    good_example_description: string;
    description: string;
    sort: number;
    good_example_img: IExampleImg;
    bad_example_img: IExampleImg;
    button_group: any;
}

export interface IExampleImg {
    id: string;
    title: string;
    description: any;
    width: number;
    height: number;
}

export interface IGetContactUs {
    id: string;
    content: string;
    headline: string;
    seo: ISeo;
    blocks: IBlockGetContactUs[];
}

export interface IBlockGetContactUs {
    collection: string;
    id: number;
    Pages_contact_us_id: PagesContactUsId;
    item: IItemGetContactUs;
}

export interface PagesContactUsId {
    id: string;
    content: string;
    headline: string;
    seo: ISeo;
    blocks: IBlockGetContactU2[];
}
export interface IItemGetContactUs {
    id: string;
    title: string;
    headline: string;
    logos: ILogoGetContactUs[];
}

export interface ILogoGetContactUs {
    id: number;
    sort: number;
    directus_files_id: IDirectusFilesIdGetContactUs;
}

export interface IDirectusFilesIdGetContactUs {
    id: string;
    title: string;
    description?: string;
    width?: number;
    height?: number;
}

export interface IBlockGetContactU2 {
    collection: string;
    id: number;
    Pages_contact_us_id: IPagesContactUsId2;
    item: IItemGetContactUs2;
}

export interface IPagesContactUsId2 {
    id: string;
    seo: string;
    content: string;
    headline: string;
    blocks: number[];
}

export interface IItemGetContactUs2 {
    id: string;
    title: string;
    headline: string;
    logos: number[];
}
