import { ref } from "vue";

export const useDialog = () => {
    const visible = ref(false);

    function show() {
        visible.value = true;
    }
    function hide() {
        visible.value = false;
    }
    function toggle() {
        visible.value = !visible.value;
    }

    return {
        visible,
        show,
        hide,
        toggle,
    };
};
