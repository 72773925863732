import { ref } from "vue";
import { defineStore } from "pinia";

interface IMenuSettings {
    isToken: false;
    isFullscreen: false;
    isCamundaPanel: false;
    isMinimap: false;
    isCheckErrors: false;
    isHiddenMenu: false;
    [key: string]: boolean;
}

export const useDiagramActionMenuStore = defineStore("diagram-action-menu", () => {
    const menuSettings = ref<IMenuSettings>({
        isCamundaPanel: false,
        isCheckErrors: false,
        isFullscreen: false,
        isHiddenMenu: false,
        isMinimap: false,
        isToken: false,
    });

    function toggleMinimap(): void {
        document.querySelector(".djs-minimap")?.classList.toggle("hidden");
        toggleMenuSettings("isMinimap");
    }
    function toggleFullscreen(): void {
        if (document.fullscreenElement) {
            document.querySelector(".editor")?.classList.remove("editor_fullscreen");
            document.querySelector(".p-menubar")?.classList.remove("hidden");

            const element = document.querySelector(".diagram-container");
            if (element) {
                element.classList.remove("h-screen");
                element.classList.add("content");
            }

            document.exitFullscreen();
        } else {
            document.body.requestFullscreen();
            document.querySelector(".editor")?.classList.add("editor_fullscreen");
            document.querySelector(".p-menubar")?.classList.add("hidden");

            const element = document.querySelector(".diagram-container");
            if (element) {
                element.classList.add("h-screen");
                element.classList.remove("content");
            }
        }

        toggleMenuSettings("isFullscreen");
    }
    function toggleHeaderVisible(): void {
        toggleMenuSettings("isHiddenMenu");

        if (menuSettings.value.isHiddenMenu) {
            document.querySelector(".editor")?.classList.add("editor_fullscreen");
        } else {
            document.querySelector(".editor")?.classList.remove("editor_fullscreen");
        }
    }

    function toggleMenuSettings(key: string): void {
        if (key in menuSettings.value) menuSettings.value[key] = !menuSettings.value[key];
    }

    function resetMenu(): void {
        for (const key in menuSettings.value) {
            menuSettings.value[key] = false;
        }
    }

    return {
        toggleMinimap,
        toggleFullscreen,
        toggleHeaderVisible,

        resetMenu,

        menuSettings,
        toggleMenuSettings,
    };
});
