import { httpClient } from "@/shared/api";
import { AxiosResponse } from "axios";
import type { INotice } from "../model";

export class NoticeApi {
    static async getNoticeCount(): Promise<number> {
        const response: AxiosResponse = await httpClient.get("/notification/count?readed=false");
        return response.data;
    }
    static async getNotice(readed: boolean, page: number): Promise<INotice[]> {
        const response: AxiosResponse = await httpClient.get(`/notification?readed=${readed}&page=${page}`);
        return response.data;
    }
    static async markReadNotice(): Promise<void> {
        await httpClient.post("/notification/markRead");
    }
    static async deleteNotice(): Promise<void> {
        await httpClient.delete(`/notification/allReaded`);
    }
}
