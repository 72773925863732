<template>
    <div class="w-full h-screen flex flex-column">
        <MainHeader />
        <main class="w-full flex-auto mt-7">
            <slot>
                <RouterView></RouterView>
            </slot>
        </main>
        <Footer v-if="showFooter" />
    </div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { MainHeader } from "@/widgets/header";
import { Footer } from "@/widgets/footer";
import { useInterfaceStore } from "@/entities/Interface";
import { useRoute, useRouter, onBeforeRouteUpdate, type NavigationGuardNext } from "vue-router";
import { ERoutesName } from "@/app/providers";
import { storeToRefs } from "pinia";

const interfaceStore = useInterfaceStore();
const { isObserver, isSearch } = storeToRefs(interfaceStore);
const route = useRoute();
const router = useRouter();
const observer = new IntersectionObserver(
    (entries: any) => {
        entries.forEach((entry: any) => {
            if (entry.isIntersecting) {
                document.querySelectorAll("#navbarRigth").forEach((link: any) => {
                    let id = link.getAttribute("href").split("#")[1];
                    if (id === entry.target.id) {
                        link.classList.add("active");
                        interfaceStore.changeActiveIdAnchor(String(id));
                    } else {
                        link.classList.remove("active");
                    }
                });
            }
        });
    },
    {
        threshold: 0.5,
    },
);
const showFooter = ref(false);

onMounted(async () => {
    interfaceStore.getHeader();
    if (route.name == ERoutesName.MARKETING) {
        const res = await interfaceStore.fetchAll(route.fullPath);
        if (!res) router.replace({ name: ERoutesName.NOT_FOUND });
        showFooter.value = true;
    } else if (route.name == ERoutesName.BLOG) {
        await Promise.all([interfaceStore.getTags(), interfaceStore.getBlog(), interfaceStore.getPosts()]);
        showFooter.value = true;
    } else if (route.name == ERoutesName.BLOG_SUBTITLE) {
        await Promise.all([interfaceStore.getBlogByTag(String(route.params.slug)), interfaceStore.getTags()]);
        showFooter.value = true;
    } else if (route.name == ERoutesName.ARTICLE) {
        await Promise.all([
            interfaceStore.getArticle(String(route.params.id)),
            interfaceStore.getBlogByTag(String(route.params.slug)),
            interfaceStore.getTags(),
        ]);
        document.querySelectorAll(".section").forEach((section: any) => {
            observer.observe(section);
        });
        showFooter.value = true;
    } else if (route.name == ERoutesName.BPMN_ELEMENT) {
        await interfaceStore.getElement(String(route.params.slug));
        showFooter.value = true;
    } else if (route.name == ERoutesName.CONTACT_SALES) {
        await interfaceStore.getContactUs();
        showFooter.value = true;
    } else {
        showFooter.value = true;
    }
    interfaceStore.getFooter();
});

onBeforeRouteUpdate(async (to: any, from: any, next: NavigationGuardNext) => {
    if (to.name == ERoutesName.MARKETING) {
        const res = await interfaceStore.fetchAll(to.fullPath);
        if (res) next();
        else next({ name: ERoutesName.NOT_FOUND });
    } else if (to.name == ERoutesName.BLOG) {
        if (isSearch.value) {
            await Promise.all([interfaceStore.getTags(), interfaceStore.getBlog()]);
            next();
        } else {
            await Promise.all([interfaceStore.getTags(), interfaceStore.getBlog(), interfaceStore.getPosts()]);
            next();
        }
    } else if (to.name == ERoutesName.BLOG_SUBTITLE) {
        await Promise.all([interfaceStore.getBlogByTag(String(to.params.slug)), interfaceStore.getTags()]);
        next();
    } else if (to.name == ERoutesName.ARTICLE) {
        await interfaceStore.getArticle(String(to.params.id));
        interfaceStore.getBlogByTag(String(to.params.slug));
        document.querySelectorAll(".section").forEach((section: any) => {
            observer.observe(section);
        });
        next();
    } else if (to.name == ERoutesName.BPMN_ELEMENT) {
        await interfaceStore.getElement(String(to.params.slug));
        next();
    } else if (to.name == ERoutesName.CONTACT_SALES) {
        await interfaceStore.getContactUs();
        showFooter.value = true;
        next();
    } else next();
});

watch(isObserver, () => {
    if (isObserver.value) {
        document.querySelectorAll(".section").forEach((section: any) => {
            observer.observe(section);
        });
        interfaceStore.stopObserver();
    }
});
</script>
