import { useCommentStore, type IComment, CommentApi, type ICommentFile } from "@/entities/Comment";
import { FileApi } from "@/entities/File";
import { useLoading, useNotification } from "@/shared/lib/composables";
import { ref } from "vue";

const filesForDelete = ref<ICommentFile[]>([]);
const sourceComment = ref<IComment>();
const { isLoading, startLoading, finishLoading } = useLoading();

export function useCommentEdit() {
    const { showError } = useNotification();

    const commentStore = useCommentStore();

    function showEditForm(comment: IComment): void {
        commentStore.replaceComment({ ...comment, isEdit: true });
        filesForDelete.value = [];
        sourceComment.value = comment;
    }
    function hideEditForm(comment?: IComment): void {
        if (sourceComment.value) commentStore.replaceComment(comment || sourceComment.value);
        filesForDelete.value = [];
        sourceComment.value = undefined;
    }

    function appendFileToDeleteList(file: ICommentFile, comment: IComment): void {
        filesForDelete.value.push(file);
        const files: ICommentFile[] = comment.files.filter(item => item.id !== file.id);
        commentStore.replaceComment({ ...comment, files });
    }

    async function saveEditedComment(comment: IComment): Promise<void> {
        try {
            startLoading();

            const promises = filesForDelete.value.map(item => FileApi.deleteById(item.id));

            await Promise.all(promises);

            const editedComment = await CommentApi.editCommentById(comment.id, { text: comment.text });

            hideEditForm(editedComment);
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,

        saveEditedComment,

        showEditForm,
        hideEditForm,

        filesForDelete,
        appendFileToDeleteList,
    };
}
