<template>
    <WrapperBlockColumn>
        <div class="flex flex-column gap-6 align-items-center text-center" ref="blockTeamRef">
            <div class="flex flex-column gap-3">
                <div v-html="data?.item?.headline"></div>
                <div v-html="data?.item?.content"></div>
            </div>
            <div class="flex flex-wrap gap-5 justify-content-center">
                <UserTeam v-for="user of data?.item?.members" :user="user" />
            </div>
        </div>
    </WrapperBlockColumn>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { WrapperBlockColumn, UserTeam } from "@/shared/ui/marketing";
import type { IBlock } from "@/entities/Interface";

// defineProps<{ data: IBlock }>();
const props = withDefaults(defineProps<{ data: IBlock; article?: boolean }>(), { article: false });
const emit = defineEmits(["getHtml"]);
const blockTeamRef = ref();

watch(blockTeamRef, () => {
    if (props.article) {
        emit("getHtml", blockTeamRef.value);
    }
});
</script>

<style scoped></style>
