<template>
    <div class="flex gap-3">
        <div class="w-full flex flex-column gap-3">
            <div class="flex flex-column gap-3">
                <FormColumn title="ИНН или название юр.лица">
                    <Dropdown
                        id="company"
                        v-model="invoice.company"
                        :options="suggestions"
                        filter
                        optionLabel="value"
                        placeholder="Введите название юр.лица"
                        @filter="getCompany"
                        class="w-100rem"
                    >
                        <template #value="slotProps">
                            <div v-if="slotProps.value" class="flex align-items-center">
                                <div>{{ slotProps.value.value }}</div>
                            </div>
                            <span v-else>
                                {{ slotProps.placeholder }}
                            </span>
                        </template>
                        <template #option="slotProps">
                            <div class="flex flex-column gap-1">
                                <div>{{ slotProps.option.value }}</div>
                                <div class="text-sm text-400">ИНН: {{ slotProps.option.data.inn }}</div>
                            </div>
                        </template>
                    </Dropdown>
                </FormColumn>
                <FormColumn title="Количество участников">
                    <InputNumber
                        v-model="invoice.quantityParticipants"
                        v-tooltip="'Не может быть меньше текущего количества участников команды'"
                        :useGrouping="false"
                        :min="countUser"
                        id="quantityParticipants"
                        class="w-full"
                    />
                </FormColumn>
                <FormColumn title="Количество месяцев">
                    <InputNumber
                        v-model="invoice.quantityMonths"
                        inputId="withoutgrouping"
                        :useGrouping="false"
                        :min="0"
                        id="quantityMonths"
                        class="w-full"
                    />
                </FormColumn>
                <h3 class="text-center">
                    {{ payFor }}
                </h3>
                <div class="flex flex-column">
                    <DownloadInvoice class="mt-2" :invoice="invoice" />
                    <router-link v-if="disabledRequestContract" to="/contact-sales" target="_blank" rel="noopener">
                        <Button class="w-full mt-2" label="Запросить договор" severity="contrast" outlined />
                    </router-link>
                    <div v-else v-tooltip="'Заключаем договор от 10 пользователей на 1 год'">
                        <Button class="w-full mt-2" label="Запросить договор" severity="contrast" outlined disabled />
                    </div>
                </div>
            </div>
            <Message class="m-0" severity="info" :closable="false">
                <li class="pl-2">1 200₽ за пользователя в месяц</li>
                <li class="pl-2"><router-link to="/license">Оплата по оферте</router-link></li>
                <li class="pl-2">Заключаем договор >10 пользователей на год</li>
                <li class="pl-2">Закрывающие документы <b>только</b> по ЭДО</li>
                <li class="pl-2">Автоматическая активация после получения платежа</li>
            </Message>
        </div>
    </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from "vue";
import { type ICalcPricePayload } from "@/entities/Invoice";
import { DownloadInvoice } from "@/features/Invoice/download";
import type { IInvoice } from "@/entities/Invoice";
import { useInvoiceStore } from "@/entities/Invoice";
import { storeToRefs } from "pinia";
import { declensionNumerals, moneyFormat } from "@/shared/lib/utils/formater";
import { FormColumn } from "@/shared/ui/form";
import { EBillingInterval } from "@/shared/lib/types";

const invoiceStore = useInvoiceStore();
const { suggestions, price, countUser } = storeToRefs(invoiceStore);

const invoice = ref<IInvoice>({
    company: null,
    quantityParticipants: 20,
    quantityMonths: 12,
});
const disabledRequestContract = computed<boolean>(() => {
    return invoice.value.quantityParticipants >= 10 && invoice.value.quantityMonths >= 12;
});

const payFor = computed<string>(() => {
    return (
        moneyFormat(price.value) +
        "₽ за " +
        invoice.value.quantityParticipants +
        " " +
        declensionNumerals(invoice.value.quantityParticipants, ["пользователя", "пользователей", "пользователей"]) +
        " на " +
        invoice.value.quantityMonths +
        " " +
        declensionNumerals(invoice.value.quantityMonths, ["месяц", "месяца", "месяцев"])
    );
});

function calcPrice(): void {
    const payload: ICalcPricePayload = {
        billingInterval: EBillingInterval.MONTHLY,
        plan: "Team",
        membersCount: invoice.value.quantityParticipants,
        locale: "ru",
        monthCount: invoice.value.quantityMonths,
    };
    invoiceStore.calcPrice(payload);
}
function getCompany(filter: any) {
    invoiceStore.getCompany(filter.value);
}

watch(invoice.value, () => {
    calcPrice();
});

onMounted(() => {
    const payload: ICalcPricePayload = {
        billingInterval: EBillingInterval.MONTHLY,
        plan: "Team",
        membersCount: 1,
        locale: "ru",
        monthCount: 1,
    };
    invoiceStore.minCountUser(payload);
    calcPrice();
});
</script>

<style scoped></style>
