<template>
    <Menu ref="commentMenuRef" id="comment_item_menu" :model="commentMenuModel" :popup="true" />
</template>

<script setup lang="ts">
import { useCommentMenu } from "..";

const { commentMenuModel, commentMenuRef } = useCommentMenu();
</script>

<style scoped></style>
