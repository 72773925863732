import { ApprovalApi } from "@/entities/Approval";
import type { ICreateApproval } from "@/entities/Approval/model";
import { useLoading, useNotification } from "@/shared/lib/composables";

export function useCreateApproval() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();

    async function createApproval(data: ICreateApproval) {
        try {
            startLoading();

            await ApprovalApi.create(data);
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        createApproval,
    };
}
