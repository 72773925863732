import { ref, shallowRef } from "vue";
import type { IGalleriaItem } from "../../types";

const files = ref<IGalleriaItem[]>([]);
const visible = ref<boolean>(false);
const customIndex = ref<number>(0);

export function useGalleria() {
    const responsiveOptions = shallowRef([
        {
            breakpoint: "1500px",
            numVisible: 5,
        },
        {
            breakpoint: "1024px",
            numVisible: 3,
        },
        {
            breakpoint: "768px",
            numVisible: 2,
        },
        {
            breakpoint: "560px",
            numVisible: 1,
        },
    ]);

    function toggleGalleriaVisible(): void {
        if (files.value.length > 0) {
            visible.value = !visible.value;
        }
    }
    function setGalleriaCustomIndex(index: number): void {
        customIndex.value = index;
    }
    function setGalleriaFiles(data: IGalleriaItem[]): void {
        files.value = data.filter(item => item.preview);
    }

    return {
        files,
        visible,
        responsiveOptions,
        customIndex,

        toggleGalleriaVisible,
        setGalleriaCustomIndex,
        setGalleriaFiles,
    };
}
