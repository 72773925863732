import { ref } from "vue";
import { defineStore } from "pinia";

import { useLoading, useNotification } from "@/shared/lib/composables";

import { useBpmnModelerOverlayStore } from "@/entities/BpmnModeler";

import type { IComment, ICommentIndicator } from "..";
import { CommentApi } from "../..";

export const useCommentStore = defineStore("comment", () => {
    const { isLoading: isLoadingGet, startLoading: startLoadingGet, finishLoading: finishLoadingGet } = useLoading();
    const { showError } = useNotification();

    const bpmnOverlayStore = useBpmnModelerOverlayStore();

    const commentIndicators = ref<ICommentIndicator[]>([]);

    const comments = ref<IComment[]>([]);
    function addComment(comment: IComment): void {
        comments.value.push(comment);
    }

    const commentsScrollRef = ref<HTMLElement>();
    const commentsScrollRightSidebarRef = ref<HTMLElement>();

    function addCommentIndicator(data: ICommentIndicator): void {
        const { elementId, count, unreadCount } = data;
        if (count > 0 || unreadCount > 0) {
            commentIndicators.value.push({
                elementId: elementId,
                count: count,
                unreadCount: unreadCount,
            });
        }
    }
    function changeCommentIndicator(data: ICommentIndicator): void {
        const { elementId, count, unreadCount } = data;

        const index: number = commentIndicators.value.findIndex(item => item.elementId === elementId);
        if (index >= 0) {
            commentIndicators.value[index].unreadCount += unreadCount;
            commentIndicators.value[index].count += count;

            if (commentIndicators.value[index].count === 0 && commentIndicators.value[index].unreadCount === 0) {
                commentIndicators.value.splice(index, 1);
            }
        } else {
            addCommentIndicator(data);
        }
    }
    function replaceCommentIndicator(data: ICommentIndicator): void {
        const index: number = commentIndicators.value.findIndex(item => item.elementId === data.elementId);
        if (index >= 0) {
            if (data.count > 0 || data.unreadCount > 0) {
                commentIndicators.value[index].count = data.count;
                commentIndicators.value[index].unreadCount = data.unreadCount;
            } else {
                commentIndicators.value.splice(index, 1);
            }
        } else {
            addCommentIndicator(data);
        }
    }
    async function getCountByDiagram(diagramId: string) {
        try {
            commentIndicators.value = await CommentApi.getCountByDiagram(diagramId);
        } catch (e) {
            showError(e);
        }
    }

    async function getCommentsBy(diagramId: string, elementIds?: string[]): Promise<void> {
        try {
            if (isLoadingGet.value) return;

            startLoadingGet();

            if (elementIds) {
                const promises = elementIds.map(item => CommentApi.getByElementId(diagramId, item));
                const resultComments: IComment[][] = await Promise.all(promises);

                comments.value = resultComments.flat();

                for (const elementId of elementIds) {
                    const countData = getCountComments(elementId);
                    replaceCommentIndicator({ elementId, ...countData });
                    bpmnOverlayStore.chnageDataCommentByElement({ elementId, ...countData });
                }

                setTimeout(() => {
                    readComments(JSON.parse(JSON.stringify(comments.value)));
                }, 2000);
            } else {
                comments.value = await CommentApi.getByDiagramId(diagramId);
            }
        } catch (e) {
            comments.value = [];
            showError(e);
        } finally {
            finishLoadingGet();
        }
    }

    async function readComments(comments: IComment[]): Promise<void> {
        try {
            if (comments.length === 0) return;

            const ids: string[] = [];

            for (const comment of comments) {
                if (!comment.author.you && !comment.readed) {
                    ids.push(comment.id);
                }
            }

            if (ids.length === 0) return;

            await CommentApi.makeRead(ids);

            const elementIds = new Set<string>();

            for (const comment of comments) {
                if (!comment.author.you && !comment.readed) {
                    replaceComment({ ...comment, readed: true });
                    comment.readed = true;
                }

                if (comment.elementId) elementIds.add(comment.elementId);
            }

            elementIds.forEach(elementId => {
                const commentsData = getCountComments(elementId, comments);
                replaceCommentIndicator({ elementId, ...commentsData });
                bpmnOverlayStore.chnageDataCommentByElement({ elementId, ...commentsData });
            });
        } catch (e) {
            showError(e);
        }
    }

    function removeById(commentId: string) {
        if (comments.value.length) {
            const index = comments.value.findIndex(item => item.id === commentId);
            if (index >= 0) {
                const elementId = comments.value[index].elementId;
                if (elementId) {
                    changeCommentIndicator({ elementId, count: -1, unreadCount: 0 });
                }
                comments.value.splice(index, 1);
            }
        }
    }
    function replaceComment(comment: IComment) {
        const index = comments.value.findIndex(item => item.id === comment.id);
        if (index >= 0) {
            comments.value.splice(index, 1, comment);
        }
    }

    function getCountComments(elementId: string, arrComments?: IComment[]): { count: number; unreadCount: number } {
        let count = 0;
        let unreadCount = 0;

        const commentsArr = arrComments ?? comments.value;

        for (const comment of commentsArr) {
            const isElement = comment.elementId === elementId;

            if (isElement) {
                if (!comment.readed) unreadCount++;
                count++;
            }
        }
        return {
            count,
            unreadCount,
        };
    }

    return {
        isLoadingGet,
        commentIndicators,

        commentsScrollRef,
        commentsScrollRightSidebarRef,

        comments,
        addComment,
        getCommentsBy,
        getCountComments,

        getCountByDiagram,
        changeCommentIndicator,

        replaceCommentIndicator,

        removeById,
        replaceComment,
    };
});
