import { useLoading, useNotification } from "@/shared/lib/composables";

import { CommentApi, useCommentStore, type IComment } from "@/entities/Comment";

export function useToggleImportant() {
    const { isLoading, startLoading, finishLoading } = useLoading();
    const { showError } = useNotification();

    const commentStore = useCommentStore();

    async function toggleImportant(commentId: string) {
        try {
            startLoading();

            const comment: IComment = await CommentApi.toggleImportantById(commentId);
            commentStore.replaceComment(comment);
        } catch (e) {
            showError(e);
        } finally {
            finishLoading();
        }
    }

    return {
        isLoading,
        toggleImportant,
    };
}
