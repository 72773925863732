import { computed, ref, watch } from "vue";
import { defineStore, storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { ID_FOLDER_DEFAULT } from "@/shared/config";
import { useNotification, useLoading } from "@/shared/lib/composables";

import {
    EProcessSecure,
    EProcessStatus,
    EProcessStatusName,
    type IDiagram,
    type IOnePorcessResponse,
    type IProcess,
    type IProcessQuery,
    type IProcessResponse,
    type ISearchProcessQuery,
    type ISearchProcessResponse,
} from "..";
import { ProcessApi } from "../../api";
import type { TProcessStatus } from "../../lib";

import { useSessionStore } from "@/entities/Session";

export const useProcessStore = defineStore("process", () => {
    const { showError } = useNotification();
    const { isLoading: loading, startLoading, finishLoading } = useLoading();
    const route = useRoute();

    const sessionStore = useSessionStore();
    const { isTeamPlan } = storeToRefs(sessionStore);

    const diagram = ref<IDiagram>();
    const diagramPermissionByMe = ref<EProcessSecure>();
    const diagramAccessAllowed = ref(true);

    const processes = ref<IProcess[]>([]);
    const activeProcess = ref<IProcess>();
    const selectedProcesses = ref<IProcess[]>([]);

    const hasProcesses = computed(() => processes.value.length > 0);
    const countSelectedProcesses = computed(() => selectedProcesses.value.length);
    const hasSelectedProcesses = computed(() => countSelectedProcesses.value > 0);

    const apiKey = computed(() => route.query.apiKey as string);

    const processStatuses = computed(() =>
        Object.keys(EProcessStatus).map(item => {
            const key = item as TProcessStatus;

            const label = EProcessStatusName[key];
            const value = EProcessStatus[key];

            return {
                label,
                value,
            };
        }),
    );

    function setActiveProcess(process: IProcess) {
        activeProcess.value = process;
    }
    function clearSelectedProcesses() {
        selectedProcesses.value = [];
    }
    function resetDiagram() {
        diagram.value = undefined;
        diagramPermissionByMe.value = EProcessSecure.EDIT;
    }
    function localeDeleteByIds(ids: string[]) {
        processes.value = processes.value.filter(item => !ids.includes(item.id));
    }
    function localeChangePublicByIds(ids: string[]) {
        for (let process of processes.value) {
            if (ids.includes(process.id)) {
                process.public = !process.public;
            }
        }
    }
    function setStatusById(id: string, status: EProcessStatus) {
        const finded = processes.value.find(item => item.id === id);
        if (finded) {
            finded.status = status;
        }
        if (diagram.value) {
            diagram.value.status = status;
        }
    }

    async function fetchById(id: string) {
        try {
            startLoading();
            const data: IOnePorcessResponse = await ProcessApi.fetchById(id);
            diagram.value = data.diagram;
            diagramPermissionByMe.value = data.permission;

            diagramAccessAllowed.value = true;
        } catch (e: any) {
            if (e.status === 403) {
                diagramAccessAllowed.value = false;
            } else {
                showError(e?.message || e);
            }
        } finally {
            finishLoading();
        }
    }
    async function fetchByMe(page: number = 0): Promise<void> {
        try {
            startLoading();

            processes.value = [];

            const query: IProcessQuery = {
                team: false,
                directAccess: false,
                enterpriseAccess: false,
                page,
            };

            const data: IProcessResponse = await ProcessApi.fetchAll(query);
            processes.value = data.returnDiagrams;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchBySharedMe(page: number = 0): Promise<void> {
        try {
            startLoading();
            processes.value = [];

            const query: IProcessQuery = {
                team: false,
                directAccess: true,
                enterpriseAccess: false,
                page,
            };

            const data: IProcessResponse = await ProcessApi.fetchAll(query);
            processes.value = data.returnDiagrams;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchByTeam(page: number = 0): Promise<void> {
        try {
            if (!isTeamPlan.value) return;

            startLoading();
            processes.value = [];

            const query: IProcessQuery = {
                team: true,
                directAccess: false,
                enterpriseAccess: false,
                page,
            };

            const data: IProcessResponse = await ProcessApi.fetchAll(query);
            processes.value = data.returnDiagrams;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchByFolder(page: number = 0, folderId: number | undefined): Promise<void> {
        try {
            if (!isTeamPlan.value) return;

            startLoading();
            processes.value = [];

            const query: IProcessQuery = {
                team: true,
                page,
                apiKey: apiKey.value,
            };
            folderId && folderId !== ID_FOLDER_DEFAULT ? (query.folderId = folderId) : (query.emptyFolder = true);

            const data: IProcessResponse = await ProcessApi.fetchAll(query);
            processes.value = data.returnDiagrams || [];
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }
    async function fetchUnpublic(): Promise<void> {
        try {
            startLoading();
            processes.value = [];
            processes.value = await ProcessApi.fetchUnpublic();
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    async function search(query: ISearchProcessQuery): Promise<void> {
        try {
            startLoading();
            processes.value = [];

            const data: ISearchProcessResponse = await ProcessApi.search(query);
            processes.value = data.content;
        } catch (e: any) {
            showError(e?.message || e);
        } finally {
            finishLoading();
        }
    }

    watch(route, () => {
        clearSelectedProcesses();
    });

    return {
        loading,
        diagram,
        diagramPermissionByMe,
        processes,
        hasProcesses,
        activeProcess,
        selectedProcesses,
        countSelectedProcesses,
        hasSelectedProcesses,
        processStatuses,
        diagramAccessAllowed,
        setStatusById,
        fetchByMe,
        fetchBySharedMe,
        fetchByTeam,
        search,
        setActiveProcess,
        fetchByFolder,
        clearSelectedProcesses,
        localeDeleteByIds,
        localeChangePublicByIds,
        fetchById,
        fetchUnpublic,
        resetDiagram,
    };
});
