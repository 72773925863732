import { httpClient } from "@/shared/api";
import { AxiosResponse } from "axios";
import type { IPlan, IUpgradePrice, IPaymentUpgrade, IPayloadUpgradePrice } from "../model";

export class PaymentApi {
    static async getCurrentPlan(): Promise<IPlan> {
        const response: AxiosResponse = await httpClient.get("/payment/currentPlan");
        return response.data;
    }
    static async getCurrentSubscription(): Promise<IPlan> {
        const response: AxiosResponse = await httpClient.get("/payment/currentSubscription");
        return response.data;
    }
    static async getInvoices(): Promise<IPlan[]> {
        const response: AxiosResponse = await httpClient.get("/payment/invoices");
        return response.data;
    }
    static async calculateUpgradePrice(payload: IPayloadUpgradePrice): Promise<IUpgradePrice> {
        const response: AxiosResponse = await httpClient.post("/payment/calculate", payload);
        return response.data;
    }
    static async paymentUpgrade(payload: IPayloadUpgradePrice): Promise<IPaymentUpgrade> {
        const response: AxiosResponse = await httpClient.post("/payment", payload);
        return response.data;
    }
    static async cancelSubscription(selectedCancelOptionts: string): Promise<void> {
        await httpClient.post("/payment/cancelSubscription/" + selectedCancelOptionts);
    }
}
