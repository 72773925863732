import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";

import { useBpmnModelerStore } from "@/entities/BpmnModeler";

const disabledUndo = ref<boolean>(false);
const disabledRedo = ref<boolean>(false);

export function useCommandStack() {
    const modelerStore = useBpmnModelerStore();
    const { modeler } = storeToRefs(modelerStore);

    const modelerCommandStack = computed(() => (modeler.value ? modeler.value.get("commandStack") : null));

    function checkState(): void {
        disabledRedo.value = !modelerCommandStack.value.canRedo();
        disabledUndo.value = !modelerCommandStack.value.canUndo();
    }

    function undo(): void {
        modelerCommandStack.value.undo();

        checkState();
    }

    function redo(): void {
        modelerCommandStack.value.redo();

        checkState();
    }

    watch(modelerCommandStack, value => {
        if (value) checkState();
    });

    return {
        disabledUndo,
        disabledRedo,
        checkState,

        undo,
        redo,
    };
}
