<template>
    <div class="flex align-items-center gap-2">
        <span class="text-color-secondary">{{ $t("described-by") }}</span>
        <router-link to="/" target="_blank" class="flex align-items-center gap-1 text-primary no-underline hover:underline">
            <img src="@/shared/assets/images/svg/favicon.svg" style="height: 15px; width: 16px" /> Stormbpmn
        </router-link>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
