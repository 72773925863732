<template>
    <div class="relative text-500">
        <InputIcon class="pi pi-search absolute icon__search" />
        <InputText
            ref="inputRef"
            v-model="inputValue"
            :placeholder="placeholder"
            class="w-full px-6 max-h-full"
            @focusin="showHotKey = false"
            @focusout="showHotKey = true"
            @input="updateValue"
        />
        <InputIcon v-if="inputValue && inputValue.length > 0" class="pi pi-times cursor-pointer absolute icon__del" @click="inputReset" />
        <Shortcut v-if="isShowShortcut && showShortcut" class="absolute icon__del">CTRL+K</Shortcut>
    </div>
</template>

<script setup lang="ts">
import { onMounted, onUnmounted, ref, computed } from "vue";
import InputIcon from "primevue/inputicon";
import { KeyboardManager, type IKeyHandler } from "@/shared/lib/browser";
import { Shortcut } from "../shortcut";

withDefaults(defineProps<{ placeholder: string; isShowShortcut?: boolean }>(), { isShowShortcut: false });
defineExpose({ inputReset });
const emits = defineEmits(["search", "clearSearch"]);
const inputValue = ref<string>("");
const keyboardManager = new KeyboardManager();
const showHotKey = ref<boolean>(true);
const inputRef = ref();

function inputReset() {
    inputValue.value = "";
    updateValue();
    emits("clearSearch");
}

const showShortcut = computed(() => {
    if (showHotKey.value) {
        if (inputValue.value && inputValue.value.length > 0) {
            return false;
        } else return true;
    } else return false;
});

function updateValue() {
    emits("search", inputValue.value);
}

onMounted(() => {
    keyboardManager.mount();
    function callbackKeyManager() {
        inputRef.value.$el.focus();
        return true;
    }
    keyboardManager.addKeyHandler("KeyK", callbackKeyManager, false, true, false);
});
onUnmounted(() => {
    keyboardManager.unmount();
});
</script>

<style scoped lang="scss">
.icon {
    &__search {
        top: 50%;
        translate: 0px -50%;
        left: 1rem;
    }
    &__del {
        top: 50%;
        translate: 0px -50%;
        right: 1rem;
    }
}
</style>
