<template>
    <div class="flex flex-column border-round border-1 border-300 hover:border-primary overflow-hidden">
        <Skeleton class="w-full border-noround" height="12rem" />
        <div class="flex flex-column gap-4 p-4">
            <div class="flex flex-column gap-2 align-items-start">
                <Skeleton width="5rem" height="1.3rem" />
                <div class="w-full flex gap-3 align-items-center justify-content-between">
                    <Skeleton width="12rem" height="1.4rem" />
                    <Skeleton width="3.4rem" height="2.3rem" />
                </div>
            </div>
            <div class="flex flex-column gap-3 align-items-start">
                <div class="flex gap-2">
                    <Skeleton width="2rem" height="1rem" />
                    <Skeleton width="6rem" height="1rem" />
                </div>
                <div class="flex gap-2">
                    <Skeleton width="2rem" height="1rem" />
                    <Skeleton width="7rem" height="1rem" />
                </div>
                <div class="flex gap-2">
                    <Skeleton width="2rem" height="1rem" />
                    <Skeleton width="3rem" height="1rem" />
                </div>
            </div>
        </div>
        <div class="p-4 mt-5">
            <Divider />
            <Skeleton width="100%" height="2.5rem" />
        </div>
    </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
